/* tslint:disable */
/* eslint-disable */
/**
 * Unyte SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { JoinedDao } from './JoinedDao';
import {
    JoinedDaoFromJSON,
    JoinedDaoFromJSONTyped,
    JoinedDaoToJSON,
} from './JoinedDao';

/**
 * 
 * @export
 * @interface JoinedDaosDto
 */
export interface JoinedDaosDto {
    /**
     * 
     * @type {Array<JoinedDao>}
     * @memberof JoinedDaosDto
     */
    daos: Array<JoinedDao>;
}

/**
 * Check if a given object implements the JoinedDaosDto interface.
 */
export function instanceOfJoinedDaosDto(value: object): boolean {
    if (!('daos' in value)) return false;
    return true;
}

export function JoinedDaosDtoFromJSON(json: any): JoinedDaosDto {
    return JoinedDaosDtoFromJSONTyped(json, false);
}

export function JoinedDaosDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): JoinedDaosDto {
    if (json == null) {
        return json;
    }
    return {
        
        'daos': ((json['daos'] as Array<any>).map(JoinedDaoFromJSON)),
    };
}

export function JoinedDaosDtoToJSON(value?: JoinedDaosDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'daos': ((value['daos'] as Array<any>).map(JoinedDaoToJSON)),
    };
}

