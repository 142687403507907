import React, { Fragment, useEffect, useState } from "react";
import {
  Dialog,
  Disclosure,
  Menu,
  Popover,
  Transition,
} from "@headlessui/react";
import { XMarkIcon, FunnelIcon } from "@heroicons/react/24/outline";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

const classNames = (...classes) => {
  return classes.filter(Boolean).join(" ");
};

export type Filter = {
  id: string;
  name: string;
  options: { value: any; label: string; checked: boolean }[];
};

type Props = {
  filters: Filter[];
  setFilters: React.Dispatch<React.SetStateAction<Filter[]>>;
};

export const Category = ({ filters, setFilters }: Props) => {
  const [open, setOpen] = useState(false);

  const handleChecked = (
    filterIdx: number,
    optionIdx: number,
    checked: boolean
  ): void => {
    setFilters((prev) => {
      prev[filterIdx].options[optionIdx].checked = checked;
      return [...prev];
    });
  };

  return (
    <div className="flex h-12 items-center bg-[#121217]">
      {/* Mobile filter dialog */}
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-30 sm:hidden" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-[#121217] bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <Dialog.Panel className="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-[#121217] py-4 pb-6 shadow-xl">
                <div className="flex items-center justify-between px-4">
                  <h2 className="text-lg font-medium text-gray-100">Filters</h2>
                  <button
                    type="button"
                    className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-[#121217] p-2 text-gray-400 hover:bg-[#121217]/50 focus:outline-none"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon
                      className="h-6 w-6 text-gray-50"
                      aria-hidden="true"
                    />
                  </button>
                </div>

                {/* Filters */}
                <form className="mt-4">
                  {filters.map((filter, filterIdx) => (
                    <Disclosure
                      as="div"
                      key={filter.name}
                      className="border-t bg-[#121217] px-4 py-6"
                    >
                      {({ open }) => (
                        <>
                          <h3 className="-mx-2 -my-3 flow-root">
                            <Disclosure.Button className="flex w-full items-center justify-between bg-[#121217] px-2 py-3 text-sm text-gray-400">
                              <span className="font-medium text-gray-100">
                                {filter.name}
                              </span>
                              <span className="ml-6 flex items-center">
                                <ChevronDownIcon
                                  className={classNames(
                                    open ? "-rotate-180" : "rotate-0",
                                    "h-5 w-5 transform"
                                  )}
                                  aria-hidden="true"
                                />
                              </span>
                            </Disclosure.Button>
                          </h3>
                          <Disclosure.Panel className="pt-6">
                            <div className="space-y-6">
                              {filter.options.map((option, optionIdx) => (
                                <div
                                  key={option.value}
                                  className="flex items-center"
                                >
                                  <input
                                    id={`filter-mobile-${filter.id}-${optionIdx}`}
                                    name={`${filter.id}[]`}
                                    type="checkbox"
                                    className="h-4 w-4 rounded bg-[#121217] text-indigo-600 focus:ring-indigo-500"
                                    onChange={(event) =>
                                      handleChecked(
                                        filterIdx,
                                        optionIdx,
                                        event.target.checked
                                      )
                                    }
                                    defaultChecked={option.checked}
                                    defaultValue={option.value}
                                  />
                                  <label
                                    htmlFor={`filter-mobile-${filter.id}-${optionIdx}`}
                                    className="ml-3 text-sm text-gray-50"
                                  >
                                    {option.label}
                                  </label>
                                </div>
                              ))}
                            </div>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  ))}
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <div className="flex h-9 items-center justify-center rounded-full border border-gray-600 bg-[#131218] px-3 text-center">
        <section aria-labelledby="filter-heading">
          <h2 id="filter-heading" className="sr-only">
            Product filters
          </h2>

          <div className="flex items-center justify-between">
            <Menu as="div" className="relative inline-block text-left">
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              ></Transition>
            </Menu>

            <button
              type="button"
              className="inline-block w-3 text-sm font-medium text-gray-50 outline-none hover:text-gray-300 sm:hidden"
              onClick={() => setOpen(true)}
            >
              <FunnelIcon className="h-4 w-4 text-gray-50" />
            </button>

            <Popover.Group className="hidden sm:flex sm:items-baseline sm:space-x-8">
              {filters.map((filter, filterIdx) => (
                <Popover
                  as="div"
                  key={filter.name}
                  id={`desktop-menu-${filterIdx}`}
                  className="relative inline-block items-center text-left"
                >
                  <div>
                    <Popover.Button className="group inline-flex items-center justify-center text-sm font-medium text-gray-50 hover:text-gray-300">
                      <span>{filter.name}</span>
                      <ChevronDownIcon
                        className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-50 group-hover:text-gray-300"
                        aria-hidden="true"
                      />
                    </Popover.Button>
                  </div>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Popover.Panel className="absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-[#121217] p-4 shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <form className="space-y-4">
                        {filter.options.map((option, optionIdx) => (
                          <div key={option.value} className="flex items-center">
                            <input
                              id={`filter-${filter.id}-${optionIdx}`}
                              name={`${filter.id}[]`}
                              onChange={(event) =>
                                handleChecked(
                                  filterIdx,
                                  optionIdx,
                                  event.target.checked
                                )
                              }
                              defaultValue={option.value}
                              defaultChecked={option.checked}
                              type="checkbox"
                              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                            />
                            <label
                              htmlFor={`filter-${filter.id}-${optionIdx}`}
                              className="ml-3 whitespace-nowrap pr-6 text-sm font-medium text-gray-100"
                            >
                              {option.label}
                            </label>
                          </div>
                        ))}
                      </form>
                    </Popover.Panel>
                  </Transition>
                </Popover>
              ))}
            </Popover.Group>
          </div>
        </section>
      </div>
    </div>
  );
};
