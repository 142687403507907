import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { ImCamera } from "react-icons/im";
import { BiPencil } from "react-icons/bi";
import UniSwaTextField from "../atoms/UniSwaTextField.tsx";
import { useLocation } from "react-router-dom";
import { uploadFile } from "../../actions/firebase/imageUpload.ts";
import { useUser } from "src/hooks/useUser.ts";
import { resizeImageToMaxSize } from "src/utils/image.ts";

interface RegisterUser {
  uid: string;
  name: string;
  email: string;
  address: string;
}
interface Props {
  userInfo: RegisterUser;
  setUser: React.Dispatch<React.SetStateAction<RegisterUser>>;
  profileImage: any;
  setProfileImage: React.Dispatch<React.SetStateAction<any>>;
  setUploadedFileImageUrl: React.Dispatch<React.SetStateAction<string>>;
  isLocked: boolean;
  setStep: React.Dispatch<React.SetStateAction<number>>;
}

const UserRegister: React.FC<Props> = ({
  userInfo,
  setUser,
  profileImage,
  setUploadedFileImageUrl,
  setProfileImage,
  isLocked,
  setStep,
}) => {
  const location = useLocation();
  const [iconFile, setIconFile] = useState<File | null>(null);
  const [authCheck, setAuthCheck] = useState<boolean>(false);
  const { user } = useUser();

  const setUrl = async () => {
    try {
      const url = await handleUpload();
      if (!url) return;
      setUploadedFileImageUrl(url);
    } catch (error) {
      console.error(error);
      alert("ユーザーの登録に失敗しました。");
    }
  };

  const handleUpload = async () => {
    // fileをpng, jpg, jpeg, webpのみ許可
    if (!iconFile) {
      console.error("iconFile is not found");
      return;
    }
    try {
      let uploadingFile = iconFile;
      if (iconFile.size > 5 * 1024 * 1024) {
        uploadingFile = await resizeImageToMaxSize(iconFile, 5);
      }
      const result = await uploadFile(uploadingFile, userInfo.uid);
      return result;
    } catch (error) {
      console.error(error);
      alert("画像のアップロードに失敗しました。");
      return null;
    }
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setProfileImage(event.target?.result);
        setIconFile(file);
        if (!reader.result) return;
        const base64 = (reader.result as string).split(",")[1];
        console.log(base64);
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    if (!authCheck) return;
    const getAuthAccount = async () => {
      if (!user) return;
      if (!user.twitterUserName) return;
      alert("認証に成功しました。");
    };
    getAuthAccount();
  }, [authCheck]);
  return (
    <div className="h-scren mx-auto mt-10 flex w-full flex-col lg:mt-28 lg:w-2/3 lg:flex-row">
      <div className="mx-auto w-full text-white lg:w-1/4">
        <div className="mb-2 hidden text-center text-sm lg:block">
          <FormattedMessage id="icon_image" defaultMessage="アイコン画像" />
        </div>
        <label
          htmlFor="profileImage"
          className="relative m-auto flex h-20 w-20 items-center justify-center rounded-full bg-[#38373D] hover:cursor-pointer lg:h-40 lg:w-40"
        >
          <input
            type="file"
            className="hidden bg-gray-50"
            id="profileImage"
            onChange={handleImageChange}
          />
          <div className="absolute bottom-[6px] right-[4px] flex h-4 w-4 items-center justify-center rounded-full bg-white lg:hidden">
            <BiPencil className="text-xs text-[#29282E]" />
          </div>
          {!profileImage ? (
            <div className="flex flex-col">
              <ImCamera className="mx-auto text-center text-xl text-[#29282E] lg:mb-2 lg:text-5xl" />
              <div className="text-center text-sm text-[#8B8B8C] hover:cursor-pointer">
                <div className="hidden lg:block">
                  <FormattedMessage
                    id="select_file"
                    defaultMessage="ファイルを"
                  />
                </div>
                <div className="hidden lg:block">
                  <FormattedMessage
                    id="select_file2"
                    defaultMessage="選択する"
                  />
                </div>
              </div>
            </div>
          ) : (
            <img
              src={profileImage}
              alt="Profile"
              className="h-20 w-20 cursor-pointer rounded-full lg:h-40 lg:w-40"
            />
          )}
        </label>
      </div>
      <div className="w-full lg:w-3/4">
        <div className="mx-auto w-11/12 text-sm lg:w-4/5">
          <div className="mb-4 text-white">
            <div className="flex justify-between">
              <div className="mb-2 ml-1 lg:ml-6">
                <FormattedMessage
                  id="user_register_name"
                  defaultMessage="ユーザー名"
                />
              </div>
              <p className="mb-2 mr-2">{userInfo.name.length} / 50</p>
            </div>
            <UniSwaTextField
              value={userInfo.name}
              placeholder={userInfo.address}
              maxLength={50}
              isCreateDao={true}
              onChange={(e) => setUser({ ...userInfo, name: e.target.value })}
            />
          </div>
        </div>
        <div className="mx-auto w-11/12 text-sm lg:w-4/5">
          <div className="mb-4 text-white">
            <div className="mb-2 ml-1 lg:ml-6">
              <FormattedMessage
                id="user_register_email"
                defaultMessage="メールアドレス"
              />
            </div>
            <UniSwaTextField
              value={userInfo.email}
              isCreateDao={true}
              disabled={isLocked}
              onChange={(e) => setUser({ ...userInfo, email: e.target.value })}
            />
          </div>
        </div>
        <div className="mt-12 flex w-full justify-center">
          <button
            className="mx-auto w-11/12 rounded-full bg-unyte py-2 text-lg text-white lg:w-32"
            onClick={() => {
              setUrl();
              setStep(4);
            }}
          >
            <FormattedMessage id="user_register_button" defaultMessage="登録" />
          </button>
        </div>
      </div>
    </div>
  );
};
export default UserRegister;
