import React from "react";

const ProgressBar = ({ step, width = 4 }) => {
  const progressWidth = (step / width) * 100;

  return (
    <div className="relative mt-1 h-4 w-10/12 rounded-3xl bg-[#38373D]">
      <div
        style={{ width: `${progressWidth}%` }}
        className="absolute top-0 h-full rounded-3xl bg-unyte-bg-img transition-all duration-500"
      ></div>
    </div>
  );
};

export default ProgressBar;
