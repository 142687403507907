import React from "react";
import { BaseProps } from "../../types/common";
import userIcon from "../../assets/userIcon.svg";

export type UserIconProps = {
  img: string | undefined;
  alt: string;
} & BaseProps;

export const UserIcon: React.FC<UserIconProps> = ({ className, img, alt }) => {
  return (
    <img
      className={`${className} h-5 w-5 rounded-full bg-[#121217]`}
      src={img ? img : userIcon}
      alt={alt}
      onError={(e) => {
        (e.target as HTMLImageElement).onerror = null;
        (e.target as HTMLImageElement).src = userIcon;
      }}
    />
  );
};
