import React from "react";
import { BaseProps } from "../../types/common/index.ts";

export type ApprovalStatusBadgeProps = {
  status: "approved" | "pending" | "rejected";
} & BaseProps;

export const ApprovalStatusBadge: React.FC<ApprovalStatusBadgeProps> = ({
  status,
  className,
}) => {
  switch (status) {
    case "approved":
      return (
        <div
          className={`${className} min-w-[68px] rounded-md bg-[#29282E] py-0.5 text-center text-xs font-semibold`}
        >
          承認済
        </div>
      );
    case "pending":
      return (
        <div
          className={`${className} min-w-[68px] rounded-md bg-white py-0.5 text-center text-xs font-semibold text-[#121217]`}
        >
          未承認
        </div>
      );
    case "rejected":
      return (
        <div
          className={`${className} min-w-[68px] rounded-md bg-[#573030] py-0.5 text-center text-xs font-semibold`}
        >
          棄却済
        </div>
      );
    default:
      console.error("Invalid approval status");
      return <div>Invalid approval status</div>;
  }
};
