/* tslint:disable */
/* eslint-disable */
/**
 * Unyte SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserDto
 */
export interface UserDto {
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    img?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    shareId: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    twitter?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    twitterUserName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    uid: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof UserDto
     */
    share: Array<number>;
}

/**
 * Check if a given object implements the UserDto interface.
 */
export function instanceOfUserDto(value: object): boolean {
    if (!('address' in value)) return false;
    if (!('email' in value)) return false;
    if (!('name' in value)) return false;
    if (!('shareId' in value)) return false;
    if (!('uid' in value)) return false;
    if (!('share' in value)) return false;
    return true;
}

export function UserDtoFromJSON(json: any): UserDto {
    return UserDtoFromJSONTyped(json, false);
}

export function UserDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserDto {
    if (json == null) {
        return json;
    }
    return {
        
        'address': json['address'],
        'email': json['email'],
        'img': json['img'] == null ? undefined : json['img'],
        'name': json['name'],
        'shareId': json['shareId'],
        'twitter': json['twitter'] == null ? undefined : json['twitter'],
        'twitterUserName': json['twitterUserName'] == null ? undefined : json['twitterUserName'],
        'uid': json['uid'],
        'share': json['share'],
    };
}

export function UserDtoToJSON(value?: UserDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'address': value['address'],
        'email': value['email'],
        'img': value['img'],
        'name': value['name'],
        'shareId': value['shareId'],
        'twitter': value['twitter'],
        'twitterUserName': value['twitterUserName'],
        'uid': value['uid'],
        'share': value['share'],
    };
}

