// n人に秘密を分散し、k人から復元する場合のshamir関数。ランダムな値を生成する関数も含む。
export function shamirSecretSharing(s, n, k) {
  // 秘密sは文字列で与えられるため、バイト列に変換する
  s = new TextEncoder().encode(s);
  // バイト列を10進数に変換
  function byteArrayToInt(byteArray) {
    var value = 0;
    for (var i = byteArray.length - 1; i >= 0; i--) {
      value = value * 256 + byteArray[i];
    }
    return value;
  }
  s = byteArrayToInt(s);

  // シェアを格納する配列
  let shares = [];
  // ランダムなaの値を生成
  let a = Math.floor(Math.random() * 100);
  // xの値は1からnまで
  for (let x = 1; x <= n; x++) {
    // f(x) = s + a * x を計算してシェアに追加
    let share = s + a * x;
    shares.push([x, share]);
  }
  // シェアの配列を返す
  return shares;
}

// シェアを引数に取り、秘密情報を復元する関数
export function shamirSecretRecovery(shares) {
  // シェアの数を取得
  let n = shares.length;
  // xとyの値を格納する配列
  let x = [];
  let y = [];
  // シェアの数だけループ
  for (let i = 0; i < n; i++) {
    // シェアからxとyの値を取り出す
    x[i] = shares[i][0];
    y[i] = shares[i][1];
  }
  // ラグランジュ補間法で多項式を求める
  let a = [];
  for (let i = 0; i < n; i++) {
    let numerator = 1;
    let denominator = 1;
    for (let j = 0; j < n; j++) {
      if (i !== j) {
        numerator *= -x[j];
        denominator *= x[i] - x[j];
      }
    }
    a[i] = (y[i] * numerator) / denominator;
  }
  let s = 0;
  for (let i = 0; i < n; i++) {
    s += a[i];
  }
  // 多項式の定数項が秘密情報になる
  // sは10進数であるため、バイト列に変換する
  function intToByteArray(value) {
    var byteArray = [0, 0, 0, 0];
    for (var index = 0; index < byteArray.length; index++) {
      var byte = value & 0xff;
      byteArray[index] = byte;
      value = (value - byte) / 256;
    }
    return byteArray;
  }
  s = intToByteArray(s);
  // uint8Arrayに変換する
  s = new Uint8Array(s);
  // バイト列を文字列に変換する
  s = new TextDecoder().decode(s);
  // console.log(s);
  return s;
}

export function splitStringIntoArray(str) {
  const array = [];
  const chunkSize = 4;

  for (let i = 0; i < str.length; i += chunkSize) {
    const chunk = str.slice(i, i + chunkSize);
    array.push(chunk);
  }

  return array;
}
