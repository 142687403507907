import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "src/hooks/useUser";
import {
  isBase64ForUnyte,
  decodeBase64ForUnyte,
  isValidShare,
  isValidPrivateKey,
} from "src/actions/shamir/utils";
import { regenerateShare } from "src/actions/shamir/regenerateShare.ts";

const ResetShare: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useUser();
  const [shareBackupStr, setShareBackupStr] = useState<string>("");

  const sendShare = async () => {
    if (!user) return;
    const results = await regenerateShare(
      user.share,
      isBase64ForUnyte(shareBackupStr)
        ? decodeBase64ForUnyte(shareBackupStr)
        : shareBackupStr.split(", ").map((s) => parseInt(s))
    );
    if (!isValidShare(results.shareLocalStorage)) {
      alert("シェアの形式が正しくありません");
      return;
    }
    if (!isValidPrivateKey(results.privateKey)) {
      alert("シェアが正しくありません");
      return;
    }
    localStorage.setItem(user.uid, results.shareLocalStorage.join(","));
    alert("シェアを再設定しました");
    navigate("/my-page");
  };

  return (
    <div className="mt-10 flex items-center justify-center bg-unyte-main lg:h-full">
      <div className="w-11/12 rounded-lg bg-white p-4 shadow-md lg:w-auto lg:p-8">
        <h1 className="mx-auto mb-5 text-center text-2xl font-bold">
          シェアの再設定
        </h1>
        <div className="relative mb-4">
          <textarea
            placeholder="保存したシェアを貼り付けてください"
            className="mb-4 w-full resize-none rounded-md border border-gray-300 p-3 shadow-sm"
            style={{ height: `12em` }}
            onChange={(e) => setShareBackupStr(e.target.value)}
          ></textarea>
          <button
            className="w-full rounded-md bg-blue-600 p-3 text-white hover:bg-blue-700"
            onClick={() => {
              sendShare();
            }}
          >
            シェアを再設定する
          </button>
        </div>
        <hr className="my-4 h-px border-0 bg-gray-200 dark:bg-gray-700 lg:my-8"></hr>
        <button
          className="w-full rounded-md bg-white p-3 text-blue-600 hover:bg-gray-100"
          onClick={() => navigate("/my-page")}
        >
          戻る
        </button>
      </div>
    </div>
  );
};

export default ResetShare;
