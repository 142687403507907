/* tslint:disable */
/* eslint-disable */
/**
 * Unyte SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RewardInfo
 */
export interface RewardInfo {
    /**
     * 報酬の送信元
     * @type {string}
     * @memberof RewardInfo
     */
    from: string;
    /**
     * 報酬の送信先
     * @type {string}
     * @memberof RewardInfo
     */
    to: string;
    /**
     * トークンの情報
     * @type {Array<string>}
     * @memberof RewardInfo
     */
    token: Array<string>;
    /**
     * 報酬の量
     * @type {number}
     * @memberof RewardInfo
     */
    reward: number;
}

/**
 * Check if a given object implements the RewardInfo interface.
 */
export function instanceOfRewardInfo(value: object): boolean {
    if (!('from' in value)) return false;
    if (!('to' in value)) return false;
    if (!('token' in value)) return false;
    if (!('reward' in value)) return false;
    return true;
}

export function RewardInfoFromJSON(json: any): RewardInfo {
    return RewardInfoFromJSONTyped(json, false);
}

export function RewardInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RewardInfo {
    if (json == null) {
        return json;
    }
    return {
        
        'from': json['from'],
        'to': json['to'],
        'token': json['token'],
        'reward': json['reward'],
    };
}

export function RewardInfoToJSON(value?: RewardInfo | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'from': value['from'],
        'to': value['to'],
        'token': value['token'],
        'reward': value['reward'],
    };
}

