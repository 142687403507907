import React, { useCallback, useEffect, useState } from "react";
import {
  useBeforeUnload,
  useBlocker,
  useLocation,
  useNavigate,
} from "react-router-dom";
import SaveShare from "../../components/register/SaveShare.tsx";
import ProgressBar from "../../components/createDao/ProgressBar.tsx";
import UserRegister from "../../components/register/UserRegister.tsx";
import ShareDescription from "../../components/register/ShareDescription.tsx";
import { useAuthState } from "react-firebase-hooks/auth";
import { getAuth } from "firebase/auth";
import { updateUser } from "../../actions/firebase/user.ts";
import {
  isValidBackupKey,
  saveShareForFirebase,
} from "src/actions/shamir/createShare.js";
import ShareConfirm from "src/components/register/ShareConfirm.tsx";
import { encodeToBase64ForUnyte } from "src/actions/shamir/utils.ts";
import { fbLogout } from "src/actions/firebase/session.ts";
import ConfirmModal from "src/components/atoms/ConfirmModal.tsx";

const Register: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [user, setUser] = useState<{
    uid: string;
    name: string;
    email: string;
    address: string;
  }>({
    uid: location.state?.user,
    name: "",
    email: "",
    address: "",
  });
  const [isCompleted, setIsCompleted] = useState(false);
  const [profileImage, setProfileImage] = useState<any>(null);
  const [uploadedFileImageUrl, setUploadedFileImageUrl] = useState<string>("");
  const [isLocked, setIsLocked] = useState<boolean>(false);
  const [isRegisterConfirmModal, setIsRegisterConfirmModal] = useState<boolean>(
    location.state?.register ? false : true
  );
  const onOk = async () => {
    setIsRegisterConfirmModal(false);
  };
  const handleLogout = async () => {
    const isLogout = await fbLogout();
    setIsRegisterConfirmModal(false);
    if (isLogout) {
      navigate("/login");
    }
  };

  const [session] = useAuthState(getAuth());

  // shareはEmailSignUp.tsxからuseNavigatorを使って渡されている
  const share = location.state?.share || [];
  const uid = location.state?.user;

  const renderFormByStep = (step: number) => {
    switch (step) {
      case 1:
        return <ShareDescription setStep={setStep} />;
      case 2:
        return (
          <SaveShare
            share={share.user}
            encodedShare={encodeToBase64ForUnyte(share.user)}
            setStep={setStep}
          />
        );
      case 3:
        return (
          <UserRegister
            userInfo={user}
            setUser={setUser}
            profileImage={profileImage}
            setProfileImage={setProfileImage}
            setUploadedFileImageUrl={setUploadedFileImageUrl}
            isLocked={isLocked}
            setStep={setStep}
          />
        );
      case 4:
        if (!uid) return <div>ユーザー情報が取得できませんでした。</div>;
        return <ShareConfirm onConfirm={initializeUser} />;
    }
  };
  const initializeUser = async (backupKey: string) => {
    console.log("initializeUser_backupKey", backupKey);
    if (!user) return;

    const encodedBackupShare = encodeToBase64ForUnyte(share.user);
    console.log(
      "initializeUser_encodedBackupShare",
      encodedBackupShare.slice(-4)
    );
    if (backupKey !== encodedBackupShare.slice(-4)) {
      throw new Error("Invalid backup key");
    }

    const isValid = await isValidBackupKey(user.uid, share.unyte, backupKey);
    if (!isValid) {
      throw new Error("Invalid backup key");
    }

    await saveShareForFirebase(user.uid, share);
    await updateUser({
      uid: user.uid,
      name: user.name,
      img: uploadedFileImageUrl,
      initialized: true,
    });
    localStorage.removeItem(`tmpShare_${user.uid}`);
    if (localStorage.getItem(`unyte_not_register_${uid}`)) {
      localStorage.removeItem(`unyte_not_register_${uid}`);
    }
    setIsCompleted(true);
  };
  useBeforeUnload(
    useCallback((e: BeforeUnloadEvent) => {
      e.preventDefault();
    }, [])
  );

  const handlePagehide = useCallback(
    (event: PageTransitionEvent) => {
      if (!isCompleted) {
        event.preventDefault();
      }
    },
    [isCompleted]
  );

  useEffect(() => {
    if (isCompleted) {
      navigate("/list", { replace: true });
      window.history.pushState(null, "", "/list");
      window.onpopstate = () => {
        window.history.pushState(null, "", "/list");
      };
    }
  }, [isCompleted]);

  useEffect(() => {
    window.history.pushState(null, "", window.location.href);
    window.onpopstate = () => {
      window.history.pushState(null, "", window.location.href);
    };

    window.addEventListener("pagehide", handlePagehide);

    return () => {
      window.removeEventListener("pagehide", handlePagehide);
    };
  }, []);

  useEffect(() => {
    if (session) {
      const init = async () => {
        if (!location.state) return;
        if (location.state?.email) {
          setIsLocked(true);
          setUser((prev) => ({ ...prev, email: location.state.email }));
        }
        if (!user) return;
        if (user.name) {
          setUser((prev) => ({ ...prev, name: user.name }));
        }

        if (user.email) {
          setUser((prev) => ({ ...prev, email: user.email }));
        }

        if (location.state.image) {
          const image = location.state.image.replace("=s96-c", "");
          setProfileImage(image);
        }
      };
      init();
    }
  }, [session]);
  return (
    <>
      <div className="bg-unyte-main">
        <div className="pt-4 text-center text-white lg:h-20 lg:p-20">
          <h1>アカウント設定</h1>
          <div className="mx-auto flex w-11/12 items-center justify-center lg:w-1/2 lg:w-3/5">
            <p className="mr-2 text-xs lg:mr-4 lg:text-lg">{step} / 4</p>
            <ProgressBar step={step} width={4} />
          </div>
        </div>
        {renderFormByStep(step)}
      </div>
      {isRegisterConfirmModal && (
        <ConfirmModal
          title={"登録中のアカウントがあります。続行しますか？"}
          onOk={onOk}
          onCancel={handleLogout}
        ></ConfirmModal>
      )}
    </>
  );
};

export default Register;
