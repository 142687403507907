import { FunctionComponent } from "react";
import SsoLogIn from "./pages/auth/SsoLogIn.tsx";
import EmailSignUp from "./pages/auth/EmailSignUp.tsx";
import EmailLogIn from "./pages/auth/EmailLogIn.tsx";
import Register from "./pages/auth/Register.tsx";
import ResetShare from "./pages/share/ResetShare.tsx";
import MyPage from "./pages/myPage/MyPage.tsx";
import Activity from "./pages/myPage/Activity.tsx";
import Credential from "./pages/myPage/Credential.tsx";
import Profile from "./pages/myPage/Profile.tsx";
import CreateDao from "./pages/dao/CreateDao.tsx";
import DaoList from "./pages/dao/DaoList.tsx";
import DaoDetail from "./pages/dao/DaoDetail.tsx";
import XAuth from "./pages/xAuth/xAuth.tsx";
import ReturnToken from "./pages/returnToken/ReturnToken.tsx";
import Admin from "./pages/admin/Admin.tsx";
import ReshowShare from "./pages/share/ReshowShare.tsx";

interface RouteType {
  path: string;
  name: string;
  component: FunctionComponent;
}

export const authRoutes: RouteType[] = [
  { path: "/login", name: "SsoLogIn", component: SsoLogIn },
  { path: "/login-with-email", name: "EmailLogIn", component: EmailLogIn },
  { path: "/signup-with-email", name: "EmailSignUp", component: EmailSignUp },
];

export const routes: RouteType[] = [
  { path: "/list", name: "DAO List", component: DaoList },
  { path: "/create-dao", name: "Create DAO", component: CreateDao },
  {
    path: "/:id/admin",
    name: "Admin",
    component: Admin,
  },
  {
    path: "/:id/admin/:mode",
    name: "Admin",
    component: Admin,
  },
  { path: "/:id", name: "DAO Detail", component: DaoDetail },
  { path: "/", name: "DAO Detail", component: DaoDetail },
  { path: "/:id/:mode", name: "DAO Detail", component: DaoDetail },
  { path: "/:id/:mode/:channelId", name: "DAO Detail", component: DaoDetail },
  {
    path: "/:id/:mode/:proposalId",
    name: "DAO Detail",
    component: DaoDetail,
  },
  {
    path: "/:id/:mode/:taskId",
    name: "DAO Detail",
    component: DaoDetail,
  },
  {
    path: "/:id/:mode/:taskId/:outputId",
    name: "DAO Detail",
    component: DaoDetail,
  },
  { path: "/register", name: "Register", component: Register },
  { path: "/reshow-share", name: "ReshowShare", component: ReshowShare },
  { path: "/reset", name: "ResetShare", component: ResetShare },
  { path: "/my-page", name: "My Page", component: MyPage },
  { path: "/my-page/profile", name: "My Page | Profile", component: Profile },
  {
    path: "/my-page/activity",
    name: "My Page | Activity",
    component: Activity,
  },
  { path: "/getXAccount", name: "getXAccount", component: XAuth },
  {
    path: "/my-page/credential",
    name: "My Page | Credential",
    component: Credential,
  },
  { path: "/return-token", name: "Return Token", component: ReturnToken },
];
