import React from "react";
import { BaseProps } from "../../../../../types/common/index.ts";
import clsx from "clsx";
import {
  getInitialTmpItem,
  tmpAddAndEditItemState,
} from "../../../../../stores/tmpItemState.ts";
import { useAtom, useSetAtom } from "jotai";
import { ItemSubmitButton } from "../ItemSubmitButton.tsx";
import { inProgressMarketState } from "../../../../../stores/inProgressMarketState.ts";
import { addItem } from "../../../../../actions/blockchain/itemActions.js";
import { itemsState } from "../../../../../stores/itemsState.ts";
import { isOpenAddPageMarketState } from "../../../../../stores/isOpenAddPageMarketState.ts";
import { useUser } from "src/hooks/useUser.ts";

export type ItemAddSubmitButtonProps = { daoId: string } & BaseProps;

export const ItemAddSubmitButton: React.FC<ItemAddSubmitButtonProps> = ({
  className,
  daoId,
}) => {
  const [tmpItem, setTmpItem] = useAtom(tmpAddAndEditItemState);
  const setItems = useSetAtom(itemsState);
  const setInProgress = useSetAtom(inProgressMarketState);
  const setIsOpenAddPage = useSetAtom(isOpenAddPageMarketState);
  const { user } = useUser();

  const handleClick = async () => {
    if (!tmpItem) return;
    setInProgress({ isShow: true, message: "アイテム追加中..." });
    try {
      const name = tmpItem.name;
      const description = tmpItem.description;
      const img = tmpItem.base64;
      const price = tmpItem.price;
      const amount = tmpItem.amount;
      if (price === 0) {
        alert("価格を0以上で入力してください。");
        setInProgress({ isShow: false, message: "" });
        return;
      }
      if (amount === 0) {
        alert("数量を0以上で入力してください。");
        setInProgress({ isShow: false, message: "" });
        return;
      }
      await addItem(daoId, name, description, img, price, amount, user);
      setItems((prev) => {
        return { ...prev, [name]: { name, description, img, price, amount } };
      });
      setTmpItem(getInitialTmpItem());
      setInProgress({ isShow: false, message: "" });
      window.location.reload();
    } catch (e) {
      console.error(e);
      if (e instanceof Error) {
        alert(
          `アイテム追加に失敗した可能性があります。ご確認ください。\n${e.stack}`
        );
      } else
        alert(
          `アイテム追加に失敗した可能性があります。ご確認ください。\n\n${JSON.stringify(
            e
          )}`
        );
      setInProgress({ isShow: false, message: "" });
      return;
    }
    setIsOpenAddPage(false);
  };

  return (
    <ItemSubmitButton
      className={clsx(className)}
      theme="add"
      onClick={handleClick}
    >
      追加する
    </ItemSubmitButton>
  );
};
