/* tslint:disable */
/* eslint-disable */
/**
 * Unyte SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ApproveOutputArgs } from './ApproveOutputArgs';
import {
    ApproveOutputArgsFromJSON,
    ApproveOutputArgsFromJSONTyped,
    ApproveOutputArgsToJSON,
} from './ApproveOutputArgs';

/**
 * 
 * @export
 * @interface ApproveOutputRequest
 */
export interface ApproveOutputRequest {
    /**
     * lambdaの関数でapproveOutputを指定
     * @type {string}
     * @memberof ApproveOutputRequest
     */
    type: ApproveOutputRequestTypeEnum;
    /**
     * approveOutputの引数
     * @type {ApproveOutputArgs}
     * @memberof ApproveOutputRequest
     */
    args: ApproveOutputArgs;
}


/**
 * @export
 */
export const ApproveOutputRequestTypeEnum = {
    CreateDao: 'createDAO',
    CreateNft: 'createNFT',
    EditDao: 'editDAO',
    AddItem: 'addItem',
    EditItem: 'editItem',
    Exchange: 'exchange',
    Proposal: 'proposal',
    Vote: 'vote',
    ApproveOutput: 'approveOutput',
    RejectOutput: 'rejectOutput',
    CreateOutput: 'createOutput',
    CreateTask: 'createTask'
} as const;
export type ApproveOutputRequestTypeEnum = typeof ApproveOutputRequestTypeEnum[keyof typeof ApproveOutputRequestTypeEnum];


/**
 * Check if a given object implements the ApproveOutputRequest interface.
 */
export function instanceOfApproveOutputRequest(value: object): boolean {
    if (!('type' in value)) return false;
    if (!('args' in value)) return false;
    return true;
}

export function ApproveOutputRequestFromJSON(json: any): ApproveOutputRequest {
    return ApproveOutputRequestFromJSONTyped(json, false);
}

export function ApproveOutputRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApproveOutputRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'type': json['type'],
        'args': ApproveOutputArgsFromJSON(json['args']),
    };
}

export function ApproveOutputRequestToJSON(value?: ApproveOutputRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'type': value['type'],
        'args': ApproveOutputArgsToJSON(value['args']),
    };
}

