import clsx from "clsx";
import { useSetAtom } from "jotai";
import { useEffect } from "react";
import { Item } from "src/components/market/Item/Item";
import { useItems } from "src/hooks/useMarket";
import { inProgressMarketState } from "src/stores/inProgressMarketState";
import {
  DaoDetailDto,
  MarketControllerFetchItemsItemStatusEnum,
} from "src/types/api";
import React from "react";
import { User } from "src/types/common";

interface Props {
  role: "admin" | "member";
  daoId: string;
  itemsStatus: Array<MarketControllerFetchItemsItemStatusEnum>;
  daoDetail: DaoDetailDto;
  user: User;
}

const Items = (props: Props) => {
  const {
    items,
    isError: isErrorItems,
    isLoading: isLoadingItems,
    mutate: itemMutate,
  } = useItems(props.daoId, props.itemsStatus);
  const setInProgress = useSetAtom(inProgressMarketState);
  useEffect(() => {
    if (isLoadingItems) {
      setInProgress({ isShow: true, message: "Loading..." });
      return;
    }
    setInProgress({ isShow: false, message: "" });
  }, [isLoadingItems]);
  return (
    <div
      className={clsx(
        "flex flex-wrap justify-center",
        "p-2 lg:p-4",
        "md:justify-start"
      )}
    >
      {items.map((item: any, index: number) => (
        <Item
          key={index}
          item={item}
          role={props.role}
          daoDetail={props.daoDetail}
          user={props.user}
          itemMutate={itemMutate}
        />
      ))}
      {isErrorItems && (
        <div className="fixed left-[calc(100svw/2)] top-[calc(100svh/2)] -translate-x-1/2 -translate-y-1/2 bg-transparent text-lg text-gray-100">
          アイテムの取得に失敗しました
        </div>
      )}
      {items && items.length === 0 && (
        <div className="fixed left-[calc(100svw/2)] top-[calc(100svh/2)] -translate-x-1/2 -translate-y-1/2 bg-transparent text-lg text-gray-100">
          アイテムがありません
        </div>
      )}
    </div>
  );
};

export default Items;
