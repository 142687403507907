import React from "react";
import { BaseProps } from "../../types/common/index.ts";

export type DaoMemberCountProps = {
  cnt: number;
} & BaseProps;

export const DaoMemberCount: React.FC<DaoMemberCountProps> = ({
  className,
  cnt,
}) => {
  return (
    <p className={`${className} text-center text-sm text-gray-500`}>
      Member: {cnt}
    </p>
  );
};
