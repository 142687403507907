import React from "react";
import { BaseProps } from "../../types/common/index.ts";
import { getSocialIcon } from "src/utils/getSocialIcon.tsx";

export type SocialLinkProps = { link: string } & BaseProps;

export const SocialLink: React.FC<SocialLinkProps> = ({ className, link }) => {
  if (link === "") return <></>;
  return (
    <div className={`${className} group relative pl-2`}>
      <a href={link} target="_blank" rel="noopener noreferrer">
        {getSocialIcon(link)}
      </a>
      <span className="invisible absolute top-6 rounded bg-slate-600 text-[12px] font-bold text-white opacity-100 group-hover:visible ">
        {link}
      </span>
    </div>
  );
};
