import React from "react";
import clsx from "clsx";
import { BaseProps } from "../../../../types/common/index.ts";

export type CloseButtonProps = {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
} & BaseProps;

export const CloseButton: React.FC<CloseButtonProps> = ({
  className,
  onClick,
}) => {
  return (
    <button className={clsx(className)} onClick={onClick}>
      <p className="text-2xl">×</p>
    </button>
  );
};
