import React from "react";
import { OnChainOutputDetail } from "./OnChainOutputDetail.tsx";
import { BaseProps } from "../../types/common/index.ts";
import { Output } from "src/types/api/index.ts";

export type OnChainOutputDetailsProps = {
  outputs: Output[];
  plannedApprovalOutputs: Output[];
  setPlannedApprovalOutputs: React.Dispatch<React.SetStateAction<Output[]>>;
  role: "member" | "admin";
  heightlighOutputId?: string;
} & BaseProps;

export const OnChainOutputDetails: React.FC<OnChainOutputDetailsProps> = ({
  className,
  outputs,
  plannedApprovalOutputs,
  setPlannedApprovalOutputs,
  role,
  heightlighOutputId,
}: OnChainOutputDetailsProps) => {
  if (outputs.length <= 0) return <></>;
  return (
    <div className={`${className}`}>
      {outputs.map((output, i) => (
        <OnChainOutputDetail
          key={i}
          output={output}
          plannedApprovalOutputs={plannedApprovalOutputs}
          setPlannedApprovalOutputs={setPlannedApprovalOutputs}
          role={role}
          isHighlighted={heightlighOutputId === output.outputId}
        />
      ))}
    </div>
  );
};
