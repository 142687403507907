/* tslint:disable */
/* eslint-disable */
/**
 * Unyte SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TaskOperationForOutputsForAllTasks
 */
export interface TaskOperationForOutputsForAllTasks {
    /**
     * 
     * @type {number}
     * @memberof TaskOperationForOutputsForAllTasks
     */
    reward: number;
}

/**
 * Check if a given object implements the TaskOperationForOutputsForAllTasks interface.
 */
export function instanceOfTaskOperationForOutputsForAllTasks(value: object): boolean {
    if (!('reward' in value)) return false;
    return true;
}

export function TaskOperationForOutputsForAllTasksFromJSON(json: any): TaskOperationForOutputsForAllTasks {
    return TaskOperationForOutputsForAllTasksFromJSONTyped(json, false);
}

export function TaskOperationForOutputsForAllTasksFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaskOperationForOutputsForAllTasks {
    if (json == null) {
        return json;
    }
    return {
        
        'reward': json['reward'],
    };
}

export function TaskOperationForOutputsForAllTasksToJSON(value?: TaskOperationForOutputsForAllTasks | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'reward': value['reward'],
    };
}

