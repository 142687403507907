import React from "react";
import { Switch } from "@mui/material";
import { useChannels } from "src/actions/firebase/channels.ts";
import AdminChannelDetail from "src/components/admin/message/AdminChannelDetail";

interface Props {
  daoId: string;
}

const AdminChannelList: React.FC<Props> = ({ daoId }) => {
  const [includeArchives, setIncludeArchives] = React.useState(true);
  const { channels, mutate } = useChannels(daoId, includeArchives);

  return (
    <div className="m-8">
      <div className="mb-4 flex items-center">
        <Switch
          sx={{
            "& .MuiSwitch-track": {
              background: "white",
            },
          }}
          onChange={(e) => {
            setIncludeArchives(e.target.checked);
          }}
          defaultChecked
        />
        <div>アーカイブしたチャンネルも表示</div>
      </div>
      <div>
        {(() => {
          if (channels.length === 0) {
            return <div className="text-white">Loading...</div>;
          }
          return (
            <div className="flex flex-col space-y-4">
              {channels.map((channel) => (
                <AdminChannelDetail
                  key={channel.id}
                  daoId={daoId}
                  channel={channel}
                  onUpdate={() => {
                    mutate();
                  }}
                />
              ))}
            </div>
          );
        })()}
      </div>
    </div>
  );
};

export default AdminChannelList;
