import { useCallback, useEffect, useRef, useState } from "react";

export function useLocalStorage<T>(key: string, initialValue: T) {
  const prevItem = useRef<string | null>(null);
  const prevValue = useRef<T>();
  const getLocalStorageValue = useCallback(
    (key: string): T => {
      const item = localStorage.getItem(key);
      if (prevItem.current != null && prevItem.current == item) {
        return prevValue.current as T;
      }
      prevItem.current = item;
      prevValue.current = item ? JSON.parse(item) : initialValue;
      return prevValue.current as T;
    },
    [initialValue]
  );

  const [value, setValue] = useState<T>(() => getLocalStorageValue(key));

  const setLocalStorageValue = useCallback(
    (key: string, setStateAction: T | ((prevState: T) => T)) => {
      const newValue =
        setStateAction instanceof Function
          ? setStateAction(getLocalStorageValue(key))
          : setStateAction;

      localStorage.setItem(key, JSON.stringify(newValue));
      setValue(() => newValue);
    },
    []
  );

  useEffect(() => {
    const id = setInterval(() => {
      setValue(() => getLocalStorageValue(key));
    }, 1000);
    return () => clearInterval(id);
  }, [key]);

  return [value, setLocalStorageValue] as const;
}
