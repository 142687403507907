import React, { useRef } from "react";
import { BaseProps, InputFile } from "../../types/common/index.ts";
import pdfIcon from "../../assets/pdf.svg";
import textIcon from "../../assets/text.svg";
import videoIcon from "../../assets/video.svg";
import { getBase64 } from "../../utils/format.ts";
import { getFilesSize } from "../../utils/files.ts";

const MAX_FILE_MEGA_SIZE = 4;
const MAX_FILE_SIZE = MAX_FILE_MEGA_SIZE * 1024 * 1024;
const MAX_FILE_CNT = 10;

const getOverSizeFile = (files: File[]): string[] => {
  const overSizeFileNames: string[] = [];
  for (let file of files) {
    if (file.size > MAX_FILE_SIZE) overSizeFileNames.push(file.name);
  }
  return overSizeFileNames;
};

const fileImageConverter = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      if (typeof event.target?.result === "string")
        return resolve(event.target?.result);
      return reject("Failed to read file");
    };
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });
};

export type UploadFileButtonProps = {
  inputFiles: InputFile[];
  setInputFiles: React.Dispatch<React.SetStateAction<InputFile[]>>;
} & BaseProps;

export const UploadFileButton: React.FC<UploadFileButtonProps> = ({
  className,
  inputFiles,
  setInputFiles,
}) => {
  const inputFileRef = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    if (!inputFileRef.current) return;
    inputFileRef.current.click();
  };

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;

    const files = Array.from(e.target.files);
    const filesSize = getFilesSize([
      ...inputFiles.map((f) => f.file),
      ...files,
    ]);
    if (filesSize > MAX_FILE_SIZE) {
      alert(`ファイルサイズの合計が${MAX_FILE_MEGA_SIZE}MBを超えています。`);
      return;
    }

    const overSizeFileNames = getOverSizeFile(files);
    if (overSizeFileNames.length > 0) {
      alert(
        `下記ファイルは${MAX_FILE_MEGA_SIZE}MBより大きいためアップロードできません。\n\n${overSizeFileNames.join(
          "\n"
        )}`
      );
      return;
    }

    const tmpFiles = await Promise.all(
      files.map(async (file) => {
        const result = await fileImageConverter(file);
        const base64 = getBase64(result);
        if (file.type.startsWith("image/"))
          return { file, thumbnail: result, base64 };
        if (file.type.startsWith("video/"))
          return { file, thumbnail: videoIcon, base64 };
        if (file.type.startsWith("application/pdf"))
          return { file, thumbnail: pdfIcon, base64 };
        return { file, thumbnail: textIcon, base64 };
      })
    );
    const newFiles = [...inputFiles, ...tmpFiles].filter(
      (file, index, self) =>
        self.findIndex((f) => f.file.name === file.file.name) === index // 重複を削除
    );

    if (newFiles.length > MAX_FILE_CNT) {
      alert(`ファイルは${MAX_FILE_CNT}個までしか添付できません。`);
      return;
    }

    setInputFiles(newFiles);
  };

  return (
    <>
      <button
        className={`${className} h-8 rounded-full bg-gray-500 px-6 text-xs text-white`}
        onClick={handleClick}
      >
        ファイルを添付する
      </button>
      <input
        ref={inputFileRef}
        className="hidden"
        name="file"
        type="file"
        multiple
        onChange={handleChange}
      />
    </>
  );
};
