/* tslint:disable */
/* eslint-disable */
/**
 * Unyte SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TaskAuthor
 */
export interface TaskAuthor {
    /**
     * 
     * @type {string}
     * @memberof TaskAuthor
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof TaskAuthor
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof TaskAuthor
     */
    img?: string;
}

/**
 * Check if a given object implements the TaskAuthor interface.
 */
export function instanceOfTaskAuthor(value: object): boolean {
    if (!('address' in value)) return false;
    if (!('name' in value)) return false;
    return true;
}

export function TaskAuthorFromJSON(json: any): TaskAuthor {
    return TaskAuthorFromJSONTyped(json, false);
}

export function TaskAuthorFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaskAuthor {
    if (json == null) {
        return json;
    }
    return {
        
        'address': json['address'],
        'name': json['name'],
        'img': json['img'] == null ? undefined : json['img'],
    };
}

export function TaskAuthorToJSON(value?: TaskAuthor | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'address': value['address'],
        'name': value['name'],
        'img': value['img'],
    };
}

