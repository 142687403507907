/* tslint:disable */
/* eslint-disable */
/**
 * Unyte SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SendBackupShareEmailRequest
 */
export interface SendBackupShareEmailRequest {
    /**
     * メール送信先
     * @type {string}
     * @memberof SendBackupShareEmailRequest
     */
    to: string;
    /**
     * バックアップシェア
     * @type {string}
     * @memberof SendBackupShareEmailRequest
     */
    backupShare: string;
}

/**
 * Check if a given object implements the SendBackupShareEmailRequest interface.
 */
export function instanceOfSendBackupShareEmailRequest(value: object): boolean {
    if (!('to' in value)) return false;
    if (!('backupShare' in value)) return false;
    return true;
}

export function SendBackupShareEmailRequestFromJSON(json: any): SendBackupShareEmailRequest {
    return SendBackupShareEmailRequestFromJSONTyped(json, false);
}

export function SendBackupShareEmailRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SendBackupShareEmailRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'to': json['to'],
        'backupShare': json['backupShare'],
    };
}

export function SendBackupShareEmailRequestToJSON(value?: SendBackupShareEmailRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'to': value['to'],
        'backupShare': value['backupShare'],
    };
}

