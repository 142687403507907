import Resizer from "react-image-file-resizer";

type Dimensions = {
  width: number;
  height: number;
};

export const resizeImage = (
  file: File,
  dimensions: Dimensions
): Promise<string | File | Blob | ProgressEvent<FileReader>> => {
  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      600,
      dimensions.height,
      "JPEG", // リサイズ後の画像の形式
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64" // 出力形式
    );
  });
};

export const resizeImageToMaxSize = (
  file: File,
  maxSizeMB = 5
): Promise<File> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        let width = img.width;
        let height = img.height;
        const maxSize = maxSizeMB * 1024 * 1024; // バイト単位に変換

        // アスペクト比を維持しながら、ファイルサイズが5MB以下になるまでリサイズ
        while (file.size > maxSize && width > 0 && height > 0) {
          width *= 0.9;
          height *= 0.9;

          const canvas = document.createElement("canvas");
          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext("2d");
          ctx?.drawImage(img, 0, 0, width, height);

          canvas.toBlob(
            (blob) => {
              if (blob) {
                const resizedFile = new File([blob], file.name, {
                  type: "image/jpeg",
                  lastModified: Date.now(),
                });

                if (resizedFile.size <= maxSize) {
                  resolve(resizedFile);
                }
              }
            },
            "image/jpeg",
            0.7
          ); // 画質を70%に設定
        }
      };
      img.src = e.target?.result as string;
    };
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });
};

export const getImageDimensions = async (file: File): Promise<Dimensions> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = URL.createObjectURL(file);

    img.onload = () => {
      resolve({
        width: img.width,
        height: img.height,
      });
    };

    img.onerror = (error) => {
      reject(error);
    };
  });
};
