import React from "react";
import clsx from "clsx";
import { useAtom } from "jotai";
import { ItemNumberInput } from "../ItemNumberInput.tsx";
import { BaseProps } from "../../../../../types/common/index.ts";
import { tmpAddAndEditItemState } from "src/stores/tmpItemState.ts";

export type ItemAmountInputProps = {} & BaseProps;

export const ItemAmountInput: React.FC<ItemAmountInputProps> = ({
  className,
}) => {
  const [tmpItem, setTmpItem] = useAtom(tmpAddAndEditItemState);
  const amount = tmpItem && tmpItem.amount;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTmpItem((prev) => {
      const newTmpItem = { ...prev };
      newTmpItem.amount = Number(e.target.value);
      return newTmpItem;
    });
  };

  return (
    <ItemNumberInput
      id="item-amount"
      className={clsx(className)}
      msgId="item_amount"
      defaultMessage="数量"
      value={amount}
      onChange={handleChange}
    />
  );
};
