/* tslint:disable */
/* eslint-disable */
/**
 * Unyte SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ThanksGiven } from './ThanksGiven';
import {
    ThanksGivenFromJSON,
    ThanksGivenFromJSONTyped,
    ThanksGivenToJSON,
} from './ThanksGiven';

/**
 * 
 * @export
 * @interface GetThanksResponse
 */
export interface GetThanksResponse {
    /**
     * 
     * @type {Array<ThanksGiven>}
     * @memberof GetThanksResponse
     */
    thanks: Array<ThanksGiven>;
}

/**
 * Check if a given object implements the GetThanksResponse interface.
 */
export function instanceOfGetThanksResponse(value: object): boolean {
    if (!('thanks' in value)) return false;
    return true;
}

export function GetThanksResponseFromJSON(json: any): GetThanksResponse {
    return GetThanksResponseFromJSONTyped(json, false);
}

export function GetThanksResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetThanksResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'thanks': ((json['thanks'] as Array<any>).map(ThanksGivenFromJSON)),
    };
}

export function GetThanksResponseToJSON(value?: GetThanksResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'thanks': ((value['thanks'] as Array<any>).map(ThanksGivenToJSON)),
    };
}

