import { ChevronRightIcon } from "@heroicons/react/24/outline";
import React, { useState } from "react";
import ThanksModal from "src/components/thanks/ThanksModal";
import { ANONYMOUS_ICON } from "src/const/const";
import { useThanks } from "src/hooks/useThanks";
import { ThanksGiven } from "src/types/api";
import { getFormatedDatetime } from "src/utils/formatDatetime";

const ThanksCard = ({
  thank,
  setTargetThanks,
}: {
  thank: NonNullable<
    ReturnType<typeof useThanks>["thanks"]
  > extends (infer ElementType)[]
    ? ElementType
    : never;
  setTargetThanks: (target: ThanksGiven) => void;
}) => {
  return (
    <button
      className="flex w-full flex-col rounded-md"
      onClick={() => setTargetThanks(thank)}
    >
      <div
        className={`"border-b h-1/5 w-full border-gray-800 bg-unyte-main py-4 text-sm leading-6 placeholder:rounded-2xl lg:p-4`}
      >
        <div className="flex flex-row items-center">
          <div className="h-full">
            <div className="relative h-8 w-8">
              <img
                className="mx-auto h-8 w-8 rounded-full bg-gray-300"
                src={thank?.from.img || ANONYMOUS_ICON}
                alt="user icon"
                onError={(e) => {
                  e.currentTarget.onerror = null;
                  e.currentTarget.src = ANONYMOUS_ICON;
                }}
              />
            </div>
          </div>
          <ChevronRightIcon className="h-6 fill-gray-300" />
          <div className="h-full">
            <div className="relative h-8 w-8">
              <img
                className="mx-auto h-8 w-8 rounded-full bg-gray-300 "
                src={thank?.to.img || ANONYMOUS_ICON}
                alt="user icon"
                onError={(e) => {
                  e.currentTarget.onerror = null;
                  e.currentTarget.src = ANONYMOUS_ICON;
                }}
              />
            </div>
          </div>
          <div className="ml-auto flex w-fit flex-col text-gray-400">
            <div className="w-full text-[10px]">{`${getFormatedDatetime(
              new Date(thank.createdAt)
            )}`}</div>
            {thank.amount ? (
              <div className="w-full text-xl font-bold">
                {thank.amount / 1.0}
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <div className="h-4/5 w-full py-4 lg:p-4">
        <p className="whitespace-pre-wrap text-left text-gray-300">
          {thank.message}
        </p>
      </div>
    </button>
  );
};

const ThanksList = ({
  thanks,
  daoIcon,
}: {
  thanks: ReturnType<typeof useThanks>["thanks"];
  daoIcon: any;
}) => {
  const [targetThanks, setTargetThanks] = useState<ThanksGiven | null>(null);
  return (
    <>
      <div className="w-full divide-y rounded-md border border-gray-950">
        {(thanks &&
          thanks.map((thank, idx) => (
            <ThanksCard
              thank={thank}
              key={idx}
              setTargetThanks={setTargetThanks}
            />
          ))) || (
          <div>
            <p className="text-gray-200">There&lsquo;s no thanks ...</p>
          </div>
        )}
      </div>
      <ThanksModal
        txHash={targetThanks?.txHash || null}
        opened={!!targetThanks}
        close={() => setTargetThanks(null)}
        from={targetThanks?.from || null}
        to={targetThanks?.to || null}
        daoIcon={daoIcon}
        message={targetThanks?.message ?? ""}
        amount={targetThanks?.amount ?? 0}
      />
    </>
  );
};

export default ThanksList;
