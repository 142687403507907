/* tslint:disable */
/* eslint-disable */
/**
 * Unyte SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { RejectOutputInfos } from './RejectOutputInfos';
import {
    RejectOutputInfosFromJSON,
    RejectOutputInfosFromJSONTyped,
    RejectOutputInfosToJSON,
} from './RejectOutputInfos';

/**
 * 
 * @export
 * @interface RejectOutputArgs
 */
export interface RejectOutputArgs {
    /**
     * rejectOutputの情報
     * @type {Array<RejectOutputInfos>}
     * @memberof RejectOutputArgs
     */
    approvalInfos: Array<RejectOutputInfos>;
}

/**
 * Check if a given object implements the RejectOutputArgs interface.
 */
export function instanceOfRejectOutputArgs(value: object): boolean {
    if (!('approvalInfos' in value)) return false;
    return true;
}

export function RejectOutputArgsFromJSON(json: any): RejectOutputArgs {
    return RejectOutputArgsFromJSONTyped(json, false);
}

export function RejectOutputArgsFromJSONTyped(json: any, ignoreDiscriminator: boolean): RejectOutputArgs {
    if (json == null) {
        return json;
    }
    return {
        
        'approvalInfos': ((json['approvalInfos'] as Array<any>).map(RejectOutputInfosFromJSON)),
    };
}

export function RejectOutputArgsToJSON(value?: RejectOutputArgs | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'approvalInfos': ((value['approvalInfos'] as Array<any>).map(RejectOutputInfosToJSON)),
    };
}

