import React from "react";
import { BaseProps } from "../../types/common/index.ts";
import { IPFS_BASE_URL } from "src/config/config.ts";

export type DaoAvatarProps = {
  avatar: string;
} & BaseProps;

export const DaoAvatar: React.FC<DaoAvatarProps> = ({ className, avatar }) => {
  return (
    <img
      className={`${className} rounded-full`}
      src={`${IPFS_BASE_URL}${avatar}`}
      alt="avatar"
    />
  );
};
