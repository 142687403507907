import { NETWORK_ERROR_MSG, CREATE_NFT_ENDPOINT } from "../../const/const.ts";
import { fireauth } from "src/actions/firebase/config.js";

async function createNFTAction(owner, daoId, name, type, img) {
  const json = {
    type: "createNFT",
    args: {
      owner: owner,
      type: type,
      name: name,
      daoId: daoId,
      img: img,
      admins: [
        "0xdC56956cD5441348c758519AFb66a10D86cbe7b2",
        "0xf39fd6e51aad88f6f4ce6ab8827279cfffb92266",
      ],
    },
  };
  const user = fireauth.currentUser;
  const token = await user.getIdToken(true);
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(json),
  };
  let response;
  try {
    response = await fetch(CREATE_NFT_ENDPOINT, options);
  } catch (e) {
    console.error(e);
    throw new Error(NETWORK_ERROR_MSG);
  }
  const responseJson = await response.json();
  if (responseJson.status !== "success") throw new Error(responseJson.message);
  return responseJson;
}
/**
 *
 * @param {*} daoId DAOのIDを指定
 * @param {*} name NFTのmetadataに入れる名前
 * @param {*} type NFTの種類
 * @param {*} img NFTの画像
 * @returns NFTを発行した際の通信情報
 */
export async function createNFT(daoId, name, type, img, owner) {
  if (daoId === "" || name === "" || img === null) {
    alert("DAO名と説明文、画像を全て入力してください");
    return;
  }
  // NFT発行のためにlambdaエンドポイントを叩く
  const response = await createNFTAction(owner.address, daoId, name, type, img);
  console.log(response);
  return response;
}

export async function getOpenseaCollection(url, tokneId = "") {
  const urlArray = url.split("opensea.io/collection/")[1];
  // openseaのurlからcollectionAddress, tokenIdを取得
  // https://github.com/ProjectOpenSea/opensea-js/blob/39da0468b40a7d751507affdeab442376765ea56/src/api/api.ts#L362C16-L362C35
  // token情報を取得する

  // let tokenInfo = await getNFTsByCollection(collectionName, tokenId);
  // let tokenAddress = tokenInfo[0].address;
  // return tokenAddress, tokenId;
  return urlArray;
}

export async function getOpenseaCollectionInfo(url) {
  // 入力されたURLで404が返ってくるかを確認する
  const response = await fetch(url);
  if (!response.ok) {
    alert("URLが正しくありません。");
    return;
  }
  // urlからhttps://opensea.io/ja/assets/を取り除く
  url = url.replace("https://opensea.io/ja/assets/", "");
  const chainId = url.split("/")[0];
  const tokenAddress = url.split("/")[1];
  const tokenId = url.split("/")[2];
  return { tokenAddress, chainId, tokenId };
}
