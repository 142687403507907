import React, { useState } from "react";
import { UserIcon } from "./UserIcon.tsx";
import { UserName } from "./UserName.tsx";
import { TxLink } from "./TxLink.tsx";
import { ApproveCheckBox } from "./ApproveCheckBox.tsx";
import { IPFS_BASE_URL } from "../../config/config.ts";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  ClipboardDocumentIcon,
} from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { ApprovalStatusBadge } from "./ApprovalStatusBadge.tsx";
import { Output } from "src/types/api/index.ts";

const NOT_FOUND = -1;

const isSelectFn = (targetOutput: Output, plannedApprovalOutputs: Output[]) => {
  const targetIndex = plannedApprovalOutputs.findIndex(
    (plannedApprovalOutput) =>
      plannedApprovalOutput.outputId === targetOutput.outputId
  );
  return targetIndex !== NOT_FOUND;
};

const getBgAndBorderColor = (isSelect: boolean) => {
  if (isSelect) return "bg-checked-color border-checked-border-color";
  return "bg-transparent border-transparent";
};

const getExtName = (filePath: string) => {
  const match = filePath.match(/\.[0-9a-z]+$/i);
  if (!match) return "";
  return match[0];
};

const separateOutputFilesToImgAndOther = (outputFiles: string[]) => {
  if (!outputFiles) return [[], []];
  const imgExts = [".jpg"]; // 画像ファイルはリサイズ処理で必ずjpgになるので他の形式は無視
  return [
    outputFiles.filter((outputFile) =>
      imgExts.includes(getExtName(outputFile))
    ),
    outputFiles.filter(
      (outputFile) => !imgExts.includes(getExtName(outputFile))
    ),
  ];
};

const replaceIpfsPath = (filePath: string) => {
  if (filePath.startsWith("ipfs://"))
    return filePath.replace("ipfs://", IPFS_BASE_URL);
  return `${IPFS_BASE_URL}${filePath}`;
};

export type OnChainOutputDetailProps = {
  output: Output;
  plannedApprovalOutputs: Output[];
  setPlannedApprovalOutputs: React.Dispatch<React.SetStateAction<Output[]>>;
  role: "member" | "admin";
  isHighlighted: boolean;
};

export const OnChainOutputDetail: React.FC<OnChainOutputDetailProps> = ({
  output,
  plannedApprovalOutputs,
  setPlannedApprovalOutputs,
  role,
  isHighlighted,
}: OnChainOutputDetailProps) => {
  const navigate = useNavigate();
  const daoId = location.pathname.split("/")[1];
  const taskId = location.pathname.split("/")[3];

  const [showDetail, setShowDetail] = useState<boolean>(false);

  const isSelect = isSelectFn(output, plannedApprovalOutputs);

  const handleClick = (targetOutput: Output) => {
    if (targetOutput.status !== "pending" || role !== "admin") return;
    setPlannedApprovalOutputs((prev) => {
      const targetIndex = prev.findIndex(
        (output) => output.outputId === targetOutput.outputId
      );
      if (targetIndex === NOT_FOUND) return [...prev, targetOutput];
      return prev.filter((_, index) => index !== targetIndex);
    });
  };

  const handleClickCopy = () => {
    navigate(`/${daoId}/task/${taskId}/${output.outputId}`);
    navigator.clipboard.writeText(location.href);
  };

  const [outputImgFiles, outputOtherFiles] = separateOutputFilesToImgAndOther(
    output.outputFiles ?? []
  );

  return (
    <div
      className={`mt-2 flex w-full flex-col gap-y-1 rounded border-[1px] p-2 ${getBgAndBorderColor(
        isSelect
      )} ${isHighlighted && "border-white"}`}
    >
      <div
        className={`flex w-full ${
          role === "admin" && output.status === "pending" && "cursor-pointer"
        }`}
        onClick={() => {
          handleClick(output);
        }}
      >
        {role === "admin" && (
          <ApproveCheckBox
            readOnly // コンソールで `Warning: You provided a `checked` prop to a form field without an `onChange` handler. This will render a read-only field. If the field should be mutable use `defaultChecked`. Otherwise, set either `onChange` or `readOnly`.` という警告が出るので、readOnlyを追加
            className={`mr-4 ${output.status !== "pending" && "invisible"}`}
            disabled={output.status !== "pending"}
            checked={isSelect}
          />
        )}
        <div className={`flex w-full flex-col gap-y-1`}>
          <div className="flex items-center justify-between">
            <div className="flex items-center justify-between gap-x-2 lg:ml-6">
              <UserIcon img={output.author.img} alt={output.author.name} />
              <UserName name={output.author.name} />
              <TxLink url={output.link} />
            </div>
            <div className="flex flex-col items-end gap-2">
              <ApprovalStatusBadge status={output.status} />
              <button onClick={handleClickCopy}>
                <ClipboardDocumentIcon className="h-5 w-5" />
              </button>
            </div>
          </div>
          <div className="text-xs lg:ml-6">
            {new Date(output.date).toLocaleDateString()}
          </div>
        </div>
      </div>
      <div className="flex w-full items-center justify-center">
        {showDetail ? (
          <button
            onClick={() => {
              setShowDetail(false);
            }}
          >
            <ChevronUpIcon className="h-5 w-5" />
          </button>
        ) : (
          <button
            onClick={() => {
              setShowDetail(true);
            }}
          >
            <ChevronDownIcon className="h-5 w-5" />
          </button>
        )}
      </div>
      {showDetail && (
        <div className="break-all rounded bg-[#121217] px-6 py-4">
          <p className="whitespace-pre-wrap">{output.note}</p>
          {outputImgFiles.map((outputImgFile, i) => {
            return (
              <img
                key={i}
                className="mt-4"
                src={replaceIpfsPath(outputImgFile)}
                alt={i.toString()}
              />
            );
          })}
          {outputOtherFiles.map((outputOtherFile, i) => {
            const ipfsLink = replaceIpfsPath(outputOtherFile);
            return (
              <div key={i} className="mt-4">
                <a
                  className="text-blue-500 hover:underline"
                  href={ipfsLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  {ipfsLink}
                </a>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
