import React, { InputHTMLAttributes } from "react";
import { BaseProps } from "../../types/common";

export type ApproveCheckBoxProps = {} & BaseProps &
  InputHTMLAttributes<HTMLInputElement>;

export const ApproveCheckBox: React.FC<ApproveCheckBoxProps> = ({
  className,
  ...props
}) => {
  return (
    <input
      className={`${className} rounded bg-transparent text-gray-500`}
      type="checkbox"
      {...props}
    />
  );
};
