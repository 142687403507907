import React from "react";
import clsx from "clsx";
import { useSetAtom } from "jotai";
import { BaseProps } from "../../../../types/common/index.ts";
import { CloseButton } from "../CloseButton/CloseButton.tsx";
import { NavTaskButton } from "./NavTaskButton/NavTaskButton.tsx";
import { OutsideModal } from "../OutsideModal/OutsideModal.tsx";
import { isOpenInsufficientPointModalMarketState } from "../../../../stores/isOpenInsufficientPointModalMarketState.ts";

export type InsufficientPointModalProps = { daoId: string } & BaseProps;

export const InsufficientPointModal: React.FC<InsufficientPointModalProps> = ({
  className,
  daoId,
}) => {
  const setIsOpenModal = useSetAtom(isOpenInsufficientPointModalMarketState);

  const closeModal = () => {
    setIsOpenModal(false);
  };

  return (
    <div
      className={clsx(
        className,
        "fixed inset-0",
        "z-40",
        "flex items-center justify-center",
        "h-screen"
      )}
    >
      <OutsideModal onClick={closeModal} />
      <div
        className={clsx(
          "bg-[#28282D] bg-unyte",
          "text-white",
          "rounded-lg",
          "p-px",
          "w-[90%] max-w-lg",
          "shadow-xl",
          "transform transition-all",
          "flex items-center justify-center"
        )}
      >
        <div
          className={clsx(
            "h-full w-full",
            "bg-[#28282D]",
            "px-8 py-8",
            "rounded-lg",
            "flex flex-col items-center justify-center",
            "relative"
          )}
        >
          <CloseButton
            className={clsx("absolute right-4 top-2")}
            onClick={closeModal}
          />
          <div className={clsx("text-xl font-bold", "mb-8")}>
            トークンが足りません
          </div>
          <div className={clsx("mb-8")}>
            アイテムを購入するためにはトークンが必要です。トークンはタスクを完了することで獲得できます。
          </div>
          <NavTaskButton daoId={daoId} />
        </div>
      </div>
    </div>
  );
};
