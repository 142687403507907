/* tslint:disable */
/* eslint-disable */
/**
 * Unyte SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EditItemArgs } from './EditItemArgs';
import {
    EditItemArgsFromJSON,
    EditItemArgsFromJSONTyped,
    EditItemArgsToJSON,
} from './EditItemArgs';

/**
 * 
 * @export
 * @interface EditItemRequest
 */
export interface EditItemRequest {
    /**
     * Lambdaの関数でEditItemを指定
     * @type {string}
     * @memberof EditItemRequest
     */
    type: EditItemRequestTypeEnum;
    /**
     * EditItemの引数
     * @type {EditItemArgs}
     * @memberof EditItemRequest
     */
    args: EditItemArgs;
}


/**
 * @export
 */
export const EditItemRequestTypeEnum = {
    CreateDao: 'createDAO',
    CreateNft: 'createNFT',
    EditDao: 'editDAO',
    AddItem: 'addItem',
    EditItem: 'editItem',
    Exchange: 'exchange',
    Proposal: 'proposal',
    Vote: 'vote',
    ApproveOutput: 'approveOutput',
    RejectOutput: 'rejectOutput',
    CreateOutput: 'createOutput',
    CreateTask: 'createTask'
} as const;
export type EditItemRequestTypeEnum = typeof EditItemRequestTypeEnum[keyof typeof EditItemRequestTypeEnum];


/**
 * Check if a given object implements the EditItemRequest interface.
 */
export function instanceOfEditItemRequest(value: object): boolean {
    if (!('type' in value)) return false;
    if (!('args' in value)) return false;
    return true;
}

export function EditItemRequestFromJSON(json: any): EditItemRequest {
    return EditItemRequestFromJSONTyped(json, false);
}

export function EditItemRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): EditItemRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'type': json['type'],
        'args': EditItemArgsFromJSON(json['args']),
    };
}

export function EditItemRequestToJSON(value?: EditItemRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'type': value['type'],
        'args': EditItemArgsToJSON(value['args']),
    };
}

