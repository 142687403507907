/* tslint:disable */
/* eslint-disable */
/**
 * Unyte SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ConfirmApplicationDto
 */
export interface ConfirmApplicationDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof ConfirmApplicationDto
     */
    applications: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ConfirmApplicationDto
     */
    status: ConfirmApplicationDtoStatusEnum;
}


/**
 * @export
 */
export const ConfirmApplicationDtoStatusEnum = {
    Approved: 'APPROVED',
    Rejected: 'REJECTED'
} as const;
export type ConfirmApplicationDtoStatusEnum = typeof ConfirmApplicationDtoStatusEnum[keyof typeof ConfirmApplicationDtoStatusEnum];


/**
 * Check if a given object implements the ConfirmApplicationDto interface.
 */
export function instanceOfConfirmApplicationDto(value: object): boolean {
    if (!('applications' in value)) return false;
    if (!('status' in value)) return false;
    return true;
}

export function ConfirmApplicationDtoFromJSON(json: any): ConfirmApplicationDto {
    return ConfirmApplicationDtoFromJSONTyped(json, false);
}

export function ConfirmApplicationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConfirmApplicationDto {
    if (json == null) {
        return json;
    }
    return {
        
        'applications': json['applications'],
        'status': json['status'],
    };
}

export function ConfirmApplicationDtoToJSON(value?: ConfirmApplicationDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'applications': value['applications'],
        'status': value['status'],
    };
}

