import React from "react";
import { BaseProps } from "../../../../types/common/index.ts";
import clsx from "clsx";

const themes = {
  add: clsx("bg-gradient-to-r from-[#EE7b4d] to-[#E5007E]"),
  edit: clsx("bg-gradient-to-r from-[#5E00FF] to-[#00A0FF]"),
  none: clsx(""),
};

export type ItemSubmitButtonProps = {
  theme?: "add" | "edit" | "none";
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
} & BaseProps;

export const ItemSubmitButton: React.FC<ItemSubmitButtonProps> = ({
  className,
  children,
  theme = "none",
  onClick,
}) => {
  return (
    <button
      type="submit"
      className={clsx(
        className,
        "w-32 rounded-full",
        "text-sm font-semibold text-gray-50",
        "px-3 py-2",
        `${themes[theme]}`
      )}
      onClick={onClick}
    >
      {children}
    </button>
  );
};
