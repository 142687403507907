import React, { useState } from "react";
import ProgressBar from "../../components/createDao/ProgressBar.tsx";
import Step1 from "../../components/createDao/Step1.tsx";
import Step2 from "../../components/createDao/Step2.tsx";
import Step3 from "../../components/createDao/Step3.tsx";
import Step4 from "../../components/createDao/Step4.tsx";
import "../../components/styles/UniSwaTextField.css";
import "../../components/styles/LoadingCheckBox.css";
import {
  VotingTokenCreateType,
  GovernanceTokenCreateType,
  Erc,
} from "../../types/dao/index.ts";
import { createDAO, createDAOId } from "../../actions/blockchain/createDAO.js";
import { createNFT } from "../../actions/blockchain/createNFT.js";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { getOpenseaCollection } from "../../actions/blockchain/createNFT.js";
import { createWorkspace } from "../../actions/firebase/workspace.ts";
import { fireauth } from "src/actions/firebase/config.js";
import { useUser } from "src/hooks/useUser.ts";
import { UserDto } from "src/types/api/index.ts";

const CreateDAOPage: React.FC = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // Step1
  const [daoName, setDaoName] = useState("");
  const [description, setDescription] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [confirmedValues, setConfirmedValues] = useState<string[]>([]);
  const [profileImage, setProfileImage] = useState<any>(null);
  const [profileBase64, setProfileBase64] = useState<any>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [link1, setLink1] = useState("");
  const [link2, setLink2] = useState("");
  const [link3, setLink3] = useState("");
  // Step2
  const [openSeaUrl, setOpenSeaUrl] = useState("");
  const [membershipNFTName, setMembershipNFTName] = useState("");
  const [isCreateNewToken, setIsCreateNewToken] = useState<boolean | null>(
    null
  );
  const [erc, setErc] = useState<Erc>("erc1155");
  const [membershipNFTDescription, setMembershipNFTDescription] = useState("");
  const [membershipNFTImage, setMembershipNFTImage] = useState<any>(null);
  const [membershipNFTImageBase64, setMembershipNFTImageBase64] =
    useState<any>(null);
  // Step3
  const [votingTokenCreateType, setVotingTokenCreateType] =
    useState<VotingTokenCreateType | null>(null);
  const [votingTokenDescription, setVotingTokenDescription] =
    useState<string>("");
  const [votingTokenName, setVotingTokenName] = useState<string>("");
  const [openSeaUrlForVotingToken, setOpenSeaUrlForVotingToken] =
    useState<string>("");
  const [votingTokenImage, setVotingTokenImage] = useState<any>(null);
  const [votingTokenImageBase64, setVotingTokenImageBase64] =
    useState<any>(null);

  // Step4
  const [governanceTokenCreateType, setGovernanceTokenCreateType] =
    useState<GovernanceTokenCreateType | null>(null);
  const [governanceTokenName, setGovernanceTokenName] = useState<string>("");
  const [governanceTokenDescription, setGovernanceTokenDescription] =
    useState<string>("");
  const [governanceTokenAddress, setGovernanceTokenAddress] =
    useState<string>("");
  const [governanceTokenImage, setGovenanceTokenImage] = useState<any>(null);
  const [governanceTokenImageBase64, setGovernanceTokenImageBase64] =
    useState<any>(null);
  const { user } = useUser();
  // confirm
  const [isCreatedJoinToken, setIsCreatedJoinToken] = useState<boolean>(false);
  const [isCreatedVotingToken, setIsCreatedVotingToken] =
    useState<boolean>(false);
  const [isCreatedGovernanceToken, setIsCreatedGovernanceToken] =
    useState<boolean>(false);
  const [isCreatedDao, setIsCreatedDao] = useState<boolean>(false);
  const [daoId, setDaoId] = useState<string>("");

  const renderFormByStep = (step: number) => {
    switch (step) {
      case 1:
        return (
          <Step1
            daoName={daoName}
            setDaoName={setDaoName}
            description={description}
            setDescription={setDescription}
            inputValue={inputValue}
            setInputValue={setInputValue}
            confirmedValues={confirmedValues}
            setConfirmedValues={setConfirmedValues}
            profileImage={profileImage}
            setProfileImage={setProfileImage}
            profileBase64={profileBase64}
            setProfileBase64={setProfileBase64}
            link1={link1}
            setLink1={setLink1}
            link2={link2}
            setLink2={setLink2}
            link3={link3}
            setLink3={setLink3}
          />
        );
      case 2:
        return (
          <Step2
            openSeaUrl={openSeaUrl}
            setOpenSeaUrl={setOpenSeaUrl}
            membershipNFTName={membershipNFTName}
            setMembershipNFTName={setMembershipNFTName}
            isCreateNewToken={isCreateNewToken}
            setIsCreateNewToken={setIsCreateNewToken}
            membershipNFTDescription={membershipNFTDescription}
            setMembershipNFTDescription={setMembershipNFTDescription}
            membershipNFTImage={membershipNFTImage}
            setMembershipNFTImage={setMembershipNFTImage}
            membershipNFTImageBase64={membershipNFTImageBase64}
            setMembershipNFTImageBase64={setMembershipNFTImageBase64}
            erc={erc}
            setErc={setErc}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
          />
        );
      case 3:
        return (
          <Step3
            votingTokenCreateType={votingTokenCreateType}
            setVotingTokenCreateType={setVotingTokenCreateType}
            votingTokenName={votingTokenName}
            setVotingTokenName={setVotingTokenName}
            votingTokenDescription={votingTokenDescription}
            setVotingTokenDescription={setVotingTokenDescription}
            openSeaUrlForVotingToken={openSeaUrlForVotingToken}
            setOpenSeaUrlForVotingToken={setOpenSeaUrlForVotingToken}
            votingTokenImage={votingTokenImage}
            setVotingTokenImage={setVotingTokenImage}
            votingTokenImageBase64={votingTokenImageBase64}
            setVotingTokenImageBase64={setVotingTokenImageBase64}
          />
        );
      case 4:
        return (
          <Step4
            governanceTokenCreateType={governanceTokenCreateType}
            setGovernanceTokenCreateType={setGovernanceTokenCreateType}
            governanceTokenName={governanceTokenName}
            setGovernanceTokenName={setGovernanceTokenName}
            governanceTokenDescription={governanceTokenDescription}
            setGovernanceTokenDescription={setGovernanceTokenDescription}
            governanceTokenAddress={governanceTokenAddress}
            setGovernanceTokenAddress={setGovernanceTokenAddress}
            govenanceTokenImage={governanceTokenImage}
            setGovenanceTokenImage={setGovenanceTokenImage}
            governanceTokenImageBase64={governanceTokenImageBase64}
            setGovernanceTokenImageBase64={setGovernanceTokenImageBase64}
          />
        );
      default:
        return null;
    }
  };

  const navigateDetail = () => {
    navigate(`/${daoId}`);
  };

  const setTitle = () => {
    switch (step) {
      case 1:
        return (
          <FormattedMessage
            id="step1_title"
            defaultMessage="DAOの情報を入力する"
          />
        );
      case 2:
        return (
          <FormattedMessage
            id="step2_title"
            defaultMessage="DAO参加用NFTの発行"
          />
        );
      case 3:
        return (
          <FormattedMessage id="step3_title" defaultMessage="投票用NFTの発行" />
        );
      case 4:
        return (
          <FormattedMessage
            id="step4_title"
            defaultMessage="貢献ポイントの発行"
          />
        );
      default:
        return "";
    }
  };

  const validationStep = () => {
    switch (step) {
      case 1:
        if (daoName === "" || description === "" || profileBase64 === null) {
          alert("DAO名と説明は必須です。");
          return;
        }
        setStep((prev) => Math.max(1, prev + 1));
        break;
      case 2:
        if (isCreateNewToken) {
          if (
            membershipNFTName === "" ||
            membershipNFTDescription === "" ||
            membershipNFTImage === ""
          ) {
            alert("NFT名と説明は必須です。");
            return;
          }
        } else if (!isCreateNewToken) {
          if (openSeaUrl === "") {
            alert("OpenSeaのURLは必須です。");
            return;
          }
        } else {
          alert("NFTの作成方法を選択してください。");
          return;
        }
        setStep((prev) => Math.max(1, prev + 1));
        break;

      case 3:
        if (votingTokenCreateType === "new-create") {
          if (
            votingTokenName === "" ||
            votingTokenDescription === "" ||
            votingTokenImage === ""
          ) {
            alert("NFT名と説明は必須です。");
            return;
          }
        } else if (votingTokenCreateType === "reuse-by-opensea") {
          if (openSeaUrlForVotingToken === "") {
            alert("OpenSeaのURLは必須です。");
            return;
          }
        } else if (votingTokenCreateType === null) {
          alert("NFTの作成方法を選択してください。");
          return;
        }
        setStep((prev) => Math.max(1, prev + 1));
        break;
      case 4:
        if (governanceTokenCreateType === "new-create") {
          if (
            governanceTokenName === "" ||
            governanceTokenDescription === "" ||
            governanceTokenImage === ""
          ) {
            alert("NFT名と説明は必須です。");
            return;
          }
        } else if (governanceTokenCreateType === "reuse-by-token") {
          if (governanceTokenAddress === "") {
            alert("トークンアドレスは必須です。");
            return;
          }
        } else if (governanceTokenCreateType === null) {
          alert("NFTの作成方法を選択してください。");
          return;
        }
        initDAO(user);
        break;
    }
  };

  const initDAO = async (user: UserDto | undefined) => {
    if (user === undefined) return;
    // TODO: 関数の順番を変更。まずIDを取得→NFTを作成→DAOを作成
    setIsModalOpen(true);
    setIsLoading(true);
    const daoId = await createDAOId(user);

    // token情報の定義
    let joinNFT;
    let votingNFT;
    let contributionNFT;
    // 条件によって、NFTを作成するか、既存のNFTを使うかを判断する
    if (isCreateNewToken) {
      try {
        joinNFT = await createNFT(
          daoId,
          membershipNFTName,
          "join",
          membershipNFTImageBase64,
          user
        );
      } catch (e) {
        if (e instanceof Error) {
          alert(
            `DAO作成に失敗した可能性があります。ご確認ください。\n${e.stack}`
          );
        } else
          alert(
            `DAO作成に失敗した可能性があります。ご確認ください。\n\n${JSON.stringify(
              e
            )}`
          );
        console.error(e);
        setIsLoading(false);
        return;
      }
    } else {
      // openseaのコレクション名を取得して、NFTがあるかを確認する。
      // TODO: ここで、NFTが存在しているかを確認する
      const collectionInfo = await getOpenseaCollection(openSeaUrl);
      joinNFT = {
        tokenAddress: collectionInfo.tokenAddress,
        chainId: collectionInfo.chainId,
        tokenId: collectionInfo.tokenId,
      };
    }
    if (joinNFT) {
      setIsCreatedJoinToken(true);
    }

    if (votingTokenCreateType === "new-create") {
      try {
        votingNFT = await createNFT(
          daoId,
          votingTokenName,
          "governance",
          votingTokenImageBase64,
          user
        );
      } catch (e) {
        if (e instanceof Error) {
          alert(
            `DAO作成に失敗した可能性があります。ご確認ください。\n${e.stack}`
          );
        } else
          alert(
            `DAO作成に失敗した可能性があります。ご確認ください。\n\n${JSON.stringify(
              e
            )}`
          );
        console.error(e);
        setIsLoading(false);
        return;
      }
    } else if (votingTokenCreateType === "reuse-by-opensea") {
      // openseaのコレクション名を取得して、NFTがあるかを確認する。
      // TODO: ここで、NFTが存在しているかを確認する
      // 画面フィードバック: chainId入力欄、tokenId入力欄
      const collectionInfo = await getOpenseaCollection(
        openSeaUrlForVotingToken
      );
      votingNFT = {
        tokenAddress: collectionInfo.tokenAddress,
        chainId: collectionInfo.chainId,
        tokenId: collectionInfo.tokenId,
      };
    } else if (votingTokenCreateType === "reuse-by-membership") {
      votingNFT = {
        tokenAddress: joinNFT.tokenAddress,
        chainId: joinNFT.chainId,
        tokenId: joinNFT.tokenId,
      };
    }
    if (votingNFT) {
      setIsCreatedVotingToken(true);
    }

    if (governanceTokenCreateType === "new-create") {
      try {
        contributionNFT = await createNFT(
          daoId,
          governanceTokenName,
          "contribution",
          governanceTokenImageBase64,
          user
        );
      } catch (e) {
        if (e instanceof Error) {
          alert(
            `DAO作成に失敗した可能性があります。ご確認ください。\n${e.stack}`
          );
        } else
          alert(
            `DAO作成に失敗した可能性があります。ご確認ください。\n\n${JSON.stringify(
              e
            )}`
          );
        console.error(e);
        setIsLoading(false);
        return;
      }
    } else if (governanceTokenCreateType === "reuse-by-token") {
      contributionNFT = {
        tokenAddress: governanceTokenAddress,
        // TODO: chainIdとtokenIdどうするか決めておく
        chainId: 137,
        tokenId: 1,
      };
      // もものが存在していうしいものが存在しているかを確認する
    } else if (governanceTokenCreateType === "reuse-by-voting-token") {
      contributionNFT = {
        tokenAddress: votingNFT.tokenAddress,
        chainId: votingNFT.chainId,
        tokenId: votingNFT.tokenId,
      };
    }
    if (contributionNFT) {
      setIsCreatedGovernanceToken(true);
    }

    if (!joinNFT || !votingNFT || !contributionNFT) {
      alert("NFTの作成に失敗しました。");
      return;
    }
    const joinToken = {
      tokenAddress: process.env.REACT_APP_UNYTE1155_CONTRACT_ADDRESS,
      chainId: 137,
      tokenId: joinNFT.tokenId,
    };
    const votingToken = {
      tokenAddress: process.env.REACT_APP_UNYTE1155_CONTRACT_ADDRESS,
      chainId: 137,
      tokenId: votingNFT.tokenId,
    };
    const contributionToken = {
      tokenAddress: process.env.REACT_APP_UNYTE1155_CONTRACT_ADDRESS,
      chainId: 137,
      tokenId: contributionNFT.tokenId,
    };
    if (!fireauth.currentUser) {
      throw Error("not found auth");
    }
    await createWorkspace(daoId); // 後続の処理に失敗した場合でも、workspaceのデータは保存されてしまうため、不整合の原因となる場合がある
    const links = JSON.stringify([link1, link2, link3]);
    let createdDAOInfo;
    try {
      createdDAOInfo = await createDAO(
        daoId,
        daoName,
        description,
        profileBase64,
        confirmedValues,
        joinToken,
        votingToken,
        contributionToken,
        isOpen,
        links,
        user
      );
    } catch (e) {
      if (e instanceof Error) {
        alert(
          `DAO作成に失敗した可能性があります。ご確認ください。\n${e.stack}`
        );
      } else
        alert(
          `DAO作成に失敗した可能性があります。ご確認ください。\n\n${JSON.stringify(
            e
          )}`
        );
      console.error(e);
      setIsLoading(false);
      return;
    }
    if (createdDAOInfo) {
      setIsCreatedDao(true);
      setIsLoading(false);
      setDaoId(daoId);
    }

    // ikisuke
    // createNFTを行うために、DAOIdを取得する必要がある
    // そのため、createDAOの返り値にresponseのjsonが必要
    if (typeof createdDAOInfo === "undefined") {
      alert("DAOの作成に失敗しました。");
      return;
    }
  };

  return (
    <div className="relative flex h-[640px] items-start justify-center bg-unyte-main">
      <div className="bg-lighter-navy ml-auto mr-auto w-full max-w-5xl rounded-lg p-6">
        <div className="mb-6">
          <div className="text-md mx-auto mb-2 w-full text-center text-white">
            {setTitle()}
          </div>
          <div className="space-between m-auto flex w-4/6 ">
            <p className="w-1/12 text-[#ED7C4B]">{step} / 4</p>
            <ProgressBar step={step} />
          </div>
        </div>

        {/* モーダルの表示 */}
        {isModalOpen && (
          <div className="fixed inset-0 z-10">
            <div className="flex h-screen items-center justify-center">
              <div className="fixed inset-0 w-full bg-gray-500 bg-opacity-75 transition-opacity"></div>
              <div className="max-h-screen w-8/12 max-w-md transform overflow-hidden rounded-lg bg-unyte p-px text-left text-white shadow-xl transition-all sm:w-1/2">
                <div className="mx-auto h-full w-full rounded-lg bg-[#28282D] px-4 pb-4 pt-5">
                  {/* ローディング中にアニメーションみたいな感じで色が変われば良さそう */}
                  <div className="mb-8 mt-10">
                    {isLoading ? (
                      <div className="circle-anime mx-auto flex h-20 w-20 items-center justify-center rounded-full bg-gradient-to-r from-[#FFA88C] via-[#EA7B57] via-10% to-[#DF147F]">
                        <div className="m-auto h-16 w-16 rounded-full bg-[#28282D]"></div>
                      </div>
                    ) : (
                      <div className="mt-[72px]">
                        <div className="custom-check-icon mx-auto"></div>
                      </div>
                    )}
                    <div className="mx-auto w-full text-center">
                      {isLoading ? "作成中..." : "作成完了！"}
                    </div>
                    <div className="mx-auto mt-8 flex w-6/12 flex-col space-y-2 text-lg">
                      <label className="loading-checkbox">
                        <div className="flex">
                          <input
                            type="checkbox"
                            disabled
                            checked={isCreatedJoinToken}
                          />
                          <span className="mr-3 mt-1" />
                          <p>DAO参加用NFTの作成</p>
                        </div>
                      </label>
                      <label className="loading-checkbox">
                        <div className="flex">
                          <input
                            type="checkbox"
                            disabled
                            checked={isCreatedVotingToken}
                          />
                          <span className="mr-3 mt-1" />
                          <p>投票用NFTの作成</p>
                        </div>
                      </label>
                      <label className="loading-checkbox">
                        <div className="flex">
                          <input
                            type="checkbox"
                            disabled
                            checked={isCreatedGovernanceToken}
                          />
                          <span className="mr-3 mt-1" />
                          <p>貢献ポイントの作成</p>
                        </div>
                      </label>
                      <label className="loading-checkbox">
                        <div className="flex">
                          <input
                            type="checkbox"
                            disabled
                            checked={isCreatedDao}
                          />
                          <span className="mr-3 mt-1" />
                          <p>DAOの作成</p>
                        </div>
                      </label>
                    </div>
                  </div>
                  <button
                    className={` ${
                      isLoading
                        ? "bg-[#38373D] text-[#969697]"
                        : "bg-unyte text-white"
                    } space-between mx-auto mb-8  flex h-12 w-48 rounded-full px-4 py-2 text-center`}
                    onClick={() => navigateDetail()}
                    disabled={isLoading}
                  >
                    <div className="m-auto">
                      <p>{isLoading ? "少々お待ちください" : "DAOの詳細へ"}</p>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {renderFormByStep(step)}
        <div className="absolute bottom-[5%] left-0 w-full text-white ">
          <div className="mx-auto flex w-4/12 justify-between">
            <button
              className={`space-between flex w-28 rounded-full bg-gray-500 px-4 py-2 text-center ${
                step === 1 ? "cursor-default !bg-[#131218] text-[#131218]" : ""
              }`}
              onClick={() => setStep((prev) => Math.min(4, prev - 1))}
            >
              {/* 矢印のアイコンを突っ込む */}
              <AiOutlineLeft className="mt-1 w-1/6" />
              <div className="mx-auto w-2/5 text-center text-sm">
                <FormattedMessage
                  id="dao_create_back_button"
                  defaultMessage="戻る"
                />
              </div>
            </button>

            {step !== 4 && (
              <button
                className="space-between flex w-28 rounded-full bg-[#ED7C4B] px-4 py-2 text-center text-white"
                onClick={() => validationStep()}
              >
                <div className="mx-auto text-sm">
                  <FormattedMessage
                    id="dao_create_next_button"
                    defaultMessage="つぎへ進む"
                  />
                </div>
                <AiOutlineRight className="mt-1  w-1/6" />
              </button>
            )}
            {step === 4 && (
              <button
                className="space-between flex w-28 rounded-full bg-unyte-button-sub px-4 py-2 text-center text-white"
                onClick={() => initDAO(user)}
              >
                <div className="mx-auto text-sm">
                  <FormattedMessage
                    id="dao_create_submit_button"
                    defaultMessage="DAO作成"
                  />
                </div>
                <AiOutlineRight className="mt-1 w-1/6" />
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateDAOPage;
