/* tslint:disable */
/* eslint-disable */
/**
 * Unyte SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TaskOperation
 */
export interface TaskOperation {
    /**
     * 
     * @type {string}
     * @memberof TaskOperation
     */
    name: TaskOperationNameEnum;
    /**
     * 
     * @type {string}
     * @memberof TaskOperation
     */
    operationId: TaskOperationOperationIdEnum;
    /**
     * 
     * @type {string}
     * @memberof TaskOperation
     */
    color: TaskOperationColorEnum;
    /**
     * 
     * @type {number}
     * @memberof TaskOperation
     */
    reward: number;
    /**
     * 
     * @type {string}
     * @memberof TaskOperation
     */
    description: TaskOperationDescriptionEnum;
    /**
     * 
     * @type {string}
     * @memberof TaskOperation
     */
    value: string;
}


/**
 * @export
 */
export const TaskOperationNameEnum = {
    Other: 'Other',
    XHashTag: 'X HashTag'
} as const;
export type TaskOperationNameEnum = typeof TaskOperationNameEnum[keyof typeof TaskOperationNameEnum];

/**
 * @export
 */
export const TaskOperationOperationIdEnum = {
    _3: '3',
    _1: '1'
} as const;
export type TaskOperationOperationIdEnum = typeof TaskOperationOperationIdEnum[keyof typeof TaskOperationOperationIdEnum];

/**
 * @export
 */
export const TaskOperationColorEnum = {
    Bg171920: 'bg-[#171920]',
    Bg1719202: 'bg-[#171920]'
} as const;
export type TaskOperationColorEnum = typeof TaskOperationColorEnum[keyof typeof TaskOperationColorEnum];

/**
 * @export
 */
export const TaskOperationDescriptionEnum = {
    otherDescription: 'テキストで成果報告をするタスクです。',
    xTaskDescription: 'ハッシュタグをつけてポストするタスクです。'
} as const;
export type TaskOperationDescriptionEnum = typeof TaskOperationDescriptionEnum[keyof typeof TaskOperationDescriptionEnum];


/**
 * Check if a given object implements the TaskOperation interface.
 */
export function instanceOfTaskOperation(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('operationId' in value)) return false;
    if (!('color' in value)) return false;
    if (!('reward' in value)) return false;
    if (!('description' in value)) return false;
    if (!('value' in value)) return false;
    return true;
}

export function TaskOperationFromJSON(json: any): TaskOperation {
    return TaskOperationFromJSONTyped(json, false);
}

export function TaskOperationFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaskOperation {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'operationId': json['operationId'],
        'color': json['color'],
        'reward': json['reward'],
        'description': json['description'],
        'value': json['value'],
    };
}

export function TaskOperationToJSON(value?: TaskOperation | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'operationId': value['operationId'],
        'color': value['color'],
        'reward': value['reward'],
        'description': value['description'],
        'value': value['value'],
    };
}

