import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { MyPageView } from "../../types/dao/index.js";
import Activity from "./Activity.tsx";
import Credential from "./Credential.tsx";
import Profile from "./Profile.tsx";
import { fbLogout } from "../../actions/firebase/session.ts";
import { decryptoPrivateKey } from "../../actions/blockchain/generateKeys.js";
import "../../components/styles/SidebarAnime.css";
import { useUser } from "src/hooks/useUser.ts";
import Loading from "src/components/common/Loading.tsx";

const MyPage: React.FC = () => {
  const navigate = useNavigate();

  const modeData = [
    "プロフィール設定",
    "保有NFTの表示",
    "秘密鍵の表示",
    "シェアの表示",
    "シェアの再設定",
    "ログアウト",
  ] as const;

  const { user, isError: userError, isLoading: userLoading } = useUser();
  const location = useLocation();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const daoMode = location.pathname.split("/")[3] as MyPageView;
  const [mode, setMode] = useState<MyPageView>(daoMode ? daoMode : "profile");

  const sidebarToggle = () => {
    setSidebarOpen((prev) => !prev);
  };

  const handleModeClick = async (value: any) => {
    switch (value) {
      case "プロフィール設定":
        setMode("profile");
        break;
      case "保有NFTの表示": {
        if (!user) {
          const errorMsg = `ログインできていません`;
          console.error(errorMsg);
          return;
        }
        const raribleUrl =
          process.env.REACT_APP_STAGE === "dev"
            ? `https://testnet.rarible.com/user/${user.address}/owned`
            : `https://magiceden.io/ja/u/${user.address}?activeTab=allItems&chains=["polygon"]&wallets=["${user.address}"]`;
        window.open(raribleUrl, "_blank", "noopener, noreferrer");
        break;
      }
      case "秘密鍵の表示": {
        const keyBytes = (await decryptoPrivateKey(user)) as string[] | null;
        if (!keyBytes || keyBytes.length === 0) {
          const errorMsg = `秘密鍵の復元に失敗しました\nシェアを再設定しますか？`;
          console.error(errorMsg);
          const isConfirm = confirm(errorMsg);
          if (isConfirm) navigate("/reset");
          return;
        }
        setMode("credential");
        break;
      }
      case "シェアの表示":
        navigate("/reshow-share");
        break;
      case "シェアの再設定":
        navigate("/reset");
        break;
      case "ログアウト":
        fbLogout();
        break;
      default:
        break;
    }
  };

  const closeModal = () => {
    setSidebarOpen((prev) => false);
  };

  const whenSelectedMode = (value) =>
    value === mode ? "underline" : "text-[#969696]";

  if (userLoading) return <Loading isShow={true} />;
  if (userError || !user) return <></>;
  return (
    <>
      <div className="flex w-full flex-col lg:h-[calc(100vh-7.2rem)] lg:flex-row">
        <button
          className="block w-fit cursor-pointer text-white lg:hidden"
          onClick={() => sidebarToggle()}
        >
          <div className="hamburger-icon">
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
          </div>
        </button>
        <div className="hidden w-1/6 flex-col bg-[#1B1D24] lg:block">
          <div className="flex grow flex-row">
            <div className={`w-2/5 grow pr-4 pt-4 text-xl text-white`}>
              <ul className="mt-10">
                {modeData.map((value, idx) => (
                  <li
                    className={`ml-6 mt-6 w-full text-left uppercase hover:cursor-pointer ${whenSelectedMode(
                      value
                    )}`}
                    onClick={() => {
                      handleModeClick(value);
                    }}
                    key={idx}
                  >
                    {value}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        <div className="w-full overflow-auto lg:w-5/6">
          {mode === "profile" && <Profile />}
          {mode === "activity" && <Activity />}
          {mode === "credential" && <Credential />}
        </div>
        {sidebarOpen && (
          <>
            <div className="fixed inset-0 z-40">
              <div className="flex h-screen items-center">
                <div className="sidebarAnime flex h-screen w-2/3 flex-col bg-[#1B1D24] md:w-1/2">
                  <div className="flex grow flex-row">
                    <div className={`w-2/5 grow pr-4 pt-4 text-xl text-white`}>
                      <ul className="mt-10">
                        {modeData.map((value, idx) => (
                          <li
                            className={`ml-6 mt-6 w-full text-left uppercase hover:cursor-pointer ${whenSelectedMode(
                              value
                            )}`}
                            onClick={() => {
                              closeModal();
                              handleModeClick(value);
                            }}
                            key={idx}
                          >
                            {value}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
                <div
                  className="inset-0 h-screen w-1/3 transition-opacity md:w-1/2"
                  onClick={() => closeModal()}
                ></div>
              </div>
            </div>
            <div className="absolute top-0 z-30 h-screen w-screen bg-gray-500 opacity-50"></div>
          </>
        )}
      </div>
    </>
  );
};

export default MyPage;
