import { useIsUpdating } from "src/hooks/useIsUpdating";
import { restApi } from "src/libs/restClient";
import useSWR from "swr";

const fetcher = async (key: string) => {
  const daoId = key.split("/")[2];
  return restApi.proposalControllerFetchProposals({ daoId });
};

export const useProposals = (daoId: string | undefined) => {
  const key = daoId ? `/proposals/${daoId}` : null;
  const {
    data,
    error,
    isLoading,
    mutate: mutateProposals,
  } = useSWR(key, fetcher);
  const { isUpdating, checkUpdated } = useIsUpdating({
    data,
    mutate: mutateProposals,
  });

  const mutate = async () => {
    checkUpdated();
    await mutateProposals();
  };

  return { dataProposals: data, isError: error, isLoading, isUpdating, mutate };
};
