import React from "react";
import { BaseProps } from "../../types/common/index.ts";
import { Dao } from "src/types/api/index.ts";
import { DaoAvatar } from "src/components/dao/DaoAvatar.tsx";
import { DaoName } from "src/components/dao/DaoName.tsx";
import { DaoMemberCount } from "src/components/dao/DaoMemberCount.tsx";
import { GoDao } from "src/components/dao/GoDao.tsx";
import { JoinDao } from "src/components/dao/JoinDao.tsx";
import { CloseDao } from "src/components/dao/CloseDao.tsx";
import { NeedFundDao } from "src/components/dao/NeedFundDao.tsx";

export type DaoCardButtonProps = {
  dao: Dao;
  userAddress: string;
  setSelectedDao: React.Dispatch<React.SetStateAction<Dao | null>>;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
} & BaseProps;

export const DaoCardButton: React.FC<DaoCardButtonProps> = ({
  className,
  dao,
  userAddress,
  setSelectedDao,
  setIsModalOpen,
}) => {
  const isMember = dao.members.includes(userAddress);

  const handleCardClick = () => {
    setSelectedDao(dao);
    setIsModalOpen(true);
  };

  const selectDaoText = () => {
    if (isMember) return <GoDao className="mb-4 w-full" />;
    if (dao.isLlcDao) return <NeedFundDao className="mb-4 w-full" />;
    if (dao.isOpen) return <JoinDao className="mb-4 w-full" />;
    return <CloseDao className="mb-4 w-full" />;
  };

  return (
    <button
      key={dao.daoId}
      className={`${className} rounded-lg bg-[#1B1D24] shadow-lg lg:p-5`}
      onClick={handleCardClick}
    >
      <div className="m-auto my-2 mt-4 w-full lg:my-4">
        <DaoAvatar
          className="mx-auto mb-2 h-20 w-20 lg:mb-6"
          avatar={dao.avatar}
        />
        <DaoName className="text-center text-lg font-bold" name={dao.name} />
        <DaoMemberCount className="mb-2 lg:mb-6" cnt={dao.members.length} />
        <div className="mx-auto w-3/5">{selectDaoText()}</div>
      </div>
    </button>
  );
};
