import React from "react";
import { OffChainOutputDetail } from "src/components/Task/OffChainOutputDetail.tsx";
import { Output, Task } from "src/types/api/index.ts";

export type OffChainOutputDetailsProps = {
  outputs: Output[];
  task: Task;
};

export const OffChainOutputDetails: React.FC<OffChainOutputDetailsProps> = ({
  outputs,
  task,
}: OffChainOutputDetailsProps) => {
  if (outputs.length <= 0) return <></>;
  return (
    <>
      {task.type === "xTask" &&
        outputs.map((output) => (
          <OffChainOutputDetail
            task={task}
            output={output}
            key={output.outputId}
          />
        ))}
    </>
  );
};
