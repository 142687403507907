/* tslint:disable */
/* eslint-disable */
/**
 * Unyte SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Vote } from './Vote';
import {
    VoteFromJSON,
    VoteFromJSONTyped,
    VoteToJSON,
} from './Vote';

/**
 * 
 * @export
 * @interface VoteArgs
 */
export interface VoteArgs {
    /**
     * DAOのID
     * @type {string}
     * @memberof VoteArgs
     */
    daoId: string;
    /**
     * proposalのID(名前がややこしい)
     * @type {string}
     * @memberof VoteArgs
     */
    voteId: string;
    /**
     * 投票内容
     * @type {Vote}
     * @memberof VoteArgs
     */
    vote: Vote;
}

/**
 * Check if a given object implements the VoteArgs interface.
 */
export function instanceOfVoteArgs(value: object): boolean {
    if (!('daoId' in value)) return false;
    if (!('voteId' in value)) return false;
    if (!('vote' in value)) return false;
    return true;
}

export function VoteArgsFromJSON(json: any): VoteArgs {
    return VoteArgsFromJSONTyped(json, false);
}

export function VoteArgsFromJSONTyped(json: any, ignoreDiscriminator: boolean): VoteArgs {
    if (json == null) {
        return json;
    }
    return {
        
        'daoId': json['daoId'],
        'voteId': json['voteId'],
        'vote': VoteFromJSON(json['vote']),
    };
}

export function VoteArgsToJSON(value?: VoteArgs | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'daoId': value['daoId'],
        'voteId': value['voteId'],
        'vote': VoteToJSON(value['vote']),
    };
}

