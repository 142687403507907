import React from "react";
import "../../../styles/UniSwaTextField.css";
import { FormattedMessage } from "react-intl";
import clsx from "clsx";
import { useAtom } from "jotai";
import { NotSelectedItemIcon } from "./NotSelectedItemIcon/NotSelectedItemIcon.tsx";
import { BaseProps } from "../../../../types/common/index.ts";
import { tmpAddAndEditItemState } from "src/stores/tmpItemState.ts";

export type ItemIconInputProps = {} & BaseProps;

export const ItemIconInput: React.FC<ItemIconInputProps> = (
  props: ItemIconInputProps
) => {
  const [tmpItem, setTmpItem] = useAtom(tmpAddAndEditItemState);
  const image = tmpItem.image;

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (!file) return;
    const reader = new FileReader();
    reader.onload = (event) => {
      if (!reader.result || typeof reader.result !== "string") return;
      const base64 = reader.result.split(",")[1];
      setTmpItem((prev) => {
        const newTmpItem = { ...prev };
        newTmpItem.image = event.target?.result as string;
        newTmpItem.base64 = base64;
        return newTmpItem;
      });
    };
    reader.readAsDataURL(file);
  };

  return (
    <div className={clsx(props.className, "text-white")}>
      <div className={clsx("text-center text-sm", "mb-2")}>
        <FormattedMessage id="icon_image" defaultMessage="アイコン画像" />
      </div>
      <label
        className={clsx(
          "flex items-center justify-center",
          "h-40 w-40 rounded-full",
          "m-auto",
          "bg-[#38373D]",
          "hover:cursor-pointer"
        )}
        htmlFor="profileImage"
      >
        <input
          id="profileImage"
          className={clsx("hidden", "bg-gray-50")}
          type="file"
          onChange={handleImageChange}
        />
        {image === "" ? (
          <NotSelectedItemIcon />
        ) : (
          <img
            src={image}
            alt="Profile"
            className={clsx("h-40 w-40 rounded-full")}
          />
        )}
      </label>
    </div>
  );
};
