import React from "react";
import { BaseProps, InputFile } from "../../types/common/index.ts";
import { MdCancel } from "react-icons/md";

export type CancelUploadFileButtonProps = {
  inputFileName: string;
  setInputFiles: React.Dispatch<React.SetStateAction<InputFile[]>>;
} & BaseProps;

export const CancelUploadFileButton: React.FC<CancelUploadFileButtonProps> = ({
  className,
  inputFileName,
  setInputFiles,
}) => {
  const handleClick = () => {
    setInputFiles((prev) =>
      prev.filter((inputFile, index) => inputFile.file.name !== inputFileName)
    );
  };

  return (
    <button className={`${className} text-white`} onClick={handleClick}>
      <MdCancel className="h-4 w-4" />
    </button>
  );
};
