const en = {
  // ▼ togase 230923
  // sso login
  sso_login_title: "Login",
  sso_login_google: "Login with Google",
  sso_login_x: "Login with X (formerly Twitter)",
  sso_login_exit: "Login with Email",
  // email login
  email_login_title: "Email Login",
  email_login_button: "Login",
  email_login_exit: "Sign Up with Email",
  email_login_back_sso: "Back to SSO Login",
  // email signup
  email_signup_title: "Email Sign Up",
  email_signup_button: "Sign Up",
  email_signup_exit: "Login with Email",
  email_signup_back_sso: "Back to SSO Login",
  // register
  register_title: "Save Share",
  register_button_1: "Copy Share to Clipboard",
  backup_send_email: "Send Backup Key via Email",
  register_button_2: "Send Share via Email",
  register_next: "Saved, Proceed to Next",
  // frontend/src/components/register/UserRegister.tsx
  user_register_name: "Name",
  user_register_email: "Email",
  user_register_twitter: "X(Twitter) Account",
  // frontend/src/components/register/ShareDescription.tsx
  share_description_title: "Create New Share",
  share_description_text:
    'The following screen displays "Share" to be used when logging in with a different device (such as mobile) or a different browser than the one you are currently using, or when restoring your account due to a change of device model, etc. \nPlease be sure to read the following instructions, make a copy of it, name it and save it in Notepad, etc.',
  share_description_block_1:
    "The service cannot be used in environments where the share is not registered.",
  share_description_block_2:
    "Please be sure to save your share and keep it with you so that you do not lose it.",
  share_description_block_3:
    "If a share is lost, it cannot be reissued or redisplayed.",
  share_description_next_button: "OK, I understand",
  share_save_text: `You will see a string starting with "UnyteShare_". This key cannot be reissued, so be sure to write it down. Tap the "Copy" button to copy it to the clipboard. Paste it into a notepad or similar to save it.`,
  // ▲
  // frontend/src/components/register/ShareConfirm.tsx
  share_confirm_title: "Share Confirmation",
  share_confirm_description:
    "Please confirm your share. Enter the last 4 digits of your share.",
  share_confirm_lost_key: "I've lost my share",
  share_confirm_button: "Confirm",
  share_confirm_error: "The last 4 digits of the share do not match.",
  // ▲
  header_mypage: "MY PAGE",
  header_nav_search: "SEARCH",
  header_nav_participated: "PARTICIPATED DAO",
  header_menu_account: "ACCOUNT",
  header_menu_logout: "LOGOUT",
  list_join: "Join",
  list_get_nft: "Get NFT",
  list_closed: "Limited",
  list_closed_message: "Only NFT holders can participate",
  dao_create_back_button: "BACK",
  dao_create_next_button: "NEXT",
  dao_create_submit_button: "CREATE",
  dao_detail_category: "Category",
  dao_detail_website: "Website",
  dao_detail_social: "Official Site",
  step1_title: "Create DAO",
  step1_name: "DAO Name",
  step1_description: "Description",
  step1_description_admin: "Admin",
  step1_isOpen: "Open",
  step1_link: "DAO Link①",
  step1_link2: "DAO Link②",
  step1_link3: "DAO Link③",
  step_select: "Select",
  select_file: "Select File",
  select_file2: " ",
  icon_image: "Icon Image",
  step2_title: "Create Membership NFT",
  step2_new_create: "New Create",
  step2_reuse: "Reuse",
  step2_reuse_placeholder: "Opensea Collection URL",
  step2_name_placeholder: "Name",
  step2_description_placeholder: "Description",
  step2_option_1: "Require Membership NFT to Join",
  step2_option_2: "Anyone can Join",
  step3_title: "Create Voting NFT",
  step3_new_create: "New Create",
  step3_reuse: "Reuse",
  step3_reuse_joinToken: "Use Join Token",
  step3_name_placeholder: "Name",
  step3_description_placeholder: "Description",
  step3_option1: "1 Token = 1 Vote",
  step3_option2: "1 Person = 1 Vote",
  step3_reuse_placeholder: "Opensea Collection URL",
  step4_title: "Create Contribution NFT",
  step4_new_create: "New Create",
  step4_reuse: "Reuse someone Token",
  step4_reuse_governanceToken: "Use Governance Token",
  step4_name_placeholder: "Name",
  step4_description_placeholder: "Description",
  step4_reuse_placeholder: "Token Address",
  // ▼ items
  item_name: "Item Name",
  item_description: "Description",
  item_price: "Price",
  item_amount: "Amount",
  confirm_message_delete: "You are trying to delete a message. Are you sure?",
  confirm_delete: "You are trying to delete a channel. Are you sure?",
  dao_settings_add_admin:
    "Please enter the wallet address of the user you want to add",
  user_register_button: "Register",
  // thanks
  thanks_period_daily: "Past 24 hours",
  thanks_period_weekly: "Past 7 days",
  thanks_period_monthly: "Past 1 month",
  thanks_period_yearly: "Past 1 year",
  thanks_period_wholetime: "WholeTime",
  thanks_header_liquidity: "Thanks",
  thanks_modal_title: "SEND THANKS",
  thanks_modal_from: "From",
  thanks_modal_to: "To",
  thanks_modal_message: "Message",
  thanks_modal_amount: "Amount",
  thanks_modal_user_balance: "Amount Of Token You Held",
  thanks_modal_user_tip: "Tip",
};
export default en;
