import React from "react";
import { BaseProps } from "../../../../../types/common/index.ts";
import clsx from "clsx";
import {
  getInitialTmpItem,
  tmpAddAndEditItemState,
} from "../../../../../stores/tmpItemState.ts";
import { useAtom, useSetAtom } from "jotai";
import { ItemSubmitButton } from "../ItemSubmitButton.tsx";
import { inProgressMarketState } from "../../../../../stores/inProgressMarketState.ts";
import { editItem } from "../../../../../actions/blockchain/itemActions.js";
import { isOpenEditPageMarketState } from "../../../../../stores/isOpenEditPageMarketState.ts";
import { itemsState } from "../../../../../stores/itemsState.ts";
import { useUser } from "src/hooks/useUser.ts";

export type ItemEditSubmitButtonProps = {
  daoId: string;
} & BaseProps;

export const ItemEditSubmitButton: React.FC<ItemEditSubmitButtonProps> = (
  props: ItemEditSubmitButtonProps
) => {
  const [tmpItem, setTmpItem] = useAtom(tmpAddAndEditItemState);
  const setItems = useSetAtom(itemsState);
  const setInProgress = useSetAtom(inProgressMarketState);
  const setIsOpenEditPage = useSetAtom(isOpenEditPageMarketState);
  const { user } = useUser();

  const handleClick = async () => {
    if (!tmpItem) return;
    setInProgress({ isShow: true, message: "アイテム情報更新中..." });
    try {
      const name = tmpItem.name;
      const description = tmpItem.description;
      const img = tmpItem.base64 ? tmpItem.base64 : tmpItem.image;
      const price = tmpItem.price;
      const tokenId = tmpItem.tokenId;
      const amount = tmpItem.amount;
      if (price === 0) {
        alert("価格を0以上で入力してください。");
        setInProgress({ isShow: false, message: "" });
        return;
      }
      await editItem(
        props.daoId,
        tokenId,
        name,
        description,
        img,
        price,
        amount,
        user
      );
      setItems((prev) => {
        const newItems = prev.map((item) => {
          if (item === tmpItem) return tmpItem;
          return item;
        });
        return newItems;
      });
      setTmpItem(getInitialTmpItem());
      setInProgress({ isShow: false, message: "" });
      window.location.reload();
    } catch (e) {
      console.log(e);
      if (e instanceof Error) {
        alert(
          `アイテムの更新に失敗した可能性があります。ご確認ください。\n${e.stack}`
        );
      } else
        alert(
          `アイテムの更新に失敗した可能性があります。ご確認ください。\n\n${JSON.stringify(
            e
          )}`
        );
      setInProgress({ isShow: false, message: "" });
      return;
    }
    setIsOpenEditPage(false);
  };

  return (
    <ItemSubmitButton
      className={clsx(props.className)}
      theme="edit"
      onClick={handleClick}
    >
      編集する
    </ItemSubmitButton>
  );
};
