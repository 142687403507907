import React from "react";
import { BaseProps } from "../../types/common/index.ts";
import { useIntl } from "react-intl";

export type NeedFundDaoProps = {} & BaseProps;

export const NeedFundDao: React.FC<NeedFundDaoProps> = ({ className }) => {
  const intl = useIntl();

  return (
    <div
      className={`${className} rounded-full bg-[#37373C] py-2 text-gray-200`}
    >
      {intl.formatMessage({
        id: "list_need_fund",
        defaultMessage: "出資が必要",
      })}
    </div>
  );
};
