import {
  doc,
  DocumentData,
  QueryDocumentSnapshot,
  SnapshotOptions,
  setDoc,
  Timestamp,
} from "firebase/firestore";
import { firebaseFirestore } from "./config";

interface Workspace {
  id: string;
  createdAt: Date;
  updatedAt: Date;
}

export const workspaceConverter = {
  toFirestore(workspace: Workspace): DocumentData {
    return {
      createdAt: Timestamp.fromDate(workspace.createdAt),
      updatedAt: Timestamp.fromDate(workspace.updatedAt),
    };
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): Workspace {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      createdAt: data.createdAt.toDate(),
      updatedAt: data.updatedAt.toDate(),
    };
  },
};

export async function createWorkspace(daoId: string) {
  const now = new Date();
  const workspace = {
    createdAt: now,
    updatedAt: now,
  };

  await setDoc(doc(firebaseFirestore, "workspaces", daoId), workspace);
}
