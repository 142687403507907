/* tslint:disable */
/* eslint-disable */
/**
 * Unyte SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ApplicationsDto } from './ApplicationsDto';
import {
    ApplicationsDtoFromJSON,
    ApplicationsDtoFromJSONTyped,
    ApplicationsDtoToJSON,
} from './ApplicationsDto';

/**
 * 
 * @export
 * @interface ApplicationsResponseDto
 */
export interface ApplicationsResponseDto {
    /**
     * 
     * @type {string}
     * @memberof ApplicationsResponseDto
     */
    explanation: string;
    /**
     * 
     * @type {Array<ApplicationsDto>}
     * @memberof ApplicationsResponseDto
     */
    applications: Array<ApplicationsDto>;
}

/**
 * Check if a given object implements the ApplicationsResponseDto interface.
 */
export function instanceOfApplicationsResponseDto(value: object): boolean {
    if (!('explanation' in value)) return false;
    if (!('applications' in value)) return false;
    return true;
}

export function ApplicationsResponseDtoFromJSON(json: any): ApplicationsResponseDto {
    return ApplicationsResponseDtoFromJSONTyped(json, false);
}

export function ApplicationsResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplicationsResponseDto {
    if (json == null) {
        return json;
    }
    return {
        
        'explanation': json['explanation'],
        'applications': ((json['applications'] as Array<any>).map(ApplicationsDtoFromJSON)),
    };
}

export function ApplicationsResponseDtoToJSON(value?: ApplicationsResponseDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'explanation': value['explanation'],
        'applications': ((value['applications'] as Array<any>).map(ApplicationsDtoToJSON)),
    };
}

