import { restApi } from "src/libs/restClient";

export const validateEmail = (email: string): boolean => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(email) && !email.endsWith(".") && !email.includes("..");
};

export const sendEmail = async (email: string, backupShare: string) => {
  if (!validateEmail(email)) throw new Error("無効なメールアドレスです。");
  await restApi.mailControllerSendBackupKeyEmail(
    {
      sendBackupShareEmailRequest: { to: email, backupShare },
    },
    {
      headers: {
        "content-type": "application/json",
      },
    }
  );
};
