import {
  ChevronDownIcon,
  ChevronUpIcon,
  ClipboardDocumentIcon,
  DocumentTextIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import { TxLink } from "../../Task/TxLink.tsx";
import { UserName } from "../../Task/UserName.tsx";
import { UserIcon } from "../../Task/UserIcon.tsx";
import AdminHoverTaskDetail from "./AdminHoverTaskDetail.tsx";
import { ApprovalStatusBadge } from "../../Task/ApprovalStatusBadge.tsx";
import { OutputsForAllTask } from "src/types/api/index.ts";
import pdfIcon from "../../../assets/pdf.svg";
import textIcon from "../../../assets/text.svg";
import videoIcon from "../../../assets/video.svg";

const getBgAndBorderColor = (isSelected: boolean) => {
  if (isSelected) return "bg-checked-color border-checked-border-color";
  return "bg-transparent border-unyte-dark";
};
const replaceIpfsPath = (filePath: string) => {
  if (filePath.startsWith("ipfs://"))
    return filePath.replace("ipfs://", process.env.REACT_APP_IPFS_BASE_URL!);
  return `${process.env.REACT_APP_IPFS_BASE_URL}${filePath}`;
};
const getExtName = (filePath: string) => {
  const match = filePath.match(/\.[0-9a-z]+$/i);
  if (!match) return "";
  return match[0];
};
const isImage = (filePath: string) => {
  const imgExts = [".jpg"]; // 画像ファイルはリサイズ処理で必ずjpgになるので他の形式は無視
  const ext = getExtName(filePath);
  return imgExts.includes(ext);
};
const FileIcon: React.FC<{ filePath: string }> = ({ filePath }) => {
  const ext = getExtName(filePath);
  if (ext === ".pdf") {
    return <img className="h-8 w-8" src={pdfIcon} alt="pdf" />;
  }
  if (ext === ".txt") {
    return <img className="h-8 w-8" src={textIcon} alt="text" />;
  }
  if (
    [
      ".mp4",
      ".avi",
      ".mov",
      ".wmv",
      ".flv",
      ".webm",
      ".mpg",
      ".mkv",
      ".asf",
      ".vob",
    ].includes(ext)
  ) {
    return <img className="h-8 w-8" src={videoIcon} alt="video" />;
  }
  return <DocumentTextIcon className="h-8 w-8" />;
};

interface Props {
  daoId: string;
  output: OutputsForAllTask;
  isSelected: boolean;
  toggleSelectedOutput: (output: OutputsForAllTask) => void;
}

const AdminOutputCard: React.FC<Props> = ({
  daoId,
  output,
  isSelected,
  toggleSelectedOutput,
}) => {
  const handleClickCopy = () => {
    const baseUrl = window.location.origin.toString();
    navigator.clipboard.writeText(
      `${baseUrl}/${daoId}/task/${output.task.taskId}/${output.outputId}`
    );
  };

  const [isNoteExpanded, setIsNoteExpanded] = React.useState(false);
  const [isNoteOverflown, setIsNoteOverflown] = React.useState(false);

  return (
    <div
      className={`grid w-full grid-cols-12 rounded border-2 p-2 ${getBgAndBorderColor(
        isSelected
      )} ${output.status === "pending" && "cursor-pointer"}`}
      onClick={() => {
        if (output.status !== "pending") return;
        toggleSelectedOutput(output);
      }}
    >
      <input
        className={`rounded bg-transparent text-gray-500 ${
          output.status !== "pending" && "invisible"
        }`}
        type="checkbox"
        disabled={output.status !== "pending"}
        checked={isSelected}
        readOnly
      />
      <div className="col-span-11 flex flex-col justify-between gap-y-2">
        <div className={`flex flex-col gap-y-2`}>
          <div className="flex items-center justify-between">
            <div className="flex items-center justify-between gap-x-2">
              <UserIcon img={output.author.img} alt={output.author.name} />
              <UserName name={output.author.name} />
              <TxLink url={output.link} />
            </div>
            <div className="flex items-center justify-between gap-x-2">
              <ApprovalStatusBadge status={output.status} />
              <button onClick={handleClickCopy}>
                <ClipboardDocumentIcon className="h-5 w-5" />
              </button>
            </div>
          </div>
          <div className="text-xs">
            {new Date(output.date).toLocaleString("ja-JP")}
          </div>
          <AdminHoverTaskDetail task={output.task} />
          <p
            className={`whitespace-pre-wrap ${
              isNoteExpanded || "line-clamp-3"
            }`}
            ref={(ref) => {
              if (ref) {
                setIsNoteOverflown(ref.offsetHeight < ref.scrollHeight);
              }
            }}
          >
            {output.note}
          </p>
          <div className="flex w-full items-center justify-center">
            {isNoteExpanded ? (
              <button onClick={() => setIsNoteExpanded(false)}>
                <ChevronUpIcon className="h-5 w-5" />
              </button>
            ) : isNoteOverflown ? (
              <button onClick={() => setIsNoteExpanded(true)}>
                <ChevronDownIcon className="h-5 w-5" />
              </button>
            ) : null}
          </div>
        </div>
        {output.outputFiles && output.outputFiles.length > 0 && (
          <div className="flex gap-x-2 overflow-x-scroll">
            {output.outputFiles.map((outputFile: string, i: number) => {
              const ipfsLink = replaceIpfsPath(outputFile);
              return (
                <a
                  key={i}
                  className="flex h-32 w-32 flex-shrink-0 items-center justify-center bg-slate-600"
                  href={ipfsLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  {isImage(outputFile) ? (
                    <img src={ipfsLink} alt={i.toString()} />
                  ) : (
                    <FileIcon filePath={outputFile} />
                  )}
                </a>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminOutputCard;
