/* tslint:disable */
/* eslint-disable */
/**
 * Unyte SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OutputInfo
 */
export interface OutputInfo {
    /**
     * outputの作成者のアドレス
     * @type {string}
     * @memberof OutputInfo
     */
    owner: string;
    /**
     * DAOのID
     * @type {string}
     * @memberof OutputInfo
     */
    daoId: string;
    /**
     * タスクのID
     * @type {string}
     * @memberof OutputInfo
     */
    taskId: string;
    /**
     * outputのID
     * @type {string}
     * @memberof OutputInfo
     */
    outputId: string;
    /**
     * outputの内容
     * @type {string}
     * @memberof OutputInfo
     */
    output: string;
}

/**
 * Check if a given object implements the OutputInfo interface.
 */
export function instanceOfOutputInfo(value: object): boolean {
    if (!('owner' in value)) return false;
    if (!('daoId' in value)) return false;
    if (!('taskId' in value)) return false;
    if (!('outputId' in value)) return false;
    if (!('output' in value)) return false;
    return true;
}

export function OutputInfoFromJSON(json: any): OutputInfo {
    return OutputInfoFromJSONTyped(json, false);
}

export function OutputInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OutputInfo {
    if (json == null) {
        return json;
    }
    return {
        
        'owner': json['owner'],
        'daoId': json['daoId'],
        'taskId': json['taskId'],
        'outputId': json['outputId'],
        'output': json['output'],
    };
}

export function OutputInfoToJSON(value?: OutputInfo | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'owner': value['owner'],
        'daoId': value['daoId'],
        'taskId': value['taskId'],
        'outputId': value['outputId'],
        'output': value['output'],
    };
}

