import { ethers } from "ethers";
import { ProposalDataForContract } from "../../types/vote";
import proposalAbi from "../blockchain/abi/Proposal.json";
import { NETWORK_ERROR_MSG, PROPOSAL_ENDPOINT } from "../../const/const.ts";
import { fireauth } from "src/actions/firebase/config.js";

const contractAddress = process.env.REACT_APP_PROPOSAL_CONTRACT_ADDRESS;
const provider = new ethers.JsonRpcProvider(
  process.env.REACT_APP_ALCHEMY_ENDPOINT
);

const getProposals = async (
  daoId?: string | null,
  proposalId?: string | null,
  address?: string | null
) => {
  if (contractAddress) {
    const contract = new ethers.Contract(
      contractAddress,
      proposalAbi.abi,
      provider
    );
    const filter = contract.filters.Proposed(address, daoId, proposalId);
    const fromBlock = 42815066;
    const toBlock = "latest";

    const logs = await contract.queryFilter(filter, fromBlock, toBlock);
    const decoder = ethers.AbiCoder.defaultAbiCoder();
    const proposals = logs
      .map((log) => {
        try {
          const data = decoder.decode(["string"], log.data);
          const result = JSON.parse(data.toString());
          result.startTime = parseInt(result.startTime);
          result.closingTime = parseInt(result.closingTime);
          result.keys = { publicKey: result.publicKey };
          delete result.publicKey;
          return result;
        } catch (e) {
          console.error((e as Error).message);
          return null;
        }
      })
      .filter(Boolean);

    const uniqueProposals = Array.from(
      new Map(
        proposals.map((proposal) => [proposal.proposalId, proposal])
      ).values()
    );
    //console.log("proposals",{all:proposals, unique:uniqueProposals});
    return uniqueProposals.reverse();
  } else {
    throw new Error("Config Error");
  }
};

const addProposal = async (proposal: ProposalDataForContract) => {
  const user = fireauth.currentUser;
  const token = user ? await user.getIdToken(true) : "";
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ type: "proposal", args: proposal }),
  };
  let response;
  try {
    response = await fetch(PROPOSAL_ENDPOINT, options);
  } catch (e) {
    console.error(e);
    throw new Error(NETWORK_ERROR_MSG);
  }
  const responseJson = await response.json();
  if (responseJson.status !== "success") throw new Error(responseJson.message);
  return responseJson;
};

export { getProposals, addProposal };
