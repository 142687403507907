import { fireauth } from "src/actions/firebase/config";
import { restApi } from "src/libs/restClient";
import useSWR from "swr";

const fetcher = async (daoIds: string[]) => {
  const user = fireauth.currentUser;
  if (!user) {
    return undefined;
  }
  const uidToken = await user.getIdToken(true);
  return restApi.authorizeControllerFetchAuthorize({
    fetchAuthorizeDto: { daoIds, uidToken },
  });
};

const isValid = (daoIds: string[] | undefined) => {
  if (!daoIds) return false;
  return true;
};

export const useAuthorize = (daoIds?: string[]) => {
  const {
    data: dataAuthorize,
    error,
    isLoading,
  } = useSWR(
    isValid(daoIds) ? ["/authorize", daoIds!] : null,
    ([_, daoIds]) => fetcher(daoIds),
    {
      refreshInterval: 59 * 60 * 1000,
      dedupingInterval: 0,
    }
  );

  return { dataAuthorize, isError: error, isLoading };
};
