import { fireauth } from "src/actions/firebase/config";
import { REACT_MESSAGE_ENDPOINT } from "src/const/const";

async function requestReactMessageAPI(
  reactionType: string,
  uidToken: string,
  daoId: string,
  channelId: string,
  messageId: string
) {
  if (!REACT_MESSAGE_ENDPOINT) {
    throw new Error("Config Error");
  }
  const json = {
    reactionType: reactionType,
    uidToken: uidToken,
    daoId: daoId,
    channelId: channelId,
    messageId: messageId,
  };
  const user = fireauth.currentUser;
  const token = user ? await user.getIdToken(true) : "";
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(json),
  };
  const response = await fetch(REACT_MESSAGE_ENDPOINT, options);
  if (response.status !== 201) {
    // 予期せぬエラーの場合はconsole.errorを出す
    const responseJson = await response.json();
    console.error(`Authorise Error: ${responseJson}`);
  }
}

export async function reactMessage(
  reactionType: string,
  daoId: string,
  channelId: string,
  messageId: string
) {
  const uidToken = await fireauth.currentUser!.getIdToken(true);

  await requestReactMessageAPI(
    reactionType,
    uidToken,
    daoId,
    channelId,
    messageId
  );
}
