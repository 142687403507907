/* tslint:disable */
/* eslint-disable */
/**
 * Unyte SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ThanksUser
 */
export interface ThanksUser {
    /**
     * ユーザーのアドレス
     * @type {string}
     * @memberof ThanksUser
     */
    address: string;
    /**
     * ユーザーの名前
     * @type {string}
     * @memberof ThanksUser
     */
    name: string;
    /**
     * ユーザーの画像
     * @type {string}
     * @memberof ThanksUser
     */
    img: string;
}

/**
 * Check if a given object implements the ThanksUser interface.
 */
export function instanceOfThanksUser(value: object): boolean {
    if (!('address' in value)) return false;
    if (!('name' in value)) return false;
    if (!('img' in value)) return false;
    return true;
}

export function ThanksUserFromJSON(json: any): ThanksUser {
    return ThanksUserFromJSONTyped(json, false);
}

export function ThanksUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): ThanksUser {
    if (json == null) {
        return json;
    }
    return {
        
        'address': json['address'],
        'name': json['name'],
        'img': json['img'],
    };
}

export function ThanksUserToJSON(value?: ThanksUser | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'address': value['address'],
        'name': value['name'],
        'img': value['img'],
    };
}

