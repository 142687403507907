import { shamirSecretRecovery } from "../shamir/shamir";

export async function decryptoPrivateKey(user) {
  if (user === undefined) {
    console.log("user is undefined");
    return null;
  }

  // ローカルストレージから秘密鍵のシェアを取得
  const shares = localStorage.getItem(user.uid);
  if (shares === null) {
    console.log("share not found");
    return null;
  }
  const itemData = shares.split(",");
  let array = [];
  user.share.map((item, index) => {
    const result = shamirSecretRecovery([
      [1, item],
      [2, parseInt(itemData[index])],
    ]);
    array.push(result);
  });
  return array;
}
