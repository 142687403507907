import React, { useState } from "react";
import { createChannel } from "../../actions/firebase/channels.ts";

export const CreateChannelModal = ({
  daoId,
  onCreated: onCreatedCallback,
  onClose,
}: {
  daoId: string;
  onCreated: () => void;
  onClose: () => void;
}) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  function onCreate() {
    createChannel(daoId, name, description)
      .then(() => onCreatedCallback())
      .catch((e) => alert(e.message));
  }

  return (
    <div className="fixed inset-0 z-40">
      <div className="flex h-screen items-center justify-center">
        <div
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          onClick={onClose}
        ></div>
        <div className="w-11/12 max-w-xl transform overflow-hidden rounded-lg bg-[#28282D] bg-unyte p-px text-left text-white shadow-xl transition-all md:w-1/2">
          <div className="h-full w-full rounded-lg bg-[#28282D] px-4 pb-4 pt-5">
            <div className="flex justify-end">
              <button onClick={onClose}>×</button>
            </div>
            <div className="overflow-hidden rounded-lg shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
              <label htmlFor="comment" className="sr-only">
                チャンネルを作成
              </label>
              <input
                name="channel"
                id="channel"
                className="block w-full resize-none border-0 bg-[#1A1D24] py-1.5 text-gray-50 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                placeholder="チャンネル名"
                value={name}
                maxLength={100}
                onChange={(e) => setName(e.target.value)}
              />
              <input
                name="channel-description"
                id="channel-description"
                className="block w-full resize-none border-0 bg-[#1A1D24] py-1.5 text-gray-50 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                placeholder="チャンネルの説明"
                value={description}
                maxLength={100}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
            <div className="flex w-full justify-center">
              <button
                className="mb-5 mt-5 h-9 w-1/3 rounded-full bg-unyte lg:mb-10"
                onClick={onCreate}
              >
                作成
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
