import { onAuthStateChanged } from "firebase/auth";
import { useEffect } from "react";
import { fireauth } from "src/actions/firebase/config";
import { restApi } from "src/libs/restClient";
import useSWR from "swr";

const fetcher = async () => {
  return restApi.userControllerFetchUser();
};

export const useUser = () => {
  const keys: [string] = ["/user"];
  const { data, error, isLoading, mutate } = useSWR(keys, ([_]) => fetcher());
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(fireauth, async () => {
      mutate();
    });

    return () => {
      unsubscribe();
    };
  }, [mutate]);

  return { user: data, isError: error, isLoading, mutate };
};
