export const compressHashToAlphabet = (hash) => {
  let decimalValue = BigInt("0x" + hash); // 16進数から10進数に変換
  const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
  let compressed = "";
  while (decimalValue > 0) {
    const remainder = Number(decimalValue % 52n); // 52で割った余りを求める
    compressed = alphabet[remainder] + compressed; // 余りをアルファベットに変換して前に追加
    decimalValue = decimalValue / 52n; // 52で割る
  }
  return compressed.slice(0, 10); // 最初の10文字を返す
};
