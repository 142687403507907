/* tslint:disable */
/* eslint-disable */
/**
 * Unyte SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TaskAuthorForOutputsForAllTasks } from './TaskAuthorForOutputsForAllTasks';
import {
    TaskAuthorForOutputsForAllTasksFromJSON,
    TaskAuthorForOutputsForAllTasksFromJSONTyped,
    TaskAuthorForOutputsForAllTasksToJSON,
} from './TaskAuthorForOutputsForAllTasks';
import type { TaskOperationForOutputsForAllTasks } from './TaskOperationForOutputsForAllTasks';
import {
    TaskOperationForOutputsForAllTasksFromJSON,
    TaskOperationForOutputsForAllTasksFromJSONTyped,
    TaskOperationForOutputsForAllTasksToJSON,
} from './TaskOperationForOutputsForAllTasks';

/**
 * 
 * @export
 * @interface TaskForOutputsForAllTasks
 */
export interface TaskForOutputsForAllTasks {
    /**
     * 
     * @type {string}
     * @memberof TaskForOutputsForAllTasks
     */
    taskId: string;
    /**
     * 
     * @type {string}
     * @memberof TaskForOutputsForAllTasks
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof TaskForOutputsForAllTasks
     */
    description: string;
    /**
     * 
     * @type {TaskAuthorForOutputsForAllTasks}
     * @memberof TaskForOutputsForAllTasks
     */
    author: TaskAuthorForOutputsForAllTasks;
    /**
     * 
     * @type {TaskOperationForOutputsForAllTasks}
     * @memberof TaskForOutputsForAllTasks
     */
    operation: TaskOperationForOutputsForAllTasks;
}

/**
 * Check if a given object implements the TaskForOutputsForAllTasks interface.
 */
export function instanceOfTaskForOutputsForAllTasks(value: object): boolean {
    if (!('taskId' in value)) return false;
    if (!('title' in value)) return false;
    if (!('description' in value)) return false;
    if (!('author' in value)) return false;
    if (!('operation' in value)) return false;
    return true;
}

export function TaskForOutputsForAllTasksFromJSON(json: any): TaskForOutputsForAllTasks {
    return TaskForOutputsForAllTasksFromJSONTyped(json, false);
}

export function TaskForOutputsForAllTasksFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaskForOutputsForAllTasks {
    if (json == null) {
        return json;
    }
    return {
        
        'taskId': json['taskId'],
        'title': json['title'],
        'description': json['description'],
        'author': TaskAuthorForOutputsForAllTasksFromJSON(json['author']),
        'operation': TaskOperationForOutputsForAllTasksFromJSON(json['operation']),
    };
}

export function TaskForOutputsForAllTasksToJSON(value?: TaskForOutputsForAllTasks | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'taskId': value['taskId'],
        'title': value['title'],
        'description': value['description'],
        'author': TaskAuthorForOutputsForAllTasksToJSON(value['author']),
        'operation': TaskOperationForOutputsForAllTasksToJSON(value['operation']),
    };
}

