import React from "react";
import { BaseProps } from "../../types/common/index.ts";

export type DaoNameProps = {
  name: string;
} & BaseProps;

export const DaoName: React.FC<DaoNameProps> = ({ className, name }) => {
  return <h3 className={`${className} text-white`}>{name}</h3>;
};
