import React from "react";
import { formatAddress } from "../../../utils/formatAddress.ts";
import userIcon from "../../../assets/userIcon.svg";
import { TaskForOutputsForAllTasks } from "src/types/api/index.ts";

interface Props {
  task: TaskForOutputsForAllTasks;
}

const AdminHoverTaskDetail: React.FC<Props> = ({ task }) => {
  return (
    <div className="group relative hover:bg-slate-200 ">
      {task.title}
      <span className="invisible absolute left-2 top-4 flex w-full flex-col gap-y-2 rounded border-2 border-white bg-[#38373D] p-4 text-white group-hover:visible">
        <div className="text-base">{task.title}</div>
        <div className="text-xs">{task.description}</div>
        <div className="flex items-center gap-x-2 text-xs">
          <span>Created by:</span>
          <img
            className="h-4 w-4 rounded-full bg-[#1A1D24]"
            src={task.author.img || userIcon}
            alt={task.author.name}
          />
          <div className="truncate text-sm">{task.author.name}</div>
          <div className="text-xs">
            {`(${formatAddress(task.author.address)})`}
          </div>
        </div>
      </span>
    </div>
  );
};

export default AdminHoverTaskDetail;
