import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import { useAuthState } from "react-firebase-hooks/auth";
import { getAuth } from "firebase/auth";

const Profile: React.FC = () => {
  const [selectedValue, setSelectedValue] = useState("mypage-vote");
  const location = useLocation();
  let state = location.state;
  const [session] = useAuthState(getAuth());

  // 0: 賛成, 1: 反対, 2: 棄権
  const sampleVote = [
    {
      title: "フォームのUI強化",
      date: "2023/9/9 18:45:55",
      status: "0",
    },
    {
      title:
        "フォームのUI強化フォームのUI強化フォームのUI強化フォームのUI強化フォームのUI強化フォームのUI強化フォームのUI強化フォームのUI強化",
      date: "2023/9/9 18:45:55",
      status: "1",
    },
    {
      title: "フォームのUI強化",
      date: "2023/9/9 18:45:55",
      status: "2",
    },
    {
      title:
        "フォームのUI強化フォームのUI強化フォームのUI強化フォームのUI強化フォームのUI強化",
      date: "2023/9/9 18:45:55",
      status: "1",
    },
    {
      title: "フォームのUI強化",
      date: "2023/9/9 18:45:55",
      status: "0",
    },
    {
      title: "フォームのUI強化",
      date: "2023/9/9 18:45:55",
      status: "0",
    },
  ];
  const sampleTask = [
    {
      title: "フォームのUI強化",
      description: "すごいアウトプットです！",
      date: "2023/9/9 18:45:55",
    },
    {
      title:
        "フォームのUI強化フォームのUI強化フォームのUI強化フォームのUI強化フォームのUI強化フォームのUI強化フォームのUI強化フォームのUI強化",
      description: "すごいアウトプットです！",
      date: "2023/9/9 18:45:55",
    },
    {
      title: "フォームのUI強化",
      description:
        "すごいアウトプットです！すごいアウトプットです！すごいアウトプットです！すごいアウトプットです！すごいアウトプットです！すごいアウトプットです！すごいアウトプットです！すごいアウトプットです！",
      date: "2023/9/9 18:45:55",
    },
    {
      title:
        "フォームのUI強化フォームのUI強化フォームのUI強化フォームのUI強化フォームのUI強化",
      description:
        "すごいアウトプットです！すごいアウトプットです！すごいアウトプットです！すごいアウトプットです！すごいアウトプットです！すごいアウトプットです！",
      date: "2023/9/9 18:45:55",
    },
    {
      title: "フォームのUI強化",
      description: "すごいアウトプットです！",
      date: "2023/9/9 18:45:55",
    },
    {
      title: "フォームのUI強化",
      description: "すごいアウトプットです！",
      date: "2023/9/9 18:45:55",
    },
  ];

  const VoteCard = ({ item }) => {
    return (
      <div className="break-words rounded-lg bg-[#28282D] px-6 py-10 shadow-lg">
        <p className="mb-3 text-lg font-bold">{item.title}</p>
        {item.status === "0" && (
          <button
            type="button"
            className={`mb-2 inline-flex justify-center rounded-full bg-[#336AE9] px-6 py-1 text-xs font-semibold text-gray-100 sm:col-start-1`}
          >
            賛成
          </button>
        )}
        {item.status === "1" && (
          <button
            type="button"
            className={`mb-2 inline-flex justify-center rounded-full bg-[#D11277] px-6 py-1 text-xs font-semibold text-gray-100 sm:col-start-1`}
          >
            反対
          </button>
        )}
        {item.status === "2" && (
          <button
            type="button"
            className={`mb-2 inline-flex justify-center rounded-full bg-[#969696] px-6 py-1 text-xs font-semibold text-gray-100 sm:col-start-1`}
          >
            棄権
          </button>
        )}
        {/* <button
          type="button"
          className={`inline-flex w-full justify-center rounded-full ${
            userVoteStatus.type === "favor" ||
            userVoteStatus.type === "none"
              ? "bg-[#336AE9] text-gray-100"
              : "bg-transparent border-2 border-gray-400 text-gray-400"
          } px-3 py-2 text-sm font-semibold sm:col-start-1`}>
          賛成
        </button>
        <button
          type="button"
          className={`inline-flex w-full justify-center rounded-full ${
            userVoteStatus.type === "against" ||
            userVoteStatus.type === "none"
              ? "bg-[#D11277] text-gray-100"
              : "bg-transparent border-2 border-gray-400 text-gray-400"
          } px-3 py-2 text-sm font-semibold sm:col-start-2`}>
          反対
        </button>
        <button
          type="button"
          className={`inline-flex w-full justify-center rounded-full ${
            userVoteStatus.type === "abstention" ||
            userVoteStatus.type === "none"
              ? "bg-[#969696] text-gray-100"
              : "bg-transparent border-2 border-gray-400 text-gray-400"
          } px-3 py-2 text-sm font-semibold sm:col-start-3`}>
          棄権
        </button> */}
        <p className="mb-3 text-xs">{item.date}</p>
      </div>
    );
  };

  const TaskCard = ({ item }) => {
    return (
      <div className="break-words rounded-lg bg-[#28282D] px-6 py-10 shadow-lg">
        <p className="mb-3 text-lg font-bold">{item.title}</p>
        <p className="mb-1 text-sm">{item.description}</p>
        <p className="mb-3 text-xs">{item.date}</p>
        <div className="flex w-full justify-end">
          <ArrowTopRightOnSquareIcon
            className={`mr-2 h-6 font-bold`}
          ></ArrowTopRightOnSquareIcon>
        </div>
      </div>
    );
  };

  return (
    <div className="h-scren mt-28 flex w-full flex-col items-center">
      <div className="flex w-3/5 flex-col text-white">
        <div className="mb-6 flex w-full items-center">
          <div className="flex items-center">
            {/* TODO: アイコンを動的に表示する仕組み（snsアイコン？静的アイコン？） */}
            <img
              src="https://pbs.twimg.com/profile_images/1583281337674235904/ap9KtnxV_400x400.jpg"
              alt="user icon"
              className="mr-4 h-20 rounded-full"
            />
            <p className="text-2xl">Unyte</p>
          </div>
          <div className="ml-24 flex">
            <div>
              <input
                type="radio"
                id="mypage-vote"
                name="badgeRadio"
                value="mypage-vote"
                checked={selectedValue === "mypage-vote"}
                onChange={(e) => setSelectedValue(e.target.value)}
                className="hidden"
              />
              <label
                htmlFor="mypage-vote"
                className={`mr-6 flex cursor-pointer items-center rounded-full border border-unyte-dark px-10 py-2 text-lg font-medium ${
                  selectedValue === "mypage-vote"
                    ? "bg-gray-200 text-black"
                    : "text-gray-200"
                }`}
              >
                VOTE
              </label>
            </div>
            <div>
              <input
                type="radio"
                id="mypage-tasks"
                name="badgeRadio"
                value="mypage-tasks"
                checked={selectedValue === "mypage-tasks"}
                onChange={(e) => setSelectedValue(e.target.value)}
                className="hidden"
              />
              <label
                htmlFor="mypage-tasks"
                className={`flex cursor-pointer items-center rounded-full border border-unyte-dark px-10 py-2 text-lg font-medium ${
                  selectedValue === "mypage-tasks"
                    ? "bg-gray-200 text-black"
                    : "text-gray-200"
                }`}
              >
                TASKS
              </label>
            </div>
          </div>
        </div>

        <div className="grid w-full grid-cols-2 gap-4 sm:grid-cols-3 lg:grid-cols-4">
          {selectedValue === "mypage-vote" &&
            sampleVote.map((item, idx) => <VoteCard key={idx} item={item} />)}
          {selectedValue === "mypage-tasks" &&
            sampleTask.map((item, idx) => <TaskCard key={idx} item={item} />)}
        </div>
      </div>
    </div>
  );
};

export default Profile;
