import React, { useCallback, useMemo, useRef, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
  DialogClose,
} from "src/components/shadcn/dialog";
import {
  Avatar,
  AvatarImage,
  AvatarFallback,
} from "src/components/shadcn/avatar";
import { Textarea } from "src/components/shadcn/textarea";
import { Button } from "src/components/shadcn/button";
import { toast } from "sonner";
import { BaseProps } from "../../types/common/index.ts";
import { useIntl } from "react-intl";
import { useSetAtom } from "jotai";
import { inProgressDaoListState } from "src/stores/inProgressDaoListState.ts";
import { useUser } from "src/hooks/useUser.ts";
import {
  ApplyForm,
  ApplyStatus,
  ApplyStatusStatusEnum,
} from "src/types/api/index.ts";
import { BASE_BACK_END_URL } from "src/config/config.ts";
import { Loader2, X } from "lucide-react";

export type ApplyForDaoButtonProps = {
  daoId: string;
  isOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
} & BaseProps;

export const ApplyForDaoButton: React.FC<ApplyForDaoButtonProps> = ({
  className,
  daoId,
  isOpen,
  setIsModalOpen,
}) => {
  const [explanation, setExplanation] = useState<string>("");
  const [isOpenApplyForm, setIsOpenApplyForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const emailRef = useRef<HTMLInputElement>(null);
  const bioRef = useRef<HTMLTextAreaElement>(null);
  const [bio, setBio] = useState<string>("");
  const intl = useIntl();
  const setInProgress = useSetAtom(inProgressDaoListState);
  const { user } = useUser();

  const handleBioChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setBio(e.target.value);
    },
    []
  );
  const handleClickTrigger = async () => {
    const response = await fetch(
      `${BASE_BACK_END_URL}/apply/${daoId}/${user?.address}`
    );
    const data: ApplyForm = await response.json();

    if (data.hasPending) {
      toast.info("すでに申請済みです", {
        description: "このDAOへの参加申請はすでに提出されています。",
        duration: 5000,
      });
      return;
    }
    setExplanation(data.explanation);
    setIsOpenApplyForm(true);
  };

  const handleClickApply = useCallback(async () => {
    setIsLoading(true);
    const response = await fetch(`${BASE_BACK_END_URL}/apply/${daoId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        walletAddress: user?.address,
        mailAddress: emailRef.current?.value,
        message: bioRef.current?.value,
      }),
    });
    const data: ApplyStatus = await response.json();

    if (data.status === ApplyStatusStatusEnum.Success) {
      setIsOpenApplyForm(false);
      toast.success("申請に成功しました", {
        description: "DAOへの参加申請が正常に処理されました。",
        duration: 5000,
      });
    }
    setIsLoading(false);
    setIsModalOpen(false);
  }, []);

  const isBioEmpty = useMemo(() => bio.trim() === "", [bio]);

  return (
    <>
      <button
        className={`${className} rounded-full bg-unyte`}
        onClick={handleClickTrigger}
      >
        {intl.formatMessage({
          id: "list_apply",
          defaultMessage: "参加を申請する",
        })}
      </button>
      {isOpenApplyForm && (
        <Dialog open={isOpenApplyForm} onOpenChange={setIsOpenApplyForm}>
          <DialogContent className="w-11/12 rounded-md border-2 border-pink-600 bg-unyte-main text-gray-50 sm:max-w-[425px]">
            <DialogHeader>
              <DialogTitle className="text-gray-50">申請フォーム</DialogTitle>
              <DialogDescription className="text-gray-100">
                必要な内容を記入して申請してください
              </DialogDescription>
            </DialogHeader>
            <div className="grid gap-4 py-4">
              <div className="flex items-center gap-4">
                <Avatar className="text-gray-50">
                  <AvatarImage src={user?.img} alt="User Avatar" />
                  <AvatarFallback>{user?.name}</AvatarFallback>
                </Avatar>
                <div className="grid gap-1">
                  <div className="text-base font-medium text-gray-50">
                    {user?.name}
                  </div>
                </div>
              </div>
              {/* <div className="grid gap-1">
                <p>メールアドレス</p>
                <Input
                  id="email"
                  type="email"
                  placeholder="Enter your email"
                  defaultValue={user?.email || ""}
                  ref={emailRef}
                />
              </div> */}
              <div className="grid gap-1">
                <p className="text-gray-50">自由記入欄</p>
                <Textarea
                  id="bio"
                  placeholder={explanation}
                  className="min-h-[200px] resize-none text-black sm:min-h-[150px]"
                  ref={bioRef}
                  onChange={(e) => handleBioChange(e)}
                />
              </div>
            </div>
            <DialogFooter>
              <Button
                type="button"
                className="w-full bg-unyte"
                onClick={handleClickApply}
                disabled={isLoading || isBioEmpty}
              >
                {isLoading ? (
                  <>
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    送信中...
                  </>
                ) : (
                  "送信する"
                )}
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};
