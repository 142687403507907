/* tslint:disable */
/* eslint-disable */
/**
 * Unyte SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AuthorizeSuccess
 */
export interface AuthorizeSuccess {
    /**
     * 
     * @type {boolean}
     * @memberof AuthorizeSuccess
     */
    value: boolean;
}

/**
 * Check if a given object implements the AuthorizeSuccess interface.
 */
export function instanceOfAuthorizeSuccess(value: object): boolean {
    if (!('value' in value)) return false;
    return true;
}

export function AuthorizeSuccessFromJSON(json: any): AuthorizeSuccess {
    return AuthorizeSuccessFromJSONTyped(json, false);
}

export function AuthorizeSuccessFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthorizeSuccess {
    if (json == null) {
        return json;
    }
    return {
        
        'value': json['value'],
    };
}

export function AuthorizeSuccessToJSON(value?: AuthorizeSuccess | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'value': value['value'],
    };
}

