import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { STAGE } from "../../const/const.ts";
import { registerToken } from "./utils";

const REACT_APP_STAGE = process.env.REACT_APP_STAGE;

const firebaseConfig =
  REACT_APP_STAGE === STAGE.MAIN
    ? {
        apiKey: "AIzaSyDwcmmA1_bbOnCplZQkIjxJ5luI0CCYvD4",
        authDomain: "unyte-saas-production.firebaseapp.com",
        projectId: "unyte-saas-production",
        storageBucket: "unyte-saas-production.appspot.com",
        messagingSenderId: "673713685660",
        appId: "1:673713685660:web:8d8da539695316ba429959",
        measurementId: "G-1ML7YYD0KY",
      }
    : REACT_APP_STAGE === STAGE.DEMO
      ? {
          apiKey: "AIzaSyCbaTFpKQ1zemR5bWz83IqbD6hmKDxFYH8",
          authDomain: "unyte-saas-demo.firebaseapp.com",
          projectId: "unyte-saas-demo",
          storageBucket: "unyte-saas-demo.appspot.com",
          messagingSenderId: "561178389164",
          appId: "1:561178389164:web:d533eba1d39b17eded8d09",
          measurementId: "G-BSPHVLGKS2",
        }
      : REACT_APP_STAGE === STAGE.DEV
        ? {
            apiKey: "AIzaSyB3E1VqpJn0RZKerIziYnHNBBS3upVK7AE",
            authDomain: "unyte-for-enterprise.firebaseapp.com",
            projectId: "unyte-for-enterprise",
            storageBucket: "unyte-for-enterprise.appspot.com",
            messagingSenderId: "690185711199",
            appId: "1:690185711199:web:cc2d224f592ed0a66e35ea",
            measurementId: "G-8FMV2BM458",
          }
        : {};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging();
export const firebaseFirestore = getFirestore(app);
export const firebaseStorage = getStorage(app);
export const fireauth = getAuth();

// 登録処理
export const requestForToken = () => {
  const vapidKey = process.env.REACT_APP_VAPID_PUBLIC_KEY;
  return getToken(messaging, {
    vapidKey: vapidKey,
  })
    .then(async (currentToken) => {
      if (currentToken) {
        console.log("current token for client: ", currentToken);
        await registerToken(currentToken);
        alert("通知設定が完了しました。");
        return currentToken;
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
        return null;
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
      alert(
        "通知設定に失敗しました。再度ボタンをクリックしてください。スマートフォンでご利用の方は最下部のボタンから「ホーム画面へ追加」を押して再度お試しください。"
      );
    });
};
