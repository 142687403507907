import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Login,
  resetShareForResetPrivateKey,
} from "../../actions/firebase/login";
import { FormattedMessage } from "react-intl";
import { validateEmail } from "../../utils/validateEmail.ts";
import {
  fetchUser,
  getUser,
  isInitialized,
} from "src/actions/firebase/user.ts";

const EmailLogIn: React.FC = () => {
  const navigate = useNavigate();
  const [auth, setAuth] = useState({ email: "", password: "" });
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const loginWithEmail = async () => {
    if (!isValid()) return;
    const result = await Login("email", auth.email, auth.password);
    console.log("🚀 ~ loginWithEmail ~ result:", result);
    if (result[0] !== undefined && result[1] === "auth/invalid-email") {
      setEmailError("不正なEmailです。");
      return;
    } else if (result[0] !== undefined && result[1] === "auth/wrong-password") {
      setPasswordError("パスワードが誤っています。");
      return;
    } else if (result[0] !== undefined && result[1] === "auth/user-not-found") {
      setEmailError("ユーザーが存在しません。");
      return;
    } else if (result[1] === "auth/too-many-requests") {
      setPasswordError(
        "ログインに失敗しました。しばらくしてから再度お試しください。"
      );
      return;
    } else if (result[1] === "auth/invalid-login-credentials") {
      setPasswordError("パスワードが誤っています。");
      return;
    } else if (!result[0]) {
      setEmailError(
        "ログインに失敗しました。しばらくしてから再度お試しください。"
      );
      return;
    }
    if (!(await isInitialized(result[1].uid))) {
      const { userShare, localStorageShare, unyteShare } =
        await resetShareForResetPrivateKey(result[1]);
      navigate("/register", {
        state: {
          user: result[1].uid,
          email: result[1].email,
          share: {
            user: userShare,
            local: localStorageShare,
            unyte: unyteShare,
          },
        },
      });
    } else {
      navigate("/");
    }
  };

  const isValid = () => {
    setEmailError("");
    setPasswordError("");
    const validEmail = isValidEmail();
    const validPassword = isValidPassword();
    return validEmail && validPassword;
  };

  const isValidEmail = () => {
    if (auth.email === "" || !validateEmail(auth.email)) {
      setEmailError("Emailを入力してください。");
      return false;
    }
    return true;
  };

  const isValidPassword = () => {
    if (auth.password === "") {
      setPasswordError("パスワードを入力してください。");
      return false;
    }
    return true;
  };

  return (
    <div className="flex h-screen items-center justify-center bg-gray-200">
      <div className="mx-4 w-96 rounded-lg bg-white p-8 shadow-md">
        <h1 className="mb-5 text-2xl font-bold">
          <FormattedMessage
            id="email_login_title"
            defaultMessage="Emailログイン"
          />
        </h1>
        {emailError && <p className="mb-1 text-red-500">{emailError}</p>}
        <input
          type="email"
          placeholder="Email"
          className="mb-4 w-full rounded-md border border-gray-300 p-3 shadow-sm"
          onChange={(e) => {
            setAuth({ ...auth, email: e.target.value });
          }}
        />
        {passwordError && <p className="mb-1 text-red-500">{passwordError}</p>}
        <input
          type="password"
          placeholder="Password"
          className="mb-4 w-full rounded-md border border-gray-300 p-3 shadow-sm"
          onChange={(e) => {
            setAuth({ ...auth, password: e.target.value });
          }}
        />
        <button
          className="w-full rounded-md bg-blue-600 p-3 text-white hover:bg-blue-700"
          onClick={() => {
            loginWithEmail();
          }}
        >
          <FormattedMessage id="email_login_button" defaultMessage="ログイン" />
        </button>
        <hr className="my-8 h-px border-0 bg-gray-200 dark:bg-gray-700"></hr>
        <button
          className="w-full rounded-md bg-white p-3 text-blue-600 hover:bg-gray-100"
          onClick={() => navigate("/signup-with-email")}
        >
          <FormattedMessage
            id="email_login_exit"
            defaultMessage="メールアドレスで新規登録"
          />
        </button>
        <button
          className="w-full rounded-md bg-white p-3 text-blue-600 hover:bg-gray-100"
          onClick={() => navigate("/login")}
        >
          <FormattedMessage
            id="email_login_back_sso"
            defaultMessage="SSOログインに戻る"
          />
        </button>
      </div>
    </div>
  );
};

export default EmailLogIn;
