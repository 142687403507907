import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { NavigateFunction, useNavigate, useLocation } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import VoteAdd from "../../components/Voting/VoteAdd.tsx";
import { getVoteResult } from "../../actions/secretVote/vote-manager.ts";
import { BaseProps } from "../../types/common";
import Loading from "../../components/common/Loading.tsx";
import { Category, Filter } from "../../components/common/Category.tsx";
import ProposaledVote from "./ProposaledVote.tsx";
import {
  MagnifyingGlassIcon,
  PlusCircleIcon,
} from "@heroicons/react/24/outline";
import VoteDetail from "./VoteDetail.tsx";
import { decryptoPrivateKey } from "../../actions/blockchain/generateKeys.js";
import { useProposals } from "src/hooks/useProposal.ts";
import { useUser } from "src/hooks/useUser.ts";
import { Proposal, ProposalStatusEnum } from "src/types/api/index.ts";
import { useDaoDetail } from "src/hooks/useDaoDetail.ts";

const defultFilters = [
  {
    id: "category",
    name: "カテゴリー",
    options: [
      { value: "active", label: "Active", checked: true },
      { value: "passed", label: "Passed", checked: true },
      { value: "rejected", label: "Rejected", checked: true },
      { value: "preparation", label: "Preparation", checked: true },
    ],
  },
];

type Props = {
  daoId: string;
} & BaseProps;

export type ResultProposalStatus =
  | "active"
  | "passed"
  | "rejected"
  | "expired"
  | "preparation";

export type ModifiedProposal = Omit<Proposal, "status"> & {
  status: ResultProposalStatus;
};
const Vote = (props: Props) => {
  const [proposals, setProposals] = useState<ModifiedProposal[]>([]);
  const proposalsDiv = useRef<HTMLDivElement>(null);
  const {
    daoDetail,
    isError: isDaoDetailError,
    isLoading: isDaoDetailLoading,
  } = useDaoDetail(props.daoId);
  const {
    dataProposals,
    isError: isErrorProposals,
    isLoading: isLoadingProposals,
    mutate,
  } = useProposals(props.daoId);
  const detaliDiv = useRef<HTMLDivElement>(null);
  const [filters, setFilters] = useState<Filter[]>(defultFilters);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [activeProposalIndex, setActiveproposalIndex] = useState<number>(0);

  const navigate: NavigateFunction = useNavigate();
  const [inProgress, setInProgress] = useState<{
    isShow: boolean;
    message: string;
  }>({ isShow: false, message: "" });
  const [isAddProposalOpen, setIsAddProposalOpen] = useState<boolean>(false);
  const [isEditProcess, setIsEditProcess] = useState<boolean>(false);
  const { user, isError: isErrorUser, isLoading: isLoadingUser } = useUser();
  const location = useLocation();
  const daoId = location.pathname.split("/")[1];
  const [session] = useAuthState(getAuth());
  const proposalId = location.pathname.split("/")[3];

  const filteredProposals = useMemo(() => {
    if (!proposals) return [];
    const filtered = proposals.filter((proposal) => {
      const isChecked = filters[0].options?.some(
        (f) =>
          proposal.status === f.value &&
          f.checked &&
          (proposal.title.toLowerCase().includes(searchQuery) ||
            proposal.description.toLowerCase().includes(searchQuery))
      );
      return isChecked;
    });
    proposalsDiv.current?.scrollTo({ top: 0 });
    return filtered;
  }, [proposals, filters, searchQuery]);

  useEffect(() => {
    const index = filteredProposals.findIndex(
      (proposal) => proposal.proposalId === proposalId
    );
    setActiveproposalIndex(index == -1 ? 0 : index);
  }, [filteredProposals, proposalId]);

  useEffect(() => {
    const checkShare = async () => {
      try {
        const keyBytes = (await decryptoPrivateKey(user)) as string[] | null;
        if (!keyBytes || keyBytes.length === 0)
          throw new Error("秘密鍵の復元に失敗しました。");
      } catch (e: unknown) {
        if (e instanceof Error) {
          console.error(e);
          setInProgress({ isShow: false, message: "" });
          const isConfirm = confirm(`${e.message}\nシェアを再設定しますか？`);
          if (isConfirm) navigate("/reset");
        } else {
          console.error("Unknown error: checkShare ~ Vote");
        }
      }
    };
    checkShare();
  }, [user]);

  useEffect(() => {
    if (!session || !daoId) return;
    const init = async () => {
      // `VoteDetail.tsx` で実行している「"復号/計算中..."」表示と重なってしまうため、復号が行われるときは表示しない（proposals存在する = 復号が行われる）
      if (proposals.length === 0)
        setInProgress({ isShow: true, message: "Loading..." });
      try {
        if (!dataProposals) return;
        const proposals = await Promise.all(
          dataProposals.proposals.map(
            async (proposal: Proposal): Promise<ModifiedProposal> => {
              const checkVotes = proposal.voters;
              if (proposal.status === ProposalStatusEnum.Expired) {
                const modifiedProposal: ModifiedProposal = {
                  ...proposal,
                  status: "expired",
                };
                let isPassed: boolean | undefined;
                // dev環境のオンチェーンデータにpublicKeyがないデータが紛れ込んでしまったことによるエラー回避のためにtry-catchで囲む
                try {
                  isPassed = await getVoteResult({
                    proposalId: proposal.proposalId,
                    publicKey: proposal.publicKey,
                    votes: checkVotes,
                  });
                } catch (e: unknown) {
                  console.error(e);
                }
                if (isPassed === true) {
                  modifiedProposal.status = "passed";
                } else if (isPassed === false) {
                  modifiedProposal.status = "rejected";
                }
                return modifiedProposal;
              }
              return proposal as ModifiedProposal;
            }
          )
        );
        setProposals(proposals);
      } catch (e: unknown) {
        if (e instanceof Error) {
          console.error(e.message);
          alert(e.message);
        } else {
          console.error("Unknown error: init ~ Vote");
        }
      } finally {
        setInProgress({ isShow: false, message: "" });
      }
    };
    init();
  }, [session, daoId, dataProposals]);

  const callbackHandler = useCallback(
    (ProposalToAdd: ModifiedProposal | null): void => {
      if (ProposalToAdd) {
        const now = Date.now();
        if (now > ProposalToAdd.startTime && now < ProposalToAdd.endTime) {
          ProposalToAdd.status = "active";
        } else if (now > ProposalToAdd.endTime) {
          ProposalToAdd.status = "passed";
        } else {
          ProposalToAdd.status = "preparation";
        }
        mutate();
      }
      setIsAddProposalOpen(false);
      setIsEditProcess(false);
      window.scrollTo({ top: 0 });
      if (detaliDiv && detaliDiv.current) {
        detaliDiv.current.scrollTo({ top: 0 });
      }
    },
    [dataProposals]
  );

  const editCallbackHandler = useCallback((IsEditProcess: boolean): void => {
    setIsAddProposalOpen(false);
    setIsEditProcess(IsEditProcess);
  }, []);

  const handleNewVoteClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    setIsAddProposalOpen(true);
  };

  const handleClick = (index: number): void => {
    navigate(`/${daoId}/vote/${filteredProposals[index].proposalId}`);
    setActiveproposalIndex(index);
  };

  const handleSerchQueryChanged = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    event.preventDefault();
    setSearchQuery(event.target.value.toLowerCase());
  };
  if (isDaoDetailLoading || isLoadingProposals || isLoadingUser)
    return <Loading isShow={true} message="Loading..." />;
  if (isDaoDetailError || isErrorProposals || isErrorUser) return <></>;
  return (
    <div className="mt-8 min-h-[100vh-300px] max-w-screen-2xl lg:mt-0">
      <div className="relative mx-auto bg-[#121217] px-2">
        <div
          className={`${
            isAddProposalOpen || isEditProcess ? "hidden" : "flex"
          } items-center justify-between gap-x-4 bg-[#121217]`}
        >
          <div className="flex h-12 w-3/4 items-center justify-between gap-x-4 sm:w-1/2">
            <div className="flex h-12 flex-grow items-center justify-between bg-[#121217]">
              <label htmlFor="search" className="sr-only">
                Search
              </label>
              <div className="relative flex w-full">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                  <MagnifyingGlassIcon
                    className="h-5 w-5 text-gray-50"
                    aria-hidden="true"
                  />
                </div>
                <input
                  id="search"
                  name="search"
                  className="block w-full rounded-full border-0 bg-[#121217] py-1.5 pl-10 pr-3 text-gray-50 ring-1 ring-inset ring-gray-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"
                  type="search"
                  value={searchQuery}
                  onChange={handleSerchQueryChanged}
                />
              </div>
            </div>
            <div className="flex">
              <Category filters={filters} setFilters={setFilters} />
            </div>
          </div>
          <div
            className={`flex h-12 flex-shrink items-center justify-end bg-[#121217]`}
          >
            <button
              className="relative flex h-9 w-9 items-center justify-center gap-x-1 rounded-full bg-gradient-to-r from-[#EE7b4d] to-[#E5007E] text-gray-50 sm:w-32"
              onClick={handleNewVoteClick}
            >
              <span className="hidden w-fit text-sm sm:flex">新規作成</span>
              <PlusCircleIcon className="flex h-6 w-6 items-center justify-center" />
            </button>
          </div>
        </div>
        <div
          className={`relative ${
            filteredProposals.length === 0 || isAddProposalOpen || isEditProcess
              ? "hidden"
              : "flex"
          } `}
        >
          <div
            className={`hidden-scrollbar flex h-[calc(100svh-210px)] w-full snap-y snap-mandatory flex-col px-0 md:w-1/2 md:overflow-y-scroll`}
            ref={proposalsDiv}
          >
            {filteredProposals.map((proposal, index) => {
              return (
                <div key={`${proposal.proposalId}${index}`}>
                  <div className="">
                    <ProposaledVote
                      proposal={proposal}
                      index={index}
                      daoId={props.daoId}
                      activeIndex={activeProposalIndex}
                      callback={callbackHandler}
                      onClick={handleClick}
                      updateTime={new Date().getTime()}
                    />
                  </div>
                  {index === activeProposalIndex && (
                    <div className="mb-8 md:hidden">
                      <VoteDetail
                        daoId={daoId}
                        proposal={filteredProposals[activeProposalIndex]}
                        activeIndex={activeProposalIndex}
                        callback={callbackHandler}
                        editCallbackHandler={editCallbackHandler}
                        updateTime={new Date().getTime()}
                      />
                    </div>
                  )}
                </div>
              );
            })}
          </div>
          <div
            className="hidden h-[calc(100svh-210px)] w-1/2 overflow-y-scroll md:block"
            ref={detaliDiv}
          >
            {filteredProposals[activeProposalIndex] && (
              <VoteDetail
                daoId={daoId}
                proposal={filteredProposals[activeProposalIndex]}
                activeIndex={activeProposalIndex}
                user={user!}
                callback={callbackHandler}
                editCallbackHandler={editCallbackHandler}
                updateTime={new Date().getTime()}
              />
            )}
          </div>
        </div>
      </div>
      <div>
        {isAddProposalOpen && user && (
          <VoteAdd
            user={user}
            callback={callbackHandler}
            daoId={daoId}
            daoDetail={daoDetail}
          />
        )}
        {isEditProcess && user && (
          <VoteAdd
            user={user}
            callback={callbackHandler}
            daoId={daoId}
            propossalForEdit={filteredProposals[activeProposalIndex]}
            daoDetail={daoDetail}
          />
        )}
      </div>
      <Loading {...inProgress} />
      {!inProgress.isShow &&
        filteredProposals.length === 0 &&
        !isAddProposalOpen && (
          <div className="fixed left-[calc(100svw/2)] top-[calc(100svh/2)] -translate-x-1/2 -translate-y-1/2 bg-transparent text-lg text-gray-100">
            <div className="">提案がありません</div>
          </div>
        )}
    </div>
  );
};

export default Vote;
