import { UserDto } from "src/types/api";
import {
  ShareTypeEnum,
  recoverPrivateKey,
  recoverShare,
} from "src/actions/shamir/utils";

export async function reshowBackupShare(user: UserDto) {
  const shareUnyteDB = user.share;
  const shareLocalStorageStr = localStorage.getItem(user.uid);
  if (shareLocalStorageStr === null) throw new Error("share not found");
  const shareLocalStorage = shareLocalStorageStr
    .split(",")
    .map((s) => parseInt(s));
  const privateKey = recoverPrivateKey({
    shareInfo1: {
      share: shareUnyteDB,
      type: ShareTypeEnum.UnyteDB,
    },
    shareInfo2: {
      share: shareLocalStorage,
      type: ShareTypeEnum.LocalStorage,
    },
  });
  return recoverShare(ShareTypeEnum.Backup, privateKey, shareUnyteDB);
}
