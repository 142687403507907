import { createOutput } from "src/actions/blockchain/createOutput";
import { useIsUpdating } from "src/hooks/useIsUpdating";
import { useUser } from "src/hooks/useUser";
import { restApi } from "src/libs/restClient";
import { InputFile } from "src/types/common";
import useSWR from "swr";

type SubmitOutputType = {
  userAddress: string;
  outputId: string;
  outputText: string;
  inputFiles: InputFile[];
};

const fetcher = async (daoId: string, taskId: string) => {
  return restApi.taskControllerFetchOutputs({
    daoId: daoId,
    taskId: taskId,
  });
};

export const useOutputs = (daoId: string, taskId: string) => {
  const keys = [`tasks/${daoId}/${taskId}/outputs`, daoId, taskId];
  const { data, error, isLoading, mutate } = useSWR(
    keys,
    ([_, daoId, taskId]) => fetcher(daoId, taskId)
  );
  const { isUpdating, checkUpdated } = useIsUpdating({
    data,
    mutate,
  });
  const { user } = useUser();

  const submitOutput = async ({
    userAddress,
    outputId,
    outputText,
    inputFiles,
  }: SubmitOutputType) => {
    if (!data || !user) return;
    const output = {
      author: {
        address: userAddress,
      },
      note: outputText,
      date: new Date().getTime(),
      verified: false,
      verifier: "",
    };
    await createOutput(daoId, taskId, outputId, output, inputFiles, user);
    checkUpdated();
    await mutate();
  };

  return {
    outputs: data?.outputs,
    isError: error,
    isLoading,
    isUpdating,
    mutate,
    submitOutput,
    checkUpdated,
  };
};
