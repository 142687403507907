import React, { useState } from "react";
// @ts-ignore
import {
  ViewMessage,
  deleteMessage,
  editMessage,
} from "../../actions/firebase/message.ts";
import { useIntl } from "react-intl";

export const EditForm = ({
  daoId,
  channelId,
  message,
  onUpdate: onUpdateCallback,
  onCancel,
}: {
  daoId: string;
  channelId: string;
  message: ViewMessage;
  onUpdate: () => void;
  onCancel: () => void;
}) => {
  const [text, setText] = useState(message.text);

  const onUpdate = () => {
    editMessage(daoId, channelId, message.id, text, [], []);
    onUpdateCallback();
  };

  return (
    <div className="flex items-start space-x-4">
      <div className="min-w-0 flex-1">
        <div className="relative space-y-2">
          <div className="overflow-hidden rounded-lg shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
            <label htmlFor="comment" className="sr-only">
              Add your comment
            </label>
            <textarea
              rows={3}
              name="comment"
              id="comment"
              className="block w-full resize-none border-0 bg-[#1A1D24] py-1.5 text-gray-50 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
              placeholder="Add your comment..."
              value={text}
              onChange={(e) => setText(e.target.value)}
            />
          </div>

          <div className="flex items-center justify-end gap-x-2">
            <button
              onClick={onCancel}
              className="flex h-8 w-fit items-center justify-center gap-x-1 rounded-full bg-gray-500 px-2 text-gray-50"
            >
              <span className="flex w-fit text-sm">キャンセル</span>
            </button>
            <button
              onClick={onUpdate}
              className="flex h-8 w-16 items-center justify-center gap-x-1 rounded-full bg-gradient-to-r from-[#EE7b4d] to-[#E5007E] text-gray-50"
            >
              <span className="flex w-fit text-sm">更新</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
