import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { reshowBackupShare } from "../../actions/shamir/reshowShare";
import { useUser } from "src/hooks/useUser";
import { sendEmail, validateEmail } from "src/actions/email/sendEmail";
import Loading from "src/components/common/Loading";
import { BiCopy } from "react-icons/bi";
import { FormattedMessage } from "react-intl";
import {
  UNYTE_SHARE_PREFIX,
  encodeToBase64ForUnyte,
} from "src/actions/shamir/utils";

const ReshowShare: React.FC = () => {
  const navigate = useNavigate();
  const { user, isLoading: isLoadingUser } = useUser();
  const [shareBackupStr, setShareBackupStr] = useState<string>("");
  const [email, setEmail] = useState<string>(user?.email || "");
  const [error, setError] = useState<string | undefined>();
  const [isLoadingSendEmail, setIsLoadingSendEmail] = useState<boolean>(false);

  useEffect(() => {
    if (!user?.email) return;
    setEmail(user.email);
  }, [user?.email]);

  const handleClickCopy = async () => {
    if (!shareBackupStr) return;
    try {
      await navigator.clipboard.writeText(shareBackupStr);
      alert("クリップボードにコピーしました");
    } catch (err) {
      alert("コピーに失敗しました");
    }
  };

  const handleClickReshow = async () => {
    if (!user) return;
    try {
      const shareBackup = await reshowBackupShare(user);
      setShareBackupStr(encodeToBase64ForUnyte(shareBackup));
    } catch (error) {
      console.error(error);
      if (error instanceof Error) {
        const isConfirm = confirm(
          `バックアップシェアの再表示に失敗しました。\n\nError: ${error.message}\n\nシェアを再設定しますか？`
        );
        if (isConfirm) navigate("/reset");
        return;
      }
      alert("不明なエラーです。運営にお問い合わせください。");
      return;
    }
  };

  const handleClickSendEmail = async () => {
    if (!user || !email) return;
    setIsLoadingSendEmail(true);
    let shareBackup: number[];
    try {
      shareBackup = await reshowBackupShare(user);
    } catch (error) {
      console.error(error);
      if (error instanceof Error) {
        const isConfirm = confirm(
          `バックアップシェアの復元に失敗しました。\n\nError: ${error.message}\n\nシェアを再設定しますか？`
        );
        if (isConfirm) navigate("/reset");
        setIsLoadingSendEmail(false);
        return;
      }
      alert("不明なエラーです。運営にお問い合わせください。");
      setIsLoadingSendEmail(false);
      return;
    }
    const isConfirm = confirm("送信しますか？");
    if (!isConfirm) {
      setIsLoadingSendEmail(false);
      return;
    }
    try {
      await sendEmail(email, encodeToBase64ForUnyte(shareBackup));
    } catch (error) {
      console.error(error);
      alert(`バックアップシェアの送信に失敗しました。\n\nError: ${error}`);
      setIsLoadingSendEmail(false);
      return;
    }
    setIsLoadingSendEmail(false);
    alert("メールを送信しました。");
  };

  return (
    <>
      <Loading isShow={isLoadingUser || !user || isLoadingSendEmail} />
      <div className="mb-10 mt-10 flex items-center justify-center bg-unyte-main lg:mt-0 lg:h-full">
        <div className="w-11/12 rounded-lg bg-white p-4 shadow-md lg:w-full lg:max-w-[480px] lg:p-8">
          <h1 className="mx-auto mb-5 text-center text-xl font-bold lg:text-2xl">
            バックアップシェアの再表示
          </h1>
          <button
            className="mb-3 w-full rounded-md bg-blue-600 p-3 text-sm text-white hover:bg-blue-700 disabled:bg-gray-400 lg:text-base"
            disabled={!user}
            onClick={handleClickReshow}
          >
            バックアップシェアを再表示する
          </button>
          <div className="">
            <textarea
              value={shareBackupStr}
              className="mb-1 h-48 w-full resize-none rounded-md border border-gray-300 p-3 shadow-sm"
              disabled
              placeholder={`${UNYTE_SHARE_PREFIX}_••••••••••••`}
            />
            <div className="flex w-full justify-end">
              <button
                className="flex items-center justify-center text-sm text-gray-500"
                disabled={!shareBackupStr}
                onClick={handleClickCopy}
              >
                <BiCopy />
                <FormattedMessage
                  id="register_button_1"
                  defaultMessage="コピー"
                />
              </button>
            </div>
          </div>
          <hr className="my-8 h-px border-0 bg-gray-300"></hr>
          <div className="mb-4">
            <div className="mb-3">
              <input
                value={email}
                className="mb-1 w-full rounded-md border border-gray-300 p-3 shadow-sm"
                placeholder="unyte@example.com"
                onChange={(e) => {
                  const newEmail = e.target.value;
                  setEmail(newEmail);
                  !validateEmail(newEmail)
                    ? setError("無効なメールアドレスです。")
                    : setError(undefined);
                }}
              />
              {error && <p className="text-red-500">{error}</p>}
            </div>
            <button
              className="w-full rounded-md bg-blue-600 p-3 text-sm text-white hover:bg-blue-700 disabled:bg-gray-400 lg:text-base"
              disabled={!user || error !== undefined || isLoadingSendEmail}
              onClick={handleClickSendEmail}
            >
              バックアップシェアをメールで送信する
            </button>
          </div>
          <hr className="my-8 h-px border-0 bg-gray-300"></hr>
          <button
            className="w-full rounded-md bg-white p-3 text-sm text-blue-600 hover:bg-gray-100 lg:text-base"
            onClick={() => navigate("/my-page")}
          >
            戻る
          </button>
        </div>
      </div>
    </>
  );
};

export default ReshowShare;
