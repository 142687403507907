import React from "react";
import clsx from "clsx";
import { useAtom } from "jotai";
import { ItemTextInput } from "../ItemTextInput.tsx";
import { tmpAddAndEditItemState } from "../../../../../stores/tmpItemState.ts";
import { BaseProps } from "../../../../../types/common/index.ts";

export type ItemDescriptionInputProps = {} & BaseProps;

export const ItemDescriptionInput: React.FC<ItemDescriptionInputProps> = ({
  className,
}) => {
  const [tmpItem, setTmpItem] = useAtom(tmpAddAndEditItemState);
  const description = tmpItem.description;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTmpItem((prev) => {
      const newTmpItem = { ...prev };
      newTmpItem.description = e.target.value;
      return newTmpItem;
    });
  };

  return (
    <ItemTextInput
      className={clsx(className)}
      msgId="item_description"
      defaultMessage="アイテムについての説明を入力してください。"
      value={description}
      onChange={handleChange}
    />
  );
};
