/* tslint:disable */
/* eslint-disable */
/**
 * Unyte SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PurchaseArgs
 */
export interface PurchaseArgs {
    /**
     * 購入者のアドレス
     * @type {string}
     * @memberof PurchaseArgs
     */
    buyer: string;
    /**
     * トークンのID
     * @type {number}
     * @memberof PurchaseArgs
     */
    tokenId: number;
    /**
     * 購入するアイテムの数
     * @type {number}
     * @memberof PurchaseArgs
     */
    amount: number;
}

/**
 * Check if a given object implements the PurchaseArgs interface.
 */
export function instanceOfPurchaseArgs(value: object): boolean {
    if (!('buyer' in value)) return false;
    if (!('tokenId' in value)) return false;
    if (!('amount' in value)) return false;
    return true;
}

export function PurchaseArgsFromJSON(json: any): PurchaseArgs {
    return PurchaseArgsFromJSONTyped(json, false);
}

export function PurchaseArgsFromJSONTyped(json: any, ignoreDiscriminator: boolean): PurchaseArgs {
    if (json == null) {
        return json;
    }
    return {
        
        'buyer': json['buyer'],
        'tokenId': json['tokenId'],
        'amount': json['amount'],
    };
}

export function PurchaseArgsToJSON(value?: PurchaseArgs | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'buyer': value['buyer'],
        'tokenId': value['tokenId'],
        'amount': value['amount'],
    };
}

