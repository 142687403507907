import React, { useEffect, useRef, useState } from "react";
import { getAuth } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { NavigateFunction, useNavigate, useLocation } from "react-router-dom";
import { decryptoPrivateKey } from "../../actions/blockchain/generateKeys.js";
import { ethers } from "ethers";

import { fetchUserByWallet } from "../../actions/firebase/utils.js";
import Loading from "../../components/common/Loading.tsx";
import { STAGE } from "src/const/const.ts";
import { useUser } from "src/hooks/useUser.ts";
import { UserDto } from "src/types/api/index.ts";

type ReturnTokenRequestType = {
  userAddress: string | null;
  userName: string | null;
  recieverAddress: string | null;
  recieverName: string | null;
  tokenId: number;
  amount: number;
  timestamp: number;
  signature: string;
};

type ReturnTokenResultType = {
  status: string;
  message: string;
};

const abi = [
  {
    inputs: [
      {
        internalType: "address",
        name: "from",
        type: "address",
      },
      {
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "id",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
      {
        internalType: "bytes",
        name: "data",
        type: "bytes",
      },
    ],
    name: "safeTransferFrom",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

const returnToken = async (
  from: string,
  to: string,
  tokenId: number,
  amount: number,
  user: UserDto | undefined
): Promise<ReturnTokenResultType> => {
  // 秘密鍵の復元
  let keyBytes: string[] = [];
  try {
    keyBytes = (await decryptoPrivateKey(user)) as string[];
    if (!keyBytes || keyBytes.length === 0)
      throw new Error("秘密鍵の復元に失敗しました。");
  } catch (e) {
    console.error(e);
    return { status: "error", message: "秘密鍵の復元に失敗しました。" };
  }
  const privateKey = keyBytes.join("").replace("\u0000\u0000", "");

  // トークン返還処理
  try {
    const provider = new ethers.JsonRpcProvider(
      process.env.REACT_APP_ALCHEMY_ENDPOINT
    );
    const wallet = new ethers.Wallet(privateKey, provider);
    const contract = new ethers.Contract(
      process.env.REACT_APP_UNYTE1155_CONTRACT_ADDRESS as string,
      abi,
      wallet
    );
    const tx = await contract.safeTransferFrom(from, to, tokenId, amount, "0x");
    console.log("tx", tx);
    const receipt = await tx.wait();
    console.log("receipt", receipt);
    return { status: "success", message: receipt.hash };
  } catch (e) {
    console.error(e);
    return { status: "error", message: "返還に失敗しました。" };
  }
};

const ReturnToken = () => {
  const [session] = useAuthState(getAuth());
  const { user } = useUser();
  const navigate: NavigateFunction = useNavigate();
  const search = useLocation().search;

  const [requestData, setRequestData] = useState<ReturnTokenRequestType | null>(
    null
  );
  const [returnTokenResult, setReturnTokenResult] =
    useState<ReturnTokenResultType>({
      status: "",
      message: "",
    });

  const [inProgress, setInProgress] = useState<{
    isShow: boolean;
    message: string;
  }>({ isShow: false, message: "" });
  const isProcessing = useRef<boolean>(false);

  useEffect(() => {
    if (!session) return;
    const init = async () => {
      // 返還リクエストURL作成スクリプトで署名に用いた秘密鍵に紐づくアドレスと同じものを設定すること
      const verifierAddress = "0xC8C72bd5Ba0F0c57Fe425C9F13217b3ED5D3d210";

      const data: ReturnTokenRequestType = {
        userAddress: null,
        userName: null,
        recieverAddress: null,
        recieverName: null,
        tokenId: 0,
        amount: 0,
        timestamp: 0,
        signature: "",
      };

      try {
        setInProgress({ isShow: true, message: "署名検証中..." });

        if (user) {
          data.userAddress = user.address;
          data.userName = user.name;
        }

        //URLパラメータからリクエストデータを取得
        const query = new URLSearchParams(search);

        data.recieverAddress = query.get("reciever");
        if (data.recieverAddress) {
          const reciever = await fetchUserByWallet(data.recieverAddress);
          data.recieverName = reciever?.name;
        }
        data.tokenId = parseInt(query.get("tokenId") || "0");
        data.amount = parseInt(query.get("amount") || "0");
        data.timestamp = parseInt(query.get("timestamp") || "0");
        setRequestData(data);
        console.log("requestData", data);

        // 署名検証
        const signature = query.get("signature") || "";
        const isReturnedAlready =
          localStorage.getItem(`UsedSignature_${signature}`) !== null;
        if (isReturnedAlready) {
          setReturnTokenResult({
            status: "error",
            message: "すでに返還済みです",
          });
          return;
        }

        data.signature = signature;
        const verifyData = {
          userAddress: data.userAddress,
          recieverAddress: data.recieverAddress,
          tokenId: data.tokenId,
          amount: data.amount,
          timestamp: data.timestamp,
        };
        const signerAddress = ethers.verifyMessage(
          JSON.stringify(verifyData),
          signature
        );
        if (signerAddress !== verifierAddress) {
          console.error("署名が一致しません");
          setReturnTokenResult({
            status: "error",
            message: "署名が一致しません",
          });
        }
      } catch (e) {
        console.error(e);
        setReturnTokenResult({
          status: "error",
          message: "予期せぬエラーが発生しました",
        });
      } finally {
        setInProgress({ isShow: false, message: "" });
      }
    };
    init();
  }, [session]);

  const goToHomeHandler = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    navigate("/");
  };

  const execHandler = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    try {
      event.preventDefault();

      if (isProcessing.current) return;
      if (
        requestData === null ||
        requestData.userAddress === null ||
        requestData.recieverAddress === null ||
        requestData.amount === 0
      )
        return;

      // トークン返還処理
      isProcessing.current = true;
      setInProgress({ isShow: true, message: "トークン返還中..." });

      returnToken(
        requestData.userAddress,
        requestData.recieverAddress,
        requestData.tokenId,
        requestData.amount,
        user
      ).then((result: ReturnTokenResultType) => {
        setReturnTokenResult(result);
        if (result.status === "success") {
          localStorage.setItem(
            `UsedSignature_${requestData.signature}`,
            result.message
          );
        }
        setInProgress({ isShow: false, message: "" });
        isProcessing.current = false;
      });
    } catch (e) {
      console.error(e);
      setReturnTokenResult({
        status: "error",
        message: "予期せぬエラーが発生しました",
      });
      setInProgress({ isShow: false, message: "" });
      isProcessing.current = false;
    }
  };

  // トークン返還処理完了時
  if (returnTokenResult.status === "success")
    return (
      <div>
        <div className="p-4 text-2xl text-white">トークン返還</div>
        <div className="flex w-full items-center justify-center p-4 text-white">
          <div className="flex-row items-center justify-center space-y-8">
            <div className="text-3xl text-white">返還しました</div>
            <div className="text-sm text-blue-300 underline">
              <a
                href={
                  process.env.REACT_APP_STAGE === STAGE.MAIN ||
                  process.env.REACT_APP_STAGE === STAGE.DEMO
                    ? `https://polygonscan.com/tx/${returnTokenResult.message}`
                    : `https://amoy.polygonscan.com/tx/${returnTokenResult.message}`
                }
                target="_blank"
                rel="noreferrer"
              >
                確認サイトへ
              </a>
            </div>
            <button
              className="w-36 rounded-2xl bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700"
              onClick={goToHomeHandler}
            >
              Homeへ
            </button>
          </div>
        </div>
      </div>
    );

  // トークン返還処理前
  return (
    <div>
      <div className="p-4 text-2xl text-white">トークン返還</div>
      <div className="flex w-full items-center justify-center p-4 text-white">
        <div className="flex-row items-center justify-center space-y-8">
          <div className="flex-row items-center justify-around">
            <div className="text-sm font-bold text-gray-400">返還元</div>
            <div className="text-base">{requestData?.userName}</div>
            <div className="text-sm">{requestData?.userAddress}</div>
          </div>
          <div className="flex-row items-center justify-around">
            <div className="text-sm font-bold text-gray-400">返還先</div>
            <div className="text-base">{requestData?.recieverName}</div>
            <div className="text-sm">{requestData?.recieverAddress}</div>
          </div>
          <div className="flex-row items-center justify-around">
            <div className="text-sm font-bold text-gray-400">返還数</div>
            <div className="text-xl">{requestData?.amount}</div>
          </div>
          <div className="text-sm text-red-300">
            {returnTokenResult.message}
          </div>
          <div className="flex justify-between gap-x-2">
            {returnTokenResult.status !== "error" && (
              <button
                className="w-36 rounded-2xl bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700"
                onClick={execHandler}
              >
                返 還
              </button>
            )}
            <button
              className="w-36 rounded-2xl bg-red-500 px-4 py-2 font-bold text-white hover:bg-red-700"
              onClick={goToHomeHandler}
            >
              キャンセル
            </button>
          </div>
        </div>
      </div>
      <Loading {...inProgress} />
    </div>
  );
};

export default ReturnToken;
