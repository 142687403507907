import React from "react";
import AdminOutputCard from "../../../components/admin/task/AdminOutputCard.tsx";
import { AllSelectCheckBox } from "../../../components/admin/task/AllSelectCheckBox.tsx";
import { SelectedOutputCount } from "../../../components/Task/SelectedOutputCount.tsx";
import { Switch } from "@mui/material";
import { useAdminOutputs } from "src/hooks/useAdminOutputs.ts";
import { OutputsForAllTask } from "src/types/api/index.ts";

interface Props {
  daoId: string;
}

const AdminOutputList: React.FC<Props> = ({ daoId }) => {
  const [showPendingOnly, setShowPendingOnly] = React.useState(true);

  const { outputs, isLoading, isError, approve, reject } = useAdminOutputs(
    daoId,
    showPendingOnly
      ? {
          approvalStatus: ["pending"],
        }
      : {}
  );

  const [selectedOutputIds, setSelectedOutputIds] = React.useState<Set<string>>(
    new Set([])
  );
  const toggleSelectedOutput = (output: OutputsForAllTask) => {
    if (output.status !== "pending") return;
    if (selectedOutputIds.has(output.outputId)) {
      selectedOutputIds.delete(output.outputId);
    } else {
      selectedOutputIds.add(output.outputId);
    }
    setSelectedOutputIds(new Set(selectedOutputIds));
  };

  return (
    <div className="m-8">
      <div className="mb-4 flex items-center">
        <Switch
          sx={{
            "& .MuiSwitch-track": {
              background: "white",
            },
          }}
          onChange={(e) => {
            setShowPendingOnly(e.target.checked);
          }}
          defaultChecked
        />
        <div>未承認のみ表示</div>
      </div>
      <div>
        {(() => {
          if (isLoading) return <div className="text-white">Loading...</div>;
          if (isError) return <div className="text-white">Error</div>;
          if (!outputs) return <div className="text-white">No data</div>;
          return (
            <div>
              {outputs.length > 0 && (
                <div className="mb-4 flex w-full flex-wrap items-center justify-between gap-y-4">
                  <div className="flex shrink-0 items-center gap-x-6">
                    <AllSelectCheckBox
                      outputs={outputs}
                      selectedOutputIds={selectedOutputIds}
                      setSelectedOutputIds={setSelectedOutputIds}
                    />
                    <SelectedOutputCount
                      pendingOutputsCount={
                        outputs.filter((output) => output.status === "pending")
                          .length
                      }
                      selectedOutputsCount={selectedOutputIds.size}
                    />
                  </div>
                  <div className="flex shrink-0 gap-x-6">
                    <button
                      className={`h-8 rounded-full px-6 text-xs text-white ${
                        selectedOutputIds.size
                          ? "bg-task-reject-button"
                          : "bg-gray-500"
                      }`}
                      disabled={!selectedOutputIds.size}
                      onClick={async () => {
                        const confirm =
                          window.confirm("成果物を棄却しますか？");
                        if (!confirm) return;
                        try {
                          await reject(
                            outputs.filter((output) =>
                              selectedOutputIds.has(output.outputId)
                            )
                          );
                        } catch (e) {
                          console.error(e);
                          if (e instanceof Error) {
                            alert(
                              `成果物の棄却に失敗した可能性があります。ご確認ください。\n${e.stack}`
                            );
                          } else
                            alert(
                              `成果物の棄却に失敗した可能性があります。ご確認ください。\n\n${JSON.stringify(
                                e
                              )}`
                            );
                          return;
                        }
                        setSelectedOutputIds(new Set([]));
                      }}
                    >
                      棄却する
                    </button>
                    <button
                      className={`h-8 rounded-full px-6 text-xs text-white ${
                        selectedOutputIds.size
                          ? "bg-task-button"
                          : "bg-gray-500"
                      }`}
                      disabled={!selectedOutputIds.size}
                      onClick={async () => {
                        const confirm =
                          window.confirm("成果物を承認しますか？");
                        if (!confirm) return;
                        try {
                          await approve(
                            outputs.filter((output) =>
                              selectedOutputIds.has(output.outputId)
                            )
                          );
                        } catch (e) {
                          console.error(e);
                          if (e instanceof Error) {
                            alert(
                              `成果物の承認に失敗した可能性があります。ご確認ください。\n${e.stack}`
                            );
                          } else
                            alert(
                              `成果物の承認に失敗した可能性があります。ご確認ください。\n\n${JSON.stringify(
                                e
                              )}`
                            );
                          return;
                        }
                        setSelectedOutputIds(new Set([]));
                      }}
                    >
                      承認する
                    </button>
                  </div>
                </div>
              )}
              <div className="grid grid-cols-1 gap-2 text-white md:grid-cols-2 xl:grid-cols-3">
                {outputs.map((output) => (
                  <AdminOutputCard
                    key={output.outputId}
                    daoId={daoId}
                    output={output}
                    isSelected={selectedOutputIds.has(output.outputId)}
                    toggleSelectedOutput={toggleSelectedOutput}
                  />
                ))}
              </div>
            </div>
          );
        })()}
      </div>
    </div>
  );
};

export default AdminOutputList;
