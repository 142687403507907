import React from "react";
import { BaseProps, InputFile, Progress } from "../../types/common/index.ts";
import { ethers } from "ethers";
import { createEventNotification } from "../../actions/firebase/notification.ts";
import { NotificationEvent } from "../../types/notification/index.ts";
import { useOutputs } from "src/hooks/useOutputs.ts";
import { useUser } from "src/hooks/useUser.ts";

const MAX_OUTPUT_TEXT_LENGTH = 1000;

type CheckInputType = {
  isOk: boolean;
  message?: string;
};

const checkInput = ({
  daoId,
  taskId,
  outputText,
}: {
  daoId: string;
  taskId: string;
  outputText: string;
}): CheckInputType => {
  if (!outputText || !daoId || !taskId)
    return {
      isOk: false,
      message: "入力が不正です。",
    };
  if (outputText.trim().length > MAX_OUTPUT_TEXT_LENGTH)
    return {
      isOk: false,
      message: `成果物の長さは${MAX_OUTPUT_TEXT_LENGTH}文字以下にしてください。`,
    };
  return {
    isOk: true,
  };
};

export type SubmitOutputButtonProps = {
  daoId: string;
  taskId: string;
  outputText: string;
  setInProgress: (value: React.SetStateAction<Progress>) => void;
  setOutputText: (value: React.SetStateAction<string>) => void;
  inputFiles: InputFile[];
  setInputFiles: React.Dispatch<React.SetStateAction<InputFile[]>>;
  adminAddresses: string[];
} & BaseProps;

export const SubmitOutputButton: React.FC<SubmitOutputButtonProps> = ({
  className,
  daoId,
  taskId,
  outputText,
  setInProgress,
  setOutputText,
  inputFiles,
  setInputFiles,
  adminAddresses,
}) => {
  const { submitOutput } = useOutputs(daoId, taskId);
  const { user } = useUser();

  const handleClick = async () => {
    if (!user) return;
    const check = checkInput({ daoId, taskId, outputText });
    if (check.isOk === false) {
      alert(check.message);
      return;
    }

    setInProgress({ isShow: true, message: "成果物を提出しています。" });

    const outputId = ethers.keccak256(
      ethers.toUtf8Bytes(user.address + new Date().getTime().toString())
    );
    try {
      await submitOutput({
        userAddress: user.address,
        outputId,
        outputText,
        inputFiles,
      });
    } catch (e) {
      console.error(e);
      setInProgress({ isShow: false, message: "" });
      if (e instanceof Error) {
        alert(
          `成果物の提出に失敗した可能性があります。ご確認ください。\n${e.stack}`
        );
      } else
        alert(
          `成果物の提出に失敗した可能性があります。ご確認ください。\n\n${JSON.stringify(
            e
          )}`
        );
      return;
    }

    const notificationEvent: NotificationEvent = {
      daoId: daoId,
      timestamp: Date.now(),
      eventType: "submitOutput",
      message: outputText,
      from: user.address,
      to: adminAddresses,
      data: {
        taskId: taskId,
        outputId: outputId,
      },
    };
    createEventNotification(notificationEvent);

    setOutputText("");
    setInputFiles([]);
    setInProgress({ isShow: false, message: "" });
  };

  return (
    <button
      className={`${className} h-8 w-fit rounded-full px-8 text-sm text-white disabled:opacity-50 ${
        outputText.trim() === "" ? "bg-gray-500" : "bg-task-button"
      }`}
      onClick={handleClick}
      disabled={outputText.trim() === ""}
    >
      提出する
    </button>
  );
};
