import {
  ShareTypeEnum,
  recoverPrivateKey,
  recoverShare,
} from "src/actions/shamir/utils";

export async function regenerateShare(
  shareUnyteDB: number[],
  shareBackup: number[]
) {
  const privateKey = recoverPrivateKey({
    shareInfo1: {
      share: shareUnyteDB,
      type: ShareTypeEnum.UnyteDB,
    },
    shareInfo2: {
      share: shareBackup,
      type: ShareTypeEnum.Backup,
    },
  });
  const shareLocalStorage = recoverShare(
    ShareTypeEnum.LocalStorage,
    privateKey,
    shareUnyteDB
  );
  return { shareLocalStorage, privateKey };
}
