import { createEventNotification } from "src/actions/firebase/notification";
import { useIsUpdating } from "src/hooks/useIsUpdating";
import { restApi } from "src/libs/restClient";
import { SelectablePeriod } from "src/pages/thanks/Thanks";
import { NotificationEvent } from "src/types/notification";
import useSWR from "swr";

const fetcherThanks = async (
  daoId: string,
  address?: string,
  start?: Date,
  end?: Date
) => {
  const startUnix = start ? Math.floor(start.getTime()) : undefined;
  const endUnix = end ? Math.floor(end.getTime()) : undefined;
  return restApi.thanksControllerGetThanks({
    daoId,
    userAddress: address,
    start: startUnix,
    end: endUnix,
  });
};

const fetcherUsers = async (daoId: string) => {
  return restApi.thanksControllerGetUsers({ daoId });
};

export const useThanks = (
  daoId: string,
  address?: string,
  searchQuery?: string,
  selectedPeriod: SelectablePeriod = "wholetime"
) => {
  const end = new Date();
  let start: Date | undefined = new Date();
  let startOfWeek: Date | undefined;
  switch (selectedPeriod) {
    case "daily":
      // 今日
      // start = new Date(end.getFullYear(), end.getMonth(), end.getDate(), 0, 0, 0, 0);

      // 直近24時間
      start = new Date(end.getTime() - 24 * 60 * 60 * 1000);
      break;
    case "weekly":
      // 今週
      // startOfWeek = new Date(end.getTime() - (end.getDay() - 1) * 24 * 60 * 60 * 1000);
      // start = new Date(startOfWeek.getFullYear(), startOfWeek.getMonth(), startOfWeek.getDate(), 0, 0, 0, 0);

      // 直近7日間
      start = new Date(end.getTime() - 7 * 24 * 60 * 60 * 1000);
      break;
    case "monthly":
      // 今月
      // start = new Date(end.getFullYear(), end.getMonth(), 1, 0, 0, 0, 0);

      // 直近30日間
      start = new Date(end.getTime() - 30 * 24 * 60 * 60 * 1000);
      break;
    case "yearly":
      // 今年
      // start = new Date(end.getFullYear(), 0, 1, 0, 0, 0, 0);

      // 直近1年
      start = new Date(end.getTime() - 365 * 24 * 60 * 60 * 1000);
      break;
    case "wholetime":
      start = undefined;
  }

  const {
    data: dataThanks,
    error: errorThanks,
    isLoading: isThanksLoading,
    mutate: _mutateThanks,
  } = useSWR(
    [`thanks/${daoId}`, daoId, selectedPeriod, address],
    ([_, daoId]) => fetcherThanks(daoId, address, start, end)
  );
  const { isUpdating: isThanksUpdating, checkUpdated: checkThanksUpdated } =
    useIsUpdating({
      data: dataThanks,
      mutate: _mutateThanks,
    });

  const mutateThanks = async () => {
    checkThanksUpdated();
    await _mutateThanks();
  };

  const sendThanks = async (
    from: string,
    to: string,
    message: string,
    amount?: number
  ) => {
    return restApi
      .thanksControllerCreateThanks(
        { createThanksRequest: { daoId, message, from, to, amount } },
        {
          headers: {
            "content-type": "application/json",
          },
        }
      )
      .then(async (res) => {
        await mutateThanks();
        const notificationEvent: NotificationEvent = {
          daoId: daoId,
          timestamp: Date.now(),
          eventType: "thanks",
          message: message,
          from: from,
          to: [to],
          data: {
            thanksId: res.thanksId,
            from: from,
            to: to,
            message: message,
            amount: amount ? amount : 0,
          },
        };
        createEventNotification(notificationEvent);
        return res;
      })
      .catch((err) => alert(err))
      .finally(mutateThanks);
  };

  const {
    data: dataUsers,
    error: errorUsers,
    isLoading: isUsersLoading,
    mutate: mutateUsers,
  } = useSWR([`thanks/${daoId}/users`, daoId], ([_, daoId]) =>
    fetcherUsers(daoId)
  );
  const { isUpdating: isUsersUpdating, checkUpdated: checkUsersUpdated } =
    useIsUpdating({
      data: dataUsers,
      mutate: mutateUsers,
    });

  return {
    thanks: dataThanks?.thanks.filter(
      (thank) =>
        searchQuery == null ||
        thank.from.address.toLowerCase().includes(searchQuery) ||
        thank.to.address.toLowerCase().includes(searchQuery) ||
        thank.message.toLowerCase().includes(searchQuery)
    ),
    daoUsers: dataUsers?.users,
    isError: errorThanks ?? errorUsers,
    isLoading: isThanksLoading || isUsersLoading,
    isUpdating: isThanksUpdating || isUsersUpdating,
    sendThanks,
    mutateThanks,
    mutateDaoUsers: async () => {
      checkUsersUpdated();
      await mutateUsers();
    },
  };
};
