import React from "react";
import { BaseProps } from "../../types/common/index.ts";

export type OverlayProps = {
  onClick: () => void;
} & BaseProps;

export const Overlay: React.FC<OverlayProps> = ({ className, onClick }) => {
  return (
    <div
      className={`${className} fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity`}
      onClick={onClick}
    />
  );
};
