import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { CheckIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { BellIcon } from "@heroicons/react/24/solid";
import { useLocation, useNavigate } from "react-router-dom";
import { Badge, FormControlLabel, Switch } from "@mui/material";
import unyteWide from "../../assets/unyte_wide.png";
import unyteSquare from "../../assets/unyte_square.png";
import { fbLogout } from "../../actions/firebase/session.ts";
import { ClipboardDocumentIcon } from "@heroicons/react/24/outline";
import { formatAddress } from "../../utils/formatAddress.ts";
import userIcon from "../../assets/userIcon.svg";
import { useNotifications } from "../../actions/firebase/notification.ts";
import {
  LocalStorageNotificationItem,
  MessageEventData,
  VoteEventData,
  ApproveTaskEventData,
  SubmitOutputEventData,
  AddTaskEventData,
  RejectTaskEventData,
} from "../../types/notification/index.ts";
import { useUser } from "src/hooks/useUser.ts";

const UnyteHeader: React.FC = () => {
  const { user } = useUser();
  const [isOpenNotification, setIsOpenNotification] = useState(false);
  const [isOpenAccount, setIsOpenAccount] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const {
    notifications,
    unreadNotificationsCount,
    readAllNotifications,
    readNotification,
    showUnreadOnly,
    setShowUnreadOnly,
  } = useNotifications();
  const navigate = useNavigate();
  const location = useLocation();

  const navigateDetail = () => {
    // 現在のURLを取得
    // NOTE: リロードを防ぐ形で実装しているので、ページを増やす際は注意
    console.log(location.pathname);
    const path = location.pathname.split("/")[1];
    if (path.match(/0x/)) {
      return;
    } else {
      navigate("/");
    }
  };

  const onClickLogout = () => {
    fbLogout();
  };

  const copyHandling = () => {
    navigator.clipboard.writeText(user?.address ?? "");
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };
  const navigateList = () => {
    navigate("/list");
  };

  const onClickNotification = (notification: LocalStorageNotificationItem) => {
    readNotification(notification, user);
    setIsOpenNotification(false);

    switch (notification.eventType) {
      case "message": {
        const messageEventData = notification.data as MessageEventData;
        // TODO: messageIdまで指定
        navigate(
          `/${notification.daoId}/message/${messageEventData.channelId}`
        );
        break;
      }
      case "vote":
        navigate(
          `/${notification.daoId}/vote/${
            (notification.data as VoteEventData).proposalId
          }`
        );
        break;
      case "addTask": {
        const { taskId } = notification.data as AddTaskEventData;
        navigate(`/${notification.daoId}/task/${taskId}`);
        break;
      }
      case "approveTask": {
        const { taskId, outputId } = notification.data as ApproveTaskEventData;
        navigate(`/${notification.daoId}/task/${taskId}/${outputId}`);
        break;
      }
      case "rejectTask": {
        const { taskId, outputId } = notification.data as RejectTaskEventData;
        navigate(`/${notification.daoId}/task/${taskId}/${outputId}`);
        break;
      }
      case "submitOutput": {
        const { taskId, outputId } = notification.data as SubmitOutputEventData;
        navigate(`/${notification.daoId}/task/${taskId}/${outputId}`);
        break;
      }
      case "thanks": {
        // const { thanksId } = notification.data as ThanksEventData;
        navigate(`/${notification.daoId}/thanks`);
        break;
      }
      default: {
        const unreachable: never = notification.eventType;
        console.error("Unimplemented notification event type: ", unreachable);
        break;
      }
    }
  };

  return (
    <header className="fixed left-0 top-0 z-30">
      <nav className="sticky w-screen border-b border-unyte-dark bg-[#121217] px-1 py-1 lg:px-2 lg:py-8">
        <div className="mx-auto flex max-w-screen-2xl flex-wrap items-center justify-between">
          {/* logo */}

          {location.pathname === "/list" && (
            <a href="/">
              <img
                src={unyteWide}
                alt="unyte"
                className="hidden h-9 px-2 lg:flex lg:px-8"
              />
              <img
                src={unyteSquare}
                alt="unyte"
                className="flex h-9 px-2 lg:hidden lg:px-8"
              />
            </a>
          )}
          {location.pathname !== "/list" && (
            <>
              <a href="/" className="hidden lg:flex">
                <img
                  src={unyteWide}
                  alt="unyte"
                  className="hidden h-9 px-2 lg:flex lg:px-8"
                />
              </a>
              <div className="lg:hidden">
                <img
                  src={unyteSquare}
                  alt="unyte"
                  className="invisible h-9 px-2 lg:hidden lg:px-8"
                />
              </div>
            </>
          )}

          {/* user icon */}
          <div
            className="group relative order-3 flex items-center"
            onMouseMove={() => setIsOpenAccount(true)}
            onMouseLeave={() => setIsOpenAccount(false)}
            onTouchStart={() => setIsOpenAccount(true)}
          >
            <label className="flex cursor-pointer items-center rounded-full px-2 py-2 text-lg font-medium text-gray-200 lg:px-8">
              <img
                src={user?.img ?? unyteSquare}
                alt="profile"
                className="h-8 w-8 rounded-full"
                onError={(e) => {
                  e.currentTarget.onerror = null;
                  e.currentTarget.src = userIcon;
                }}
              />
              {/* <p className="hidden lg:flex lg:w-auto ml-4">{userName}</p> */}
            </label>

            {/* menu ▼ */}
            <div
              className={`${
                isOpenAccount ? "visible opacity-100" : "invisible opacity-0"
              } absolute -left-40 top-12 z-40 w-48 rounded-md bg-[#38373D] shadow-lg transition-opacity duration-200 lg:-left-32`}
            >
              <button
                className="group flex w-full items-center space-x-2 rounded-t-md px-4 py-2 text-sm text-gray-200 hover:bg-gray-500"
                onClick={() => {
                  copyHandling();
                }}
              >
                <span className="h-4 w-4">
                  {isCopied ? (
                    <CheckIcon />
                  ) : (
                    <ClipboardDocumentIcon></ClipboardDocumentIcon>
                  )}
                </span>
                <span>{formatAddress(user?.address ?? "")}</span>
              </button>
              <button
                className="flex w-full items-center space-x-2 px-4 py-2 text-gray-200 hover:bg-gray-500"
                onClick={() => {
                  setIsOpenAccount(false);
                  navigate("/my-page");
                }}
              >
                <FormattedMessage
                  id="header_menu_account"
                  defaultMessage="アカウント設定"
                />
              </button>
              <button
                onClick={onClickLogout}
                className="flex w-full items-center space-x-2 rounded-b-md px-4 py-2 text-gray-200 hover:bg-gray-500"
              >
                <FormattedMessage
                  id="header_menu_logout"
                  defaultMessage="ログアウト"
                />
              </button>
            </div>
            {/* ▲ */}
          </div>

          {/* bell */}
          <div
            className="group relative order-2 flex items-center"
            onMouseEnter={() => setIsOpenNotification(true)}
            onMouseLeave={() => setIsOpenNotification(false)}
            onTouchStart={() => setIsOpenNotification(true)}
          >
            <div className="flex h-12 w-16 cursor-pointer items-center justify-center">
              <Badge
                badgeContent={unreadNotificationsCount}
                overlap="circular"
                sx={{
                  "& .MuiBadge-badge": {
                    color: "white",
                    backgroundColor: "#ff1493",
                  },
                }}
              >
                <BellIcon className="h-8 text-gray-200" />
              </Badge>
            </div>
            {/* notifications ▼ */}
            <div
              className={`${
                isOpenNotification
                  ? "visible opacity-100"
                  : "invisible opacity-0"
              } absolute -left-40 top-12 z-40 max-h-[70vh] w-72 divide-y divide-gray-600 overflow-x-scroll rounded-md bg-[#38373D] shadow-lg transition-opacity duration-200 lg:-left-48 lg:w-96`}
            >
              <div className="flex w-full flex-col divide-y divide-gray-800">
                <FormControlLabel
                  control={
                    <Switch
                      checked={showUnreadOnly}
                      onChange={(event) =>
                        setShowUnreadOnly(event.target.checked)
                      }
                    />
                  }
                  label="未読のみ表示"
                  className="w-full px-4 text-gray-200"
                />
                <button
                  onClick={() => {
                    readAllNotifications(user);
                  }}
                  className="w-full"
                >
                  <div className="flex items-center px-4 py-2">
                    <CheckIcon className="h-4 w-4 text-gray-200" />
                    <div className="text-gray-200">全て既読にする</div>
                  </div>
                </button>
              </div>
              {notifications.map((notification, index) => (
                <button
                  key={index}
                  className="group flex w-full flex-row py-2 pr-4 hover:bg-gray-500"
                  onClick={() => {
                    onClickNotification(notification);
                  }}
                >
                  <div className="shrink-0 grow-0 items-center py-2 pl-4 pr-2">
                    <Badge
                      color="primary"
                      badgeContent={notification.isNew ? 1 : 0}
                      variant="dot"
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      sx={{
                        "& .MuiBadge-badge": {
                          backgroundColor: "#ff1493",
                        },
                      }}
                    >
                      <img
                        className="h-8 w-8 rounded-full bg-gray-300"
                        src={notification.iconSrc}
                        alt="icon"
                      />
                    </Badge>
                  </div>
                  <div className="flex min-w-0 grow flex-col">
                    <div className="flex h-12 items-center">
                      <div className="truncate text-base font-bold text-gray-200">
                        {notification.title}
                      </div>
                    </div>
                    <div className="-mt-2 line-clamp-3 text-left text-sm text-gray-200">
                      {notification.message}
                    </div>
                    <div className="w-full text-right text-sm text-gray-400">
                      {new Date(notification.timestamp).toLocaleString()}
                    </div>
                  </div>
                </button>
              ))}
            </div>
            {/* ▲ */}
          </div>

          {/* center button */}
          <div
            className="order-1 flex w-auto items-center justify-between"
            id="mobile-menu-2"
          >
            <ul className="flex flex-row gap-x-4 font-medium lg:mt-0 lg:space-x-8">
              <li>
                <button
                  className="group flex cursor-pointer items-center rounded-full border border-unyte-dark px-1 py-1 text-lg font-medium text-gray-200 hover:bg-gray-200 lg:px-10"
                  onClick={() => {
                    navigateDetail();
                  }}
                >
                  <CheckIcon className="mr-0 h-6 font-bold text-gray-200 group-hover:text-black lg:mr-2" />
                  <div className="hidden group-hover:text-black lg:block">
                    <FormattedMessage
                      id="header_nav_participated"
                      defaultMessage="参加中のDAO"
                    />
                  </div>
                </button>
              </li>
              <li>
                <button
                  className="group mr-4 flex cursor-pointer items-center rounded-full border border-unyte-dark px-1 py-1 text-lg font-medium text-gray-200 hover:bg-gray-200 lg:mr-0 lg:px-14"
                  onClick={() => {
                    navigateList();
                  }}
                >
                  <MagnifyingGlassIcon className="mr-0 h-6 font-bold text-gray-200 group-hover:text-black lg:mr-2" />
                  <div className="hidden group-hover:text-black lg:block">
                    <FormattedMessage
                      id="header_nav_search"
                      defaultMessage="検索"
                    />
                  </div>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default UnyteHeader;
