/* tslint:disable */
/* eslint-disable */
/**
 * Unyte SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { JoinDAOArgs } from './JoinDAOArgs';
import {
    JoinDAOArgsFromJSON,
    JoinDAOArgsFromJSONTyped,
    JoinDAOArgsToJSON,
} from './JoinDAOArgs';

/**
 * 
 * @export
 * @interface JoinDAORequest
 */
export interface JoinDAORequest {
    /**
     * 参加するDAOのアドレスと参加者のアドレス
     * @type {JoinDAOArgs}
     * @memberof JoinDAORequest
     */
    args: JoinDAOArgs;
}

/**
 * Check if a given object implements the JoinDAORequest interface.
 */
export function instanceOfJoinDAORequest(value: object): boolean {
    if (!('args' in value)) return false;
    return true;
}

export function JoinDAORequestFromJSON(json: any): JoinDAORequest {
    return JoinDAORequestFromJSONTyped(json, false);
}

export function JoinDAORequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): JoinDAORequest {
    if (json == null) {
        return json;
    }
    return {
        
        'args': JoinDAOArgsFromJSON(json['args']),
    };
}

export function JoinDAORequestToJSON(value?: JoinDAORequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'args': JoinDAOArgsToJSON(value['args']),
    };
}

