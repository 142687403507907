/* tslint:disable */
/* eslint-disable */
/**
 * Unyte SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TokenInfo } from './TokenInfo';
import {
    TokenInfoFromJSON,
    TokenInfoFromJSONTyped,
    TokenInfoToJSON,
} from './TokenInfo';

/**
 * 
 * @export
 * @interface CreateDAOArgs
 */
export interface CreateDAOArgs {
    /**
     * 作成するDAOのID
     * @type {string}
     * @memberof CreateDAOArgs
     */
    id: string;
    /**
     * 作成するDAOの名前
     * @type {string}
     * @memberof CreateDAOArgs
     */
    name: string;
    /**
     * 作成するDAOの説明文
     * @type {string}
     * @memberof CreateDAOArgs
     */
    description: string;
    /**
     * 作成するDAOの画像
     * @type {string}
     * @memberof CreateDAOArgs
     */
    img: string;
    /**
     * 作成するDAOの管理者のアドレス
     * @type {Array<string>}
     * @memberof CreateDAOArgs
     */
    admins: Array<string>;
    /**
     * 作成するDAOの参加トークン
     * @type {TokenInfo}
     * @memberof CreateDAOArgs
     */
    joinToken: TokenInfo;
    /**
     * 作成するDAOのガバナンストークン
     * @type {TokenInfo}
     * @memberof CreateDAOArgs
     */
    governanceToken: TokenInfo;
    /**
     * 作成するDAOの貢献トークン
     * @type {TokenInfo}
     * @memberof CreateDAOArgs
     */
    contributionToken: TokenInfo;
    /**
     * 作成するDAOの公開状態
     * @type {boolean}
     * @memberof CreateDAOArgs
     */
    isOpen: boolean;
    /**
     * 作成するDAOのリンク
     * @type {Array<string>}
     * @memberof CreateDAOArgs
     */
    links: Array<string>;
}

/**
 * Check if a given object implements the CreateDAOArgs interface.
 */
export function instanceOfCreateDAOArgs(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('name' in value)) return false;
    if (!('description' in value)) return false;
    if (!('img' in value)) return false;
    if (!('admins' in value)) return false;
    if (!('joinToken' in value)) return false;
    if (!('governanceToken' in value)) return false;
    if (!('contributionToken' in value)) return false;
    if (!('isOpen' in value)) return false;
    if (!('links' in value)) return false;
    return true;
}

export function CreateDAOArgsFromJSON(json: any): CreateDAOArgs {
    return CreateDAOArgsFromJSONTyped(json, false);
}

export function CreateDAOArgsFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateDAOArgs {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'description': json['description'],
        'img': json['img'],
        'admins': json['admins'],
        'joinToken': TokenInfoFromJSON(json['joinToken']),
        'governanceToken': TokenInfoFromJSON(json['governanceToken']),
        'contributionToken': TokenInfoFromJSON(json['contributionToken']),
        'isOpen': json['isOpen'],
        'links': json['links'],
    };
}

export function CreateDAOArgsToJSON(value?: CreateDAOArgs | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'description': value['description'],
        'img': value['img'],
        'admins': value['admins'],
        'joinToken': TokenInfoToJSON(value['joinToken']),
        'governanceToken': TokenInfoToJSON(value['governanceToken']),
        'contributionToken': TokenInfoToJSON(value['contributionToken']),
        'isOpen': value['isOpen'],
        'links': value['links'],
    };
}

