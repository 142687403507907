import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

export const uploadFile = async (
  file: File,
  uid: string
): Promise<string | null> => {
  const storage = getStorage();
  const storageRef = ref(storage, `userIcons/${uid}/${file.name}`);

  // file to blob
  try {
    const snapshot = await uploadBytes(storageRef, file);
    const downloadURL = await getDownloadURL(snapshot.ref);
    return downloadURL;
  } catch (error) {
    console.log(error);
    return null;
  }
};
