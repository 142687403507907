import {
  doc,
  setDoc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { fireauth, firebaseFirestore } from "./config";
import { isInitialized } from "./user";

export async function checkUser(user) {
  if (user !== null) {
    const docRef = doc(firebaseFirestore, "users", user.uid);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return true;
    } else {
      console.log("No such document! Will create user");
      return false;
    }
  } else {
    console.log("user is null");
    return false;
  }
}

export async function fetchPrivateKey(proposalId) {
  const user = fireauth.currentUser;
  if (user) {
    const docRef = doc(firebaseFirestore, "proposals", proposalId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      return null;
    }
  } else {
    return null;
  }
}

export async function fetchUsers(address) {
  if (!Array.isArray(address)) {
    return;
  }
  let result = [];
  await Promise.all(
    address.map(async (addr) => {
      const q = query(
        collection(firebaseFirestore, "users"),
        where("address", "==", addr)
      );
      const querySnapshot = await getDocs(q);
      if (querySnapshot.size > 0) {
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          const userObj = {
            name: data.name,
            address: data.address,
            img: data.img,
          };
          result.push(userObj);
        });
      }
    })
  );
  return result;
}

export async function fetchUserByWallet(address) {
  const q = query(
    collection(firebaseFirestore, "users"),
    where("address", "==", address)
  );
  const querySnapshot = await getDocs(q);
  if (querySnapshot.size > 0) {
    return querySnapshot.docs[querySnapshot.size - 1].data();
  }
  return null;
}

export async function register(user) {
  await setDoc(doc(firebaseFirestore, "users", user.uid), {
    name: user.displayName,
    email: user.email,
    img: user.photoURL,
    uid: user.uid,
    twitter:
      user.providerData[0].providerId === "twitter.com"
        ? user.providerData[0].uid
        : null,
    initialized: false,
  });
  console.log("Document written with ID: ", user.uid);
}

export async function searchUserByEmail(address) {
  // emailもしくはTwitterアカウントで検索する
  const q = query(
    collection(firebaseFirestore, "users"),
    where("email", "==", address)
  );
  const querySnapshot = await getDocs(q);
  let result = null;
  if (querySnapshot.size > 0) {
    querySnapshot.forEach((doc) => {
      console.log(doc.id, " => ", doc.data());
      result = doc.data();
    });
  }
  return result;
}

export async function searchUserByTwitter(userId) {
  const q = query(
    collection(firebaseFirestore, "users"),
    where("twitter", "==", userId)
  );
  const querySnapshot = await getDocs(q);
  let result = null;
  if (querySnapshot.size > 0) {
    querySnapshot.forEach((doc) => {
      result = doc.data();
    });
  }
  return result;
}

export async function registerToken(tokenId) {
  const auth = fireauth;
  const user = auth.currentUser;
  console.log(user.uid);
  if (user) {
    await setDoc(doc(firebaseFirestore, "users", user.uid, "tokens", tokenId), {
      id: tokenId,
    });
    return true;
  } else {
    return false;
  }
}
