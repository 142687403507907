import React from "react";
import { BaseProps, InputFile } from "../../types/common/index.ts";
import { CancelUploadFileButton } from "./CancelUploadFileButton.tsx";

export type UploadFileProps = {
  inputFile: InputFile;
  setInputFiles: React.Dispatch<React.SetStateAction<InputFile[]>>;
} & BaseProps;

export const UploadFile: React.FC<UploadFileProps> = ({
  className,
  inputFile,
  setInputFiles,
}) => {
  const { file, thumbnail } = inputFile;

  return (
    <div className={`${className} relative flex items-center justify-center`}>
      <img src={thumbnail} alt={file.name} />
      <CancelUploadFileButton
        className="absolute right-0 top-0"
        inputFileName={file.name}
        setInputFiles={setInputFiles}
      />
    </div>
  );
};
