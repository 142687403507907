import React from "react";
import { BaseProps } from "../../types/common";
import { ApproveCheckBox } from "./ApproveCheckBox.tsx";
import { Output } from "src/types/api/index.ts";

export type AllSelectCheckBoxProps = {
  outputs: Output[];
  plannedApprovalOutputs: Output[];
  setPlannedApprovalOutputs: React.Dispatch<React.SetStateAction<Output[]>>;
} & BaseProps;

export const AllSelectCheckBox: React.FC<AllSelectCheckBoxProps> = ({
  className,
  outputs,
  plannedApprovalOutputs,
  setPlannedApprovalOutputs,
}) => {
  const pendingOutputs = outputs.filter(
    (output) => output.status === "pending"
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setPlannedApprovalOutputs(pendingOutputs);
      return;
    }
    setPlannedApprovalOutputs([]);
  };

  return (
    <div
      className={`${className} flex items-center gap-x-4 text-xs text-gray-100`}
    >
      <ApproveCheckBox
        checked={
          pendingOutputs.length === plannedApprovalOutputs.length &&
          pendingOutputs.length > 0
        }
        disabled={pendingOutputs.length === 0}
        onChange={handleChange}
      />
      <p>全選択</p>
    </div>
  );
};
