/* tslint:disable */
/* eslint-disable */
/**
 * Unyte SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ApprovedOutputAuthor } from './ApprovedOutputAuthor';
import {
    ApprovedOutputAuthorFromJSON,
    ApprovedOutputAuthorFromJSONTyped,
    ApprovedOutputAuthorToJSON,
} from './ApprovedOutputAuthor';
import type { TaskAuthor } from './TaskAuthor';
import {
    TaskAuthorFromJSON,
    TaskAuthorFromJSONTyped,
    TaskAuthorToJSON,
} from './TaskAuthor';
import type { TaskOperation } from './TaskOperation';
import {
    TaskOperationFromJSON,
    TaskOperationFromJSONTyped,
    TaskOperationToJSON,
} from './TaskOperation';

/**
 * 
 * @export
 * @interface Task
 */
export interface Task {
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    taskId: string;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    description: string;
    /**
     * 
     * @type {TaskAuthor}
     * @memberof Task
     */
    author: TaskAuthor;
    /**
     * 
     * @type {number}
     * @memberof Task
     */
    startTime: number;
    /**
     * 
     * @type {number}
     * @memberof Task
     */
    closingTime: number;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    status: TaskStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    type: TaskTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    repeatId: TaskRepeatIdEnum;
    /**
     * 
     * @type {number}
     * @memberof Task
     */
    maxOutput: number;
    /**
     * 
     * @type {TaskOperation}
     * @memberof Task
     */
    operation: TaskOperation;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    hashtag?: string;
    /**
     * 
     * @type {Array<ApprovedOutputAuthor>}
     * @memberof Task
     */
    approvedOutputAuthors: Array<ApprovedOutputAuthor>;
}


/**
 * @export
 */
export const TaskStatusEnum = {
    Preparation: 'preparation',
    Active: 'active',
    Expired: 'expired',
    Cancelled: 'cancelled'
} as const;
export type TaskStatusEnum = typeof TaskStatusEnum[keyof typeof TaskStatusEnum];

/**
 * @export
 */
export const TaskTypeEnum = {
    Other: 'other',
    XTask: 'xTask'
} as const;
export type TaskTypeEnum = typeof TaskTypeEnum[keyof typeof TaskTypeEnum];

/**
 * @export
 */
export const TaskRepeatIdEnum = {
    None: 'none',
    Day: 'day',
    Infinite: 'infinite'
} as const;
export type TaskRepeatIdEnum = typeof TaskRepeatIdEnum[keyof typeof TaskRepeatIdEnum];


/**
 * Check if a given object implements the Task interface.
 */
export function instanceOfTask(value: object): boolean {
    if (!('taskId' in value)) return false;
    if (!('title' in value)) return false;
    if (!('description' in value)) return false;
    if (!('author' in value)) return false;
    if (!('startTime' in value)) return false;
    if (!('closingTime' in value)) return false;
    if (!('status' in value)) return false;
    if (!('type' in value)) return false;
    if (!('repeatId' in value)) return false;
    if (!('maxOutput' in value)) return false;
    if (!('operation' in value)) return false;
    if (!('approvedOutputAuthors' in value)) return false;
    return true;
}

export function TaskFromJSON(json: any): Task {
    return TaskFromJSONTyped(json, false);
}

export function TaskFromJSONTyped(json: any, ignoreDiscriminator: boolean): Task {
    if (json == null) {
        return json;
    }
    return {
        
        'taskId': json['taskId'],
        'title': json['title'],
        'description': json['description'],
        'author': TaskAuthorFromJSON(json['author']),
        'startTime': json['startTime'],
        'closingTime': json['closingTime'],
        'status': json['status'],
        'type': json['type'],
        'repeatId': json['repeatId'],
        'maxOutput': json['maxOutput'],
        'operation': TaskOperationFromJSON(json['operation']),
        'hashtag': json['hashtag'] == null ? undefined : json['hashtag'],
        'approvedOutputAuthors': ((json['approvedOutputAuthors'] as Array<any>).map(ApprovedOutputAuthorFromJSON)),
    };
}

export function TaskToJSON(value?: Task | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'taskId': value['taskId'],
        'title': value['title'],
        'description': value['description'],
        'author': TaskAuthorToJSON(value['author']),
        'startTime': value['startTime'],
        'closingTime': value['closingTime'],
        'status': value['status'],
        'type': value['type'],
        'repeatId': value['repeatId'],
        'maxOutput': value['maxOutput'],
        'operation': TaskOperationToJSON(value['operation']),
        'hashtag': value['hashtag'],
        'approvedOutputAuthors': ((value['approvedOutputAuthors'] as Array<any>).map(ApprovedOutputAuthorToJSON)),
    };
}

