import React from "react";
import { BaseProps } from "../../../../types/common/index.ts";
import clsx from "clsx";
import {
  getInitialTmpItem,
  tmpAddAndEditItemState,
} from "../../../../stores/tmpItemState.ts";
import { isOpenEditPageMarketState } from "../../../../stores/isOpenEditPageMarketState.ts";
import { isOpenAddPageMarketState } from "../../../../stores/isOpenAddPageMarketState.ts";
import { useSetAtom } from "jotai";

export type ItemCancelButtonProps = {} & BaseProps;

export const ItemCancelButton: React.FC<ItemCancelButtonProps> = ({
  className,
}) => {
  const setTmpItem = useSetAtom(tmpAddAndEditItemState);
  const setIsOpenEditPage = useSetAtom(isOpenEditPageMarketState);
  const setIsOpenAddPage = useSetAtom(isOpenAddPageMarketState);

  const handleClick = async () => {
    setTmpItem(getInitialTmpItem());
    setIsOpenEditPage(false);
    setIsOpenAddPage(false);
  };

  return (
    <button
      type="button"
      className={clsx(
        className,
        "w-32 rounded-full",
        "text-sm font-semibold leading-6 text-gray-100"
      )}
      onClick={handleClick}
    >
      キャンセル
    </button>
  );
};
