import { HiMiniPaperAirplane } from "react-icons/hi2";
import React, { useState } from "react";
import SendThanksModal from "src/components/thanks/SendThanksModal";
import ThanksFilter from "src/components/thanks/ThanksFilter";
import ThanksList from "src/components/thanks/ThanksList";
import ThanksHeader from "src/components/thanks/ThanksHeader";
import { useThanks } from "src/hooks/useThanks";
import { useUser } from "src/hooks/useUser";
import { useJoinedDaos } from "src/hooks/useJoinedDaos";
import unyteSquare from "../../assets/unyte_square.png";
import Loading from "src/components/common/Loading";

export const selectablePeriods = [
  "daily",
  "weekly",
  "monthly",
  "yearly",
  "wholetime",
] as const;
export type SelectablePeriod = (typeof selectablePeriods)[number];

const Thanks = ({ daoId }: { daoId: string }) => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [selectedPeriod, setSelectedPeriod] =
    useState<SelectablePeriod>("wholetime");

  const handleSearchQueryChanged = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    event.preventDefault();
    setSearchQuery(event.target.value.toLowerCase());
  };

  const [opened, setOpened] = useState<boolean>(false);
  const [filterAddr, setFilterAddr] = useState<string | undefined>(undefined);
  const { thanks, sendThanks, daoUsers, mutateThanks, isLoading } = useThanks(
    daoId,
    filterAddr || undefined,
    searchQuery,
    selectedPeriod
  );
  const { user } = useUser();
  const { dataJoinedDaos } = useJoinedDaos(user?.address);
  const joinedDaos = dataJoinedDaos?.daos ?? [];
  const avatar = joinedDaos.find((dao) => dao.daoId === daoId)?.avatar;
  const daoIcon = avatar
    ? process.env.REACT_APP_IPFS_BASE_URL + avatar
    : unyteSquare;

  return (
    <div className="h-screen min-h-[100vh-7.2rem] max-w-screen-2xl lg:h-full lg:overflow-y-auto">
      <Loading isShow={isLoading} message="Getting thanks" />
      <ThanksHeader
        amount={thanks?.length || 0}
        selectedPeriod={selectedPeriod}
      />
      <ThanksFilter
        user={user}
        filterAddr={filterAddr}
        setFilterAddr={(addr) => {
          setFilterAddr(addr);
          mutateThanks();
        }}
        daoIcon={daoIcon}
        searchQuery={searchQuery}
        handleSearchQueryChanged={handleSearchQueryChanged}
        selectedPeriod={selectedPeriod}
        setSelectedPeriod={(period) => {
          setSelectedPeriod(period);
          mutateThanks();
        }}
      />
      <div className="flex w-full p-4 pb-0 lg:flex-row">
        <ThanksList thanks={thanks} daoIcon={daoIcon} />
      </div>
      {user && (
        <SendThanksModal
          user={user}
          sendThanks={sendThanks}
          daoUsers={daoUsers}
          daoIcon={daoIcon}
          opened={opened}
          close={() => setOpened(false)}
        />
      )}
      <div className="fixed bottom-2 right-2 lg:bottom-10 lg:right-10">
        <button
          className="m-2 flex h-14 w-14 items-center justify-center gap-x-1 rounded-full bg-gradient-to-r from-[#EE7b4d] to-[#E5007E] text-gray-50 lg:h-20 lg:w-20"
          onClick={() => setOpened(true)}
        >
          <HiMiniPaperAirplane className="flex h-10 h-6 w-6 rotate-[-45deg] items-center justify-center lg:w-10" />
        </button>
      </div>
    </div>
  );
};

export default Thanks;
