import React from "react";
import UniSwaTextField from "../../../atoms/UniSwaTextField.tsx";
import "../../../styles/UniSwaTextField.css";
import { FormattedMessage } from "react-intl";
import clsx from "clsx";
import { BaseProps } from "../../../../types/common/index.ts";

export type ItemTextInputProps = {
  msgId: string;
  defaultMessage: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
} & BaseProps;

export const ItemTextInput: React.FC<ItemTextInputProps> = ({
  className,
  msgId,
  defaultMessage,
  value = "",
  onChange = () => {},
}) => {
  return (
    <div className={clsx(className)}>
      <div className={clsx("mb-2 ml-6")}>
        <FormattedMessage id={msgId} defaultMessage={defaultMessage} />
      </div>
      <UniSwaTextField value={value} isCreateDao={true} onChange={onChange} />
    </div>
  );
};
