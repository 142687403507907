import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Login,
  resetShareForResetPrivateKey,
} from "../../actions/firebase/login";
import logoX from "../../assets/logo-x.png";
import logoGoogle from "../../assets/logo-g.png";
import { FormattedMessage } from "react-intl";
import { isInitialized } from "src/actions/firebase/user";
import { register } from "src/actions/firebase/utils";

const SsoLogIn: React.FC = () => {
  const navigate = useNavigate();

  const login = async (key: string) => {
    const response = await Login(key, "", "");
    if (response && response[0] === "newUser") {
      navigate("/register", {
        /**  2023-10-27
         * どの方法でログインしたかを判定するために、stateにproviderIdを追加
         */
        state: {
          user: response[1].uid,
          image: response[1].photoURL,
          twitter:
            response[1].providerData[0].providerId === "twitter.com"
              ? response[1].providerData[0].displayName
              : "",
          share: {
            user: response[2].userShare,
            local: response[2].localStorageShare,
            unyte: response[2].unyteShare,
          },
        },
      });
    } else if (response && response[0] === true) {
      // 変数、動作名の抽象度が高いため、リファクタリングしたい気持ちはある
      if (!(await isInitialized(response[1].uid))) {
        const { userShare, localStorageShare, unyteShare } =
          await resetShareForResetPrivateKey(response[1]);
        navigate("/register", {
          state: {
            user: response[1].uid,
            email: response[1].email,
            share: {
              user: userShare,
              local: localStorageShare,
              unyte: unyteShare,
            },
            register: true,
          },
        });
      } else {
        navigate("/");
      }
    } else if (response[1] === "auth/too-many-requests") {
      alert("ログインに失敗しました。しばらくしてから再度お試しください。");
      return;
    }
  };

  return (
    <div className="flex h-screen items-center justify-center bg-gray-200">
      <div className="mx-4 w-96 rounded-lg bg-white p-8 shadow-md">
        <h1 className="mb-5 text-2xl font-bold">
          <FormattedMessage id="sso_login_title" defaultMessage="ログイン" />
        </h1>
        <button
          onClick={() => {
            login("twitter");
          }}
          className="mb-5 flex w-full items-center rounded-md border border-black bg-white p-2 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          <img src={logoX} alt="Icon" className="mr-2 h-5 w-5" />
          <span>
            <FormattedMessage
              id="sso_login_x"
              defaultMessage="X（旧Twitter）でログイン"
            />
          </span>
        </button>
        <button
          onClick={() => {
            login("google");
          }}
          className="mb-5 flex w-full items-center rounded-md border border-black bg-white p-2 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          <img src={logoGoogle} alt="Icon" className="mr-2 h-5 w-5" />
          <span>
            <FormattedMessage
              id="sso_login_google"
              defaultMessage="Googleでログイン"
            />
          </span>
        </button>
        <hr className="my-8 h-px border-0 bg-gray-200 dark:bg-gray-700"></hr>
        <button
          className="w-full rounded-md bg-white p-3 text-blue-600 hover:bg-gray-100"
          onClick={() => navigate("/login-with-email")}
        >
          <FormattedMessage
            id="sso_login_exit"
            defaultMessage="Emailでログイン"
          />
        </button>
      </div>
    </div>
  );
};

export default SsoLogIn;
