import React from "react";
import clsx from "clsx";
import { BaseProps } from "../../../../types/common/index.ts";

export type OutsideModalProps = {
  onClick?: React.MouseEventHandler<HTMLDivElement>;
} & BaseProps;

export const OutsideModal: React.FC<OutsideModalProps> = ({
  className,
  onClick,
}) => {
  return (
    <div
      className={clsx(
        className,
        "fixed inset-0",
        "bg-gray-500 bg-opacity-75 transition-opacity"
      )}
      onClick={onClick}
    />
  );
};
