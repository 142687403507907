import React from "react";
import { BaseProps } from "../../types/common";

export type SelectedOutputCountProps = {
  pendingOutputsCount: number;
  selectedOutputsCount: number;
} & BaseProps;

export const SelectedOutputCount: React.FC<SelectedOutputCountProps> = ({
  className,
  pendingOutputsCount,
  selectedOutputsCount,
}) => {
  return (
    <div className={`${className} text-xs text-gray-100`}>
      <span className="mr-1">{selectedOutputsCount}</span>
      <span className="mr-1">/</span>
      <span className="mr-1">{pendingOutputsCount}</span>
      <span className="">件選択中</span>
    </div>
  );
};
