import { useEffect, useRef, useState } from "react";
import { KeyedMutator } from "swr";

const INTERVAL_TIME = 1000;
const MAX_INTERVAL_CNT = 10;

type UseIsUpdatingArgs<T> = {
  data: T | undefined;
  mutate: KeyedMutator<T>;
};

export const useIsUpdating = <T>({ data, mutate }: UseIsUpdatingArgs<T>) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const dataRef = useRef(data);
  const prevDataRef = useRef(data);

  const checkUpdated = () => {
    dataRef.current = data;
    prevDataRef.current = data;
    setIsUpdating(true);
  };

  useEffect(() => {
    dataRef.current = data;
  }, [data]);

  useEffect(() => {
    if (!isUpdating) return;
    let intervalId: NodeJS.Timer;
    let count = 0;
    if (
      JSON.stringify(prevDataRef.current) === JSON.stringify(dataRef.current)
    ) {
      intervalId = setInterval(() => {
        count++;
        if (
          JSON.stringify(prevDataRef.current) !==
            JSON.stringify(dataRef.current) ||
          count > MAX_INTERVAL_CNT
        ) {
          clearInterval(intervalId);
          setIsUpdating(false);
          return;
        }
        mutate();
      }, INTERVAL_TIME);
    }
  }, [isUpdating]);

  return { isUpdating, checkUpdated };
};
