/* tslint:disable */
/* eslint-disable */
/**
 * Unyte SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Dao
 */
export interface Dao {
    /**
     * 
     * @type {string}
     * @memberof Dao
     */
    daoId: string;
    /**
     * 
     * @type {string}
     * @memberof Dao
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Dao
     */
    description: string;
    /**
     * 
     * @type {boolean}
     * @memberof Dao
     */
    isOpen: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof Dao
     */
    members: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Dao
     */
    links: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Dao
     */
    avatar: string;
    /**
     * 
     * @type {boolean}
     * @memberof Dao
     */
    isLlcDao: boolean;
}

/**
 * Check if a given object implements the Dao interface.
 */
export function instanceOfDao(value: object): boolean {
    if (!('daoId' in value)) return false;
    if (!('name' in value)) return false;
    if (!('description' in value)) return false;
    if (!('isOpen' in value)) return false;
    if (!('members' in value)) return false;
    if (!('links' in value)) return false;
    if (!('avatar' in value)) return false;
    if (!('isLlcDao' in value)) return false;
    return true;
}

export function DaoFromJSON(json: any): Dao {
    return DaoFromJSONTyped(json, false);
}

export function DaoFromJSONTyped(json: any, ignoreDiscriminator: boolean): Dao {
    if (json == null) {
        return json;
    }
    return {
        
        'daoId': json['daoId'],
        'name': json['name'],
        'description': json['description'],
        'isOpen': json['isOpen'],
        'members': json['members'],
        'links': json['links'],
        'avatar': json['avatar'],
        'isLlcDao': json['isLlcDao'],
    };
}

export function DaoToJSON(value?: Dao | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'daoId': value['daoId'],
        'name': value['name'],
        'description': value['description'],
        'isOpen': value['isOpen'],
        'members': value['members'],
        'links': value['links'],
        'avatar': value['avatar'],
        'isLlcDao': value['isLlcDao'],
    };
}

