import React, { useState, useEffect } from "react";
import Loading from "../../components/common/Loading.tsx";
import { useAtom } from "jotai";
import { useDaos } from "src/hooks/useDaos.ts";
import { DaoCardButton } from "src/components/dao/DaoCardButton.tsx";
import { Dao } from "src/types/api/index.ts";
import { useUser } from "src/hooks/useUser.ts";
import { DaoDetailModal } from "src/components/dao/DaoDetailModal.tsx";
import { inProgressDaoListState } from "src/stores/inProgressDaoListState.ts";
import { Toaster } from "sonner";

const sortDaosByMember = (daos: Dao[], userAddress: string) =>
  daos.toSorted((a, b) => {
    if (a.members.includes(userAddress) && !b.members.includes(userAddress))
      return -1;
    if (!a.members.includes(userAddress) && b.members.includes(userAddress))
      return 1;
    return 0;
  });

const DaoList: React.FC = () => {
  const {
    dataDaos,
    isError: isErrorDaos,
    isLoading: isLoadingDaos,
  } = useDaos();
  const { user, isError: isErrorUser, isLoading: isLoadingUser } = useUser();
  const [selectedDao, setSelectedDao] = useState<Dao | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [inProgress, setInProgress] = useAtom(inProgressDaoListState);

  useEffect(() => {
    if (isLoadingDaos || isLoadingUser) {
      setInProgress({ isShow: true, message: "Loading..." });
      return;
    }
    setInProgress({ isShow: false, message: "" });
  }, [isLoadingDaos, isLoadingUser]);

  if (isLoadingDaos || isLoadingUser) return <Loading {...inProgress} />;
  if (isErrorDaos || !dataDaos || isErrorUser || !user) return <></>;

  const { daos } = dataDaos;
  const sortedDaosByMember = sortDaosByMember(daos, user.address);

  return (
    <>
      <Loading {...inProgress} />
      {inProgress.isShow && (
        <div className="fixed inset-0 z-40 bg-gray-500 opacity-75"></div> // 画面全体を灰色にする
      )}
      <div className="m-auto flex w-full flex-1 flex-col bg-unyte-main p-2 lg:p-4 ">
        <div className="m-auto mt-3 grid w-full grid-cols-1 gap-x-2 gap-y-4 drop-shadow-lg sm:grid-cols-2 md:grid-cols-3 lg:w-8/12 lg:grid-cols-4">
          {sortedDaosByMember.map((dao) => {
            return (
              <DaoCardButton
                key={dao.daoId}
                className="m-1"
                dao={dao}
                setSelectedDao={setSelectedDao}
                setIsModalOpen={setIsModalOpen}
                userAddress={user.address}
              />
            );
          })}
        </div>
        <DaoDetailModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          selectedDao={selectedDao}
          setSelectedDao={setSelectedDao}
          userAddress={user.address}
        />
      </div>
      <Toaster />
    </>
  );
};

export default DaoList;
