/* tslint:disable */
/* eslint-disable */
/**
 * Unyte SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Dao } from './Dao';
import {
    DaoFromJSON,
    DaoFromJSONTyped,
    DaoToJSON,
} from './Dao';

/**
 * 
 * @export
 * @interface DaoDto
 */
export interface DaoDto {
    /**
     * 
     * @type {Array<Dao>}
     * @memberof DaoDto
     */
    daos: Array<Dao>;
}

/**
 * Check if a given object implements the DaoDto interface.
 */
export function instanceOfDaoDto(value: object): boolean {
    if (!('daos' in value)) return false;
    return true;
}

export function DaoDtoFromJSON(json: any): DaoDto {
    return DaoDtoFromJSONTyped(json, false);
}

export function DaoDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DaoDto {
    if (json == null) {
        return json;
    }
    return {
        
        'daos': ((json['daos'] as Array<any>).map(DaoFromJSON)),
    };
}

export function DaoDtoToJSON(value?: DaoDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'daos': ((value['daos'] as Array<any>).map(DaoToJSON)),
    };
}

