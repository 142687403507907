import React, { useEffect, useState, useRef } from "react";
import Loading from "../../components/common/Loading.tsx";
import { InputFile, Progress } from "../../types/common";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import { createOnChainTask } from "../../actions/blockchain/createTask";
import { OffChainOutputDetails } from "../../components/Task/OffChainOutputDetails.tsx";
import { useRole } from "../../actions/firebase/role.ts";
import userIcon from "../../assets/userIcon.svg";
import { updateXTask } from "../../actions/firebase/createXTask.js";
import { AllSelectCheckBox } from "../../components/Task/AllSelectCheckBox.tsx";
import { SelectedOutputCount } from "../../components/Task/SelectedOutputCount.tsx";
import { ApproveButton } from "../../components/Task/ApproveButton.tsx";
import { OnChainOutputDetails } from "../../components/Task/OnChainOutputDetails.tsx";
import { UploadFileButton } from "../../components/Task/UploadFileButton.tsx";
import { UploadFile } from "../../components/Task/UploadFile.tsx";
import { SubmitOutputButton } from "../../components/Task/SubmitOutputButton.tsx";
import { getFilesSize } from "../../utils/files.ts";
import { Output, Task } from "src/types/api/index.ts";
import { useOutputs } from "src/hooks/useOutputs.ts";
import { ProposedTaskData } from "src/types/task/index.ts";
import { useDaoDetail } from "src/hooks/useDaoDetail.ts";
import { useUser } from "src/hooks/useUser.ts";

enum ClickHandler {
  taskEdit = 4,
  taskCancel = 5,
}

const formatAddress = (address: string) => {
  try {
    const start = address.substring(0, 6);
    const end = address.substring(address.length - 4);
    return `${start}...${end}`;
  } catch (e) {
    console.error(e);
  }
};

interface Props {
  daoId: string;
  task: Task;
  callback: (taskIdToAdd: string | null) => void;
  editCallbackHandler: (IsEditProcess: boolean) => void;
  outputText: string;
  setOutputText: React.Dispatch<React.SetStateAction<string>>;
  heighlightOutputId?: string;
}

const TaskDetail: React.FC<Props> = (props: Props) => {
  const {
    daoDetail,
    isError: isDaoDetailError,
    isLoading: isDaoDetailLoading,
  } = useDaoDetail(props.daoId);
  const { outputs } = useOutputs(props.daoId, props.task.taskId);
  const role = useRole(props.daoId);
  const [inProgress, setInProgress] = useState<Progress>({
    isShow: false,
    message: "",
  });
  const [isMore, setIsMore] = useState<boolean>(false);
  const [plannedApprovalOutputs, setPlannedApprovalOutputs] = useState<
    Output[]
  >([]);
  const descriptionRef = useRef<HTMLParagraphElement>(null);
  const [inputFiles, setInputFiles] = useState<InputFile[]>([]);
  const [fileSize, setFileSize] = useState<string>("0.0");
  const { user } = useUser();

  function buttonClickHandler(selected: number): void {
    switch (selected) {
      case ClickHandler.taskEdit:
        props.editCallbackHandler(true);
        return;
      case ClickHandler.taskCancel:
        taskCancelHandler();
        return;
      default:
        return;
    }
  }

  const taskCancelHandler = async () => {
    // props.taskのstatusを"cancelled"にする
    const cancelledTask: ProposedTaskData = {
      taskId: props.task.taskId,
      daoId: props.daoId,
      author: props.task.author,
      title: props.task.title,
      operation: props.task.operation,
      repeatId: props.task.repeatId,
      maxOutput: props.task.maxOutput,
      description: props.task.description,
      startTime: props.task.startTime,
      closingTime: props.task.closingTime,
      isOnChain: props.task.type === "other",
      status: "cancelled",
    };
    if (
      props.task.type === "other" &&
      (props.task.author.address === user?.address || role === "admin")
    ) {
      const confirm = window.confirm("タスクをキャンセルしますか？");
      if (!confirm) return;
      setInProgress({
        isShow: true,
        message: "タスクをキャンセルしています。",
      });
      try {
        await createOnChainTask(cancelledTask, user);
      } catch (e) {
        console.error(e);
        setInProgress({ isShow: false, message: "" });
        if (e instanceof Error) {
          alert(
            `タスクのキャンセルに失敗した可能性があります。ご確認ください。\n${e.stack}`
          );
        } else
          alert(
            `タスクのキャンセルに失敗した可能性があります。ご確認ください。\n\n${JSON.stringify(
              e
            )}`
          );
        return;
      }
      alert("タスクをキャンセルしました。");
    } else if (props.task.type === "xTask") {
      const confirm = window.confirm("タスクをキャンセルしますか？");
      if (!confirm) return;
      setInProgress({
        isShow: true,
        message: "タスクをキャンセルしています。",
      });
      await updateXTask(props.daoId, props.task.taskId, cancelledTask);
      alert("タスクをキャンセルしました。");
    } else {
      alert("ブロックチェーンに登録されていないタスクはキャンセルできません。");
    }
    setInProgress({ isShow: false, message: "" });
    props.callback(null);
  };

  useEffect(() => {
    setIsMore(false);
    // Ref要素を更新する
  }, [props.task]);
  useEffect(() => {
    if (inputFiles.length === 0) setFileSize("0.0");
    const size = getFilesSize(inputFiles.map((inputFile) => inputFile.file));
    // ファイルサイズの表記をMBに変換する
    const displaySize = (size / 1024 / 1024).toFixed(1);
    setFileSize(displaySize);
  }, [inputFiles]);

  useEffect(() => {
    if (isDaoDetailLoading) {
      setInProgress({ isShow: true, message: "Loading..." });
      return;
    }
    setInProgress({ isShow: false, message: "" });
  }, [isDaoDetailLoading]);

  if (isDaoDetailLoading) return <Loading {...inProgress} />;
  if (isDaoDetailError || !daoDetail) return <></>;
  return (
    <div className="h-full w-full bg-[#1A1D24] px-4 pb-4 pt-2 md:bg-[#121217] md:px-8">
      {props.task && (
        <div className="w-full text-gray-50">
          <div className="hidden text-base md:flex">{props.task.title}</div>

          <div>
            <p
              ref={descriptionRef}
              className={`ml-0 mt-0 whitespace-pre-wrap text-xs md:ml-6 md:mt-2 ${
                isMore ? "" : "line-clamp-6"
              }`}
            >
              {props.task.description}
            </p>
            <div className="mt-2 flex justify-center">
              <button onClick={() => setIsMore(!isMore)}>
                {isMore ? (
                  <ChevronUpIcon className="h-5 w-5" />
                ) : (
                  <ChevronDownIcon className="h-5 w-5" />
                )}
              </button>
            </div>
            <div className="ml-0 mt-2 flex gap-x-2 text-xs md:ml-6">
              <span>Created by:</span>
              <img
                className="h-4 w-4 rounded-full bg-[#1A1D24]"
                src={props.task.author.img ? props.task.author.img : userIcon}
                onError={(e) => {
                  e.currentTarget.onerror = null;
                  e.currentTarget.src = userIcon;
                }}
                alt={props.task.author.name}
              />
              <div className="truncate text-sm">{props.task.author.name}</div>
              <div className="text-xs">
                {`(${formatAddress(props.task.author.address)})`}
              </div>
            </div>
            <div className="flex flex-col items-start justify-start">
              {props.task.status === "active" &&
                props.task.type === "other" && (
                  <div className="ml-0 mt-4 w-full space-y-2 text-xs text-gray-100 md:ml-6">
                    <p>成果物の詳細を入力してください。</p>
                    <div className="mx-auto w-full">
                      <div className="relative">
                        <textarea
                          id="vote-description"
                          name="description"
                          value={props.outputText}
                          onChange={(event) =>
                            props.setOutputText(event.target.value)
                          }
                          rows={4}
                          maxLength={1000}
                          className="custom-text-field mb-2 w-full rounded-3xl border px-2 py-4 text-gray-50"
                        />
                        <p className="absolute bottom-4 right-3 z-20 opacity-30">
                          {props.outputText.length} / 1000
                        </p>
                      </div>
                      <div className="mb-4 flex w-[100%] flex-wrap items-stretch">
                        {inputFiles.map((inputFile, i) => (
                          <UploadFile
                            key={i}
                            className="w-[calc(100%_/_3)] p-2"
                            inputFile={inputFile}
                            setInputFiles={setInputFiles}
                          />
                        ))}
                      </div>
                      <div className="flex items-center justify-between">
                        <UploadFileButton
                          inputFiles={inputFiles}
                          setInputFiles={setInputFiles}
                        />
                        <SubmitOutputButton
                          daoId={props.daoId}
                          taskId={props.task.taskId}
                          outputText={props.outputText}
                          setInProgress={setInProgress}
                          setOutputText={props.setOutputText}
                          inputFiles={inputFiles}
                          setInputFiles={setInputFiles}
                          adminAddresses={daoDetail.admins}
                        />
                      </div>
                      <p className="mt-2 text-gray-500">
                        {fileSize}MB / 4.0 MB
                      </p>
                    </div>
                  </div>
                )}
              {props.task.status === "active" &&
                props.task.type === "xTask" && (
                  <div className="ml-0 mt-4 w-full space-y-2 text-xs text-gray-100 md:ml-6">
                    {props.task.operation.operationId === "1" && (
                      //button風なui
                      <div className="mt-2 flex items-center justify-center">
                        <a
                          className="w-full truncate rounded-full bg-task-button px-4 py-2 text-center lg:w-fit"
                          href={`https://x.com/intent/tweet?hashtags=${props.task.operation.value}`}
                        >
                          <span className="font-bold">
                            {" "}
                            #{props.task.operation.value}
                          </span>{" "}
                          <span>でポスト</span>
                        </a>
                      </div>
                    )}
                    {props.task.operation.operationId === "2" && (
                      <div className="mt-2 flex items-center justify-center">
                        <a
                          className="w-full rounded-full bg-task-button px-4 py-2 text-center lg:w-fit"
                          href={`https://twitter.com/intent/retweet?tweet_id=${
                            props.task.operation.value
                              .split("/")
                              [
                                props.task.operation.value.split("/").length - 1
                              ].split("?")[0]
                          }`}
                        >
                          <span className="font-bold">リポストする</span>
                        </a>
                      </div>
                    )}
                  </div>
                )}
              <hr className="my-8 w-full bg-gray-500 opacity-30" />
              <div className="mb-4 text-xs text-gray-100">成果物一覧</div>
              {outputs &&
                outputs.length > 0 &&
                props.task.type === "other" &&
                role === "admin" && (
                  <div className="mb-4 flex w-full items-center justify-between">
                    <div className="flex items-center gap-x-6">
                      <AllSelectCheckBox
                        outputs={outputs}
                        plannedApprovalOutputs={plannedApprovalOutputs}
                        setPlannedApprovalOutputs={setPlannedApprovalOutputs}
                      />
                      <SelectedOutputCount
                        pendingOutputsCount={
                          outputs.filter(
                            (output) => output.status === "pending"
                          ).length
                        }
                        selectedOutputsCount={plannedApprovalOutputs.length}
                      />
                    </div>
                    <ApproveButton
                      plannedApprovalOutputs={plannedApprovalOutputs}
                      setInProgress={setInProgress}
                      daoId={props.daoId}
                      task={props.task}
                      setPlannedApprovalOutputs={setPlannedApprovalOutputs}
                    />
                  </div>
                )}
              {props.task.type === "xTask" ? (
                <OffChainOutputDetails
                  outputs={outputs ?? []}
                  task={props.task}
                />
              ) : (
                <OnChainOutputDetails
                  className="max-h-[500px] w-full overflow-y-auto"
                  outputs={outputs ?? []}
                  plannedApprovalOutputs={plannedApprovalOutputs}
                  setPlannedApprovalOutputs={setPlannedApprovalOutputs}
                  role={role}
                  heightlighOutputId={props.heighlightOutputId}
                />
              )}
            </div>
          </div>

          {props.task.status === "preparation" && (
            <div className="mt-8 grid-cols-3 sm:mt-16 sm:grid sm:grid-flow-row-dense sm:gap-3">
              {props.task.author.address === user?.address && (
                <button
                  type="button"
                  className="flex w-full items-center justify-center rounded-full bg-gradient-to-r from-[#5E00FF] to-[#00A0FF] px-3 py-2 text-sm font-semibold text-gray-50 md:w-fit"
                  onClick={() => buttonClickHandler(ClickHandler.taskEdit)}
                >
                  <div className="flex items-center gap-x-1">
                    <span>編集する</span>
                  </div>
                </button>
              )}

              {(props.task.author.address === user?.address ||
                role === "admin") && (
                <button
                  type="button"
                  className="mt-3 inline-flex w-full justify-center rounded-full bg-gray-700 px-3 py-2 text-sm font-semibold text-gray-100 shadow-sm disabled:text-gray-500 sm:col-start-2 sm:mt-0 md:w-fit"
                  onClick={() => buttonClickHandler(ClickHandler.taskCancel)}
                >
                  <div className="flex items-center gap-x-1">
                    <span>削除する</span>
                  </div>
                </button>
              )}
            </div>
          )}
        </div>
      )}
      <Loading {...inProgress} />
    </div>
  );
};

export default TaskDetail;
