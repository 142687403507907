import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import "../styles/LoadingCheckBox.css";

interface Props {
  setStep: React.Dispatch<React.SetStateAction<number>>;
}

const ShareDescription: React.FC<Props> = ({ setStep }) => {
  const [checkboxes, setCheckboxes] = useState([false, false, false]);

  const handleCheckboxChange = (index: number) => {
    const newCheckboxes = [...checkboxes];
    newCheckboxes[index] = !newCheckboxes[index];
    setCheckboxes(newCheckboxes);
  };

  const allChecked = checkboxes.every(Boolean);
  return (
    <div className="mt-6 flex w-full items-center justify-center bg-unyte-main">
      <div className="mx-auto w-11/12 rounded-lg bg-white p-4 shadow-md lg:w-5/12 lg:p-8">
        <div className="mx-auto w-full">
          <h1 className="mb-4 text-center text-2xl font-bold lg:mb-5">
            <FormattedMessage
              id="share_description_title"
              defaultMessage="シェアを作成する"
            />
          </h1>
          <div className="mx-auto w-full lg:w-4/5">
            <p className="mb-4 text-center text-xs text-gray-500 lg:mb-8">
              <FormattedMessage
                id="share_description_text"
                defaultMessage="別端末でログインされる際や、機種変更などでアカウントを復旧する際に必要になるシェアをこれから表示いたします。以下の取り扱いに関する注意事項をお読みいただき、保存の準備をしてください。"
              />
            </p>
          </div>
          <div className="mx-auto mb-8 space-y-4 lg:w-4/5">
            {/**ここからまとめることできる */}
            {[1, 2, 3].map((num, index) => (
              <>
                <label
                  htmlFor={`checkbox_${num}`}
                  key={num}
                  className="flex h-1/3 flex-row items-center rounded-md bg-gray-100 p-2 hover:cursor-pointer lg:p-4"
                >
                  <div className="mr-2 w-1/12">
                    <label className="register-checkbox hover:cursor-default">
                      <input
                        type="checkbox"
                        id={`checkbox_${num}`}
                        checked={checkboxes[index]}
                        onChange={() => handleCheckboxChange(index)}
                      />
                      <span className="mr-3 mt-1" />
                    </label>
                  </div>
                  <div className="w-11/12 text-sm">
                    <FormattedMessage
                      id={`share_description_block_${num}`}
                      defaultMessage={
                        num === 1
                          ? "シェアの登録が行われていない環境では、サービスのご利用ができません。"
                          : num === 2
                            ? "シェアは必ず保存して、紛失しないようお手元で保管ください。"
                            : num === 3
                              ? "シェアを紛失された場合に、再発行や再表示はできません。"
                              : ""
                      }
                    />
                  </div>
                </label>
                {/**ここまで */}
              </>
            ))}
          </div>
          <div className="w-full">
            <button
              className={`mx-auto block w-full rounded-lg p-3 text-gray-100  lg:w-4/5 ${
                allChecked
                  ? "bg-unyte hover:bg-unyte-hover active:bg-unyte-active"
                  : "cursor-not-allowed bg-gray-400"
              }`}
              onClick={() => allChecked && setStep(2)}
              disabled={!allChecked}
            >
              <FormattedMessage
                id="share_description_next_button"
                defaultMessage="はい、理解しました"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShareDescription;
