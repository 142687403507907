/* tslint:disable */
/* eslint-disable */
/**
 * Unyte SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OutputsForAllTask } from './OutputsForAllTask';
import {
    OutputsForAllTaskFromJSON,
    OutputsForAllTaskFromJSONTyped,
    OutputsForAllTaskToJSON,
} from './OutputsForAllTask';

/**
 * 
 * @export
 * @interface OutputsForAllTasksDto
 */
export interface OutputsForAllTasksDto {
    /**
     * 
     * @type {Array<OutputsForAllTask>}
     * @memberof OutputsForAllTasksDto
     */
    outputs: Array<OutputsForAllTask>;
}

/**
 * Check if a given object implements the OutputsForAllTasksDto interface.
 */
export function instanceOfOutputsForAllTasksDto(value: object): boolean {
    if (!('outputs' in value)) return false;
    return true;
}

export function OutputsForAllTasksDtoFromJSON(json: any): OutputsForAllTasksDto {
    return OutputsForAllTasksDtoFromJSONTyped(json, false);
}

export function OutputsForAllTasksDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OutputsForAllTasksDto {
    if (json == null) {
        return json;
    }
    return {
        
        'outputs': ((json['outputs'] as Array<any>).map(OutputsForAllTaskFromJSON)),
    };
}

export function OutputsForAllTasksDtoToJSON(value?: OutputsForAllTasksDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'outputs': ((value['outputs'] as Array<any>).map(OutputsForAllTaskToJSON)),
    };
}

