/* tslint:disable */
/* eslint-disable */
/**
 * Unyte SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EditDAOArgs } from './EditDAOArgs';
import {
    EditDAOArgsFromJSON,
    EditDAOArgsFromJSONTyped,
    EditDAOArgsToJSON,
} from './EditDAOArgs';

/**
 * 
 * @export
 * @interface EditDAORequest
 */
export interface EditDAORequest {
    /**
     * Lambdaの関数でEditDAOを指定
     * @type {string}
     * @memberof EditDAORequest
     */
    type: EditDAORequestTypeEnum;
    /**
     * EditDAOの引数
     * @type {EditDAOArgs}
     * @memberof EditDAORequest
     */
    args: EditDAOArgs;
}


/**
 * @export
 */
export const EditDAORequestTypeEnum = {
    CreateDao: 'createDAO',
    CreateNft: 'createNFT',
    EditDao: 'editDAO',
    AddItem: 'addItem',
    EditItem: 'editItem',
    Exchange: 'exchange',
    Proposal: 'proposal',
    Vote: 'vote',
    ApproveOutput: 'approveOutput',
    RejectOutput: 'rejectOutput',
    CreateOutput: 'createOutput',
    CreateTask: 'createTask'
} as const;
export type EditDAORequestTypeEnum = typeof EditDAORequestTypeEnum[keyof typeof EditDAORequestTypeEnum];


/**
 * Check if a given object implements the EditDAORequest interface.
 */
export function instanceOfEditDAORequest(value: object): boolean {
    if (!('type' in value)) return false;
    if (!('args' in value)) return false;
    return true;
}

export function EditDAORequestFromJSON(json: any): EditDAORequest {
    return EditDAORequestFromJSONTyped(json, false);
}

export function EditDAORequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): EditDAORequest {
    if (json == null) {
        return json;
    }
    return {
        
        'type': json['type'],
        'args': EditDAOArgsFromJSON(json['args']),
    };
}

export function EditDAORequestToJSON(value?: EditDAORequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'type': value['type'],
        'args': EditDAOArgsToJSON(value['args']),
    };
}

