import { atom } from "jotai";
import { Progress } from "../types/common";

export type InProgressDaoListState = Progress;

/**
 * DAO一覧処理の状態
 */
export const inProgressDaoListState = atom<InProgressDaoListState>({
  isShow: false,
  message: "",
});
