import { StringFormat } from "firebase/storage";

export const formatAddress = (address: string) => {
  try {
    const start = address.substring(0, 6);
    const end = address.substring(address.length - 4);
    return `${start}...${end}`;
  } catch (e) {
    console.error((e as Error).message);
  }
};

export const formatName = (name: string, strNum: number) => {
  try {
    if (name.length <= strNum) {
      return name;
    }
    const start = name.substring(0, strNum);
    return `${start}...`;
  } catch (e) {
    console.error((e as Error).message);
  }
};
