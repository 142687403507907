/* tslint:disable */
/* eslint-disable */
/**
 * Unyte SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Item } from './Item';
import {
    ItemFromJSON,
    ItemFromJSONTyped,
    ItemToJSON,
} from './Item';

/**
 * 
 * @export
 * @interface ItemsDto
 */
export interface ItemsDto {
    /**
     * 
     * @type {Array<Item>}
     * @memberof ItemsDto
     */
    items: Array<Item>;
}

/**
 * Check if a given object implements the ItemsDto interface.
 */
export function instanceOfItemsDto(value: object): boolean {
    if (!('items' in value)) return false;
    return true;
}

export function ItemsDtoFromJSON(json: any): ItemsDto {
    return ItemsDtoFromJSONTyped(json, false);
}

export function ItemsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ItemsDto {
    if (json == null) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(ItemFromJSON)),
    };
}

export function ItemsDtoToJSON(value?: ItemsDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'items': ((value['items'] as Array<any>).map(ItemToJSON)),
    };
}

