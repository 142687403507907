import { restApi } from "src/libs/restClient";
import useSWR from "swr";

const fetcher = async (userAddress: string | undefined) => {
  if (!userAddress) return;
  return restApi.joinedDaosControllerFetchJoinedDaos({ userAddress });
};

export const useJoinedDaos = (userAddress: string | undefined) => {
  const key = userAddress ? `/joined-daos/${userAddress}` : null;
  const { data, error, isLoading } = useSWR(
    [key, userAddress],
    ([_, userAddress]) => fetcher(userAddress)
  );
  return { dataJoinedDaos: data, isError: error, isLoading };
};
