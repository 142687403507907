/* tslint:disable */
/* eslint-disable */
/**
 * Unyte SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReactMessageRequest
 */
export interface ReactMessageRequest {
    /**
     * 
     * @type {string}
     * @memberof ReactMessageRequest
     */
    reactionType: ReactMessageRequestReactionTypeEnum;
    /**
     * User ID token
     * @type {string}
     * @memberof ReactMessageRequest
     */
    uidToken: string;
    /**
     * DAO ID
     * @type {string}
     * @memberof ReactMessageRequest
     */
    daoId: string;
    /**
     * Channel ID
     * @type {string}
     * @memberof ReactMessageRequest
     */
    channelId: string;
    /**
     * Message ID
     * @type {string}
     * @memberof ReactMessageRequest
     */
    messageId: string;
}


/**
 * @export
 */
export const ReactMessageRequestReactionTypeEnum = {
    Good: '👍',
    Bad: '👎',
    Happy: '😄',
    Party: '🎉',
    Sad: '😕',
    Heart: '❤️',
    Rocket: '🚀',
    Eyes: '👀'
} as const;
export type ReactMessageRequestReactionTypeEnum = typeof ReactMessageRequestReactionTypeEnum[keyof typeof ReactMessageRequestReactionTypeEnum];


/**
 * Check if a given object implements the ReactMessageRequest interface.
 */
export function instanceOfReactMessageRequest(value: object): boolean {
    if (!('reactionType' in value)) return false;
    if (!('uidToken' in value)) return false;
    if (!('daoId' in value)) return false;
    if (!('channelId' in value)) return false;
    if (!('messageId' in value)) return false;
    return true;
}

export function ReactMessageRequestFromJSON(json: any): ReactMessageRequest {
    return ReactMessageRequestFromJSONTyped(json, false);
}

export function ReactMessageRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReactMessageRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'reactionType': json['reactionType'],
        'uidToken': json['uidToken'],
        'daoId': json['daoId'],
        'channelId': json['channelId'],
        'messageId': json['messageId'],
    };
}

export function ReactMessageRequestToJSON(value?: ReactMessageRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'reactionType': value['reactionType'],
        'uidToken': value['uidToken'],
        'daoId': value['daoId'],
        'channelId': value['channelId'],
        'messageId': value['messageId'],
    };
}

