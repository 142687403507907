import React, { useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { BiCopy } from "react-icons/bi";
import { sendEmail } from "src/actions/email/sendEmail";
import e from "express";

interface Props {
  share: number[];
  encodedShare: string;
  setStep: React.Dispatch<React.SetStateAction<number>>;
}

const SaveShare: React.FC<Props> = ({ share, encodedShare, setStep }) => {
  const textareaRef = useRef(null);
  const [email, setEmail] = useState("");
  const [isInvalidEmail, setIsInvalidEmail] = useState(false);

  const handleCopy = async () => {
    const textarea = textareaRef.current;
    textarea.value = encodedShare;
    textarea.select();
    try {
      await navigator.clipboard.writeText(encodedShare);
      alert("クリップボードにコピーしました");
    } catch (err) {
      alert("コピーに失敗しました");
    }
  };

  const sendShare = () => {
    alert("実装中");
  };

  return (
    <div className="mt-8 flex w-full items-center justify-center bg-unyte-main">
      <div className="mx-auto w-11/12 rounded-lg bg-white p-4 shadow-md lg:w-1/3 lg:p-8">
        <div className="mx-auto w-full">
          <h1 className="mb-5 text-center text-2xl font-bold">
            <FormattedMessage
              id="register_title"
              defaultMessage="シェアの保存"
            />
          </h1>
          <div className="mx-auto lg:w-4/5">
            <p className="mb-8 text-center text-sm text-gray-500">
              <FormattedMessage
                id="share_save_text"
                defaultMessage="UnyteShare_から始まる文字列が表示されます。このシェアは再発行できませんので、必ず控えてください。"
              />
            </p>
          </div>
          {share.length > 0 && (
            <div className="mb-4">
              <textarea
                rows={share.length / 2}
                value={encodedShare}
                readOnly
                className="mb-2 w-full resize-none rounded-md border border-gray-300 p-3 shadow-sm"
              ></textarea>
              <button
                className="mx-auto flex items-center justify-center text-sm text-gray-500"
                onClick={handleCopy}
              >
                <BiCopy />
                <FormattedMessage
                  id="register_button_1"
                  defaultMessage="コピー"
                />
              </button>
              {/* 
            @Comment 今回はシェアをメールで送信する機能は実装しない
            @Author: ikisuke
            <button
              className="w-full p-3 bg-blue-600 text-white rounded-md hover:bg-blue-700"
              onClick={() => {
                sendShare();
              }}>
              <FormattedMessage
                id="register_button_2"
                defaultMessage="シェアをメールで送信"
              />
            </button> */}
            </div>
          )}
          <hr className="mb-8 mt-4 h-px border-0 bg-gray-200 dark:bg-gray-300 lg:mb-8 lg:mt-8"></hr>
          <div className="w-full">
            <div className="relative">
              {isInvalidEmail && (
                <p className="absolute -top-4 left-0 text-xs text-red-500">
                  無効なメールアドレスです
                </p>
              )}
              <div className="mx-auto w-full">
                <input
                  type="email"
                  placeholder="Email"
                  className={`mb-4 w-full rounded-md border ${
                    isInvalidEmail ? "border-red-500" : "border-gray-300"
                  } p-3 shadow-sm`}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>
            <button
              className="mx-auto mb-4 block w-full w-full rounded-lg bg-task-button p-3 text-gray-100 transition-colors duration-300 hover:bg-task-button-hover active:bg-task-button-active"
              onClick={() => {
                setIsInvalidEmail(false);
                sendEmail(email, encodedShare)
                  .then(() => alert("メールを送信しました"))
                  .catch((e) => {
                    if (e.message === "無効なメールアドレスです。") {
                      setIsInvalidEmail(true);
                      return;
                    } else {
                      alert(
                        "メールの送信に失敗しました。しばらくしてから再度お試しください。"
                      );
                      return;
                    }
                  });
              }}
            >
              <FormattedMessage
                id="backup_send_email"
                defaultMessage="バックアップシェアをメールで送信する"
              />
            </button>
            <button
              className="mx-auto mb-4 block w-full rounded-lg bg-unyte p-3 text-gray-100 transition-colors duration-300 hover:bg-unyte-hover active:bg-unyte-active"
              onClick={() => setStep(3)}
            >
              <FormattedMessage
                id="register_next"
                defaultMessage="保存したので次へ"
              />
            </button>
          </div>
        </div>
        <textarea
          ref={textareaRef}
          style={{ position: "absolute", left: "-9999px" }}
        ></textarea>
      </div>
    </div>
  );
};

export default SaveShare;
