/* tslint:disable */
/* eslint-disable */
/**
 * Unyte SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JoinedDao
 */
export interface JoinedDao {
    /**
     * 
     * @type {string}
     * @memberof JoinedDao
     */
    daoId: string;
    /**
     * 
     * @type {string}
     * @memberof JoinedDao
     */
    avatar: string;
}

/**
 * Check if a given object implements the JoinedDao interface.
 */
export function instanceOfJoinedDao(value: object): boolean {
    if (!('daoId' in value)) return false;
    if (!('avatar' in value)) return false;
    return true;
}

export function JoinedDaoFromJSON(json: any): JoinedDao {
    return JoinedDaoFromJSONTyped(json, false);
}

export function JoinedDaoFromJSONTyped(json: any, ignoreDiscriminator: boolean): JoinedDao {
    if (json == null) {
        return json;
    }
    return {
        
        'daoId': json['daoId'],
        'avatar': json['avatar'],
    };
}

export function JoinedDaoToJSON(value?: JoinedDao | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'daoId': value['daoId'],
        'avatar': value['avatar'],
    };
}

