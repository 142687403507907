import {
  DocumentData,
  QueryDocumentSnapshot,
  SnapshotOptions,
  Timestamp,
  addDoc,
  collection,
  getDocs,
  query,
  serverTimestamp,
  where,
} from "firebase/firestore";
import {
  LocalStorageNotificationItem,
  LocalStorageNotifications,
  MessageEventData,
  NotificationEvent,
} from "../../types/notification";
import { firebaseFirestore } from "./config";
import { useEffect, useMemo, useRef, useState } from "react";
import { useLocalStorage } from "../../utils/useLocalStorage.tsx";
import { getAuth } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import unyteSquare from "../../assets/unyte_square.png";
import { User } from "../../types/common/index.ts";
import { fetchUsers } from "./utils.js";
import { useJoinedDaos } from "src/hooks/useJoinedDaos.ts";
import { useAuthorize } from "src/hooks/useAuthorize.ts";
import { useUser } from "src/hooks/useUser.ts";
import { UserDto } from "src/types/api/index.ts";

function useUsersMap(notifications: LocalStorageNotificationItem[]) {
  const userAddresses = useRef(new Set<string>());
  const [usersMap, setUsersMap] = useState<Map<string, User>>(new Map());

  useEffect(() => {
    const newUserAdderesses: string[] = [];
    notifications.forEach((notification) => {
      if (notification.from) {
        // `from`は後から追加したので、undefinedの可能性がある
        if (!userAddresses.current.has(notification.from)) {
          userAddresses.current.add(notification.from);
          newUserAdderesses.push(notification.from);
        }
      }
    });
    const newUsersMap = new Map(usersMap);
    fetchUsers(newUserAdderesses).then((users) => {
      users?.forEach((user) => {
        newUsersMap.set(user.address, user);
      });
      setUsersMap(newUsersMap);
    });
  }, [notifications]);

  return usersMap;
}

export function useNotifications() {
  const [session] = useAuthState(getAuth());
  const [userAddress, setUserAddress] = useState("");
  const { user } = useUser();
  useEffect(() => {
    if (session) {
      const init = async () => {
        if (!user) return;
        setUserAddress(user.address);
      };
      init();
    }
  }, [session, user]);
  const key = `notifications-${userAddress}`;

  const [showUnreadOnly, setShowUnreadOnly] = useState(false);

  const getOneMonthAgoDate = () => {
    const now = new Date();
    now.setMonth(now.getMonth() - 1);
    return now;
  };
  const { dataJoinedDaos } = useJoinedDaos(userAddress);
  const { dataAuthorize } = useAuthorize(
    dataJoinedDaos?.daos.map((joinedDao) => joinedDao.daoId)
  );
  const joinedDaos = dataJoinedDaos?.daos ?? [];
  const [localStorageNotifications, setLocalStorageNotifications] =
    useLocalStorage<LocalStorageNotifications>(key, {
      notifications: [],
      prevFetchTimestamp: getOneMonthAgoDate().getTime(),
    });
  const usersMap = useUsersMap(localStorageNotifications.notifications);
  const notifications = useMemo(() => {
    return localStorageNotifications.notifications.map((notification) => {
      const title = (() => {
        const fromUserName = usersMap.get(notification.from)?.name ?? "";
        switch (notification.eventType) {
          case "message": {
            return `${fromUserName}があなたをメンションしました`;
          }
          case "vote": {
            return `${fromUserName}から提案が追加されました`;
          }
          case "addTask": {
            return `${fromUserName}からタスクが追加されました`;
          }
          case "approveTask": {
            return `${fromUserName}がタスクの成果物を承認しました`;
          }
          case "rejectTask": {
            return `${fromUserName}がタスクの成果物を棄却しました`;
          }
          case "submitOutput": {
            return `${fromUserName}がタスクの成果物を提出しました`;
          }
          case "thanks": {
            return `${fromUserName}があなたに感謝しました`;
          }
          default: {
            const unreachable: never = notification.eventType;
            console.error(
              "Unimplemented notification event type: ",
              unreachable
            );
            return "";
          }
        }
      })();
      const iconSrc = (() => {
        switch (notification.eventType) {
          case "message":
          case "thanks": {
            return usersMap.get(notification.from)?.img ?? unyteSquare;
          }
          case "vote":
          case "addTask":
          case "approveTask":
          case "rejectTask":
          case "submitOutput": {
            const avatar = joinedDaos.find(
              (dao) => dao.daoId === notification.daoId
            )?.avatar;
            return avatar
              ? process.env.REACT_APP_IPFS_BASE_URL + avatar
              : unyteSquare;
          }
          default: {
            const unreachable: never = notification.eventType;
            console.error(
              "Unimplemented notification event type: ",
              unreachable
            );
            return unyteSquare;
          }
        }
      })();
      return {
        ...notification,
        title,
        iconSrc,
      };
    });
  }, [localStorageNotifications, usersMap, joinedDaos]);
  const unreadNotifications = useMemo(
    () => notifications.filter((notification) => notification.isNew),
    [notifications]
  );
  const notificationCountPerChannel = useMemo(() => {
    const notificationCountPerChannel = new Map<string, number>();
    unreadNotifications
      .filter((notification) => notification.eventType === "message")
      .forEach((notification) => {
        const key = String([
          notification.daoId,
          (notification.data as MessageEventData).channelId,
        ]);
        const count = notificationCountPerChannel.get(key) ?? 0;
        notificationCountPerChannel.set(key, count + 1);
      });
    return notificationCountPerChannel;
  }, [unreadNotifications]);

  useEffect(() => {
    if (joinedDaos.length === 0) return;
    if (!userAddress) return;
    if (!dataAuthorize || !dataAuthorize.value) return;
    fetch();
    const id = setInterval(fetch, 1000 * 60 * 5);
    return () => clearInterval(id);
  }, [
    joinedDaos,
    userAddress,
    localStorageNotifications.prevFetchTimestamp,
    dataAuthorize,
  ]);

  function fetch() {
    Promise.all(
      joinedDaos.map(async (joinedDao) => {
        const q = query(
          createNotificationCollection(joinedDao.daoId),
          where(
            "timestamp",
            ">",
            Timestamp.fromMillis(localStorageNotifications.prevFetchTimestamp)
          ),
          where("to", "array-contains", userAddress)
        );
        const snapshot = await getDocs(q);
        const daoNotifications: NotificationEvent[] = snapshot.docs.map((doc) =>
          doc.data()
        );
        return daoNotifications;
      })
    )
      .then((notifications) => {
        const newNotifications = notifications.flat().sort((a, b) => {
          return b.timestamp - a.timestamp;
        });
        if (newNotifications.length === 0) return;

        setLocalStorageNotifications(key, (prev) => {
          if (
            newNotifications[newNotifications.length - 1].timestamp <=
            prev.prevFetchTimestamp
          )
            return prev;
          return {
            notifications: [
              ...newNotifications.map((notification) => ({
                ...notification,
                isNew: true,
              })),
              ...prev.notifications,
            ],
            prevFetchTimestamp: newNotifications[0].timestamp,
          };
        });
      })
      .catch((e) => {
        console.error(e);
      });
  }

  async function readAllNotifications(user: UserDto | undefined) {
    if (!user) return;
    setLocalStorageNotifications(`notifications-${user.address}`, (prev) => ({
      notifications: prev.notifications.map((notification) => ({
        ...notification,
        isNew: false,
      })),
      prevFetchTimestamp: prev.prevFetchTimestamp,
    }));
  }

  async function readNotification(
    notification: LocalStorageNotificationItem,
    user: UserDto | undefined
  ) {
    if (!user) return;
    setLocalStorageNotifications(`notifications-${user.address}`, (prev) => {
      const index = prev.notifications.findIndex(
        (n) =>
          n.daoId === notification.daoId &&
          n.timestamp === notification.timestamp &&
          n.message === notification.message
      );
      const updatedNotifications = prev.notifications;
      updatedNotifications[index].isNew = false;
      return {
        notifications: updatedNotifications,
        prevFetchTimestamp: prev.prevFetchTimestamp,
      };
    });
  }

  async function readChannel(
    daoId: string,
    channelId: string,
    user: UserDto | undefined
  ) {
    if (!user) return;
    setLocalStorageNotifications(`notifications-${user.address}`, (prev) => {
      const updatedNotifications = prev.notifications.map((notification) => {
        if (
          notification.daoId === daoId &&
          notification.eventType === "message" &&
          (notification.data as MessageEventData).channelId === channelId
        ) {
          return {
            ...notification,
            isNew: false,
          };
        }
        return notification;
      });
      return {
        notifications: updatedNotifications,
        prevFetchTimestamp: prev.prevFetchTimestamp,
      };
    });
  }

  if (navigator.setAppBadge) {
    navigator.setAppBadge(unreadNotifications.length);
  }
  return {
    notifications: showUnreadOnly ? unreadNotifications : notifications,
    unreadNotificationsCount: unreadNotifications.length,
    notificationCountPerChannel,
    readAllNotifications,
    readNotification,
    readChannel,
    showUnreadOnly,
    setShowUnreadOnly,
  };
}

function createNotificationCollection(daoId: string) {
  return collection(
    firebaseFirestore,
    "workspaces",
    daoId,
    "notifications"
  ).withConverter(notificationConverter);
}

const notificationConverter = {
  toFirestore(notification: NotificationEvent): DocumentData {
    return {
      ...notification,
      timestamp: serverTimestamp(),
    };
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): NotificationEvent {
    const data = snapshot.data(options);
    return {
      daoId: data.daoId,
      timestamp: (data.timestamp as Timestamp).toMillis(),
      eventType: data.eventType,
      message: data.message,
      from: data.from,
      to: data.to,
      data: data.data,
    };
  },
};

export function createEventNotification(notification: NotificationEvent) {
  // firebaseのworkspaceのsubcollectionに追加する
  // その後、通知を送る
  const notificationCollection = createNotificationCollection(
    notification.daoId
  );
  // collectionにdocumentを追加する
  console.log("通知イベントを作成したよ！:", notification);
  // documentにデータを追加する
  addDoc(notificationCollection, notification);
  return notification;
}
