import React from "react";
import { BaseProps } from "../../types/common/index.ts";
import { useNavigate } from "react-router-dom";

export type NavDaoDetailButtonProps = { daoId: string } & BaseProps;

export const NavDaoDetailButton: React.FC<NavDaoDetailButtonProps> = ({
  className,
  daoId,
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/${daoId}`);
  };

  return (
    <button
      className={`${className} rounded-full bg-unyte py-2 text-center `}
      onClick={handleClick}
    >
      DASH BOARDへ
    </button>
  );
};
