/* tslint:disable */
/* eslint-disable */
/**
 * Unyte SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CheckContractStatusDto
 */
export interface CheckContractStatusDto {
    /**
     * 
     * @type {string}
     * @memberof CheckContractStatusDto
     */
    status: CheckContractStatusDtoStatusEnum;
}


/**
 * @export
 */
export const CheckContractStatusDtoStatusEnum = {
    Unyte: 'UNYTE',
    Other: 'OTHER'
} as const;
export type CheckContractStatusDtoStatusEnum = typeof CheckContractStatusDtoStatusEnum[keyof typeof CheckContractStatusDtoStatusEnum];


/**
 * Check if a given object implements the CheckContractStatusDto interface.
 */
export function instanceOfCheckContractStatusDto(value: object): boolean {
    if (!('status' in value)) return false;
    return true;
}

export function CheckContractStatusDtoFromJSON(json: any): CheckContractStatusDto {
    return CheckContractStatusDtoFromJSONTyped(json, false);
}

export function CheckContractStatusDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CheckContractStatusDto {
    if (json == null) {
        return json;
    }
    return {
        
        'status': json['status'],
    };
}

export function CheckContractStatusDtoToJSON(value?: CheckContractStatusDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'status': value['status'],
    };
}

