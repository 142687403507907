/* tslint:disable */
/* eslint-disable */
/**
 * Unyte SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KeyPair
 */
export interface KeyPair {
    /**
     * Private key of the user
     * @type {string}
     * @memberof KeyPair
     */
    privateKey?: string;
    /**
     * Public key of the user
     * @type {string}
     * @memberof KeyPair
     */
    publicKey: string;
}

/**
 * Check if a given object implements the KeyPair interface.
 */
export function instanceOfKeyPair(value: object): boolean {
    if (!('publicKey' in value)) return false;
    return true;
}

export function KeyPairFromJSON(json: any): KeyPair {
    return KeyPairFromJSONTyped(json, false);
}

export function KeyPairFromJSONTyped(json: any, ignoreDiscriminator: boolean): KeyPair {
    if (json == null) {
        return json;
    }
    return {
        
        'privateKey': json['privateKey'] == null ? undefined : json['privateKey'],
        'publicKey': json['publicKey'],
    };
}

export function KeyPairToJSON(value?: KeyPair | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'privateKey': value['privateKey'],
        'publicKey': value['publicKey'],
    };
}

