import { atom } from "jotai";
import { Item, ItemStatusEnum } from "src/types/api";

export type AddItem = Item & {
  base64: string;
};

export const getInitialTmpItem = (): AddItem => {
  return {
    tokenId: "",
    amount: 0,
    creator: "",
    base64: "",
    name: "",
    description: "",
    symbol: "",
    image: "",
    status: ItemStatusEnum.OnSale,
    price: 0,
  };
};

/**
 * 追加 or 編集アイテムの状態
 */
export const tmpItemState = atom<Item>(getInitialTmpItem());

export const tmpAddAndEditItemState = atom<AddItem>(getInitialTmpItem());
