import React from "react";
import { BaseProps } from "../../types/common/index.ts";
import { BiLinkExternal } from "react-icons/bi";

export type TxLinkProps = { url: string } & BaseProps;

export const TxLink: React.FC<TxLinkProps> = ({ className, url }) => {
  return (
    <a
      className={`${className}`}
      href={url}
      target="_blank"
      rel="noopener noreferrer"
    >
      <BiLinkExternal />
    </a>
  );
};
