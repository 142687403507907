import { useIsUpdating } from "src/hooks/useIsUpdating";
import { restApi } from "src/libs/restClient";
import { TaskControllerFetchTasksTaskStatusEnum } from "src/types/api";
import useSWR from "swr";

const fetcher = async (
  daoId: string,
  taskStatus: Array<TaskControllerFetchTasksTaskStatusEnum>
) => {
  if (taskStatus.length === 0) return { tasks: [] };
  return restApi.taskControllerFetchTasks({
    daoId: daoId,
    taskStatus: taskStatus,
  });
};

export const useTasks = (
  daoId: string,
  taskStatus: Array<TaskControllerFetchTasksTaskStatusEnum>,
  searchQuery?: string
) => {
  const {
    data,
    error,
    isLoading,
    mutate: mutateTasks,
  } = useSWR(
    [`tasks/${daoId}?taskStatus=${taskStatus}`, daoId, taskStatus],
    ([_, daoId, taskStatus]) => fetcher(daoId, taskStatus)
  );
  const { isUpdating, checkUpdated } = useIsUpdating({
    data,
    mutate: mutateTasks,
  });

  const mutate = async () => {
    checkUpdated();
    await mutateTasks();
  };

  return {
    tasks: data?.tasks.filter(
      (task) =>
        searchQuery == null ||
        task.title.toLowerCase().includes(searchQuery) ||
        task.description.toLowerCase().includes(searchQuery)
    ),
    isError: error,
    isLoading,
    isUpdating,
    mutate,
    checkUpdated,
  };
};
