import {
  doc,
  collection,
  query,
  where,
  getDocs,
  DocumentData,
  QueryDocumentSnapshot,
  SnapshotOptions,
  updateDoc,
  getDoc,
} from "firebase/firestore";
import { firebaseFirestore } from "./config";
import { ChangeProfile } from "../../types/common";
import { compressHashToAlphabet } from "../../utils/hash";
import sha256 from "crypto-js/sha256";
import { User } from "../../types/common/index.ts";

const userConverter = {
  toFirestore(user: User): DocumentData {
    return {
      ...user,
    };
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): User {
    const data = snapshot.data(options);
    return {
      address: data.address,
      name: data.name,
      img: data.img,
      email: data.email,
      shareId: data.shareId,
      uid: data.uid,
      twitter: data.twitter,
      twitterUserName: data.twitterUserName,
      initialized: data.initialized,
    };
  },
};

function createUserRef(uid: string) {
  return doc(firebaseFirestore, "users", uid);
}

export const getUser = async (uid: string): Promise<User | null> => {
  const userDoc = createUserRef(uid).withConverter(userConverter);
  const userResult = await getDoc(userDoc);
  if (!userResult.exists()) {
    console.error("user not found");
    return null;
  }
  return userResult.data();
};

export const isInitialized = async (uid) => {
  const user = await getUser(uid);
  return user?.initialized === undefined || user.initialized === true;
};

export const updateUser = async (updateUser: ChangeProfile): Promise<void> => {
  const userDoc = createUserRef(updateUser.uid);
  const userResult = await getDoc(userDoc);
  if (!userResult.exists()) {
    console.error("user not found");
    return;
  }
  const previousUserData = userResult.data();

  if (previousUserData.twitterUserName === undefined) {
    await updateDoc(userDoc, {
      ...previousUserData,
      twitterUserName: "",
    });
  }
  // timestampを取得する
  // sha256でハッシュ化する

  const hash = sha256(updateUser.uid).toString();
  const compressedHash = compressHashToAlphabet(hash);
  const changeUserInfo = {
    name:
      !updateUser.name && !previousUserData.name
        ? `uesr-${compressedHash}`
        : !updateUser.name && previousUserData.name
          ? previousUserData.name
          : updateUser.name,
    img: !updateUser.img ? previousUserData.img : updateUser.img,
    twitter:
      !updateUser.twitter || updateUser.twitter === ""
        ? previousUserData.twitter
        : updateUser.twitter,
    twitterUserName:
      !updateUser.twitterUserName || updateUser.twitterUserName === ""
        ? previousUserData.twitterUserName === undefined
          ? ""
          : previousUserData.twitterUserName
        : updateUser.twitterUserName,
    initialized:
      updateUser.initialized === undefined ? true : updateUser.initialized,

    /**
     *
     * twitterUserNameはのちに追加された項目なので更新においてエラーの発生が多々みられる
     * updatedDocで更新されないエラーが発生した場合は、twitterUserNameを追加する必要があるかもしれない
     * 2023-10-27 ikisuke
     */
  };
  console.log(
    "🚀 ~ file: user.ts ~ line 144 ~ updateUser ~ changeUserInfo",
    changeUserInfo
  );
  console.log(userDoc);
  try {
    await updateDoc(userDoc, {
      name: changeUserInfo.name,
      img: changeUserInfo.img,
      twitter: changeUserInfo.twitter,
      initialized: changeUserInfo.initialized,
    });
  } catch (error) {
    console.error(error);
  }
};

export const disconnectTwitter = async (uid: string): Promise<void> => {
  const userDoc = createUserRef(uid);
  const userResult = await getDoc(userDoc);
  if (!userResult.exists()) {
    return;
  }
  const changeUserInfo = {
    twitter: "",
    twitterUserName: "",
  };
  try {
    await updateDoc(userDoc, {
      twitter: changeUserInfo.twitter,
      twitterUserName: changeUserInfo.twitterUserName,
    });
  } catch (error) {
    console.error(error);
  }
};

export const deleteUser = async (uid: string): Promise<void> => {
  // ユーザーの削除
  const userDoc = createUserRef(uid);
  await updateDoc(userDoc, {
    isDeleted: true,
  })
    .then(() => {
      console.log("Document successfully updated!");
    })
    .catch((error) => {
      console.error("Error updating document: ", error);
    });
};

const inQueryLimit = 30;

export async function fetchUsers(
  userIds: string[]
): Promise<Map<string, User>> {
  const map = new Map<string, User>();
  if (userIds.length < 1) return map;

  // firestoreのin句に入れる要素数は制限があるため、分割してfirestoreにアクセスする
  const userIdsArray: string[][] = [];
  while (userIds.length) {
    userIdsArray.push(userIds.splice(0, inQueryLimit));
  }

  for (const uids of userIdsArray) {
    const q = query(
      collection(firebaseFirestore, "users"),
      where("uid", "in", uids)
    ).withConverter(userConverter);

    const snapshot = await getDocs(q);
    snapshot.forEach((d) => {
      map.set(d.id, d.data());
    });
  }

  return map;
}
