/* tslint:disable */
/* eslint-disable */
/**
 * Unyte SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ApproveOutputInfos } from './ApproveOutputInfos';
import {
    ApproveOutputInfosFromJSON,
    ApproveOutputInfosFromJSONTyped,
    ApproveOutputInfosToJSON,
} from './ApproveOutputInfos';

/**
 * 
 * @export
 * @interface ApproveOutputArgs
 */
export interface ApproveOutputArgs {
    /**
     * approveOutputの情報
     * @type {Array<ApproveOutputInfos>}
     * @memberof ApproveOutputArgs
     */
    approvalInfos: Array<ApproveOutputInfos>;
}

/**
 * Check if a given object implements the ApproveOutputArgs interface.
 */
export function instanceOfApproveOutputArgs(value: object): boolean {
    if (!('approvalInfos' in value)) return false;
    return true;
}

export function ApproveOutputArgsFromJSON(json: any): ApproveOutputArgs {
    return ApproveOutputArgsFromJSONTyped(json, false);
}

export function ApproveOutputArgsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApproveOutputArgs {
    if (json == null) {
        return json;
    }
    return {
        
        'approvalInfos': ((json['approvalInfos'] as Array<any>).map(ApproveOutputInfosFromJSON)),
    };
}

export function ApproveOutputArgsToJSON(value?: ApproveOutputArgs | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'approvalInfos': ((value['approvalInfos'] as Array<any>).map(ApproveOutputInfosToJSON)),
    };
}

