import React from "react";
import { Output, Task } from "src/types/api/index.ts";
import { useAdminOutputs } from "src/hooks/useAdminOutputs.ts";
import { useOutputs } from "src/hooks/useOutputs.ts";
import { BaseProps, Progress } from "src/types/common";
import { useUser } from "src/hooks/useUser";

export type ApproveButtonProps = {
  plannedApprovalOutputs: Output[];
  setInProgress: React.Dispatch<React.SetStateAction<Progress>>;
  daoId: string;
  task: Task;
  setPlannedApprovalOutputs: React.Dispatch<React.SetStateAction<Output[]>>;
} & BaseProps;

export const ApproveButton: React.FC<ApproveButtonProps> = ({
  className,
  plannedApprovalOutputs,
  setInProgress,
  daoId,
  task,
  setPlannedApprovalOutputs,
}) => {
  const { mutate: mutateOutputs, checkUpdated } = useOutputs(
    daoId,
    task.taskId
  );
  const { approve } = useAdminOutputs(daoId, {});
  const { user: owner } = useUser();

  const isDisable = plannedApprovalOutputs.length <= 0;

  const handleClick = async () => {
    if (owner === undefined) {
      alert("ログインしてください");
      return;
    }

    const confirm = window.confirm("成果物を承認しますか？");
    if (!confirm) return;

    setInProgress({ isShow: true, message: "成果物を承認しています。" });

    try {
      await approve(
        plannedApprovalOutputs.map((output) => ({
          ...output,
          task: task,
          status: "approved",
          type: "other",
        }))
      );
    } catch (e) {
      console.error(e);
      setInProgress({ isShow: false, message: "" });
      if (e instanceof Error) {
        alert(
          `成果物の承認に失敗した可能性があります。ご確認ください。\n${e.stack}`
        );
      } else
        alert(
          `成果物の承認に失敗した可能性があります。ご確認ください。\n\n${JSON.stringify(
            e
          )}`
        );
      return;
    }
    alert("成果物を承認しました。");
    setPlannedApprovalOutputs([]);
    checkUpdated();
    await mutateOutputs();
    setInProgress({ isShow: false, message: "" });
  };

  return (
    <button
      className={`${className} h-8 rounded-full px-6 text-xs text-white ${
        isDisable ? "bg-gray-500" : "bg-task-button"
      }`}
      disabled={isDisable}
      onClick={handleClick}
    >
      承認する
    </button>
  );
};
