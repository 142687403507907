import { NETWORK_ERROR_MSG, CREATE_DAO_ENDPOINT } from "../../const/const.ts";
import { fireauth } from "src/actions/firebase/config.js";

export async function createDAOId(owner) {
  const unixtime = Math.floor(new Date().getTime() / 1000);
  // ハッシュ化
  const idUint8 = new TextEncoder("utf-8").encode(owner.uid + unixtime);
  const id = await crypto.subtle
    .digest("SHA-256", idUint8)
    .then(function (hashBuffer) {
      var hashArray = Array.from(new Uint8Array(hashBuffer));
      var hashHex = hashArray
        .map(function (b) {
          return b.toString(16).padStart(2, "0");
        })
        .join("");
      hashHex = "0x" + hashHex;
      return hashHex;
    });
  console.log(id);
  return id;
}

async function createDAOAction(
  id,
  name,
  description,
  img,
  admins,
  joinToken,
  governanceToken,
  contributionToken,
  isOpen,
  links
) {
  const json = {
    type: "createDAO",
    args: {
      id: id,
      name: name,
      description: description,
      img: img,
      admins: admins,
      joinToken: joinToken,
      governanceToken: governanceToken,
      contributionToken: contributionToken,
      isOpen: isOpen,
      links: links,
    },
  };
  const user = fireauth.currentUser;
  const token = await user.getIdToken(true);
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(json),
  };
  let response;
  try {
    response = await fetch(CREATE_DAO_ENDPOINT, options);
  } catch (e) {
    console.error(e);
    throw new Error(NETWORK_ERROR_MSG);
  }
  const responseJson = await response.json();
  if (responseJson.status !== "success") throw new Error(responseJson.message);
  return { daoId: id, response: responseJson };
}

export async function createDAO(
  daoId,
  name,
  description,
  img,
  admins,
  joinToken,
  governanceToken,
  contributionToken,
  isOpen,
  links,
  owner
) {
  console.log(joinToken, governanceToken, contributionToken);
  if (name === "" || description === "" || img === null) {
    alert("DAO名と説明文、画像を全て入力してください");
    return;
  }
  // adminsのアドレスを抜き出して配列に格納、もしアドレスが空欄ならエラーを返す
  let adminsArray = [];
  admins.map((item) => {
    if (item.address === "") {
      alert("アドレスが空欄です");
      return;
    }
    adminsArray.push(item.address);
  });
  console.log(adminsArray);
  // 管理者のアドレスを配列に格納
  adminsArray.unshift(owner.address);
  // DAOの情報をブロックチェーンに書き込むためにlambdaエンドポイントを叩く
  console.log(daoId, name, description, img, adminsArray);
  const response = await createDAOAction(
    daoId,
    name,
    description,
    img,
    adminsArray,
    joinToken,
    governanceToken,
    contributionToken,
    isOpen,
    links
  );
  return response;
}
