/* tslint:disable */
/* eslint-disable */
/**
 * Unyte SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { KeyPair } from './KeyPair';
import {
    KeyPairFromJSON,
    KeyPairFromJSONTyped,
    KeyPairToJSON,
} from './KeyPair';

/**
 * 
 * @export
 * @interface ProposalArgs
 */
export interface ProposalArgs {
    /**
     * DAOのID
     * @type {string}
     * @memberof ProposalArgs
     */
    daoId: string;
    /**
     * ProposalのID
     * @type {string}
     * @memberof ProposalArgs
     */
    proposalId: string;
    /**
     * 提案者のアドレス
     * @type {string}
     * @memberof ProposalArgs
     */
    proposer: string;
    /**
     * 提案のタイトル
     * @type {string}
     * @memberof ProposalArgs
     */
    title: string;
    /**
     * 提案の説明
     * @type {string}
     * @memberof ProposalArgs
     */
    description: string;
    /**
     * 開始時間
     * @type {string}
     * @memberof ProposalArgs
     */
    startTime: string;
    /**
     * 終了時間
     * @type {string}
     * @memberof ProposalArgs
     */
    closingTime: string;
    /**
     * 秘密鍵と公開鍵のペア
     * @type {KeyPair}
     * @memberof ProposalArgs
     */
    keys?: KeyPair;
    /**
     * 署名
     * @type {string}
     * @memberof ProposalArgs
     */
    signature?: string;
}

/**
 * Check if a given object implements the ProposalArgs interface.
 */
export function instanceOfProposalArgs(value: object): boolean {
    if (!('daoId' in value)) return false;
    if (!('proposalId' in value)) return false;
    if (!('proposer' in value)) return false;
    if (!('title' in value)) return false;
    if (!('description' in value)) return false;
    if (!('startTime' in value)) return false;
    if (!('closingTime' in value)) return false;
    return true;
}

export function ProposalArgsFromJSON(json: any): ProposalArgs {
    return ProposalArgsFromJSONTyped(json, false);
}

export function ProposalArgsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProposalArgs {
    if (json == null) {
        return json;
    }
    return {
        
        'daoId': json['daoId'],
        'proposalId': json['proposalId'],
        'proposer': json['proposer'],
        'title': json['title'],
        'description': json['description'],
        'startTime': json['startTime'],
        'closingTime': json['closingTime'],
        'keys': json['keys'] == null ? undefined : KeyPairFromJSON(json['keys']),
        'signature': json['signature'] == null ? undefined : json['signature'],
    };
}

export function ProposalArgsToJSON(value?: ProposalArgs | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'daoId': value['daoId'],
        'proposalId': value['proposalId'],
        'proposer': value['proposer'],
        'title': value['title'],
        'description': value['description'],
        'startTime': value['startTime'],
        'closingTime': value['closingTime'],
        'keys': KeyPairToJSON(value['keys']),
        'signature': value['signature'],
    };
}

