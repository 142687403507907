import React from "react";
import { BaseProps } from "../../types/common/index.ts";
import { useIntl } from "react-intl";

export type JoinDaoProps = {} & BaseProps;

export const JoinDao: React.FC<JoinDaoProps> = ({ className }) => {
  const intl = useIntl();

  return (
    <div className={`${className} rounded-full bg-[#37373C] py-2 text-white`}>
      {intl.formatMessage({
        id: "list_join",
        defaultMessage: "参加する",
      })}
    </div>
  );
};
