import React from "react";
import clsx from "clsx";
import { BaseProps, User } from "../../../../types/common/index.ts";
import { useSetAtom } from "jotai";
import { inProgressMarketState } from "../../../../stores/inProgressMarketState.ts";
import { isOpenInsufficientPointModalMarketState } from "../../../../stores/isOpenInsufficientPointModalMarketState.ts";
import { DaoDetailDto } from "src/types/api/index.ts";

export type ShowItemBuyMModalButtonProps = {
  price: any;
  disabled?: boolean;
  setIsOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  user: User;
  daoDetail: DaoDetailDto;
} & BaseProps;

export const ShowItemBuyModalButton: React.FC<ShowItemBuyMModalButtonProps> = (
  props: ShowItemBuyMModalButtonProps
) => {
  const setInProgress = useSetAtom(inProgressMarketState);
  const setIsOpenInsufficientPointModal = useSetAtom(
    isOpenInsufficientPointModalMarketState
  );

  const handleClick = async () => {
    setInProgress({ isShow: true, message: "購入手続き中..." });
    try {
      const daoUser = props.daoDetail.members.find(
        (daoUser) => daoUser.address === props.user.address
      );
      if (daoUser?.contributionToken! < props.price) {
        setIsOpenInsufficientPointModal(true);
        setInProgress({ isShow: false, message: "" });
        return;
      }
    } catch (error) {
      console.error(error);
      if (error instanceof Error)
        alert("購入に失敗した可能性があります\n\nError: " + error.message);
      else alert("購入に失敗した可能性があります\n\nUnknown Error: " + error);
    }
    props.setIsOpenModal(true);
    setInProgress({ isShow: false, message: "" });
  };

  return (
    <button
      disabled={props.disabled}
      className={clsx(
        props.className,
        "rounded-lg bg-unyte px-2 py-1 lg:px-5 lg:py-2.5",
        "text-center text-sm font-medium text-white",
        "dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800",
        "hover:bg-blue-800",
        "focus:outline-none focus:ring-4 focus:ring-blue-300",
        "disabled:cursor-not-allowed disabled:bg-blue-700 disabled:opacity-50"
      )}
      onClick={handleClick}
    >
      Buy
    </button>
  );
};
