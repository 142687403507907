import React from "react";
import { BaseProps } from "../../types/common/index.ts";
import { FormattedMessage } from "react-intl";
import { Dao } from "src/types/api/index.ts";
import { Overlay } from "src/components/dao/Overlay.tsx";
import { CloseDaoModalButton } from "src/components/dao/CloseDaoModalButton.tsx";
import { NavDaoDetailButton } from "src/components/dao/NavDaoDetailButton.tsx";
import { GetNftButton } from "src/components/dao/GetNftButton.tsx";
import { DaoAvatar } from "src/components/dao/DaoAvatar.tsx";
import { DaoName } from "src/components/dao/DaoName.tsx";
import { DaoDescription } from "src/components/dao/DaoDescription.tsx";
import { SocialLink } from "src/components/dao/SocialLink.tsx";
import { ApplyForDaoButton } from "src/components/dao/ApplyForDaoButton.tsx";

export type DaoDetailModalProps = {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedDao: Dao | null;
  setSelectedDao: React.Dispatch<React.SetStateAction<Dao | null>>;
  userAddress: string;
} & BaseProps;

export const DaoDetailModal: React.FC<DaoDetailModalProps> = ({
  className,
  isModalOpen,
  setIsModalOpen,
  selectedDao,
  setSelectedDao,
  userAddress,
}) => {
  const handleClickCloseModal = () => {
    setIsModalOpen(false);
    setSelectedDao(null);
  };

  if (!isModalOpen || !selectedDao) return <></>;

  const isMember = selectedDao.members.includes(userAddress);

  return (
    <div
      className={`${className} fixed inset-0 z-40 flex h-screen items-center justify-center`}
    >
      <Overlay onClick={handleClickCloseModal} />
      <div className="w-11/12 max-w-xl transform overflow-hidden rounded-lg bg-[#28282D] bg-unyte p-px text-left text-white shadow-xl transition-all md:w-1/2">
        <div className="h-full w-full rounded-lg bg-[#28282D] px-4 pb-4 pt-5">
          <div className={`${className} flex justify-end`}>
            <CloseDaoModalButton onClick={handleClickCloseModal} />
          </div>
          <div className="flex items-center justify-center">
            {isMember ? (
              <NavDaoDetailButton
                className="mb-3 mt-3 w-2/3 lg:mb-10 lg:w-1/3"
                daoId={selectedDao.daoId}
              />
            ) : selectedDao.isOpen ? (
              <GetNftButton
                className="mb-3 mt-3 h-9 w-2/3 lg:mb-10 lg:w-1/3"
                daoId={selectedDao.daoId}
                isOpen={selectedDao.isOpen}
              />
            ) : !selectedDao.isLlcDao ? (
              <ApplyForDaoButton
                className="mb-3 mt-3 h-9 w-2/3 lg:mb-10 lg:w-1/3"
                daoId={selectedDao.daoId}
                isOpen={selectedDao.isOpen}
                setIsModalOpen={setIsModalOpen}
              />
            ) : null}
          </div>
          <div className="mx-auto w-full pb-4 lg:w-11/12 lg:pb-14">
            <div className="flex flex-col lg:flex-row">
              <div className="mx-auto w-full lg:mr-3 lg:w-1/4">
                <DaoAvatar
                  className="mx-auto mb-4 h-24 w-24 "
                  avatar={selectedDao.avatar}
                />
              </div>
              <div className="w-full lg:w-3/4">
                <div className="ml-2 lg:ml-0">
                  <DaoName
                    className="text-xl font-medium leading-6"
                    name={selectedDao.name}
                  />
                  <DaoDescription
                    className="mb-7 mt-2 text-xs"
                    description={selectedDao.description}
                  />
                </div>
                <div className="text-xs">
                  <div className="mb-1 ml-2 font-extralight lg:ml-0">
                    <FormattedMessage
                      id="dao_detail_social"
                      defaultMessage="ソーシャルアカウント"
                    />
                  </div>
                  <div className="flex w-full justify-start space-x-4 pt-2 lg:gap-x-8 lg:pt-4 lg:text-base">
                    {selectedDao.links.map((link, i) => (
                      <SocialLink key={i} link={link} />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
