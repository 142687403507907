/* tslint:disable */
/* eslint-disable */
/**
 * Unyte SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Proposal } from './Proposal';
import {
    ProposalFromJSON,
    ProposalFromJSONTyped,
    ProposalToJSON,
} from './Proposal';

/**
 * 
 * @export
 * @interface ProposalsDto
 */
export interface ProposalsDto {
    /**
     * 
     * @type {Array<Proposal>}
     * @memberof ProposalsDto
     */
    proposals: Array<Proposal>;
}

/**
 * Check if a given object implements the ProposalsDto interface.
 */
export function instanceOfProposalsDto(value: object): boolean {
    if (!('proposals' in value)) return false;
    return true;
}

export function ProposalsDtoFromJSON(json: any): ProposalsDto {
    return ProposalsDtoFromJSONTyped(json, false);
}

export function ProposalsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProposalsDto {
    if (json == null) {
        return json;
    }
    return {
        
        'proposals': ((json['proposals'] as Array<any>).map(ProposalFromJSON)),
    };
}

export function ProposalsDtoToJSON(value?: ProposalsDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'proposals': ((value['proposals'] as Array<any>).map(ProposalToJSON)),
    };
}

