import React, { useEffect, useState } from "react";
import Datepicker, {
  DateType,
  DateValueType,
} from "react-tailwindcss-datepicker";
import "../styles/UniSwaTextField.css";

type Props = {
  value: {
    startDate: number;
    endDate: number;
  };
  handleDateChange: (value: { startDate: Date; endDate: Date }) => void;
};

const VoteDateTimePicker = (props: Props) => {
  const [value, setValue] = useState<DateValueType>({
    startDate: new Date(),
    endDate: new Date(),
  });

  useEffect(() => {
    setValue({
      startDate: new Date(props.value.startDate),
      endDate: new Date(props.value.endDate),
    });
  }, [props.value.startDate, props.value.endDate]);

  const handleValueChange = (newValue: DateValueType) => {
    setValue(newValue);
    newValue &&
      props.handleDateChange({
        startDate: new Date(newValue.startDate!.toLocaleString()),
        endDate: new Date(newValue.endDate!.toLocaleString()),
      });
  };

  return (
    <div>
      <Datepicker
        useRange={false}
        showShortcuts={true}
        startWeekOn="mon"
        configs={{
          shortcuts: {
            today: "今日",
            customToday: {
              text: "明日",
              period: {
                start: new Date(new Date().getTime() + 1000 * 60 * 60 * 24),
                end: new Date(new Date().getTime() + 1000 * 60 * 60 * 24),
              },
            },
            next7days: {
              text: "今後1週間",
              period: {
                start: new Date(),
                end: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 6),
              },
            },
            currentMonth: "今月",
          },
        }}
        value={value}
        placeholder="yyyy-mm-dd~yyyy:mm-dd"
        inputClassName="!bg-[#171920] custom-text-field bg-[#171920] mb-2 w-full p-2 border rounded-full"
        popoverDirection="up"
        onChange={handleValueChange}
      />
    </div>
  );
};

export default VoteDateTimePicker;
