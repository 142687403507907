import React from "react";
import { SelectablePeriod } from "src/pages/thanks/Thanks";
import { FormattedMessage } from "react-intl";

const ThanksHeader = ({
  amount,
  selectedPeriod,
}: {
  amount: number;
  selectedPeriod: SelectablePeriod;
}) => {
  return (
    <div className="flex h-1/6 w-full flex-row text-gray-300 lg:h-1/5">
      <div className="relative h-full w-3/5 bg-thanks-header bg-cover bg-center bg-no-repeat">
        <div className="absolute left-full top-1/3 h-1/5 w-px translate-y-2/4 bg-white"></div>
      </div>
      <div className="flex h-full w-2/5 flex-col justify-center bg-pink-900">
        <div className="text-basis basis-1/6 pl-10 font-semibold lg:text-xl">
          <FormattedMessage
            id={`thanks_period_${selectedPeriod}`}
            defaultMessage={selectedPeriod}
          />
        </div>
        <div className="shrink basis-1/6 pl-10 text-3xl font-bold lg:text-6xl">
          {amount}
        </div>
        <div className="text-basis mt-2 basis-1/6 pl-10 font-semibold lg:text-xl">
          <FormattedMessage
            id="thanks_header_liquidity"
            defaultMessage="Thanks"
          />
        </div>
      </div>
    </div>
  );
};

export default ThanksHeader;
