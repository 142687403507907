import { NETWORK_ERROR_MSG, JOINDAO_ENDPOINT } from "../../const/const.ts";
import { fireauth } from "src/actions/firebase/config.js";

async function joinDAOAction(daoId, to) {
  const json = {
    args: {
      daoId: daoId,
      to: to,
    },
  };
  const user = fireauth.currentUser;
  const token = await user.getIdToken(true);
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(json),
  };
  let response;
  try {
    response = await fetch(JOINDAO_ENDPOINT, options);
  } catch (e) {
    console.error(e);
    throw new Error(NETWORK_ERROR_MSG);
  }
  let responseJson;
  try {
    responseJson = await response.json();
    if (responseJson.status !== "success")
      throw new Error(responseJson.message);
  } catch (e) {
    console.error(e);
  }
  return responseJson;
}

export async function joinDAO(daoId, user) {
  if (user === undefined) throw new Error("ログインしてください。");

  // firestoreからシェアを取得
  const result = await joinDAOAction(daoId, user.address);
  return result;
}
