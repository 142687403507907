import React from "react";
import UniSwaTextField from "../atoms/UniSwaTextField.tsx";
import { searchUserByEmail } from "../../actions/firebase/utils";
import "../../components/styles/UniSwaTextField.css";
import { ImCamera } from "react-icons/im";
import { FormattedMessage } from "react-intl";

interface Props {
  daoName: string;
  setDaoName: React.Dispatch<React.SetStateAction<string>>;
  description: string;
  setDescription: React.Dispatch<React.SetStateAction<string>>;
  inputValue: string;
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
  confirmedValues: string[];
  setConfirmedValues: React.Dispatch<React.SetStateAction<string[]>>;
  profileImage: any;
  setProfileImage: React.Dispatch<React.SetStateAction<any>>;
  profileBase64: any;
  setProfileBase64: React.Dispatch<React.SetStateAction<any>>;
  link1: string;
  setLink1: React.Dispatch<React.SetStateAction<string>>;
  link2: string;
  setLink2: React.Dispatch<React.SetStateAction<string>>;
  link3: string;
  setLink3: React.Dispatch<React.SetStateAction<string>>;
}

const CreateDAOPage: React.FC<Props> = ({
  daoName,
  setDaoName,
  description,
  setDescription,
  inputValue,
  setInputValue,
  confirmedValues,
  setConfirmedValues,
  profileImage,
  setProfileImage,
  profileBase64,
  setProfileBase64,
  link1,
  setLink1,
  link2,
  setLink2,
  link3,
  setLink3,
}) => {
  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setProfileImage(event.target?.result);
        const base64 = reader.result.split(",")[1];
        console.log(base64);
        setProfileBase64(base64);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleKeyDown = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && inputValue.trim()) {
      const user = await searchUserByEmail(inputValue);
      if (user == null) {
        alert("ユーザーが見つかりませんでした。");
      } else {
        console.log(user);
        setInputValue("");
        setConfirmedValues((prev) => [...prev, user]);
      }
    }
  };

  const hasConfirmedValues = confirmedValues.length > 0 ? true : false;

  const adminListBackground = hasConfirmedValues ? "!bg-[#171920]" : "";

  return (
    <div className="h-scren mt-28 flex w-full">
      <div className="w-1/4 text-white">
        <div className="mb-2 text-center text-sm">
          <FormattedMessage id="icon_image" defaultMessage="アイコン画像" />
        </div>
        <label
          htmlFor="profileImage"
          className="m-auto flex h-40 w-40 items-center justify-center rounded-full bg-[#38373D] hover:cursor-pointer"
        >
          <input
            type="file"
            className="hidden bg-gray-50"
            id="profileImage"
            onChange={handleImageChange}
          />

          {!profileImage ? (
            <div className="flex flex-col">
              <ImCamera className="mx-auto mb-2 text-center text-5xl text-[#29282E]" />
              <div className="text-center text-sm text-[#8B8B8C] hover:cursor-pointer">
                <div>
                  <FormattedMessage
                    id="select_file"
                    defaultMessage="ファイルを"
                  />
                </div>
                <div>
                  <FormattedMessage
                    id="select_file2"
                    defaultMessage="選択する"
                  />
                </div>
              </div>
            </div>
          ) : (
            <img
              src={profileImage}
              alt="Profile"
              className="h-40 w-40 cursor-pointer rounded-full"
            />
          )}
        </label>
      </div>
      <div className="w-3/4 text-sm">
        <div className="mb-4 text-white">
          <div className="mb-2 ml-6">
            <FormattedMessage id="step1_name" defaultMessage="DAO 名" />
          </div>
          <UniSwaTextField
            value={daoName}
            isCreateDao={true}
            onChange={(e) => setDaoName(e.target.value)}
          />
        </div>

        <div className="mb-4 text-white">
          <div className="mb-2 ml-6">
            <FormattedMessage
              id="step1_description"
              defaultMessage="DAOについての説明を入力してください。"
            />
          </div>
          <UniSwaTextField
            value={description}
            isCreateDao={true}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
        <div className="mb-4 text-white">
          <div className="mb-2 ml-6">
            <FormattedMessage
              id="step1_description_admin"
              defaultMessage="管理者を追加したい場合は、メールアドレスを入力してください。(任意)"
            />
          </div>
          <input
            type="text"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyDown={handleKeyDown}
            className={`bg-lighter-navy custom-text-field mb-2 w-full rounded-full border p-2 placeholder-gray-500 ${adminListBackground}`}
          />
        </div>
        {confirmedValues.map((value, idx) => (
          <div
            key={idx}
            className="custom-label text-main mb-1 w-3/5 break-all rounded border p-2"
          >
            {value.address}
          </div>
        ))}

        <div className="mb-4 text-white">
          <div className="mb-2 ml-6">
            <FormattedMessage id="step1_link" defaultMessage="link" />
          </div>
          <UniSwaTextField
            value={link1}
            isCreateDao={true}
            onChange={(e) => setLink1(e.target.value)}
          />
        </div>
        <div className="mb-4 text-white">
          <div className="mb-2 ml-6">
            <FormattedMessage id="step1_link" defaultMessage="link" />
          </div>
          <UniSwaTextField
            value={link2}
            isCreateDao={true}
            onChange={(e) => setLink2(e.target.value)}
          />
        </div>
        <div className="mb-4 text-white">
          <div className="mb-2 ml-6">
            <FormattedMessage id="step1_link" defaultMessage="link" />
          </div>
          <UniSwaTextField
            value={link3}
            isCreateDao={true}
            onChange={(e) => setLink3(e.target.value)}
          />
        </div>

        {/* 各コントラクトの疎通確認のためにここのボタンを一応残しておきます。 ikisuke
        <button
          onClick={() =>
            createDAO(daoName, description, profileBase64, confirmedValues)
          }
          className="p-2 mb-1 break-all w-3/5 custom-label rounded text-main">
          <span className="text-main">DAO作成</span>
        </button>
        <button
          onClick={() =>
            createNFT(
              "0x0000000000000000000000000000000000000000000000000000000000000064",
              daoName,
              "governance",
              profileBase64
            )
          }
          className="p-2 mb-1 break-all w-3/5 custom-label rounded text-main">
          <span className="text-main">NFT作成</span>
        </button> */}
      </div>
    </div>
  );
};

export default CreateDAOPage;
