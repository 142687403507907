import React from "react";
import { BaseProps } from "../../../types/common";
import { ApproveCheckBox } from "../../Task/ApproveCheckBox.tsx";
import { OutputsForAllTask } from "src/types/api/index.ts";

export type AllSelectCheckBoxProps = {
  outputs: OutputsForAllTask[];
  selectedOutputIds: Set<string>;
  setSelectedOutputIds: React.Dispatch<React.SetStateAction<Set<string>>>;
} & BaseProps;

export const AllSelectCheckBox: React.FC<AllSelectCheckBoxProps> = ({
  className,
  outputs,
  selectedOutputIds,
  setSelectedOutputIds,
}) => {
  const pendingOutputs = outputs.filter(
    (output) => output.status === "pending"
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setSelectedOutputIds(
        new Set(pendingOutputs.map((output) => output.outputId))
      );
      return;
    }
    setSelectedOutputIds(new Set([]));
  };

  return (
    <div
      className={`${className} flex items-center gap-x-4 text-xs text-gray-100`}
    >
      <ApproveCheckBox
        checked={
          pendingOutputs.length === selectedOutputIds.size &&
          pendingOutputs.length > 0
        }
        disabled={pendingOutputs.length === 0}
        onChange={handleChange}
      />
      <p>全選択</p>
    </div>
  );
};
