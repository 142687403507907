import React, { useState, useEffect } from "react";
import { FireIcon, ArrowRightIcon } from "@heroicons/react/24/outline";
// @ts-ignore
import TaskSetting from "../../components/editDao/TaskSetting.tsx";
// @ts-ignore
import DAOInfoSetting from "../../components/editDao/DAOInfoSetting.tsx";
import { DaoTaskCreationOption } from "../../types/dao";
import { useDaoDetail } from "src/hooks/useDaoDetail.ts";
import Loading from "src/components/common/Loading.tsx";
import { Progress } from "src/types/common/index.ts";

interface Props {
  daoId: string;
}
const DaoSetting: React.FC<Props> = (props: Props) => {
  const [step, setStep] = useState(1);
  const [inProgress, setInProgress] = useState<Progress>({
    isShow: false,
    message: "",
  });
  const {
    daoDetail,
    isError: isErrorDaoDetail,
    isLoading: isLoadingDaoDetail,
    mutate: daoDetailMutate,
  } = useDaoDetail(props.daoId);

  const [daoTaskCreationOption, setDaoTaskCreationOption] =
    useState<DaoTaskCreationOption>("draft");

  const [daoName, setDaoName] = useState("");
  const [description, setDescription] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [confirmedValues, setConfirmedValues] = useState<string[]>([]);
  const [profileImage, setProfileImage] = useState<any>("");
  const [profileBase64, setProfileBase64] = useState<any>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [link1, setLink1] = useState<string>("");
  const [link2, setLink2] = useState<string>("");
  const [link3, setLink3] = useState<string>("");

  useEffect(() => {
    if (isLoadingDaoDetail) {
      setInProgress({ isShow: true, message: "Loading..." });
      return;
    }
    setInProgress({ isShow: false, message: "" });
  }, [isErrorDaoDetail, isLoadingDaoDetail]);

  useEffect(() => {
    if (!daoDetail) return;
    setDaoName(daoDetail.name || "");
    setDescription(daoDetail.description || "");
    setConfirmedValues(daoDetail.admins || []);
    setProfileImage(process.env.REACT_APP_IPFS_BASE_URL + daoDetail.avatar);
    setIsOpen(daoDetail.isOpen || false);
    setLink1(daoDetail.links[0] || "");
    setLink2(daoDetail.links[1] || "");
    setLink3(daoDetail.links[2] || "");
  }, [daoDetail]);

  useEffect(() => {
    if (!daoDetail) return;
    const imgLink = process.env.REACT_APP_IPFS_BASE_URL + daoDetail.avatar;
    // imgLinkをbase64に変換
    fetch(imgLink)
      .then((res) => res.blob())
      .then((blob) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          const base64 = reader.result as string;
          const base64data = base64.split(",")[1];
          setProfileBase64(base64data);
        };
      });
  }, [daoDetail]);

  if (isErrorDaoDetail || !daoDetail) return <></>;
  if (isLoadingDaoDetail) return <Loading {...inProgress} />;
  const renderFormByStep = (step: number) => {
    switch (step) {
      case 1:
        return (
          <div className="w-full flex-col p-8">
            <button
              onClick={() => setStep(3)}
              className="flex w-full justify-between rounded-lg border border-unyte-dark p-6"
            >
              <div className="flex items-center">
                <FireIcon
                  className="mr-8 h-8 w-8 text-gray-300"
                  aria-hidden="true"
                />
                <div className="flex-col">
                  <div className="text-lg text-gray-300">DAO情報変更</div>
                  <div className="text-sm text-gray-300">DAO情報変更</div>
                </div>
              </div>
              <div className="flex items-center">
                <ArrowRightIcon
                  className="h-8 w-8 text-gray-300"
                  aria-hidden="true"
                />
              </div>
            </button>
          </div>
        );
      case 2:
        return (
          <TaskSetting
            daoTaskCreationOption={daoTaskCreationOption}
            setDaoTaskCreationOption={setDaoTaskCreationOption}
          />
        );
      case 3:
        return (
          <DAOInfoSetting
            daoId={props.daoId}
            daoName={daoName}
            setDaoName={setDaoName}
            description={description}
            setDescription={setDescription}
            inputValue={inputValue}
            setInputValue={setInputValue}
            confirmedValues={confirmedValues}
            setConfirmedValues={setConfirmedValues}
            profileImage={profileImage}
            setProfileImage={setProfileImage}
            profileBase64={profileBase64}
            setProfileBase64={setProfileBase64}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            link1={link1}
            setLink1={setLink1}
            link2={link2}
            setLink2={setLink2}
            link3={link3}
            setLink3={setLink3}
            setStep={setStep}
            daoDetailMutate={daoDetailMutate}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="my-2 overflow-x-hidden overflow-y-scroll sm:flex lg:my-4">
      {renderFormByStep(step)}
    </div>
  );
};
export default DaoSetting;
