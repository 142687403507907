/* tslint:disable */
/* eslint-disable */
/**
 * Unyte SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OutputAuthorForOutputsForAllTasks } from './OutputAuthorForOutputsForAllTasks';
import {
    OutputAuthorForOutputsForAllTasksFromJSON,
    OutputAuthorForOutputsForAllTasksFromJSONTyped,
    OutputAuthorForOutputsForAllTasksToJSON,
} from './OutputAuthorForOutputsForAllTasks';
import type { TaskForOutputsForAllTasks } from './TaskForOutputsForAllTasks';
import {
    TaskForOutputsForAllTasksFromJSON,
    TaskForOutputsForAllTasksFromJSONTyped,
    TaskForOutputsForAllTasksToJSON,
} from './TaskForOutputsForAllTasks';

/**
 * 
 * @export
 * @interface OutputsForAllTask
 */
export interface OutputsForAllTask {
    /**
     * 
     * @type {string}
     * @memberof OutputsForAllTask
     */
    outputId: string;
    /**
     * 
     * @type {TaskForOutputsForAllTasks}
     * @memberof OutputsForAllTask
     */
    task: TaskForOutputsForAllTasks;
    /**
     * 
     * @type {OutputAuthorForOutputsForAllTasks}
     * @memberof OutputsForAllTask
     */
    author: OutputAuthorForOutputsForAllTasks;
    /**
     * 
     * @type {number}
     * @memberof OutputsForAllTask
     */
    date: number;
    /**
     * 
     * @type {string}
     * @memberof OutputsForAllTask
     */
    link: string;
    /**
     * 
     * @type {string}
     * @memberof OutputsForAllTask
     */
    note?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OutputsForAllTask
     */
    outputFiles?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof OutputsForAllTask
     */
    status: OutputsForAllTaskStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof OutputsForAllTask
     */
    type: OutputsForAllTaskTypeEnum;
}


/**
 * @export
 */
export const OutputsForAllTaskStatusEnum = {
    Approved: 'approved',
    Rejected: 'rejected',
    Pending: 'pending'
} as const;
export type OutputsForAllTaskStatusEnum = typeof OutputsForAllTaskStatusEnum[keyof typeof OutputsForAllTaskStatusEnum];

/**
 * @export
 */
export const OutputsForAllTaskTypeEnum = {
    Other: 'other',
    XTask: 'xTask'
} as const;
export type OutputsForAllTaskTypeEnum = typeof OutputsForAllTaskTypeEnum[keyof typeof OutputsForAllTaskTypeEnum];


/**
 * Check if a given object implements the OutputsForAllTask interface.
 */
export function instanceOfOutputsForAllTask(value: object): boolean {
    if (!('outputId' in value)) return false;
    if (!('task' in value)) return false;
    if (!('author' in value)) return false;
    if (!('date' in value)) return false;
    if (!('link' in value)) return false;
    if (!('status' in value)) return false;
    if (!('type' in value)) return false;
    return true;
}

export function OutputsForAllTaskFromJSON(json: any): OutputsForAllTask {
    return OutputsForAllTaskFromJSONTyped(json, false);
}

export function OutputsForAllTaskFromJSONTyped(json: any, ignoreDiscriminator: boolean): OutputsForAllTask {
    if (json == null) {
        return json;
    }
    return {
        
        'outputId': json['outputId'],
        'task': TaskForOutputsForAllTasksFromJSON(json['task']),
        'author': OutputAuthorForOutputsForAllTasksFromJSON(json['author']),
        'date': json['date'],
        'link': json['link'],
        'note': json['note'] == null ? undefined : json['note'],
        'outputFiles': json['outputFiles'] == null ? undefined : json['outputFiles'],
        'status': json['status'],
        'type': json['type'],
    };
}

export function OutputsForAllTaskToJSON(value?: OutputsForAllTask | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'outputId': value['outputId'],
        'task': TaskForOutputsForAllTasksToJSON(value['task']),
        'author': OutputAuthorForOutputsForAllTasksToJSON(value['author']),
        'date': value['date'],
        'link': value['link'],
        'note': value['note'],
        'outputFiles': value['outputFiles'],
        'status': value['status'],
        'type': value['type'],
    };
}

