import React, { useState, useRef } from "react";
// @ts-ignore
import UniSwaTextField from "../atoms/UniSwaTextField.tsx";
import "../../components/styles/UniSwaTextField.css";
import "../../components/styles/CustomRadio.css";
// @ts-ignore
import { Erc } from "../../types/dao/index.ts";
// @ts-ignore
import UnyteButton from "../atoms/UnyteButton.tsx";
import { ImCamera } from "react-icons/im";
import { useIntl, FormattedMessage } from "react-intl";
import { cpSync } from "fs";

interface Props {
  openSeaUrl: string;
  setOpenSeaUrl: React.Dispatch<React.SetStateAction<string>>;
  membershipNFTName: string;
  setMembershipNFTName: React.Dispatch<React.SetStateAction<string>>;
  isCreateNewToken: boolean | null;
  setIsCreateNewToken: React.Dispatch<React.SetStateAction<boolean | null>>;
  membershipNFTDescription: string;
  setMembershipNFTDescription: React.Dispatch<React.SetStateAction<string>>;
  membershipNFTImage: any;
  setMembershipNFTImage: React.Dispatch<React.SetStateAction<any>>;
  membershipNFTImageBase64: any;
  setMembershipNFTImageBase64: React.Dispatch<React.SetStateAction<any>>;
  erc: Erc;
  setErc: React.Dispatch<React.SetStateAction<Erc>>;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const CreateDAOPage: React.FC<Props> = ({
  openSeaUrl,
  setOpenSeaUrl,
  membershipNFTImage,
  setMembershipNFTImage,
  isCreateNewToken,
  setIsCreateNewToken,
  membershipNFTName,
  setMembershipNFTName,
  membershipNFTDescription,
  setMembershipNFTDescription,
  membershipNFTImageBase64,
  setMembershipNFTImageBase64,
  erc,
  setErc,
  isOpen,
  setIsOpen,
}) => {
  const intl = useIntl();

  const handleRadioChange = (event) => {
    console.log(event.target.value === "true");
    setIsOpen(event.target.value === "true");
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setMembershipNFTImage(event.target?.result);
        const base64 = reader.result.split(",")[1];
        console.log(base64);
        setMembershipNFTImageBase64(base64);
      };
      reader.readAsDataURL(file);
    }
  };
  return (
    <div className="mx-auto flex w-11/12 flex-col">
      <div className="text-center text-sm text-white">
        <FormattedMessage
          id="step_select"
          defaultMessage="どれか一つを選択してください"
        />
      </div>
      <div className="pb-8 pt-4">
        <div className="space-y-4">
          {/* ラジオボタンのオプション1 */}
          <div className="flex w-full text-sm">
            <div className="mx-auto space-x-12">
              <UnyteButton
                name={
                  <FormattedMessage
                    id="step2_new_create"
                    defaultMessage="新規発行"
                  />
                }
                onClick={() => setIsCreateNewToken(true)}
                buttonValue={true}
                status={isCreateNewToken}
              />
              <UnyteButton
                name={
                  <FormattedMessage
                    id="step2_reuse"
                    defaultMessage="既存のNFTを使用"
                  />
                }
                onClick={() => setIsCreateNewToken(false)}
                buttonValue={false}
                status={isCreateNewToken}
              />
            </div>
          </div>
        </div>

        {/* 選択内容に応じた表示切り替え */}
        <div className="mt-5 text-sm">
          <div className="space-between flex w-full">
            <div className="m-auto flex space-x-40">
              <div
                className={`border-b-[20px] border-r-[15px] border-r-transparent ${
                  isCreateNewToken === true
                    ? "border-b-[#29282E]"
                    : "border-b-[#131218]"
                } border-l-[15px] border-l-transparent`}
              ></div>
              <div
                className={`border-b-[20px] border-r-[15px] border-r-transparent ${
                  isCreateNewToken === false
                    ? "border-b-[#29282E]"
                    : "border-b-[#131218]"
                } border-l-[15px] border-l-transparent`}
              ></div>
            </div>
          </div>

          {isCreateNewToken === true && (
            <div className="space-between flex rounded-lg bg-[#29282E] px-12 pb-5 pt-9">
              <div className="mr-4 w-3/12">
                <label
                  htmlFor="profileImage"
                  className="m-auto flex h-32 w-32 items-center justify-center rounded-full bg-[#131218] text-white hover:cursor-pointer"
                >
                  <input
                    type="file"
                    className="hidden bg-gray-50"
                    id="profileImage"
                    onChange={handleImageChange}
                  />
                  {!membershipNFTImage ? (
                    <div className="flex flex-col">
                      <ImCamera className="mx-auto mb-2 text-center text-5xl text-[#29282E]" />
                      <div className="text-center text-sm text-[#8B8B8C] hover:cursor-pointer">
                        <div>
                          <FormattedMessage
                            id="select_file"
                            defaultMessage="ファイルを"
                          />
                        </div>
                        <div>
                          <FormattedMessage
                            id="select_file2"
                            defaultMessage="選択する"
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <img
                      src={membershipNFTImage}
                      alt="Profile"
                      className="h-32 w-32 cursor-pointer rounded-full"
                    />
                  )}
                </label>
              </div>
              <div className="w-9/12">
                <div className="space-y-2">
                  <div className="mb-5">
                    <div className="flex w-full">
                      <div className="ml-auto mr-6">
                        <UnyteButton
                          name="ERC1155"
                          onClick={() => setErc("erc1155")}
                          buttonValue={"erc1155"}
                          status={erc}
                        />
                      </div>
                      <div className="relative ml-6 mr-auto">
                        {/* ここから 未実装時の臨時対応 ikisuke */}
                        <div className="absolute flex h-[56px] w-44 items-center justify-center">
                          <p className="-rotate-[10deg] transform text-white">
                            Coming soon...
                          </p>
                        </div>
                        {/* ここまで */}
                        <UnyteButton
                          name="ERC721"
                          onClick={() => setErc("erc721")}
                          buttonValue={"erc721"}
                          status={erc}
                          disabled={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="w-full">
                    <div className="mb-2 mt-4 w-full">
                      <UniSwaTextField
                        placeholder={intl.formatMessage({
                          id: "step2_name_placeholder",
                          defaultMessage: "会員権の名前を入力してください。",
                        })}
                        isCreateDao={true}
                        addClass="!bg-[#131218] !border-[#131218]"
                        value={membershipNFTName}
                        onChange={(e) => setMembershipNFTName(e.target.value)}
                      />
                    </div>
                    <div className="w-full">
                      <div className="mb-2 w-full">
                        <UniSwaTextField
                          placeholder={intl.formatMessage({
                            id: "step2_description_placeholder",
                            defaultMessage: "会員権の説明を入力してください。",
                          })}
                          isCreateDao={true}
                          addClass="!bg-[#131218] !border-[#131218]"
                          value={membershipNFTDescription}
                          onChange={(e) =>
                            setMembershipNFTDescription(e.target.value)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mx-auto w-10/12">
                  <div className="mt-3 w-full space-x-4">
                    <label htmlFor="option1" className="text-white">
                      <input
                        type="radio"
                        id="option1"
                        name="options"
                        className="custom-radio"
                        onChange={handleRadioChange}
                        value={false}
                        checked={!isOpen}
                      />
                      <span className="radio-text relative flex items-center">
                        <FormattedMessage
                          id="step2_option_1"
                          defaultMessage="発行したNFTの入手を参加条件とする"
                        />
                      </span>
                    </label>
                  </div>
                  <div className="mt-2 w-full space-x-4">
                    <label htmlFor="option2" className="text-white">
                      <input
                        type="radio"
                        id="option2"
                        name="options"
                        className="custom-radio"
                        onChange={handleRadioChange}
                        value={true}
                        checked={isOpen}
                      />
                      <span className="radio-text relative flex items-center">
                        <FormattedMessage
                          id="step2_option_2"
                          defaultMessage="誰でも参加できる"
                        />
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          )}
          {isCreateNewToken === false && (
            <div className="mx-auto w-[600px] rounded-lg bg-[#29282E] pb-5 pt-10">
              <div className="mx-auto w-5/6">
                <UniSwaTextField
                  placeholder={intl.formatMessage({
                    id: "step2_reuse_placeholder",
                    defaultMessage:
                      "OpenseaのCollection URLを入力してください。",
                  })}
                  isCreateDao={true}
                  value={openSeaUrl}
                  addClass="!bg-[#131218] !border-[#131218]"
                  onChange={(e) => setOpenSeaUrl(e.target.value)}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CreateDAOPage;
