import React from "react";
import clsx from "clsx";
import { BaseProps } from "../../../../types/common/index.ts";
import { ItemBuyButton } from "./ItemBuyButton/ItemBuyButton.tsx";
import { CloseButton } from "../CloseButton/CloseButton.tsx";
import { OutsideModal } from "../OutsideModal/OutsideModal.tsx";

export type ItemConfirmBuyModalProps = {
  title: string;
  isOpenModal: boolean;
  setIsOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  setIsBuyFlag: React.Dispatch<React.SetStateAction<boolean>>;
} & BaseProps;

export const ItemConfirmBuyModal: React.FC<ItemConfirmBuyModalProps> = ({
  className,
  title,
  isOpenModal,
  setIsOpenModal,
  setIsBuyFlag,
}) => {
  const closeModal = () => {
    setIsOpenModal(false);
  };

  if (!isOpenModal) return <></>;
  return (
    <div
      className={clsx(
        className,
        "fixed inset-0",
        "z-40",
        "flex items-center justify-center",
        "h-screen"
      )}
    >
      <OutsideModal onClick={closeModal} />
      <div
        className={clsx(
          "bg-[#28282D] bg-unyte",
          "text-white",
          "rounded-lg",
          "p-px",
          "w-[90%] max-w-lg",
          "shadow-xl",
          "transform transition-all",
          "flex items-center justify-center"
        )}
      >
        <div
          className={clsx(
            "h-full w-full",
            "bg-[#28282D]",
            "px-8 py-8",
            "rounded-lg",
            "flex flex-col items-center justify-center",
            "relative"
          )}
        >
          <CloseButton
            className={clsx("absolute right-4 top-2")}
            onClick={closeModal}
          />
          <div className={clsx("text-xl font-bold", "mb-8")}>
            {title}を購入しますか？
          </div>
          <ItemBuyButton
            setIsBuyFlag={setIsBuyFlag}
            setIsOpenModal={setIsOpenModal}
          />
        </div>
      </div>
    </div>
  );
};
