import React from "react";
import clsx from "clsx";
import { BaseProps } from "../../types/common/index.ts";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  styled,
} from "@mui/material";

const CancelButton = styled(Button)(({ theme }) => ({
  borderRadius: "9999px",
  backgroundColor: "rgb(156 163 175)",
  "&:hover": {
    backgroundColor: "rgb(107 114 128)",
  },
  "&:active": {
    backgroundColor: "rgb(75 85 99)",
  },
}));

const OkButton = styled(Button)(({ theme }) => ({
  borderRadius: "9999px",
  background: "linear-gradient(to right, #EA7B57, #DF147F)",
  "&:hover": {
    background: "linear-gradient(to right, #C26346, #B11165)",
  },
  "&:active": {
    background: "linear-gradient(to right, #AC5840, #9A0F58)",
  },
}));

export type ConfirmModalProps = {
  title: string;
  onOk: () => Promise<void>;
  onCancel: () => Promise<void>;
  okText?: string;
  cancelText?: string;
} & BaseProps;
const ConfirmModal = ({
  title,
  onOk,
  onCancel,
  okText = "OK",
  cancelText = "Cancel",
}) => {
  return (
    <>
      <Dialog
        PaperProps={{
          className: clsx("bg-unyte p-0.5 rounded-lg"),
        }}
        open={true}
      >
        <div className="h-full w-full rounded-sm bg-unyte-sub p-1 lg:p-4">
          <DialogContent>
            <div className={clsx("text-center")}>
              <p className={clsx("text-xl font-bold text-white")}>{title}</p>
            </div>
          </DialogContent>
          <DialogActions
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <CancelButton
              onClick={onCancel}
              className={clsx("p-2", "rounded-full", "text-white", "w-1/3")}
            >
              <p className="text-white">{cancelText}</p>
            </CancelButton>
            <OkButton
              className={clsx("p-2", "rounded-full", "text-white", "w-1/3")}
              onClick={onOk}
            >
              <p className="text-white">{okText}</p>
            </OkButton>
          </DialogActions>
        </div>
      </Dialog>
    </>
  );
};

export default ConfirmModal;
