import React from "react";
import clsx from "clsx";
import { BaseProps } from "../../../../types/common";

export type ItemTitleProps = {
  title: string;
} & BaseProps;

export const ItemTitle: React.FC<ItemTitleProps> = ({ className, title }) => {
  return (
    <h5
      className={clsx(
        className,
        "tracking-tight",
        "text-lg font-bold text-gray-900 lg:text-xl",
        "dark:text-white"
      )}
    >
      {title}
    </h5>
  );
};
