/* tslint:disable */
/* eslint-disable */
/**
 * Unyte SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateThanksRequest
 */
export interface CreateThanksRequest {
    /**
     * Thanksを送付するDAOのID
     * @type {string}
     * @memberof CreateThanksRequest
     */
    daoId: string;
    /**
     * Thanksで送付するメッセージ(200文字以内)
     * @type {string}
     * @memberof CreateThanksRequest
     */
    message: string;
    /**
     * Thanksを送付するユーザーのアドレス
     * @type {string}
     * @memberof CreateThanksRequest
     */
    from: string;
    /**
     * Thanksを受け取るユーザーのアドレス
     * @type {string}
     * @memberof CreateThanksRequest
     */
    to: string;
    /**
     * Thanksで送付するトークンの量
     * @type {number}
     * @memberof CreateThanksRequest
     */
    amount?: number;
}

/**
 * Check if a given object implements the CreateThanksRequest interface.
 */
export function instanceOfCreateThanksRequest(value: object): boolean {
    if (!('daoId' in value)) return false;
    if (!('message' in value)) return false;
    if (!('from' in value)) return false;
    if (!('to' in value)) return false;
    return true;
}

export function CreateThanksRequestFromJSON(json: any): CreateThanksRequest {
    return CreateThanksRequestFromJSONTyped(json, false);
}

export function CreateThanksRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateThanksRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'daoId': json['daoId'],
        'message': json['message'],
        'from': json['from'],
        'to': json['to'],
        'amount': json['amount'] == null ? undefined : json['amount'],
    };
}

export function CreateThanksRequestToJSON(value?: CreateThanksRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'daoId': value['daoId'],
        'message': value['message'],
        'from': value['from'],
        'to': value['to'],
        'amount': value['amount'],
    };
}

