import React from "react";
import clsx from "clsx";
import { useAtom } from "jotai";
import { ItemTextInput } from "../ItemTextInput.tsx";
import {
  tmpAddAndEditItemState,
  tmpItemState,
} from "../../../../../stores/tmpItemState.ts";
import { BaseProps } from "../../../../../types/common/index.ts";

export type ItemNameInputProps = {} & BaseProps;

export const ItemNameInput: React.FC<ItemNameInputProps> = ({ className }) => {
  const [tmpItem, setTmpItem] = useAtom(tmpAddAndEditItemState);
  const name = tmpItem.name;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTmpItem((prev) => {
      return { ...prev, name: e.target.value };
    });
  };

  return (
    <ItemTextInput
      className={clsx(className)}
      msgId="item_name"
      defaultMessage="アイテム名"
      value={name}
      onChange={handleChange}
    />
  );
};
