import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import MonoStackedBar from "../atoms/MonoStackedBar.tsx";
import { Progress } from "../../types/common/index.ts";
import Loading from "../common/Loading.tsx";
import { getSocialIcon } from "../../utils/getSocialIcon.tsx";
import { useDaoDetail } from "src/hooks/useDaoDetail.ts";
import { formatName } from "src/utils/formatAddress.ts";
import { ANONYMOUS_ICON } from "src/const/const.ts";

interface Props {
  daoId: string;
}

const Main: React.FC<Props> = ({ daoId }) => {
  const [inProgress, setInProgress] = useState<Progress>({
    isShow: true,
    message: "読み込み中です",
  });
  const {
    daoDetail,
    isError: isDaoDetailError,
    isLoading: isDaoDetailLoading,
  } = useDaoDetail(daoId);

  useEffect(() => {
    if (isDaoDetailLoading) {
      setInProgress({ isShow: true, message: "Loading..." });
      return;
    }
    setInProgress({ isShow: false, message: "" });
  }, [isDaoDetailLoading]);
  if (isDaoDetailLoading) return <Loading {...inProgress} />;
  if (isDaoDetailError || !daoDetail) return <></>;
  daoDetail.members.sort((a, b) => {
    return b.contributionToken - a.contributionToken;
  });

  return (
    <div className="mt-8 lg:mt-0">
      <div className="w-full rounded-lg bg-[#131218] p-px text-left text-white">
        <div className="h-full w-full rounded-lg bg-[#131218] px-4 pt-4 lg:pt-14">
          <div className="mx-auto w-11/12">
            <div className="mb-4 block text-2xl">
              <h1>{daoDetail.name}</h1>
            </div>
            <div className="lg:pl-4">
              <div className="text-xl text-white">
                <div className="mb-2 mt-8">DASH BOARD</div>
                <div className="h-96 w-full overflow-y-scroll bg-black pl-6 lg:mb-16 lg:h-40">
                  <div>
                    <Loading {...inProgress} />
                  </div>
                  {daoDetail.members &&
                    !inProgress.isShow &&
                    daoDetail.members.map((user, index) => {
                      if (user.contributionToken === 0)
                        return <React.Fragment key={index}></React.Fragment>;
                      if (user.address === daoDetail.admins[0])
                        return <React.Fragment key={index}></React.Fragment>;
                      return (
                        <div
                          key={index}
                          className="dash-board-wrapper mb-4 mt-2 flex w-full flex-col lg:mb-0 lg:mt-4 lg:flex-row"
                        >
                          <div className="user-wrapper space-between mb-2 mr-auto flex w-full lg:mb-0 lg:w-2/6">
                            <div className="user-icon mr-auto mt-0.5 h-5 w-1/12 w-5 rounded-full bg-white lg:my-auto lg:ml-auto">
                              <img
                                src={user.img}
                                className="h-5 w-5 rounded-full"
                                onError={(e) => {
                                  (e.target as HTMLImageElement).onerror = null;
                                  (e.target as HTMLImageElement).src =
                                    ANONYMOUS_ICON;
                                }}
                              />
                            </div>
                            <div className="user-name ml-4 w-11/12 overflow-hidden">
                              <div className="w-5/6 overflow-hidden text-ellipsis">
                                <p className="text-base">
                                  {formatName(user.name, 20)}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="token-wrapper flex w-full flex-col lg:w-4/6 lg:flex-row">
                            <div className="token-point w-full text-sm lg:w-2/12 lg:text-lg">
                              {user.contributionToken} トークン
                            </div>
                            <div className="token-graph w-11/12 lg:w-9/12">
                              <MonoStackedBar
                                data={[
                                  {
                                    value: user.contributionToken,
                                    color: "#E5007E",
                                  },
                                ]}
                                height={20}
                                width={
                                  (Math.log10(user.contributionToken + 1) /
                                    Math.log10(
                                      daoDetail.members[0].contributionToken
                                    )) *
                                  100
                                }
                              />
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
              <div className="flex flex-col">
                <div className="mr-3 mr-8 mt-4 w-full w-full lg:mt-0">
                  <h3 className="mt-4 text-xl font-medium leading-6 text-gray-900 text-white">
                    DAO概要
                  </h3>
                </div>
                <div className="w-full lg:w-8/12">
                  <div className="mb-2 mt-4">
                    <p className="text-md text-gray-500 text-white">
                      {daoDetail.description}
                    </p>
                  </div>

                  <div className="text-xs">
                    {daoDetail.links && daoDetail.links.length > 0 && (
                      <div className="mb-1">
                        <p className="font-extralight">
                          <FormattedMessage
                            id="dao_detail_social"
                            defaultMessage="ソーシャルアカウント"
                          />
                        </p>
                      </div>
                    )}
                    <div className="mt-4 flex w-full justify-start gap-x-4 font-extralight">
                      {/* ソーシャルアカウントを3つ横に並べる*/}
                      {daoDetail.links &&
                        daoDetail.links
                          .filter((link) => link)
                          .map((link, idx) => {
                            return (
                              <div key={idx}>
                                <div className="group relative pl-2">
                                  <a
                                    href={link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {getSocialIcon(link)}
                                  </a>
                                  <span
                                    className="invisible absolute top-6 rounded 
                      bg-slate-600 text-[12px] font-bold text-white opacity-0 opacity-100 group-hover:visible "
                                  >
                                    {link}
                                  </span>
                                </div>
                              </div>
                            );
                          })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
