/* tslint:disable */
/* eslint-disable */
/**
 * Unyte SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateThanksResponse
 */
export interface CreateThanksResponse {
    /**
     * Thanks ID
     * @type {string}
     * @memberof CreateThanksResponse
     */
    thanksId: string;
    /**
     * Transaction Hash
     * @type {string}
     * @memberof CreateThanksResponse
     */
    txHash: string;
}

/**
 * Check if a given object implements the CreateThanksResponse interface.
 */
export function instanceOfCreateThanksResponse(value: object): boolean {
    if (!('thanksId' in value)) return false;
    if (!('txHash' in value)) return false;
    return true;
}

export function CreateThanksResponseFromJSON(json: any): CreateThanksResponse {
    return CreateThanksResponseFromJSONTyped(json, false);
}

export function CreateThanksResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateThanksResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'thanksId': json['thanksId'],
        'txHash': json['txHash'],
    };
}

export function CreateThanksResponseToJSON(value?: CreateThanksResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'thanksId': value['thanksId'],
        'txHash': value['txHash'],
    };
}

