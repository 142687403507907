import React from "react";
// @ts-ignore
import { Progress } from "../../types/common/index.ts";

const Loading = (props: Progress) => {
  return (
    <div
      className={`z-50 h-screen ${
        !props.isShow ? "hidden" : ""
      } fixed inset-0 flex max-h-screen min-h-screen items-center justify-center`}
      aria-label="読み込み中"
    >
      <div className="flex items-center justify-center backdrop-blur-sm backdrop-filter">
        <div className="h-9 w-9 animate-spin rounded-full border-4 border-blue-400 border-t-transparent" />
        <div className="m-2 text-xl font-semibold text-gray-50">
          {props.message}
        </div>
      </div>
    </div>
  );
};

export default Loading;
