import {
  doc,
  collection,
  query,
  addDoc,
  DocumentReference,
  Timestamp,
  onSnapshot,
  orderBy,
  limit,
  DocumentData,
  QueryDocumentSnapshot,
  SnapshotOptions,
  getDoc,
  runTransaction,
  Unsubscribe,
} from "firebase/firestore";
import { ref, uploadBytes, getBlob, deleteObject } from "firebase/storage";
import { fireauth, firebaseFirestore, firebaseStorage } from "./config";
import { useEffect, useRef, useState } from "react";
import { fetchUsers } from "./user.ts";
import { getImageDimensions, resizeImage } from "../../utils/image.ts";

interface Reaction {
  type: string;
  users: {
    userRef: DocumentReference;
    createdAt: Date;
  }[];
}

interface Message {
  id?: string;
  postedUserRef: DocumentReference;
  text: string;
  createdAt: Date;
  updatedAt: Date;
  filePaths: string[];
  reactions: Reaction[];
}

export const messageConverter = {
  toFirestore(message: Message): DocumentData {
    return {
      postedUserRef: message.postedUserRef,
      text: message.text,
      createdAt: Timestamp.fromDate(message.createdAt),
      updatedAt: Timestamp.fromDate(message.updatedAt),
      filePaths: message.filePaths,
    };
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): Message {
    const data = snapshot.data(options);

    const reactions: Reaction[] = [];
    if (data.reactions) {
      data.reactions.map((reaction) => {
        const users = reaction.users.map(
          (user: { userRef: DocumentReference; createdAt: Timestamp }) => {
            return {
              userRef: user.userRef,
              createdAt: user.createdAt.toDate(),
            };
          }
        );
        reactions.push({
          type: reaction.type,
          users: users,
        });
      });
    }

    return {
      id: snapshot.id,
      postedUserRef: data.postedUserRef,
      text: data.text,
      createdAt: data.createdAt.toDate(),
      updatedAt: data.updatedAt
        ? data.updatedAt.toDate()
        : data.createdAt.toDate(),
      filePaths: data.filePaths || [],
      reactions: reactions,
    };
  },
};

export function messageCollection(daoId: string, channelId: string) {
  return collection(
    firebaseFirestore,
    "workspaces",
    daoId,
    "channels",
    channelId,
    "messages"
  );
}

function messageRef(daoId: string, channelId: string, messageId: string) {
  return doc(
    firebaseFirestore,
    "workspaces",
    daoId,
    "channels",
    channelId,
    "messages",
    messageId
  );
}

function createStoragesFilePath(
  daoId: string,
  channelId: string,
  name: string
) {
  // ファイル名が重複し、意図しない更新が発生しないようにuuidをprefixにつける
  return `filesAttachedToMessages/${daoId}/${channelId}/${crypto.randomUUID()}-${name}`;
}

interface SomeFile {
  name: string;
  url: string;
}

// JSXで扱いやすくするための型

export interface ViewReaction {
  type: string;
  users: {
    uid: string;
    userName: string;
    createdAt: Date;
  }[];
}

export interface ViewMessage {
  id: string;
  uid: string;
  userName: string;
  userImageUrl: string;
  text: string;
  createdAt: Date;
  updatedAt: Date;
  imageUrls: string[];
  someFiles: SomeFile[];
  reactions: ViewReaction[];
}

const pageSize = 30;

async function fetchMessageFiles(
  messages: Message[]
): Promise<Map<string, File>> {
  const filePaths = messages.map((m) => m.filePaths).flat();
  const filePromises = filePaths.map(async (filePath) => {
    const fileRef = ref(firebaseStorage, filePath);
    const blob = await getBlob(fileRef);
    return new File([blob], filePath.split("/").pop()!, { type: blob.type });
  });
  const files = await Promise.all(filePromises);
  const map = new Map<string, File>();
  files.forEach((file) => {
    map.set(file.name, file);
  });
  return map;
}

async function fetchViewMessages(messages: Message[]): Promise<ViewMessage[]> {
  const filesPromise = fetchMessageFiles(messages);

  // 表示ユーザー情報の取得。メッセージ投稿ユーザーと、メッセージにリアクションしたユーザーの情報を取得する
  const fetchUserIds = new Set(messages.map((m) => m.postedUserRef.id));
  messages.forEach((message) => {
    message.reactions.forEach((reaction) => {
      reaction.users.forEach((user) => fetchUserIds.add(user.userRef.id));
    });
  });
  const usersPromise = fetchUsers(Array.from(fetchUserIds));

  const [files, users] = await Promise.all([filesPromise, usersPromise]);

  return messages.map((m) => {
    const user = users.get(m.postedUserRef.id);

    const imageUrls: string[] = [];
    const someFiles: SomeFile[] = [];
    m.filePaths.forEach((filepath) => {
      const fileName = filepath.split("/").pop()!;
      const file = files.get(fileName);
      if (!file) return;

      const url = URL.createObjectURL(file);
      if (file.type.startsWith("image/")) {
        imageUrls.push(url);
      } else {
        someFiles.push({
          name: file.name,
          url: url,
        });
      }
    });

    const reactions: ViewReaction[] = m.reactions.map((reaction) => {
      return {
        type: reaction.type,
        users: reaction.users.map((user) => {
          return {
            uid: user.userRef.id,
            userName: users.get(user.userRef.id)?.name || "",
            createdAt: user.createdAt,
          };
        }),
      };
    });

    return {
      id: m.id!,
      uid: m.postedUserRef.id,
      userName: user?.name!,
      userImageUrl: user?.img!,
      text: m.text,
      createdAt: m.createdAt,
      updatedAt: m.updatedAt,
      imageUrls: imageUrls,
      someFiles: someFiles,
      reactions: reactions,
    };
  });
}

export function useMessages(
  daoId: string,
  channelId: string
): [ViewMessage[], () => void, boolean] {
  const [messages, setMessages] = useState<ViewMessage[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const listner = useRef<Unsubscribe | null>(null);

  const listen = (queryLimit: number) => {
    if (listner.current) listner.current();

    const q = query(
      messageCollection(daoId, channelId),
      orderBy("createdAt", "desc"),
      limit(queryLimit)
    ).withConverter(messageConverter);

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const addedOldMessages: Message[] = []; // 次のページを追加した際に取得される古いメッセージ群
      const addedNewMessages: Message[] = []; // 新しく書き込まれたメッセージ群
      const removedIds = new Set<string>(); // 削除されたメッセージのid群
      const modifiedMessages: Message[] = []; // 変更があったメッセージ群

      snapshot.docChanges().forEach((change) => {
        const data = change.doc.data();
        const alreadyFetchedMessageIds = new Set(messages.map((m) => m.id));
        switch (change.type) {
          case "added":
            if (alreadyFetchedMessageIds.has(change.doc.id)) break;
            if (
              messages.length > 0 &&
              data.createdAt < messages[messages.length - 1].createdAt
            ) {
              addedOldMessages.push(data);
            } else {
              addedNewMessages.push(data);
            }
            break;
          case "removed":
            removedIds.add(change.doc.id);
            break;
          case "modified":
            modifiedMessages.push(data);
            break;
        }
      });

      const addedOldViewMessagesPromise = fetchViewMessages(addedOldMessages);
      const addedNewViewMessagesPromise = fetchViewMessages(addedNewMessages);
      const modifiedViewMessagesPromise = fetchViewMessages(modifiedMessages);

      Promise.all([
        addedOldViewMessagesPromise,
        addedNewViewMessagesPromise,
        modifiedViewMessagesPromise,
      ]).then(([addedOld, addedNew, modified]) => {
        setMessages((olds) => {
          const removed = olds.filter((m) => !removedIds.has(m.id));
          const updated = removed.map((m) => {
            const mod = modified.find((mod) => mod.id === m.id);
            return mod ? mod : m;
          });
          let messages = [...updated, ...addedNew, ...addedOld];
          // messagesをcreatedAtの降順にソート
          messages = messages.sort((a, b) => {
            return b.createdAt.getTime() - a.createdAt.getTime();
          });
          return messages;
        });
        setIsLoading(false);
      });
    });
    listner.current = unsubscribe;
  };

  // メッセージの購読設定
  useEffect(() => {
    setMessages([]);
    setIsLoading(true);
    listen(pageSize);

    return () => {
      if (listner.current) listner.current();
    };
  }, [daoId, channelId]);

  // 次のmessage群を取得し、追加
  function addNextPage() {
    listen(messages.length + pageSize);
  }

  return [messages, addNextPage, isLoading];
}

export async function submitMessage(
  daoId: string,
  channelId: string,
  text: string,
  files: File[]
) {
  if (!fireauth.currentUser) {
    throw Error("not found auth");
  }
  const resizeFiles = await Promise.all(
    files.map(async (file) => {
      if (file.type.startsWith("image/")) {
        try {
          const dimensions = await getImageDimensions(file);
          const image = await resizeImage(file, dimensions);
          // base64形式のimageをfileに変換
          const blob = await fetch(image).then((r) => r.blob());

          return new File([blob], file.name, { type: blob.type });
          // ここで画像をリサイズおよびアップロードする処理を追加
        } catch (error) {
          console.error("画像のサイズを取得できませんでした。", error);
        }
      } else {
        return file;
      }
    })
  );

  const filePathsPromise = resizeFiles.map(async (file) => {
    const path = createStoragesFilePath(daoId, channelId, file!.name);
    const fileRef = ref(firebaseStorage, path);
    await uploadBytes(fileRef, file!);
    return fileRef.fullPath;
  });

  const col = messageCollection(daoId, channelId).withConverter(
    messageConverter
  );
  const createdAt = new Date(Date.now());

  const filePaths = await Promise.all(filePathsPromise);
  const message: Message = {
    postedUserRef: doc(firebaseFirestore, "users", fireauth.currentUser.uid),
    text: text,
    createdAt: createdAt,
    updatedAt: createdAt,
    filePaths: filePaths,
    reactions: [],
  };
  const docRef = await addDoc(col, message);
  return docRef.id;
  // 追加したメッセージのidを返す
}

export async function editMessage(
  daoId: string,
  channelId: string,
  messageId: string,
  text: string,
  addFiles: File[],
  deleteFilePath: string[]
) {
  if (!fireauth.currentUser) {
    throw Error("not found auth");
  }

  const mref = messageRef(daoId, channelId, messageId);
  const doc = await getDoc(mref.withConverter(messageConverter));
  const message = doc.data();
  if (!message) {
    throw new Error("not found message");
  }
  /**
   * @date 2023-11-17
   * 編集で新規ファイルのアップデートは一旦行わないようにする
   * @author ikisuke
   */

  // const deletedFilePaths = message.filePaths.filter(filePath => {
  //   return deleteFilePath.includes(filePath)
  // })

  // const addFilePaths = addFiles.map((file) => {
  //   const path = createStoragesFilePath(daoId, channelId, file.name)
  //   const fileRef = ref(firebaseStorage, path)
  //   return fileRef.fullPath
  // })

  // const filePaths = Array.from(new Set([...deletedFilePaths, ...addFilePaths]))

  await runTransaction(firebaseFirestore, async (transaction) => {
    transaction.update(mref, {
      id: messageId,
      postedUserRef: message.postedUserRef,
      text: text,
      createdAt: message.createdAt,
      updatedAt: new Date(Date.now()),
    });

    /**
     * @date 2023-11-17
     * 編集で新規ファイルのアップデートは一旦行わないようにする
     * @author ikisuke
     */
    // 新規ファイルのアップロード
    // for (const file of addFiles) {
    //   const path = createStoragesFilePath(daoId, channelId, file.name)
    //   const fileRef = ref(firebaseStorage, path)
    //   await uploadBytes(fileRef, file)
    // }
    // ファイルの削除
    for (const filePath of deleteFilePath) {
      const fileRef = ref(firebaseStorage, filePath);
      await deleteObject(fileRef);
    }
  });
}

export function deleteMessage(
  daoId: string,
  channelId: string,
  messageId: string
) {
  if (!fireauth.currentUser) {
    throw Error("not found auth");
  }

  runTransaction(firebaseFirestore, async (transaction) => {
    const mref = messageRef(daoId, channelId, messageId);
    const doc = await transaction.get(mref.withConverter(messageConverter));
    const message = doc.data();
    if (!message) {
      throw new Error("not found message");
    }

    transaction.delete(mref);

    // ファイルの削除
    for (const filePath of message.filePaths) {
      const fileRef = ref(firebaseStorage, filePath);
      await deleteObject(fileRef);
    }
  });
}
