import React, { useRef, useState, useEffect } from "react";
import { submitMessage } from "src/actions/firebase/message.ts";
import { FaCirclePlus } from "react-icons/fa6";
import { AiOutlineSend } from "react-icons/ai";
import { MdCancel } from "react-icons/md";
import pdfIcon from "src/assets/pdf.svg";
import textIcon from "src/assets/text.svg";
import videoIcon from "src/assets/video.svg";
import userIcon from "src/assets/userIcon.svg";
import { createEventNotification } from "src/actions/firebase/notification.ts";
import { NotificationEvent } from "src/types/notification/index.ts";
import { EVERYONE_MENTION } from "src/const/const.ts";
import { useDaoDetail } from "src/hooks/useDaoDetail.ts";
import { useUser } from "src/hooks/useUser";

interface InputFile {
  file: File;
  thumbnail: string;
}

interface mentionedUser {
  name: string;
  address: string;
}
const Input = ({ daoId, channelId }: { daoId: string; channelId: string }) => {
  const [text, setText] = useState("");
  const [files, setFiles] = useState<InputFile[]>([]);
  const inputFileRef = useRef<HTMLInputElement>(null);
  const {
    daoDetail,
    isError: isDaoDetailError,
    isLoading: isDaoDetailLoading,
  } = useDaoDetail(daoId);
  const daoUsers = daoDetail?.members ?? [];
  const [isComposing, setIsComposing] = useState(false);
  const [mentionMode, setMentionMode] = useState(false);
  const [sortedUsers, setSortedUsers] = useState<any[]>([]);
  const [mentionedUsers, setMentionedUsers] = useState<mentionedUser[]>([]);
  const { user } = useUser();

  // NOTE: Function
  const onMentionableUserClickHandler = (user) => {
    if (text === "" || text === null) return;
    // メンション機能 文字の先頭が@、もしくは他の文字の後に空白と@がある場合該当する文字列を置き換える
    // また、<@>で囲まれた文字列は無視する
    // textからmentionedUsersのname部分を取得して、直前に@がある場合は、<@address>に置換する
    let tmpText = text;
    mentionedUsers.forEach((mentionedUser) => {
      tmpText = tmpText.replace(
        `@${mentionedUser.name}`,
        `<${mentionedUser.address}>`
      );
    });
    // @everyoneの場合の処理
    // <@address>部分を除き、文字の先頭が@、もしくは他の文字の後に空白と@がある場合該当する文字列を置き換える
    // また、<@>で囲まれた文字列は置き換えない
    if (!tmpText.match(/(^|\s)@/g)) return;
    let partialMatch = "";
    for (let i = 1; i <= user.name.length; i++) {
      const substring = user.name.slice(0, i);
      if (tmpText.toLowerCase().includes(`@${substring.toLowerCase()}`)) {
        partialMatch = substring;
      } else {
        break;
      }
    }
    const mention = tmpText.match(/@[^ ]*/)?.[0].trim(); // @mentionedUsersのnameをtextから除外する
    if (!mention) return;
    // <@address>の文字列に変換
    const mentionText = `@${user.name} `;
    tmpText = tmpText.replace(mention.toLowerCase(), mentionText);
    // tmpTextからuser
    mentionedUsers.forEach((user) => {
      tmpText = tmpText.replace(`<${user.address}>`, `@${user.name} `);
    });
    setText(tmpText);
    // mentionedUsersに追加

    setMentionedUsers((oldUsers) => [
      ...oldUsers,
      { name: user.name, address: user.address },
    ]);
  };
  const mentionCheck = (text: string) => {
    return text.match(/<@\w+>/g);
  };
  const onChangeFiles = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    // 追加されたファイルの最後を取得して、image以外で尚且つ5MBより大きいファイルがあれば、alertを表示
    const fileArray = Array.from(e.target.files);
    let overSizeFileNames: string[] = [];
    for (let file of fileArray) {
      if (!file.type.startsWith("image/") && file.size > 5 * 1024 * 1024)
        overSizeFileNames.push(file.name);
    }
    if (overSizeFileNames.length > 0) {
      alert(
        `以下のファイルは5MB以上のファイルのため、アップロードできませんでした。\n${overSizeFileNames.join(
          "\n"
        )}`
      );
      return;
    }

    // FileListをFile[]に変換
    const newFiles = await Promise.all(
      Array.from(e.target.files).map(async (file) => {
        // fileがpng, jpg, jpeg, gifの場合は、画像のimgタグのsrcに変換
        if (file.type.startsWith("image/")) {
          const thumbnail = await fileImageConverter(file);
          return { file, thumbnail };
        } else if (file.type.startsWith("video/")) {
          return { file, thumbnail: videoIcon };
        } else if (file.type.startsWith("application/pdf")) {
          return { file, thumbnail: pdfIcon };
        } else {
          return { file, thumbnail: textIcon };
        }
      })
    );

    setFiles((oldFiles) => {
      return [...oldFiles, ...newFiles].filter(
        (file, index, self) =>
          self.findIndex((f) => f.file.name === file.file.name) === index // 重複を削除
      );
    });
  };
  // Utility

  const inputChangeHandler = (event) => {
    // メンション機能 文字の先頭が@、もしくは他の文字の後に空白と@がある場合
    setText(event.target.value);
    let tmpText = event.target.value;
    mentionedUsers.forEach((user) => {
      tmpText = tmpText.replace(`@${user.name}`, `<@${user.address}>`);
    });
    if (tmpText.match(/(^|\s)@\S*/g)) {
      // <@address>部分を除き、文字の先頭が@、もしくは他の文字の後に空白と@がある場合該当する文字列を置き換える
      // また、<@>で囲まれた文字列は置き換えない
      const mention = tmpText.match(/(^|\s)@\S*/g)[0]?.trim();
      // mentionが<>で囲まれている場合は、returnを行う。先読みや後読みは、jsではサポートされていないため、<>で囲まれているかどうかを判定する
      if (mention.match(/<@(\w+)>/g)) return;

      // const mention = event.target.value.match(/(^|\s)@(\w+|$)/g)[0].trim();
      // mentionの@以降の文字列を取得して、daoUsersのnameに含まれているかどうかを判定
      const mentionName = mention.split("@")[1];
      if (mentionName === "") {
        setSortedUsers(daoUsers.slice(0, 8));
        setMentionMode(true);
        return;
      }
      const mentionUsers = daoUsers.filter((user) => {
        // user.nameにmentionNameが含まれているかどうかを判定
        // user.nameはstringなので、toLowerCase()で小文字に変換してから判定
        return (
          user.name &&
          user.name.toLowerCase().includes(mentionName.toLowerCase())
        );
      });
      if (mentionUsers.length === 0) {
        setSortedUsers([]);
        return;
      } else if (mentionUsers.length <= 8) {
        setSortedUsers(mentionUsers);
      } else {
        setSortedUsers(mentionUsers.slice(0, 8));
      }
      setMentionMode(true);
    } else {
      setMentionMode(false);
    }
  };

  // fileを画像のimgタグのsrcに変換
  const fileImageConverter = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        if (typeof event.target?.result === "string") {
          resolve(event.target?.result);
        } else {
          reject("Failed to read file");
        }
      };
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [text]);

  // TODO: UIから呼び出し
  const deleteFile = (index: number) => {
    setFiles((olds) => olds.filter((_, i) => i !== index));
  };

  const uploadImageHandler = () => {
    if (inputFileRef.current) {
      inputFileRef.current.click();
    }
  };

  const trimSpace = (str: string) => {
    // 行の先頭と末尾の全角と半角のスペースを削除
    // eslint-disable-next-line no-irregular-whitespace
    str = str.replace(/^[ 　]+|[ 　]+$/g, "");
    // 行の先頭と末尾の改行を削除
    str = str.replace(/^[\r\n]+|[\r\n]+$/g, "");
    // 最後が改行の場合、文字が出現するまで削除
    return str;
  };
  const displayText = (text: string) => {
    // <@0x...>で囲まれた文字列を置換
    const mention = text.match(/<@(\w+)>/g);
    if (mention) {
      mention.forEach((m) => {
        const address = m.match(/<@(\w+)>/)[1];
        let user = daoUsers.find((user) => user.address === address);
        if (EVERYONE_MENTION.address === address) {
          user = EVERYONE_MENTION;
        }
        if (user) {
          text = text.replace(m, `@${user.name}`);
        } else {
          text = text.replace(m, `@不明なユーザー`);
        }
      });
    }
    return text;
  };

  const onSubmit = async (e) => {
    let tmpText = trimSpace(text);
    if (
      (e.key !== "Enter" || e.shiftKey || isComposing) &&
      e.type !== "click"
    ) {
      return;
    }
    if (
      e.key === "Enter" &&
      ((!e.ctrlKey && e.metaKey) || (e.ctrlKey && !e.metaKey)) &&
      tmpText.length === 0
    ) {
      e.preventDefault();
      return;
    }
    if (
      !(
        e.key === "Enter" &&
        ((!e.ctrlKey && e.metaKey) || (e.ctrlKey && !e.metaKey))
      ) &&
      e.type !== "click"
    )
      return;
    // textを一時的に保存
    if (tmpText.length === 0 && files.length === 0) return;
    // mentionedUsersのnameがtextにそれぞれ含まれているか,含まれている場合、直前が@である場合は、<@address>に置換する
    mentionedUsers.forEach((user) => {
      if (tmpText.includes(user.name)) {
        const mention = `@${user.name}`;
        const mentionText = `<@${user.address}>`;
        tmpText = tmpText.replace(mention, mentionText);
      }
    });
    // 文頭が@もしくは、他の文字の後に空白と@がある場合、daoUsersのnameが/完全一致して、完全一致部分の直前が@である場合は、<@address>に置換する
    if (tmpText.match(/(^|\s)@(\w+)/g)) {
      console.log("mention");
      const mentions = tmpText.match(/(^|\s)@(\w+)/g);
      // const mention = tmpText.match(/(^|\s)@(\w+|$)/g)[0];
      if (!mentions) return;
      for (let mention of mentions) {
        // mentionが<>で囲まれている場合は、continueを行う。先読みや後読みは、jsではサポートされていないため、<>で囲まれているかどうかを判定する
        if (mention.match(/<@(\w+)>/g)) continue;
        const mentionName = mention.split("@")[1];
        const mentionUsers = daoUsers.filter((user) => {
          // user.nameにmentionNameが含まれているかどうかを判定
          // user.nameはstringなので、toLowerCase()で小文字に変換してから判定
          return (
            user.name &&
            user.name.toLowerCase().includes(mentionName.toLowerCase())
          );
        });
        if (mentionUsers.length > 0) {
          const mentionText = `@${mentionUsers[0].name}`;
          const mentionAddress = `<@${mentionUsers[0].address}> `;
          tmpText = tmpText.replace(mentionText, mentionAddress);
        }
      }
    }

    // mentionUsersが存在する場合、mentionUsersのaddressを取得し、<@address>に置換する

    // ShiftKey が押されているときは改行、日本語入力中は確定するのを防ぐ
    const submitFiles = files.map((file) => file.file);

    if (e.type === "click") {
      const result = submitMessage(daoId, channelId, tmpText, submitFiles);
      const mentions = mentionCheck(tmpText);
      if (mentions) {
        const daoUsersAddressExceptMe = daoUsers
          .map((user) => user.address)
          .filter((address) => address !== user?.address);
        result
          .then((messageId) => {
            const notificationEvent: NotificationEvent = {
              daoId: daoId,
              timestamp: Date.now(),
              eventType: "message",
              message: displayText(tmpText),
              from: user?.address ?? "",
              to: mentionCheckUserOrEveryone(mentions)
                ? daoUsersAddressExceptMe
                : mentions.map((mention) => mention.slice(2, -1)),
              data: {
                channelId: channelId,
                messageId: messageId,
              },
            };
            createEventNotification(notificationEvent);
          })
          .catch((e) => {
            console.error(e);
          });
      }

      // textareaRefを初期化
      setText("");
      setFiles([]);
      setMentionMode(false);
      setMentionedUsers([]);
      return;
    }

    e.preventDefault();
    const result = submitMessage(daoId, channelId, tmpText, submitFiles);
    const mentions = mentionCheck(tmpText);
    if (mentions) {
      const daoUsersAddressExceptMe = daoUsers
        .map((user) => user.address)
        .filter((address) => address !== user?.address);
      result
        .then((messageId) => {
          const notificationEvent: NotificationEvent = {
            daoId: daoId,
            timestamp: Date.now(),
            eventType: "message",
            message: displayText(tmpText),
            from: user?.address ?? "",
            to: mentionCheckUserOrEveryone(mentions)
              ? daoUsersAddressExceptMe
              : mentions.map((mention) => mention.slice(2, -1)),
            data: {
              channelId: channelId,
              messageId: messageId,
            },
          };
          createEventNotification(notificationEvent);
        })
        .catch((e) => {
          console.error(e);
        });
    }

    // textareaRefを初期化
    setText("");
    setFiles([]);
    setMentionMode(false);
    setMentionedUsers([]);
    return;
  };
  const mentionCheckUserOrEveryone = (mentions: string[]) => {
    return mentions.find(
      (address) => address.slice(2, -1) === EVERYONE_MENTION.address
    );
  };

  return (
    <div className={`flex w-full items-start space-x-4 bg-unyte-main`}>
      <div className="min-w-0 flex-1">
        <div className="mx-auto flex w-[96%] flex-row items-center justify-center">
          <div
            tabIndex={0}
            className="mr-4 max-h-72 w-full rounded-lg bg-[#1A1D24] shadow-sm ring-0 ring-inset"
          >
            {files && files.length > 0 && (
              <div>
                <div className="mb-2 flex gap-x-2">
                  {files.map((file, index) => (
                    <div key={index} className="relative">
                      <img
                        className="h-[100px] w-[100px]"
                        src={file.thumbnail}
                        alt=""
                      />
                      <button
                        className="absolute -right-2 -top-2 text-white"
                        onClick={() => deleteFile(index)}
                      >
                        <MdCancel />
                      </button>
                    </div>
                  ))}
                </div>
                <hr className="mb-2 opacity-30" />
              </div>
            )}
            <div className="flex flex-row items-center px-2 lg:px-0">
              <div className="mr-3 w-[7%] lg:w-[3%]">
                <button
                  className="ml-2 flex justify-center"
                  onClick={uploadImageHandler}
                >
                  <FaCirclePlus className="text-gray-500 lg:text-lg" />
                </button>
                <input
                  ref={inputFileRef}
                  className="hidden"
                  name="file"
                  type="file"
                  multiple
                  onChange={onChangeFiles}
                />
              </div>
              <label htmlFor="comment" className="sr-only">
                Add your comment
              </label>
              <div className="relative w-[93%] lg:w-[97%]">
                <textarea
                  ref={textareaRef}
                  rows={1}
                  name="comment"
                  id="comment"
                  className="block max-h-72 w-full resize-none rounded-lg border-0 bg-[#1A1D24] px-1 text-sm text-gray-50 placeholder:text-gray-400 focus:ring-0 sm:leading-6 lg:px-2 lg:text-base"
                  placeholder="Add your comment..."
                  value={displayText(text)}
                  maxLength={1000}
                  onChange={(e) => inputChangeHandler(e)}
                  onKeyDown={(e) => onSubmit(e)}
                  onCompositionStart={() => setIsComposing(true)}
                  onCompositionEnd={() => setIsComposing(false)}
                />
                <div
                  className={`${
                    mentionMode ? "" : "hidden"
                  } absolute bottom-10 left-0 w-full bg-unyte-main`}
                >
                  {sortedUsers.map((user, idx) => (
                    <>
                      <button
                        key={idx}
                        className="flex w-full items-center p-2"
                        onClick={() => {
                          onMentionableUserClickHandler(user);
                          setMentionMode(false);
                        }}
                      >
                        <img
                          src={user.img ? user.img : userIcon}
                          className="h-4 w-4 rounded-full lg:h-8 lg:w-8"
                          onError={(e) => {
                            e.currentTarget.onerror = null;
                            e.currentTarget.src = userIcon;
                          }}
                        />
                        <span className="ml-1 truncate text-xs text-white lg:ml-2">
                          {user.name}
                        </span>
                      </button>
                      <hr className="w-full opacity-30" />
                    </>
                  ))}
                  <button
                    key={"everyone"}
                    className="flex w-full items-center p-2"
                    onClick={() => {
                      onMentionableUserClickHandler(EVERYONE_MENTION);
                      setMentionMode(false);
                    }}
                  >
                    <img
                      src={userIcon}
                      className="h-4 w-4 rounded-full lg:h-8 lg:w-8"
                      onError={(e) => {
                        e.currentTarget.onerror = null;
                        e.currentTarget.src = userIcon;
                      }}
                    />
                    <span className="ml-1 truncate text-xs text-white lg:ml-2">
                      @everyone
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <button
            onClick={(e) => onSubmit(e)}
            className={`h-8 ${
              trimSpace(text) === "" && files.length === 0
                ? "bg-gray-500"
                : "bg-unyte"
            } mb-1 w-8 rounded-full text-gray-50 lg:w-16`}
            disabled={trimSpace(text) === "" && files.length === 0}
          >
            <span className="mx-auto hidden w-fit text-sm lg:flex">送信</span>
            <span className="mx-auto flex w-fit text-sm lg:hidden">
              <AiOutlineSend />
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Input;
