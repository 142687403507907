import React, { useEffect, useRef, useState } from "react";
import { ethers } from "ethers";
import * as voteLib from "../../actions/secretVote/vote-lib.js";
import { decryptoPrivateKey } from "../../actions/blockchain/generateKeys";
import Loading from "../../components/common/Loading.tsx";
import VoteDateTimePicker from "../../components/Voting/VoteDateTimePicker.tsx";
import { addProposal } from "../../actions/secretVote/proposal-manager.ts";
import "../styles/UniSwaTextField.css";
import {
  ProposalUser,
  ProposalData,
  ProposalDataForContract,
  TypedMessageDomain,
  ProposalTypedMessageType,
} from "../../types/vote";
import { useNavigate } from "react-router-dom";
import { NotificationEvent } from "../../types/notification/index.ts";
import { createEventNotification } from "../../actions/firebase/notification.ts";
import { ModifiedProposal } from "src/pages/voting/Vote.tsx";
import { DaoDetailDto } from "src/types/api/index.ts";
import { useUser } from "src/hooks/useUser.ts";

type Props = {
  daoId: string;
  user: ProposalUser;
  callback: (proposalToAdd: ModifiedProposal | null) => void;
  propossalForEdit?: ModifiedProposal | null;
  daoDetail: DaoDetailDto | undefined;
};

const contractAddress = process.env
  .REACT_APP_PROPOSAL_CONTRACT_ADDRESS as string;

const VoteAdd = (props: Props) => {
  const navigate = useNavigate();
  const title = useRef<HTMLInputElement>(null);
  const description = useRef<HTMLTextAreaElement>(null);
  const startTime = useRef<number | null>(null);
  const closingTime = useRef<number | null>(null);
  const [proposal, setProposal] = useState<ProposalData | null>(null);
  const [inProgress, setInProgress] = useState<{
    isShow: boolean;
    message: string;
  }>({ isShow: false, message: "" });
  const { user } = useUser();

  const typedMessageDomain: TypedMessageDomain = {
    name: "Proposal",
    chainId: Number(process.env.REACT_APP_OUTPUT_CHAIN_ID),
    verifyingContract: contractAddress,
    version: "1",
    salt: "",
  };

  const typedMessageType: ProposalTypedMessageType = {
    Proposal: [
      { name: "daoId", type: "bytes32" },
      { name: "proposalId", type: "bytes32" },
      { name: "proposer", type: "address" },
      { name: "title", type: "string" },
      { name: "description", type: "string" },
      { name: "startTime", type: "string" },
      { name: "closingTime", type: "string" },
    ],
  };

  useEffect(() => {
    const init = async () => {
      setInProgress({ isShow: true, message: "Loading..." });
      await voteLib.init();
      const keys = voteLib.keygen();
      const now = new Date();
      const initialData: ProposalData = props.propossalForEdit
        ? (() => {
            const { endTime, ...rest } = props.propossalForEdit;
            return {
              ...rest,
              closingTime: endTime,
              daoId: props.daoId,
              keys: { publicKey: rest.publicKey },
            };
          })()
        : {
            proposalId: ethers.keccak256(ethers.toUtf8Bytes(keys.publicKey)),
            daoId: props.daoId,
            keys: keys,
            proposer: props.user,
            title: "",
            description: "",
            startTime: now.setDate(now.getDate() + 1),
            closingTime: now.setHours(23, 59, 59) + 1000 * 60 * 60 * 24 * 7,
            voters: [],
            status: "preparation",
          };
      if (title.current) {
        props.propossalForEdit &&
          (title.current.style.backgroundColor = "#171920");
        title.current.value = initialData.title;
      }
      if (description.current) {
        props.propossalForEdit &&
          (description.current.style.backgroundColor = "#171920");
        description.current.value = initialData.description;
      }
      startTime.current = initialData.startTime;
      closingTime.current = initialData.closingTime;
      setProposal(initialData);
      setInProgress({ isShow: false, message: "" });
    };
    init();
  }, []);

  const cancelHandler = () => {
    props.callback(null);
  };

  const addHandler = async () => {
    if (title.current?.value.length === 0) {
      alert("タイトルが入力されていません");
      return;
    }
    if (description.current?.value.length === 0) {
      alert("提案内容が入力されていません");
      return;
    }

    setInProgress({ isShow: true, message: "提案を登録中..." });

    const proposalToAdd = {
      ...(proposal as ProposalData),
      title: title.current?.value ?? "",
      description: description.current?.value ?? "",
      startTime: startTime.current!,
      closingTime: closingTime.current!,
    };

    let proposalForContract: ProposalDataForContract;
    try {
      const keyBytes = (await decryptoPrivateKey(user)) as string[] | null;
      if (!keyBytes || keyBytes.length === 0) {
        throw new Error("秘密鍵の復元に失敗しました。");
      }

      const privateKey = keyBytes.join("").replace("\u0000\u0000", "");
      const wallet = new ethers.Wallet(privateKey);

      typedMessageDomain.salt = ethers.keccak256(
        ethers.toUtf8Bytes(proposalToAdd.closingTime.toString())
      );

      proposalForContract = {
        daoId: proposalToAdd.daoId,
        proposalId: proposalToAdd.proposalId,
        proposer: wallet.address,
        title: proposalToAdd.title,
        description: proposalToAdd.description,
        startTime: proposalToAdd.startTime.toString(),
        closingTime: proposalToAdd.closingTime.toString(),
      };

      proposalToAdd.signature = await wallet.signTypedData(
        typedMessageDomain,
        typedMessageType,
        proposalForContract
      );

      const recoveredAddress = ethers.verifyTypedData(
        typedMessageDomain,
        typedMessageType,
        proposalForContract,
        proposalToAdd.signature
      );

      if (recoveredAddress !== props.user.address) {
        throw new Error("事前署名検証に失敗しました。");
      }
    } catch (e) {
      console.error(e);
      setInProgress({ isShow: false, message: "" });
      if (e instanceof Error) {
        const isConfirm = confirm(
          `提案の登録に失敗しました\n${e.message}\nシェアを再設定しますか？`
        );
        if (isConfirm) navigate("/reset");
      } else alert("不明なエラーです。運営にお問い合わせください。");
      return;
    }

    proposalForContract.signature = proposalToAdd.signature;
    proposalForContract.keys = proposalToAdd.keys;

    try {
      await addProposal(proposalForContract);
      const daoName = props.daoDetail?.name ?? "DAO";
      const daoUsersAddressExceptMe =
        props.daoDetail?.members
          .map((user) => user.address)
          .filter((address) => address !== props.user.address) ?? [];
      const notificationEvent: NotificationEvent = {
        daoId: props.daoId,
        timestamp: Date.now(),
        eventType: "vote",
        message: `${daoName}に新しい提案「${proposalToAdd.title}」が追加されました`,
        from: props.user.address,
        to: daoUsersAddressExceptMe,
        data: {
          proposalId: proposalToAdd.proposalId,
        },
      };
      createEventNotification(notificationEvent);
    } catch (e) {
      console.error(e);
      setInProgress({ isShow: false, message: "" });
      if (e instanceof Error) {
        alert(
          `提案の登録に失敗した可能性があります。ご確認ください。\n${e.stack}`
        );
      } else
        alert(
          `提案の登録に失敗した可能性があります。ご確認ください。\n\n${JSON.stringify(
            e
          )}`
        );
      return;
    }

    console.log("提案の登録に成功しました");
    setInProgress({ isShow: false, message: "" });
    props.callback(proposalToAdd);
  };

  const handleDateChange = (value: { startDate: Date; endDate: Date }) => {
    startTime.current = new Date(value.startDate).setHours(0, 0, 0);
    closingTime.current = new Date(value.endDate).setHours(23, 59, 59);
  };

  function handleChange(
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    if (e.target.value.length > 0) {
      e.target.style.backgroundColor = "#171920";
    } else {
      e.target.style.backgroundColor = "#3b4154";
    }
  }

  return (
    <div className="container mx-auto min-h-screen bg-unyte-main px-4 sm:px-6 lg:px-8">
      <div className="mx-4 mt-8 space-y-1">
        <div className="justify-around md:flex">
          <div>
            <h2 className="min-w-[150px] text-base font-semibold leading-7 text-gray-100">
              提案の新規作成
            </h2>
          </div>
          <div className="flex w-full flex-col space-y-3">
            <div className="mt-2 space-y-1">
              <p className="text-xs text-gray-50 ">
                提案のタイトルを入力してください。
              </p>
              <input
                type="text"
                name="title"
                id="vote-title"
                autoComplete="vote-title"
                onChange={handleChange}
                ref={title}
                readOnly={false}
                className="custom-text-field mb-2 w-full rounded-full border p-2 text-gray-50"
              />
            </div>
            <div className="mt-2 space-y-1">
              <p className="text-xs text-gray-50 ">
                提案内容の詳細を入力してください。
              </p>
              <textarea
                id="vote-description"
                name="description"
                onChange={handleChange}
                rows={6}
                className="custom-text-field mb-2 w-full rounded-3xl border p-2 text-gray-50"
                defaultValue={""}
                ref={description}
              />
            </div>
            <div className="mt-2 space-y-1">
              <p className="text-xs text-gray-50 ">
                投票期間を選択してください。
              </p>
              <input
                type="text"
                name="startDate"
                id="vote-startDate"
                autoComplete="vote-startDate"
                readOnly={false}
                className="custom-text-field mb-2 hidden w-full rounded-full border bg-[#171920] p-2 placeholder-shown:bg-[#3b4154]"
              />
              {proposal?.startTime && (
                <VoteDateTimePicker
                  value={{
                    startDate: proposal.startTime,
                    endDate: proposal.closingTime,
                  }}
                  handleDateChange={handleDateChange}
                />
              )}
            </div>
            {/* <div className="mt-2 space-y-1 space-x-3">
              <p className="text-xs text-gray-50 ">
                投票期間終了後に個人の投票結果を
              </p>
              <div className="flex items-center space-x-4">
                <div className="flex items-center space-x-1">
                  <input
                    type="radio"
                    id="showResult"
                    name="isShowResult"
                    className={`${
                      props.propossalForEdit
                        ? "text-[#5E00FF]"
                        : "text-[#E5007E]"
                    }`}
                  />
                  <label className="text-sm text-gray-50" htmlFor="showResult">
                    表示する
                  </label>
                </div>
                <div className="flex items-center space-x-1">
                  <input
                    type="radio"
                    id="notShowResult"
                    name="isShowResult"
                    className={`${
                      props.propossalForEdit
                        ? "text-[#5E00FF]"
                        : "text-[#E5007E]"
                    }`}
                    checked
                  />
                  <label
                    className="text-sm text-gray-50"
                    htmlFor="notShowResult"
                  >
                    表示しない
                  </label>
                </div>
              </div>
            </div>
             */}
          </div>
        </div>
      </div>

      <div className="mt-6 flex items-center justify-center gap-x-12">
        <button
          type="submit"
          className={`w-32 rounded-full bg-gradient-to-r px-3 py-2 text-sm font-semibold ${
            props.propossalForEdit
              ? "from-[#5E00FF] to-[#00A0FF]"
              : "from-[#EE7b4d] to-[#E5007E]"
          } text-gray-50`}
          onClick={addHandler}
        >
          {props.propossalForEdit ? "編集する" : "追加する"}
        </button>
        <button
          type="button"
          className="w-32 rounded-full text-sm font-semibold leading-6 text-gray-100"
          onClick={cancelHandler}
        >
          キャンセル
        </button>
      </div>
      <Loading {...inProgress} />
    </div>
  );
};

export default VoteAdd;
