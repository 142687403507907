import { firebaseFirestore } from "../firebase/config";
import {
  doc,
  updateDoc,
  getDoc,
  setDoc,
  collection,
  addDoc,
} from "firebase/firestore";
import {
  splitStringIntoArray,
  shamirSecretSharing,
  shamirSecretRecovery,
} from "./shamir";
import { ethers } from "ethers";
import {
  decodeBase64ForUnyte,
  encodeToBase64ForUnyte,
  isValidPrivateKey,
  recoverPrivateKey,
  ShareTypeEnum,
} from "./utils";

export async function checkShare(user) {
  return new Promise((resolve, reject) => {
    if (user !== null) {
      const docRef = doc(firebaseFirestore, "users", user.uid);
      getDoc(docRef).then(async (docSnap) => {
        console.log(docSnap.exists(), "docSnap.exists()");
        if (docSnap.exists()) {
          const data = docSnap.data();
          if (data.shareId === undefined) {
            const result = await createShare(user);
            resolve(result);
          } else {
            resolve("already created share");
          }
        } else {
          resolve("no such user");
        }
      });
    }
    // resolve("user is null");
  });
}

async function createWallet() {
  // ウォレットを作成
  let wallet = ethers.Wallet.createRandom();
  // ウォレットのアドレスを取得
  let address = wallet.address;
  // ウォレットの秘密鍵を取得
  let privateKey = wallet.privateKey;
  return { publicKey: address, privateKey: privateKey };
}

export async function createShare(user) {
  // テスト用例
  const wallet = await createWallet();
  const walletFraction = splitStringIntoArray(wallet.privateKey);
  let resultForUnyte = [];
  let resultForUser = [];
  let resultForUserSaving = [];
  const result = walletFraction.map((item) => {
    const shares = shamirSecretSharing(item, 5, 2);
    resultForUnyte.push(shares[0][1]);
    resultForUser.push(shares[1][1]);
    resultForUserSaving.push(shares[2][1]);
    // shamirSecretRecovery([[1,shares[0][1]], [2,shares[1][1]]]);
    return resultForUser;
  });
  // ローカルストレージに保存
  localStorage.setItem(user.uid, resultForUser);
  localStorage.setItem(
    `tmpShare_${user.uid}`,
    encodeToBase64ForUnyte(resultForUserSaving).slice(0, -4)
  );
  return {
    userShare: resultForUserSaving,
    localStorageShare: resultForUser,
    unyteShare: resultForUnyte,
  };
}

export async function saveShareForFirebase(uid, share) {
  try {
    // シェアを使用して秘密鍵を復元する関数
    let array = [];
    share.unyte.map((item, index) => {
      console.log(share.unyte[index], parseInt(share.user[index]));
      const result = shamirSecretRecovery([
        [1, share.unyte[index]],
        [2, parseInt(share.local[index])],
      ]);
      array.push(result);
    });
    if (!array || array.length === 0) {
      const errorMsg = `秘密鍵の復元に失敗しました\n秘密鍵を再生成します。`;
      alert(errorMsg);
      window.location.reload();
    }
    const privateKey = array.join("").replace("\u0000\u0000", "");
    const wallet = new ethers.Wallet(privateKey);

    const shareRef = await addDoc(
      collection(firebaseFirestore, "users", uid, "shares"),
      {
        share: share.unyte,
      }
    );
    await updateDoc(doc(firebaseFirestore, "users", uid), {
      address: wallet.address,
      shareId: shareRef.id,
    });
  } catch (e) {
    console.error("shareの保存に失敗しました :", e);
    throw new Error(e);
  }
}

export async function isValidBackupKey(uid, shareUnyteDB, backupKey) {
  // TODO: Extract the backup key and perform recovery
  const partialShare = localStorage.getItem(`tmpShare_${uid}`);
  const encodedShare = partialShare + backupKey;
  const decodedShare = decodeBase64ForUnyte(encodedShare);
  const privateKey = recoverPrivateKey({
    shareInfo1: {
      share: decodedShare,
      type: ShareTypeEnum.Backup,
    },
    shareInfo2: {
      share: shareUnyteDB,
      type: ShareTypeEnum.UnyteDB,
    },
  });
  return isValidPrivateKey(privateKey);
  // Retrieve the share for storage from localStorage
  // Compare the retrieved share with the share stored in Firestore and perform recovery
  // After successful recovery, remove the share from localStorage
}

export async function createShareFromPrivateKey(user, privateKey) {
  // privateKeyからウォレットを作成
  const wallet = new ethers.Wallet(privateKey);
  if (wallet === undefined) {
    console.log("wallet is undefined");
    return null;
  } else if (wallet.address !== user.address) {
    console.log("wallet address is not equal to user address");
    return null;
  }
  const walletFraction = splitStringIntoArray(privateKey);
  let resultForUnyte = [];
  let resultForUser = [];
  let resultForUserSaving = [];
  walletFraction.map((item) => {
    const shares = shamirSecretSharing(item, 5, 2);
    resultForUnyte.push(shares[0][1]);
    resultForUser.push(shares[1][1]);
    resultForUserSaving.push(shares[2][1]);
    // shamirSecretRecovery([[1,shares[0][1]], [2,shares[1][1]]]);
    return resultForUser;
  });
  // ローカルストレージに保存
  await updateDoc(
    doc(firebaseFirestore, "users", user.uid, "shares", user.shareId),
    {
      share: resultForUnyte,
    }
  );
  localStorage.setItem(user.uid, resultForUser);
  const result = {
    resultForUnyte: resultForUnyte,
    resultForUser: resultForUser,
    resultForUserSaving: resultForUserSaving,
  };
  return result;
}

export async function storeShareLog(user, oldShare, newShare) {
  const date = new Date();
  const newLogRef = doc(collection(firebaseFirestore, "shareLog"));
  await setDoc(newLogRef, {
    uid: user.uid,
    old: oldShare,
    new: newShare,
    createdAt: date,
    updatedAt: date,
  });
}
