/* tslint:disable */
/* eslint-disable */
/**
 * Unyte SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OutputInfo } from './OutputInfo';
import {
    OutputInfoFromJSON,
    OutputInfoFromJSONTyped,
    OutputInfoToJSON,
} from './OutputInfo';

/**
 * 
 * @export
 * @interface RejectOutputInfos
 */
export interface RejectOutputInfos {
    /**
     * outputの情報
     * @type {OutputInfo}
     * @memberof RejectOutputInfos
     */
    outputInfo: OutputInfo;
}

/**
 * Check if a given object implements the RejectOutputInfos interface.
 */
export function instanceOfRejectOutputInfos(value: object): boolean {
    if (!('outputInfo' in value)) return false;
    return true;
}

export function RejectOutputInfosFromJSON(json: any): RejectOutputInfos {
    return RejectOutputInfosFromJSONTyped(json, false);
}

export function RejectOutputInfosFromJSONTyped(json: any, ignoreDiscriminator: boolean): RejectOutputInfos {
    if (json == null) {
        return json;
    }
    return {
        
        'outputInfo': OutputInfoFromJSON(json['outputInfo']),
    };
}

export function RejectOutputInfosToJSON(value?: RejectOutputInfos | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'outputInfo': OutputInfoToJSON(value['outputInfo']),
    };
}

