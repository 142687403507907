import React from "react";
import { BaseProps } from "../../types/common/index.ts";

export type DaoDescriptionProps = {
  description: string;
} & BaseProps;

export const DaoDescription: React.FC<DaoDescriptionProps> = ({
  className,
  description,
}) => {
  return <div className={`${className} text-white`}>{description}</div>;
};
