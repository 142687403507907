import React, { useEffect, useState } from "react";
import MessageList from "../../components/message/MessageList.tsx";
import Input from "../../components/message/Input.tsx";
import { useNotifications } from "../../actions/firebase/notification.ts";
import { fetchUserByWallet } from "src/actions/firebase/utils.js";
import { useDaoDetail } from "src/hooks/useDaoDetail.ts";
import { useChannel } from "src/actions/firebase/channels.ts";
import { useUser } from "src/hooks/useUser.ts";
import { useUnreadMessageCount } from "src/actions/message/unreadMessageCount.ts";
import ScrollDiv from "src/components/atoms/ScrollDiv.tsx";

const Message = ({
  daoId,
  channelId,
}: {
  daoId: string;
  channelId: string;
}) => {
  const channel = useChannel(daoId, channelId);
  const { readChannel: readChannelNotifications } = useNotifications();
  const { readChannel: readChannelMessages } = useUnreadMessageCount();
  const [adminUids, setAdminUids] = useState<string[]>([]);
  const {
    daoDetail,
    isError: isErrorDaoDetail,
    isLoading: isLoadingDaoDetail,
  } = useDaoDetail(daoId);
  const { user } = useUser();

  useEffect(() => {
    if (isLoadingDaoDetail || isErrorDaoDetail || !daoDetail) return;
    const getAdminUids = async () => {
      const adminUids = await Promise.all(
        daoDetail.admins.map(async (walletAddress) => {
          const userDoc = await fetchUserByWallet(walletAddress);
          return userDoc?.uid;
        })
      );

      if (adminUids) {
        setAdminUids(adminUids.filter((uid) => uid) as string[]);
      }
    };
    getAdminUids();
  }, [isLoadingDaoDetail, daoDetail]);
  useEffect(() => {
    readChannelNotifications(daoId, channelId, user);
    readChannelMessages(daoId, channelId);
  }, [daoId, channelId]);

  if (!channel) {
    return <div>Loading...</div>;
  }
  return (
    <div className="relative h-[calc(100vh-71px)] lg:h-[calc(100vh-7.2rem)]">
      <div className="absolute top-0 z-10 h-[60px] w-full border-y border-gray-700 bg-unyte-main px-2">
        <div className="group relative flex h-full items-center gap-x-4 truncate px-12">
          <h1 className="text-white">{channel.name}</h1>
          <ScrollDiv className="text-sm text-gray-500">
            {channel.description}
          </ScrollDiv>
        </div>
      </div>
      <div className="mx-auto h-full max-w-6xl bg-unyte-main py-8 pl-2 lg:px-8 lg:pr-2">
        <div className="h-[100%] overflow-y-scroll lg:h-[95%]">
          <MessageList
            daoId={daoId}
            channelId={channelId}
            adminUids={adminUids}
          />
        </div>
        <div className="sticky bottom-0 left-0 bg-unyte-main">
          <Input daoId={daoId} channelId={channelId} />
        </div>
      </div>
    </div>
  );
};

export default Message;
