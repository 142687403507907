import React, { useEffect, useState } from "react";
import { BaseProps, User } from "../../../types/common/index.ts";
import { ItemImage } from "./ItemImage/ItemImage.tsx";
import clsx from "clsx";
import { ItemTitle } from "./ItemTitle/ItemTitle.tsx";
import { ItemDescription } from "./ItemDescription/ItemDescription.tsx";
import { ItemPrice } from "./ItemPrice/ItemPrice.tsx";
import { ItemInventory } from "./ItemInventory/ItemInventory.tsx";
import { ItemEditButton } from "./ItemEditButton/ItemEditButton.tsx";
import { ShowItemBuyModalButton } from "./ShowItemBuyModalButton/ShowItemBuyModalButton.tsx";
import { useAtom } from "jotai";
import { ItemConfirmBuyModal } from "../Modal/ItemConfirmBuyModal/ItemConfirmBuyModal.tsx";
import { inProgressMarketState } from "../../../stores/inProgressMarketState.ts";
import { purchase } from "../../../actions/blockchain/itemActions.js";
import { DaoDetailDto } from "src/types/api/index.ts";
import { KeyedMutator } from "swr";
import { useUser } from "src/hooks/useUser.ts";

export type ItemProps = {
  item: any;
  role: "admin" | "member";
  daoDetail: DaoDetailDto;
  user: User;
  itemMutate: KeyedMutator<any>;
} & BaseProps;

export const Item: React.FC<ItemProps> = (props: ItemProps) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isBuyFlag, setIsBuyFlag] = useState(false);
  const [inProgress, setInProgress] = useAtom(inProgressMarketState);
  const { user } = useUser();

  const name = props.item.name;
  const description = props.item.description;
  const img = props.item.image.match(/https:\/\/ipfs.io\/ipfs\//)
    ? props.item.image.replace(
        /https:\/\/ipfs.io\/ipfs\//,
        process.env.REACT_APP_IPFS_BASE_URL
      )
    : props.item.image;
  const price = props.item.price;
  const tokenId = props.item.tokenId;
  const inventory = props.item.amount;

  const purchaseItem = async () => {
    setInProgress({ isShow: true, message: "購入手続き中..." });
    try {
      const amount = 1;
      await purchase(tokenId, amount, user);
      alert("購入に成功しました");
      props.itemMutate();
    } catch (e) {
      console.error(e);
      if (e instanceof Error) {
        alert(
          `アイテムの購入に失敗した可能性があります。ご確認ください。\n${e.stack}`
        );
      } else
        alert(
          `アイテムの購入に失敗した可能性があります。ご確認ください。\n\n${JSON.stringify(
            e
          )}`
        );
    }
    setInProgress({ isShow: false, message: "" });
  };

  useEffect(() => {
    if (!isBuyFlag) return;
    purchaseItem();
    props.itemMutate();
    setIsBuyFlag(false);
  }, [isBuyFlag]);

  return (
    <>
      {!inProgress.isShow && (
        <div
          className={clsx(
            props.className,
            "w-full max-w-lg px-2 pb-4 lg:px-2",
            "md:w-1/2",
            "lg:w-1/3",
            "xl:w-1/4"
          )}
        >
          <div
            className={clsx(
              "flex flex-row lg:flex-col",
              "h-full rounded-lg shadow",
              "border border-gray-200",
              "bg-white",
              "dark:border-gray-700 dark:bg-gray-800"
            )}
          >
            <div className="flex w-1/4 items-center px-2 lg:my-0 lg:w-full  lg:px-0">
              <ItemImage
                className={clsx("my-2 lg:my-0 lg:rounded-t-lg")}
                src={img}
              />
            </div>
            <div
              className={clsx(
                "flex w-3/4 flex-row lg:h-full lg:w-full lg:flex-col",
                "p-2 lg:p-3"
              )}
            >
              <div className="w-2/3 lg:w-full">
                <ItemTitle className={clsx("mb-2")} title={name} />
                <ItemDescription
                  className={clsx("mb-3")}
                  description={description}
                />
              </div>
              <div className="flex h-full w-1/3 flex-col justify-end lg:w-full">
                <div className={clsx("flex flex-col items-end pb-2 lg:hidden")}>
                  {props.role === "admin" ? (
                    <ItemEditButton className={clsx("")} item={props.item} />
                  ) : (
                    <div className={clsx("w-1/2")}></div>
                  )}
                </div>
                <div className={clsx("flex flex-col items-end", "mb-4")}>
                  <ItemPrice className={clsx("mb-2")} price={price} />
                  <ItemInventory className={clsx("")} inventory={inventory} />
                </div>
                <div className="flex items-center justify-between">
                  {props.role === "admin" ? (
                    <ItemEditButton
                      className={clsx("hidden lg:flex")}
                      item={props.item}
                    />
                  ) : (
                    <div className={clsx("w-1/2")}></div>
                  )}
                  <ShowItemBuyModalButton
                    className={clsx("w-full lg:w-1/2")}
                    disabled={Number(inventory) === 0}
                    daoDetail={props.daoDetail}
                    price={price}
                    setIsOpenModal={setIsOpenModal}
                    user={props.user}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <ItemConfirmBuyModal
        title={name}
        isOpenModal={isOpenModal}
        setIsOpenModal={setIsOpenModal}
        setIsBuyFlag={setIsBuyFlag}
      />
    </>
  );
};
