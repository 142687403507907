/* tslint:disable */
/* eslint-disable */
/**
 * Unyte SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CreateNFTArgs } from './CreateNFTArgs';
import {
    CreateNFTArgsFromJSON,
    CreateNFTArgsFromJSONTyped,
    CreateNFTArgsToJSON,
} from './CreateNFTArgs';

/**
 * 
 * @export
 * @interface CreateNFTRequest
 */
export interface CreateNFTRequest {
    /**
     * Lambdaの関数でCreateNFTを指定
     * @type {string}
     * @memberof CreateNFTRequest
     */
    type: CreateNFTRequestTypeEnum;
    /**
     * CreateNFTの引数
     * @type {CreateNFTArgs}
     * @memberof CreateNFTRequest
     */
    args: CreateNFTArgs;
}


/**
 * @export
 */
export const CreateNFTRequestTypeEnum = {
    CreateDao: 'createDAO',
    CreateNft: 'createNFT',
    EditDao: 'editDAO',
    AddItem: 'addItem',
    EditItem: 'editItem',
    Exchange: 'exchange',
    Proposal: 'proposal',
    Vote: 'vote',
    ApproveOutput: 'approveOutput',
    RejectOutput: 'rejectOutput',
    CreateOutput: 'createOutput',
    CreateTask: 'createTask'
} as const;
export type CreateNFTRequestTypeEnum = typeof CreateNFTRequestTypeEnum[keyof typeof CreateNFTRequestTypeEnum];


/**
 * Check if a given object implements the CreateNFTRequest interface.
 */
export function instanceOfCreateNFTRequest(value: object): boolean {
    if (!('type' in value)) return false;
    if (!('args' in value)) return false;
    return true;
}

export function CreateNFTRequestFromJSON(json: any): CreateNFTRequest {
    return CreateNFTRequestFromJSONTyped(json, false);
}

export function CreateNFTRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateNFTRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'type': json['type'],
        'args': CreateNFTArgsFromJSON(json['args']),
    };
}

export function CreateNFTRequestToJSON(value?: CreateNFTRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'type': value['type'],
        'args': CreateNFTArgsToJSON(value['args']),
    };
}

