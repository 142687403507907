const {
  NETWORK_ERROR_MSG,
  APPROVE_OUTPUT_ENDPOINT,
  REJECT_OUTPUT_ENDPOINT,
} = require("../../const/const.ts");
const { fireauth } = require("src/actions/firebase/config.js");

const approveOutputAction = async (approvalInfos, type) => {
  const json = {
    type: type === "approve" ? "approveOutput" : "rejectOutput",
    args: {
      approvalInfos: approvalInfos,
    },
  };

  const user = fireauth.currentUser;
  const token = await user.getIdToken(true);

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(json),
  };
  let response;
  try {
    response = await fetch(
      type === "approve" ? APPROVE_OUTPUT_ENDPOINT : REJECT_OUTPUT_ENDPOINT,
      options
    );
  } catch (e) {
    console.error(e);
    throw new Error(NETWORK_ERROR_MSG);
  }
  const responseJson = await response.json();
  if (responseJson.status !== "success") throw new Error(responseJson.message);
  return responseJson;
};

/**
 * 成果物の承認
 * @param approvalInfos 成果物の情報
 */
export const approveOutput = async (approvalInfos) => {
  await approveOutputAction(approvalInfos, "approve");
};

/**
 * 成果物の棄却
 * @param rejectionInfos 成果物の情報
 */
export const rejectOutput = async (rejectionInfos) => {
  await approveOutputAction(rejectionInfos, "reject");
};
