import React from "react";
import { BaseProps } from "../../types/common/index.ts";

export type CloseDaoModalButtonProps = { onClick: () => void } & BaseProps;

export const CloseDaoModalButton: React.FC<CloseDaoModalButtonProps> = ({
  className,
  onClick,
}) => {
  return (
    <button className={`${className}`} onClick={onClick}>
      <p className="text-2xl">×</p>
    </button>
  );
};
