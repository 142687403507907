/* tslint:disable */
/* eslint-disable */
/**
 * Unyte SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Output } from './Output';
import {
    OutputFromJSON,
    OutputFromJSONTyped,
    OutputToJSON,
} from './Output';

/**
 * 
 * @export
 * @interface OutputsDto
 */
export interface OutputsDto {
    /**
     * 
     * @type {Array<Output>}
     * @memberof OutputsDto
     */
    outputs: Array<Output>;
}

/**
 * Check if a given object implements the OutputsDto interface.
 */
export function instanceOfOutputsDto(value: object): boolean {
    if (!('outputs' in value)) return false;
    return true;
}

export function OutputsDtoFromJSON(json: any): OutputsDto {
    return OutputsDtoFromJSONTyped(json, false);
}

export function OutputsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OutputsDto {
    if (json == null) {
        return json;
    }
    return {
        
        'outputs': ((json['outputs'] as Array<any>).map(OutputFromJSON)),
    };
}

export function OutputsDtoToJSON(value?: OutputsDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'outputs': ((value['outputs'] as Array<any>).map(OutputToJSON)),
    };
}

