import React, { useCallback, useEffect, useMemo, useState } from "react";
import { AddItem } from "../../components/market/AddEditItem/AddItem/AddItem.tsx";
import { useRole } from "../../actions/firebase/role.ts";
import { useAtom, useAtomValue } from "jotai";
import clsx from "clsx";
import { inProgressMarketState } from "../../stores/inProgressMarketState.ts";
import { isOpenEditPageMarketState } from "../../stores/isOpenEditPageMarketState.ts";
import { isOpenAddPageMarketState } from "../../stores/isOpenAddPageMarketState.ts";
import { EditItem } from "../../components/market/AddEditItem/EditItem/EditItem.tsx";
import { ContributionPoint } from "../../components/market/ContributionPoint/ContributionPoint.tsx";
import { OpenAddPageButton } from "../../components/market/OpenAddPageButton/OpenAddPageButton.tsx";
import { isOpenInsufficientPointModalMarketState } from "../../stores/isOpenInsufficientPointModalMarketState.ts";
import { Category, Filter } from "../../components/common/Category.tsx";
import { InsufficientPointModal } from "../../components/market/Modal/InsufficientPointModal/InsufficientPointModal.tsx";
import Loading from "../../components/common/Loading.tsx";
import { useDaoDetail } from "src/hooks/useDaoDetail.ts";
import { MarketControllerFetchItemsItemStatusEnum } from "src/types/api/index.ts";
import Items from "src/components/market/Item/Items.tsx";
import { useUser } from "src/hooks/useUser.ts";

interface Props {
  daoId: string;
}
const defaultFilters = [
  {
    id: "category",
    name: "カテゴリー",
    options: [
      {
        value: MarketControllerFetchItemsItemStatusEnum.OnSale,
        label: "on sale",
        checked: true,
      },
      {
        value: MarketControllerFetchItemsItemStatusEnum.SoldOut,
        label: "sold out",
        checked: false,
      },
    ],
  },
];

const Market: React.FC<Props> = ({ daoId }) => {
  const role = useRole(daoId);
  const isOpenEditPage = useAtomValue(isOpenEditPageMarketState);
  const isOpenAddPage = useAtomValue(isOpenAddPageMarketState);
  const isOpenInsufficientPointModal = useAtomValue(
    isOpenInsufficientPointModalMarketState
  );

  const [filters, setFilters] = useState<Filter[]>(defaultFilters);
  const itemsStatus =
    filters[0].options
      ?.filter((option) => option.checked)
      .map((option) => option.value) || [];
  const [inProgress, setInProgress] = useAtom(inProgressMarketState);
  const { user, isError: isErrorUser, isLoading: isLoadingUser } = useUser();
  const {
    daoDetail,
    isError: isErrorDaoDetail,
    isLoading: isLoadingDaoDetail,
  } = useDaoDetail(daoId);

  useEffect(() => {
    if (isLoadingDaoDetail) {
      setInProgress({ isShow: true, message: "Loading..." });
      return;
    }
    setInProgress({ isShow: false, message: "" });
  }, [isLoadingDaoDetail, isLoadingUser]);

  if (isLoadingDaoDetail || isLoadingUser) return <Loading {...inProgress} />;
  if (isErrorDaoDetail || !daoDetail || isErrorUser || !user) return <></>;
  return (
    <div className={clsx("lg:h-full lg:overflow-y-scroll")}>
      <div
        className={clsx(
          `${isOpenAddPage || isOpenEditPage ? "hidden" : ""}`,
          "h-[calc(100vh-57px)]",
          "lg:h-full"
        )}
      >
        <div>
          <div
            className={clsx(
              "flex items-center justify-end",
              "bg-[#121217]",
              "px-4 py-2",
              "w-full",
              "sticky top-0",
              "border-b border-unyte-dark"
            )}
          >
            <ContributionPoint
              className={clsx("h-8", "flex items-center")}
              daoDetail={daoDetail}
              user={user}
            />
            <div className="flex">
              <Category filters={filters} setFilters={setFilters} />
            </div>
            {role === "admin" && <OpenAddPageButton className={clsx("ml-4")} />}
          </div>
          <Items
            daoId={daoId}
            itemsStatus={itemsStatus}
            daoDetail={daoDetail}
            role={role}
            user={user}
          />
        </div>
      </div>
      {<Loading {...inProgress} />}
      {isOpenAddPage && role === "admin" && <AddItem daoId={daoId} />}
      {isOpenEditPage && role === "admin" && <EditItem daoId={daoId} />}
      {isOpenInsufficientPointModal && <InsufficientPointModal daoId={daoId} />}
    </div>
  );
};

export default Market;
