import React from "react";
import clsx from "clsx";
import { BaseProps, User } from "../../../types/common";
import { DaoDetailDto } from "src/types/api/index.ts";

export type ContributionPointProps = {
  daoDetail: DaoDetailDto;
  user: User;
} & BaseProps;

export const ContributionPoint: React.FC<ContributionPointProps> = (
  props: ContributionPointProps
) => {
  const currentDaoUser = props.daoDetail.members.find(
    (daoUser) => daoUser.address === props.user.address
  );

  return (
    <div className={clsx(props.className, "text-white")}>
      保有量: {currentDaoUser?.contributionToken || 0} トークン
    </div>
  );
};
