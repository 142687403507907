/* tslint:disable */
/* eslint-disable */
/**
 * Unyte SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApplyStatus
 */
export interface ApplyStatus {
    /**
     * 
     * @type {string}
     * @memberof ApplyStatus
     */
    status: ApplyStatusStatusEnum;
}


/**
 * @export
 */
export const ApplyStatusStatusEnum = {
    Success: 'success',
    Failure: 'failure'
} as const;
export type ApplyStatusStatusEnum = typeof ApplyStatusStatusEnum[keyof typeof ApplyStatusStatusEnum];


/**
 * Check if a given object implements the ApplyStatus interface.
 */
export function instanceOfApplyStatus(value: object): boolean {
    if (!('status' in value)) return false;
    return true;
}

export function ApplyStatusFromJSON(json: any): ApplyStatus {
    return ApplyStatusFromJSONTyped(json, false);
}

export function ApplyStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplyStatus {
    if (json == null) {
        return json;
    }
    return {
        
        'status': json['status'],
    };
}

export function ApplyStatusToJSON(value?: ApplyStatus | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'status': value['status'],
    };
}

