/* tslint:disable */
/* eslint-disable */
/**
 * Unyte SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AddItemArgs } from './AddItemArgs';
import {
    AddItemArgsFromJSON,
    AddItemArgsFromJSONTyped,
    AddItemArgsToJSON,
} from './AddItemArgs';

/**
 * 
 * @export
 * @interface AddItemRequest
 */
export interface AddItemRequest {
    /**
     * Lambda関数でAddItemを指定
     * @type {string}
     * @memberof AddItemRequest
     */
    type: AddItemRequestTypeEnum;
    /**
     * AddItemの引数
     * @type {AddItemArgs}
     * @memberof AddItemRequest
     */
    args: AddItemArgs;
}


/**
 * @export
 */
export const AddItemRequestTypeEnum = {
    CreateDao: 'createDAO',
    CreateNft: 'createNFT',
    EditDao: 'editDAO',
    AddItem: 'addItem',
    EditItem: 'editItem',
    Exchange: 'exchange',
    Proposal: 'proposal',
    Vote: 'vote',
    ApproveOutput: 'approveOutput',
    RejectOutput: 'rejectOutput',
    CreateOutput: 'createOutput',
    CreateTask: 'createTask'
} as const;
export type AddItemRequestTypeEnum = typeof AddItemRequestTypeEnum[keyof typeof AddItemRequestTypeEnum];


/**
 * Check if a given object implements the AddItemRequest interface.
 */
export function instanceOfAddItemRequest(value: object): boolean {
    if (!('type' in value)) return false;
    if (!('args' in value)) return false;
    return true;
}

export function AddItemRequestFromJSON(json: any): AddItemRequest {
    return AddItemRequestFromJSONTyped(json, false);
}

export function AddItemRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddItemRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'type': json['type'],
        'args': AddItemArgsFromJSON(json['args']),
    };
}

export function AddItemRequestToJSON(value?: AddItemRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'type': value['type'],
        'args': AddItemArgsToJSON(value['args']),
    };
}

