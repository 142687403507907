import { Cog8ToothIcon } from "@heroicons/react/24/outline";
import { Badge } from "@mui/material";
import { useState, useEffect } from "react";
import { Channel, ChannelWithUnreadCount } from "src/actions/firebase/channels";
import { useRole } from "src/actions/firebase/role";
import { CreateChannelModal } from "src/pages/dao/CreateChannelModal";
import { EditChannelModal } from "src/pages/dao/EditChannelModal";
import React from "react";

const ChannelList: React.FC<{
  daoId: string;
  selectedChannelId: string | null;
  onSelectChannel: (channelId: string | null) => void;
  channels: ChannelWithUnreadCount[];
  mutateChannels: () => void;
}> = ({
  daoId,
  selectedChannelId,
  onSelectChannel,
  channels,
  mutateChannels,
}) => {
  const role = useRole(daoId);
  const [creating, setCreating] = useState(false);
  const [editingChannel, setEditingChannel] = useState<Channel | null>(null);

  const whenSelectedChannel = (value) =>
    value === selectedChannelId ? "underline" : "text-[#969696]";

  const canEditChannels = role === "admin";

  return (
    <>
      <ul className="w-full truncate text-sm">
        {channels.map((channel) => (
          <li key={channel.id}>
            <div className={`mt-3 flex w-full flex-row items-center gap-x-2 `}>
              <div className="flex w-8 items-center justify-center lg:w-16">
                <Badge
                  badgeContent={
                    channel.unreadCount === "New" ? "N" : channel.unreadCount
                  }
                  sx={{
                    "& .MuiBadge-badge": {
                      color: "white",
                      backgroundColor: "#ff1493",
                    },
                  }}
                />
              </div>
              <button
                className={`w-10/12 truncate ${whenSelectedChannel(
                  channel.id
                )}`}
                onClick={() => {
                  onSelectChannel(channel.id!);
                }}
              >
                <p className="truncate text-left">{channel.name}</p>
              </button>
              {canEditChannels && (
                <button
                  className="w-1/12"
                  onClick={() => setEditingChannel(channel)}
                >
                  <Cog8ToothIcon className="h-4 w-4 text-white" />
                </button>
              )}
            </div>
          </li>
        ))}
        {canEditChannels && (
          <li className={`mt-3 w-full text-right hover:cursor-pointer`}>
            <button
              className="h-4 w-1/12 text-white"
              onClick={() => setCreating(true)}
            >
              <p className="h-4 w-4 items-center justify-center text-white">
                +
              </p>
            </button>
          </li>
        )}
      </ul>
      {creating && (
        <CreateChannelModal
          daoId={daoId}
          onCreated={() => {
            mutateChannels();
            setCreating(false);
          }}
          onClose={() => setCreating(false)}
        />
      )}
      {editingChannel && (
        <EditChannelModal
          daoId={daoId}
          channel={editingChannel}
          onUpdate={(channel) => {
            mutateChannels();
            setEditingChannel(null);
          }}
          onDelete={(channelId) => {
            mutateChannels();
            if (channelId === selectedChannelId) {
              onSelectChannel(
                channels.find((c) => c.id !== channelId)?.id ?? null
              );
            }
            setEditingChannel(null);
          }}
          onClose={() => setEditingChannel(null)}
        />
      )}
    </>
  );
};

export { ChannelList };
