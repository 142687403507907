import React, { useEffect, useRef, useState } from "react";
import { ethers } from "ethers";
import { OPERATIONS } from "src/const/const.ts";
import Loading from "src/components/common/Loading.tsx";
import { createOnChainTask } from "src/actions/blockchain/createTask.js";
import { createXTask } from "src/actions/firebase/createXTask.js";
import { createEventNotification } from "src/actions/firebase/notification.ts";
import VoteDateTimePicker from "src/components/Voting/VoteDateTimePicker.tsx";
import { NotificationEvent } from "src/types/notification/index.ts";
import { ProposedTaskData } from "src/types/task/index.ts";
import { useDaoDetail } from "src/hooks/useDaoDetail";
import { useUser } from "src/hooks/useUser";

type Props = {
  daoId: string;
  callback: (taskIdToAdd: string | null) => void;
};

const TaskAdd = (props: Props) => {
  const { daoDetail } = useDaoDetail(props.daoId);
  const title = useRef<HTMLInputElement>(null);
  const [titleCharCount, setTitleCharCount] = useState<number>(0);
  const description = useRef<HTMLTextAreaElement>(null);
  const [descriptionCharCount, setDescriptionCharCount] = useState<number>(0);
  const startTime = useRef<number | null>(null);
  const closingTime = useRef<number | null>(null);
  const operationValue = useRef<HTMLInputElement>(null);
  const operationReword = useRef<HTMLInputElement>(null);
  const [proposedTask, setProposedTask] = useState<ProposedTaskData | null>(
    null
  );
  const [operation, setOperation] = useState<{
    operationId: string;
    name: string;
    color: string;
    reward: number | null;
    value?: string;
  }>(OPERATIONS[0]);
  const [inProgress, setInProgress] = useState<{
    isShow: boolean;
    message: string;
  }>({ isShow: false, message: "" });
  const { user } = useUser();

  useEffect(() => {
    if (!user) return;
    const init = async () => {
      setInProgress({ isShow: true, message: "Loading..." });
      const initialData: ProposedTaskData = {
        taskId: ethers.keccak256(
          ethers.toUtf8Bytes(user.address + new Date().getTime().toString())
        ),
        daoId: props.daoId,
        author: user,
        title: "",
        description: "",
        startTime: new Date().setHours(0, 0, 0) + 1000 * 60 * 60 * 24 * 1,
        closingTime: new Date().setHours(23, 59, 59) + 1000 * 60 * 60 * 24 * 8,
        repeatId: "infinite",
        maxOutput: 0,
        operation: OPERATIONS[0],
      };
      title.current && (title.current.value = initialData.title);
      description.current &&
        (description.current.value = initialData.description);
      startTime.current = initialData.startTime;
      closingTime.current = initialData.closingTime;
      operationReword.current &&
        initialData.operation.reward &&
        (operationReword.current.value =
          initialData.operation.reward?.toString());
      operationValue.current &&
        initialData.operation.value &&
        (operationValue.current.value = initialData.operation.value);
      setOperation(initialData.operation);
      setProposedTask(initialData);
      setInProgress({ isShow: false, message: "" });
    };
    init();
  }, [user]);

  const cancelHandler = () => {
    props.callback(null);
  };

  const handleDateChange = (value: { startDate: Date; endDate: Date }) => {
    startTime.current = new Date(value.startDate).setHours(0, 0, 0);
    closingTime.current = new Date(value.endDate).setHours(23, 59, 59);
  };

  async function addHandler(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): Promise<void> {
    if (title.current?.value.trim().length === 0) {
      alert("タスク名が入力されていません");
      return;
    }
    if (title.current?.value && title.current?.value.trim().length > 100) {
      alert("タスク名は100文字以内で入力してください");
      return;
    }
    if (description.current?.value.trim().length === 0) {
      alert("タスクの詳細が入力されていません");
      return;
    }
    if (
      description.current?.value &&
      description.current?.value.trim().length > 1000
    ) {
      alert("タスクの詳細は1000文字以内で入力してください");
      return;
    }

    if (
      !operationReword.current?.value ||
      parseInt(operationReword.current!.value) <= 0 ||
      (operation.operationId === "1" &&
        parseInt(operationReword.current!.value) <= 0) ||
      (operation.operationId === "3" &&
        parseInt(operationReword.current!.value) <= 0)
    ) {
      alert("報酬額は1以上で入力してください");
      return;
    }
    if (
      operation.operationId === "1" &&
      operationValue.current?.value.trim().length === 0
    ) {
      alert("ハッシュタグを入力してください");
      return;
    }
    if (
      operation.operationId === "2" &&
      operationValue.current?.value.trim().length === 0 &&
      (!operationValue.current?.value
        .trim()
        .startsWith("https://twitter.com/") ||
        !operationValue.current?.value.trim().startsWith("https://x.com/"))
    ) {
      alert("リポストして欲しいポストURLを入力してください");
      return;
    }
    if (!daoDetail) {
      alert("DAOの情報が取得できませんでした");
      return;
    }
    setInProgress({ isShow: true, message: "タスクを登録中..." });
    const proposedTaskToAdd = {
      ...(proposedTask as ProposedTaskData),
      title: title.current?.value ?? "",
      description: description.current?.value ?? "",
      startTime: startTime.current!,
      closingTime: closingTime.current!,
      operation: {
        ...operation,
        reward: parseInt(operationReword.current!.value),
        value: operationValue.current?.value ?? "",
      },
    };
    const daoUsersAddressExceptMe = daoDetail.members
      .map((user) => user.address)
      .filter((address) => address !== user?.address);
    const notificationEvent: NotificationEvent = {
      daoId: proposedTaskToAdd.daoId,
      timestamp: Date.now(),
      eventType: "addTask",
      message: `${proposedTaskToAdd.title}\n${proposedTaskToAdd.description}`,
      from: proposedTaskToAdd.author.address,
      to: daoUsersAddressExceptMe,
      data: {
        taskId: proposedTaskToAdd.taskId,
      },
    };
    if (operation.operationId === "3") {
      try {
        await createOnChainTask(proposedTaskToAdd, user);
        createEventNotification(notificationEvent);
      } catch (e) {
        console.error(e);
        setInProgress({ isShow: false, message: "" });
        if (e instanceof Error) {
          alert(
            `タスクの登録に失敗した可能性があります。ご確認ください。\n${e.stack}`
          );
        } else
          alert(
            `タスクの登録に失敗した可能性があります。ご確認ください。\n\n${JSON.stringify(
              e
            )}`
          );
        return;
      }
      proposedTaskToAdd.isOnChain = true;
    } else {
      await createXTask(proposedTaskToAdd, props.daoId);
      createEventNotification(notificationEvent);
      proposedTaskToAdd.isOnChain = false;
    }
    setInProgress({ isShow: false, message: "" });
    props.callback(proposedTaskToAdd.taskId);
  }

  function handleDescriptionChange(
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    if (e.target.value.length > 0) {
      e.target.style.backgroundColor = "#171920";
    } else {
      e.target.style.backgroundColor = "#3b4154";
    }
    setDescriptionCharCount(e.target.value.length);
  }

  function handleTitleChange(
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    if (e.target.value.length > 0) {
      e.target.style.backgroundColor = "#171920";
    } else {
      e.target.style.backgroundColor = "#3b4154";
    }
    setTitleCharCount(e.target.value.length);
  }

  function handleChange(
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    if (e.target.value.length > 0) {
      e.target.style.backgroundColor = "#171920";
    } else {
      e.target.style.backgroundColor = "#3b4154";
    }
  }
  useEffect(() => {
    document
      .getElementById("task-reward")!
      .addEventListener("wheel", function (e) {
        e.preventDefault();
      });
  }, []);

  return (
    <div className="container mx-auto bg-unyte-main px-4 sm:px-6 lg:px-8">
      <div className="mx-4 mt-8 space-y-1">
        <div className="flex flex-col justify-around md:flex-row">
          <div>
            <h2 className="min-w-[150px] text-base font-semibold leading-7 text-gray-100">
              タスクの新規作成
            </h2>
          </div>
          <div className="flex w-full flex-col space-y-3">
            <div className="mt-2 space-y-1">
              <p className="text-xs text-gray-50 ">
                タスクのタイトルを入力してください。
              </p>
              <div className="relative">
                <input
                  type="text"
                  name="title"
                  id="vote-title"
                  autoComplete="vote-title"
                  onChange={handleTitleChange}
                  ref={title}
                  maxLength={100}
                  readOnly={false}
                  className="custom-text-field w-full rounded-full border p-2 text-gray-50"
                />
                <div className="absolute bottom-2 right-2 z-20 h-fit">
                  <p className="text-white opacity-30">
                    {titleCharCount} / 100
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-1 space-y-1">
              <p className="text-xs text-gray-50 ">
                タスク内容の詳細を入力してください。
              </p>
              <div className="relative">
                <textarea
                  id="vote-description"
                  name="description"
                  onChange={handleDescriptionChange}
                  rows={6}
                  maxLength={1000}
                  className="custom-text-field w-full rounded-3xl border p-2 text-gray-50"
                  defaultValue={""}
                  ref={description}
                />
                <p className="absolute bottom-2 right-4 z-20 text-white opacity-30">
                  {descriptionCharCount} / 1000
                </p>
              </div>
            </div>

            <div className="flex flex-col gap-y-2">
              {/* <p className="text-xs text-gray-50 ">
                タスクの種別を選択してください。
              </p>
              <TaskOperationSelect
                daoId={props.daoId}
                operationChanged={handleOperationChange}
              /> */}
              {operation?.operationId !== "3" && (
                <div className="mt-1">
                  <input
                    type="text"
                    name="title"
                    id="task-hashtag"
                    onChange={handleChange}
                    ref={operationValue}
                    autoComplete="task-data"
                    placeholder={
                      operation?.operationId === "1"
                        ? "ハッシュタグを入力してください、 ex: #hashtag"
                        : "リポストして欲しいポストURLを入力してください。ex: https://twitter.com/..."
                    }
                    readOnly={false}
                    className="custom-text-field w-full rounded-full border p-2"
                  />
                </div>
              )}
              <div className="mt-1">
                <p className="mb-1 text-xs text-gray-50 ">
                  報酬額を入力してください。ex: 100
                </p>
                <input
                  type="number"
                  onKeyDown={(event) => {
                    (event.key === "e" ||
                      event.key === "-" ||
                      event.key === "+" ||
                      event.key === "*" ||
                      event.key === "." ||
                      event.key === "/") &&
                      event.preventDefault();
                  }}
                  name="title"
                  id="task-reward"
                  onChange={handleChange}
                  ref={operationReword}
                  autoComplete="task-reward"
                  placeholder="報酬額を入力してください。ex: 100"
                  readOnly={false}
                  className="custom-text-field w-full rounded-full border p-2"
                />
              </div>
            </div>

            <div className="mt-1 space-y-1">
              <p className="text-xs text-gray-50 ">
                有効期間を選択してください。
              </p>
              <input
                type="text"
                name="startDate"
                id="vote-startDate"
                autoComplete="vote-startDate"
                readOnly={false}
                className="custom-text-field hidden w-full rounded-full border bg-[#171920] p-2 placeholder-shown:bg-[#3b4154]"
              />
              {proposedTask?.startTime && (
                <VoteDateTimePicker
                  value={{
                    startDate: proposedTask.startTime,
                    endDate: proposedTask.closingTime,
                  }}
                  handleDateChange={handleDateChange}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="mt-6 flex flex-col items-center justify-center gap-x-12 gap-y-4 md:flex-row">
        <button
          type="submit"
          className="w-full rounded-full bg-gradient-to-r from-[#EE7b4d] to-[#E5007E] px-3 py-2 text-sm font-semibold text-gray-50 md:w-fit"
          onClick={addHandler}
        >
          追加する
        </button>
        <button
          type="button"
          className="mb-4 w-full rounded-full text-sm font-semibold leading-6 text-gray-100 md:mb-0 md:w-fit"
          onClick={cancelHandler}
        >
          キャンセル
        </button>
      </div>
      <Loading {...inProgress} />
    </div>
  );
};

export default TaskAdd;
