/* tslint:disable */
/* eslint-disable */
/**
 * Unyte SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Item
 */
export interface Item {
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    tokenId: string;
    /**
     * 
     * @type {number}
     * @memberof Item
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    creator: string;
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    symbol: string;
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    image: string;
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    status: ItemStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof Item
     */
    price: number;
}


/**
 * @export
 */
export const ItemStatusEnum = {
    OnSale: 'onSale',
    SoldOut: 'soldOut'
} as const;
export type ItemStatusEnum = typeof ItemStatusEnum[keyof typeof ItemStatusEnum];


/**
 * Check if a given object implements the Item interface.
 */
export function instanceOfItem(value: object): boolean {
    if (!('tokenId' in value)) return false;
    if (!('amount' in value)) return false;
    if (!('creator' in value)) return false;
    if (!('name' in value)) return false;
    if (!('description' in value)) return false;
    if (!('symbol' in value)) return false;
    if (!('image' in value)) return false;
    if (!('status' in value)) return false;
    if (!('price' in value)) return false;
    return true;
}

export function ItemFromJSON(json: any): Item {
    return ItemFromJSONTyped(json, false);
}

export function ItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): Item {
    if (json == null) {
        return json;
    }
    return {
        
        'tokenId': json['tokenId'],
        'amount': json['amount'],
        'creator': json['creator'],
        'name': json['name'],
        'description': json['description'],
        'symbol': json['symbol'],
        'image': json['image'],
        'status': json['status'],
        'price': json['price'],
    };
}

export function ItemToJSON(value?: Item | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'tokenId': value['tokenId'],
        'amount': value['amount'],
        'creator': value['creator'],
        'name': value['name'],
        'description': value['description'],
        'symbol': value['symbol'],
        'image': value['image'],
        'status': value['status'],
        'price': value['price'],
    };
}

