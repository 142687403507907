import React, { useState } from "react";
import { useIntl } from "react-intl";
import { Channel, editChannel } from "../../actions/firebase/channels.ts";

export const EditChannelModal = ({
  daoId,
  channel,
  onUpdate: onUpdateCallback,
  onDelete: onDeleteCallback,
  onClose,
}: {
  daoId: string;
  channel: Channel;
  onUpdate: (channel: Channel) => void;
  onDelete: (channelId: string) => void;
  onClose: () => void;
}) => {
  const intl = useIntl();

  const [name, setName] = useState<string>(channel.name);
  const [description, setDescription] = useState<string>(
    channel.description ?? ""
  );
  const [archived, setArchived] = useState<boolean>(channel.archived ?? false);

  function onUpdate() {
    editChannel(channel.id, daoId, name, description, archived).then(
      (channel) => {
        onUpdateCallback(channel);
        if (archived) {
          onDeleteCallback(channel.id);
        }
      }
    );
  }

  return (
    <div className="fixed inset-0 z-40">
      <div className="flex h-screen items-center justify-center">
        <div
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          onClick={onClose}
        ></div>
        <div className="w-11/12 max-w-xl transform overflow-hidden rounded-lg bg-unyte p-px text-left text-white shadow-xl transition-all md:w-1/2">
          <div className="h-full w-full space-y-2 rounded-lg bg-[#28282D] px-4 pb-4 pt-5">
            <div className="flex justify-end">
              <button onClick={onClose}>×</button>
            </div>
            <div className="space-y-2 overflow-hidden">
              <div>
                <label htmlFor="channel" className="sr-only">
                  チャンネル名
                </label>
                <input
                  name="channel"
                  id="channel"
                  className="block w-full resize-none rounded bg-[#1A1D24] text-gray-50 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                  placeholder="チャンネル名"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div>
                <label htmlFor="channel-description" className="sr-only">
                  チャンネルの説明
                </label>
                <input
                  name="channel-description"
                  id="channel-description"
                  className="block w-full resize-none rounded bg-[#1A1D24] text-gray-50 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                  placeholder="チャンネルの説明"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
              <div className="space-x-4 pl-2">
                <input
                  name="channel-archived"
                  id="channel-archived"
                  className="h-4 w-4 rounded"
                  type="checkbox"
                  checked={archived}
                  onChange={(e) => setArchived(e.target.checked)}
                />
                <label htmlFor="channel-archived" className="text-sm">
                  アーカイブ（チャンネル一覧に表示されなくなります）
                </label>
              </div>
            </div>
            <div className="flex justify-center gap-x-8">
              <button
                className="mb-5 mt-5 h-9 w-1/3 rounded-full bg-unyte lg:mb-10"
                onClick={onUpdate}
              >
                更新
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
