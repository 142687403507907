/* tslint:disable */
/* eslint-disable */
/**
 * Unyte SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Proposer } from './Proposer';
import {
    ProposerFromJSON,
    ProposerFromJSONTyped,
    ProposerToJSON,
} from './Proposer';
import type { Voter } from './Voter';
import {
    VoterFromJSON,
    VoterFromJSONTyped,
    VoterToJSON,
} from './Voter';

/**
 * 
 * @export
 * @interface Proposal
 */
export interface Proposal {
    /**
     * 
     * @type {string}
     * @memberof Proposal
     */
    proposalId: string;
    /**
     * 
     * @type {Proposer}
     * @memberof Proposal
     */
    proposer: Proposer;
    /**
     * 
     * @type {string}
     * @memberof Proposal
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof Proposal
     */
    description: string;
    /**
     * 
     * @type {number}
     * @memberof Proposal
     */
    startTime: number;
    /**
     * 
     * @type {number}
     * @memberof Proposal
     */
    endTime: number;
    /**
     * 
     * @type {string}
     * @memberof Proposal
     */
    publicKey: string;
    /**
     * 
     * @type {string}
     * @memberof Proposal
     */
    status: ProposalStatusEnum;
    /**
     * 
     * @type {Array<Voter>}
     * @memberof Proposal
     */
    voters: Array<Voter>;
}


/**
 * @export
 */
export const ProposalStatusEnum = {
    Preparation: 'preparation',
    Active: 'active',
    Expired: 'expired',
    Cancelled: 'cancelled'
} as const;
export type ProposalStatusEnum = typeof ProposalStatusEnum[keyof typeof ProposalStatusEnum];


/**
 * Check if a given object implements the Proposal interface.
 */
export function instanceOfProposal(value: object): boolean {
    if (!('proposalId' in value)) return false;
    if (!('proposer' in value)) return false;
    if (!('title' in value)) return false;
    if (!('description' in value)) return false;
    if (!('startTime' in value)) return false;
    if (!('endTime' in value)) return false;
    if (!('publicKey' in value)) return false;
    if (!('status' in value)) return false;
    if (!('voters' in value)) return false;
    return true;
}

export function ProposalFromJSON(json: any): Proposal {
    return ProposalFromJSONTyped(json, false);
}

export function ProposalFromJSONTyped(json: any, ignoreDiscriminator: boolean): Proposal {
    if (json == null) {
        return json;
    }
    return {
        
        'proposalId': json['proposalId'],
        'proposer': ProposerFromJSON(json['proposer']),
        'title': json['title'],
        'description': json['description'],
        'startTime': json['startTime'],
        'endTime': json['endTime'],
        'publicKey': json['publicKey'],
        'status': json['status'],
        'voters': ((json['voters'] as Array<any>).map(VoterFromJSON)),
    };
}

export function ProposalToJSON(value?: Proposal | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'proposalId': value['proposalId'],
        'proposer': ProposerToJSON(value['proposer']),
        'title': value['title'],
        'description': value['description'],
        'startTime': value['startTime'],
        'endTime': value['endTime'],
        'publicKey': value['publicKey'],
        'status': value['status'],
        'voters': ((value['voters'] as Array<any>).map(VoterToJSON)),
    };
}

