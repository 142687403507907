import React, { useState } from "react";
import { useIntl } from "react-intl";
// @ts-ignore
import { ViewMessage, deleteMessage } from "../../actions/firebase/message.ts";

export const DeleteModal = ({
  daoId,
  channelId,
  message,
  confirmMessage,
  onDelete: onDeleteCallback,
  onClose,
}: {
  daoId: string;
  channelId: string;
  message: ViewMessage;
  confirmMessage: string;
  onDelete: () => void;
  onClose: () => void;
}) => {
  const intl = useIntl();

  function onDelete() {
    deleteMessage(daoId, channelId, message.id);
    onDeleteCallback();
  }

  return (
    <div className="fixed inset-0 z-40">
      <div className="flex h-screen items-center justify-center">
        <div
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          onClick={onClose}
        ></div>
        <div className="w-11/12 max-w-xl transform overflow-hidden rounded-lg bg-[#28282D] bg-unyte p-px text-left text-white shadow-xl transition-all md:w-1/2">
          <div className="h-full w-full rounded-lg bg-[#28282D] px-4 pb-4 pt-5">
            <div className="flex justify-end">
              <button onClick={onClose}>×</button>
            </div>
            <div>
              <p className="text-center">
                {intl.formatMessage({ id: confirmMessage })}
              </p>
            </div>
            <div className="flex justify-center">
              <button
                className="mb-5 mt-5 h-9 w-1/3 rounded-full bg-unyte text-center lg:mb-10"
                onClick={onDelete}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
