/* tslint:disable */
/* eslint-disable */
/**
 * Unyte SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DaoDetailDtoMember
 */
export interface DaoDetailDtoMember {
    /**
     * 
     * @type {string}
     * @memberof DaoDetailDtoMember
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof DaoDetailDtoMember
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof DaoDetailDtoMember
     */
    img?: string;
    /**
     * 
     * @type {number}
     * @memberof DaoDetailDtoMember
     */
    contributionToken: number;
}

/**
 * Check if a given object implements the DaoDetailDtoMember interface.
 */
export function instanceOfDaoDetailDtoMember(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('address' in value)) return false;
    if (!('contributionToken' in value)) return false;
    return true;
}

export function DaoDetailDtoMemberFromJSON(json: any): DaoDetailDtoMember {
    return DaoDetailDtoMemberFromJSONTyped(json, false);
}

export function DaoDetailDtoMemberFromJSONTyped(json: any, ignoreDiscriminator: boolean): DaoDetailDtoMember {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'address': json['address'],
        'img': json['img'] == null ? undefined : json['img'],
        'contributionToken': json['contributionToken'],
    };
}

export function DaoDetailDtoMemberToJSON(value?: DaoDetailDtoMember | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'address': value['address'],
        'img': value['img'],
        'contributionToken': value['contributionToken'],
    };
}

