/* tslint:disable */
/* eslint-disable */
/**
 * Unyte SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DaoDetailDtoMember } from './DaoDetailDtoMember';
import {
    DaoDetailDtoMemberFromJSON,
    DaoDetailDtoMemberFromJSONTyped,
    DaoDetailDtoMemberToJSON,
} from './DaoDetailDtoMember';

/**
 * 
 * @export
 * @interface DaoDetailDto
 */
export interface DaoDetailDto {
    /**
     * 
     * @type {string}
     * @memberof DaoDetailDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof DaoDetailDto
     */
    description: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DaoDetailDto
     */
    links: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DaoDetailDto
     */
    admins: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof DaoDetailDto
     */
    isOpen: boolean;
    /**
     * 
     * @type {string}
     * @memberof DaoDetailDto
     */
    avatar: string;
    /**
     * 
     * @type {Array<DaoDetailDtoMember>}
     * @memberof DaoDetailDto
     */
    members: Array<DaoDetailDtoMember>;
}

/**
 * Check if a given object implements the DaoDetailDto interface.
 */
export function instanceOfDaoDetailDto(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('description' in value)) return false;
    if (!('links' in value)) return false;
    if (!('admins' in value)) return false;
    if (!('isOpen' in value)) return false;
    if (!('avatar' in value)) return false;
    if (!('members' in value)) return false;
    return true;
}

export function DaoDetailDtoFromJSON(json: any): DaoDetailDto {
    return DaoDetailDtoFromJSONTyped(json, false);
}

export function DaoDetailDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DaoDetailDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'description': json['description'],
        'links': json['links'],
        'admins': json['admins'],
        'isOpen': json['isOpen'],
        'avatar': json['avatar'],
        'members': ((json['members'] as Array<any>).map(DaoDetailDtoMemberFromJSON)),
    };
}

export function DaoDetailDtoToJSON(value?: DaoDetailDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'description': value['description'],
        'links': value['links'],
        'admins': value['admins'],
        'isOpen': value['isOpen'],
        'avatar': value['avatar'],
        'members': ((value['members'] as Array<any>).map(DaoDetailDtoMemberToJSON)),
    };
}

