/* tslint:disable */
/* eslint-disable */
/**
 * Unyte SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Voter } from './Voter';
import {
    VoterFromJSON,
    VoterFromJSONTyped,
    VoterToJSON,
} from './Voter';

/**
 * 
 * @export
 * @interface VotesDto
 */
export interface VotesDto {
    /**
     * 
     * @type {string}
     * @memberof VotesDto
     */
    proposalId: string;
    /**
     * 
     * @type {Array<Voter>}
     * @memberof VotesDto
     */
    voters: Array<Voter>;
}

/**
 * Check if a given object implements the VotesDto interface.
 */
export function instanceOfVotesDto(value: object): boolean {
    if (!('proposalId' in value)) return false;
    if (!('voters' in value)) return false;
    return true;
}

export function VotesDtoFromJSON(json: any): VotesDto {
    return VotesDtoFromJSONTyped(json, false);
}

export function VotesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): VotesDto {
    if (json == null) {
        return json;
    }
    return {
        
        'proposalId': json['proposalId'],
        'voters': ((json['voters'] as Array<any>).map(VoterFromJSON)),
    };
}

export function VotesDtoToJSON(value?: VotesDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'proposalId': value['proposalId'],
        'voters': ((value['voters'] as Array<any>).map(VoterToJSON)),
    };
}

