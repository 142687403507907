/* tslint:disable */
/* eslint-disable */
/**
 * Unyte SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateNFTArgs
 */
export interface CreateNFTArgs {
    /**
     * DAOのオーナーのアドレス
     * @type {string}
     * @memberof CreateNFTArgs
     */
    owner: string;
    /**
     * NFTの種類
     * @type {string}
     * @memberof CreateNFTArgs
     */
    type: CreateNFTArgsTypeEnum;
    /**
     * NFTの名前
     * @type {string}
     * @memberof CreateNFTArgs
     */
    name: string;
    /**
     * DAOのID
     * @type {string}
     * @memberof CreateNFTArgs
     */
    daoId: string;
    /**
     * NFTの画像
     * @type {string}
     * @memberof CreateNFTArgs
     */
    img: string;
}


/**
 * @export
 */
export const CreateNFTArgsTypeEnum = {
    Join: 'join',
    Governance: 'governance',
    Contribution: 'contribution'
} as const;
export type CreateNFTArgsTypeEnum = typeof CreateNFTArgsTypeEnum[keyof typeof CreateNFTArgsTypeEnum];


/**
 * Check if a given object implements the CreateNFTArgs interface.
 */
export function instanceOfCreateNFTArgs(value: object): boolean {
    if (!('owner' in value)) return false;
    if (!('type' in value)) return false;
    if (!('name' in value)) return false;
    if (!('daoId' in value)) return false;
    if (!('img' in value)) return false;
    return true;
}

export function CreateNFTArgsFromJSON(json: any): CreateNFTArgs {
    return CreateNFTArgsFromJSONTyped(json, false);
}

export function CreateNFTArgsFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateNFTArgs {
    if (json == null) {
        return json;
    }
    return {
        
        'owner': json['owner'],
        'type': json['type'],
        'name': json['name'],
        'daoId': json['daoId'],
        'img': json['img'],
    };
}

export function CreateNFTArgsToJSON(value?: CreateNFTArgs | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'owner': value['owner'],
        'type': value['type'],
        'name': value['name'],
        'daoId': value['daoId'],
        'img': value['img'],
    };
}

