import React from "react";
import clsx from "clsx";
import { BaseProps } from "../../../../types/common";

export type ItemPriceProps = {
  price: number;
} & BaseProps;

export const ItemPrice: React.FC<ItemPriceProps> = ({ className, price }) => {
  return (
    <div
      className={clsx(
        className,
        "text-sm font-bold text-gray-900 lg:text-lg",
        "dark:text-white"
      )}
    >
      {price}
      <span className={clsx("ml-2", "font-normal")}>トークン</span>
    </div>
  );
};
