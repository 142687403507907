import { useNavigate } from "react-router-dom";
import { ADMIN_MENU_ITEMS } from "src/const/const";
import React from "react";

const AdminMenu: React.FC<{
  daoId: string;
}> = ({ daoId }) => {
  const navigate = useNavigate();
  return (
    <>
      <ul className="w-full truncate text-sm">
        {ADMIN_MENU_ITEMS.map((adminMenuItem) => (
          <li key={adminMenuItem}>
            <div className={`justify ml-12 mt-3 flex items-center gap-x-2`}>
              <button
                className={`truncate text-[#969696]`}
                onClick={() => {
                  navigate(`/${daoId}/admin/${adminMenuItem}`);
                }}
              >
                <p className="truncate">{adminMenuItem}</p>
              </button>
            </div>
          </li>
        ))}
      </ul>
    </>
  );
};

export { AdminMenu };
