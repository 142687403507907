import React from "react";
import { ThanksUser } from "src/types/api";
import { ANONYMOUS_ICON, STAGE } from "src/const/const";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { FormattedMessage } from "react-intl";

export type ThanksReceiptProps = {
  from: ThanksUser | null;
  to: ThanksUser | null;
  message: string;
  amount: number;
  daoIcon: any;
};

const PropertyAndValue = ({
  property,
  value,
}: {
  property: string; // i18n id
  value: string;
}) => {
  return (
    <div className="mt-5 flex flex-col space-y-1 pl-3">
      <p className="text-sm font-semibold text-gray-200 lg:text-lg">
        <FormattedMessage id={property} />:
      </p>
      <p className="whitespace-pre-wrap text-sm text-gray-200 lg:text-lg">
        {value}
      </p>
    </div>
  );
};

const ThanksReceipt = ({
  from,
  to,
  message,
  amount,
  daoIcon,
}: ThanksReceiptProps) => {
  return (
    <div className="flex flex-col">
      <img
        className="mx-auto w-1/5 rounded-full bg-gray-300 lg:w-1/3"
        src={daoIcon}
        alt="dao icon"
      />
      <div className="mx-auto mt-2 text-4xl text-red-500">Thanks!!</div>
      <div className="mt-2 flex flex-row">
        <div className="ml-auto h-full">
          <div className="relative h-8 w-8">
            <img
              className="mx-auto h-8 w-8 rounded-full bg-gray-300"
              src={from?.img || ANONYMOUS_ICON}
              alt="user icon"
              onError={(e) => {
                e.currentTarget.onerror = null;
                e.currentTarget.src = ANONYMOUS_ICON;
              }}
            />
          </div>
        </div>
        <ChevronRightIcon className="mx-2 h-6 fill-gray-300" />
        <div className="mr-auto h-full">
          <div className="relative h-8 w-8">
            <img
              className="mx-auto h-8 w-8 rounded-full bg-gray-300 "
              src={to?.img || ANONYMOUS_ICON}
              alt="user icon"
              onError={(e) => {
                e.currentTarget.onerror = null;
                e.currentTarget.src = ANONYMOUS_ICON;
              }}
            />
          </div>
        </div>
      </div>
      <div className="mt-2">
        <PropertyAndValue
          property="thanks_modal_from"
          value={from?.name || ""}
        />
        <PropertyAndValue property="thanks_modal_to" value={to?.name || ""} />
        <PropertyAndValue property="thanks_modal_message" value={message} />
        {amount !== 0 && (
          <PropertyAndValue
            property="thanks_modal_amount"
            value={amount.toString()}
          />
        )}
      </div>
    </div>
  );
};

export default ThanksReceipt;
