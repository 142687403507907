import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { NavigateFunction, useNavigate, useLocation } from "react-router-dom";
import {
  MagnifyingGlassIcon,
  PlusCircleIcon,
} from "@heroicons/react/24/outline";
import { useTasks } from "src/hooks/useTasks.ts";
import TaskEdit from "src/components/Task/TaskEdit.tsx";
import TaskAdd from "src/components/Task/TaskAdd.tsx";
import { Category, Filter } from "src/components/common/Category.tsx";
import Loading from "src/components/common/Loading.tsx";
import ProposaledTask from "src/pages/task/ProposaledTask.tsx";
import TaskDetail from "src/pages/task/TaskDetail";
import { useUser } from "src/hooks/useUser";

const defultFilters = [
  {
    id: "category",
    name: "カテゴリー",
    options: [
      { value: "active", label: "Active", checked: true },
      { value: "expired", label: "expired", checked: false },
      { value: "preparation", label: "Preparation", checked: true },
    ],
  },
];

interface Props {
  daoId: string;
  outputId?: string;
}

const Task = (props: Props) => {
  const location = useLocation();
  const taskId = location.pathname.split("/")[3];
  const [filters, setFilters] = useState<Filter[]>(defultFilters);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const {
    tasks,
    isLoading: isLoadingTasks,
    isError: isErrorTasks,
    mutate: mutateTasks,
  } = useTasks(
    props.daoId,
    filters[0].options.filter((f) => f.checked).map((f) => f.value),
    searchQuery
  );
  const selectedTask = useMemo(() => {
    return tasks?.find((t) => t.taskId === taskId);
  }, [tasks, taskId]);
  const tasksDiv = useRef<HTMLDivElement>(null);
  const detaliDiv = useRef<HTMLDivElement>(null);
  const [outputText, setOutputText] = useState<string>("");
  const {
    user: currentUser,
    isLoading: isLoadingCurrentUser,
    isError: isErrorCurrentUser,
  } = useUser();
  const navigate: NavigateFunction = useNavigate();
  const [isAddTaskOpen, setIsAddTaskOpen] = useState<boolean>(false);
  const [isEditProcess, setIsEditProcess] = useState<boolean | null>(null);

  useEffect(() => {
    if (tasks && tasks.length > 0 && !taskId) {
      navigate(`/${props.daoId}/task/${tasks[0].taskId}`);
      tasksDiv.current?.scrollTo({ top: 0 });
    }
  }, [tasks]);

  useEffect(() => {
    if (isErrorCurrentUser || (!isLoadingCurrentUser && !currentUser)) {
      navigate("/");
    }
  }, [currentUser, isLoadingCurrentUser, isErrorCurrentUser]);

  const callbackHandler = useCallback((taskIdToAdd: string | null): void => {
    mutateTasks();
    if (taskIdToAdd) {
      navigate(`/${props.daoId}/task/${taskIdToAdd}`);
    }
    setIsAddTaskOpen(false);
    setIsEditProcess(null);
    window.scrollTo({ top: 0 });
    detaliDiv.current?.scrollTo({ top: 0 });
  }, []);

  const editCallbackHandler = useCallback((IsEditProcess: boolean): void => {
    setIsAddTaskOpen(false);
    setIsEditProcess(IsEditProcess);
  }, []);

  const handleClick = (taskId: string): void => {
    if (outputText !== "") {
      const confirm = window.confirm(
        "編集中の内容が破棄されますがよろしいですか？"
      );
      if (!confirm) {
        return;
      }
    }
    setOutputText("");
    navigate(`/${props.daoId}/task/${taskId}`);
  };

  const handleSerchQueryChanged = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    event.preventDefault();
    setSearchQuery(event.target.value.toLowerCase());
  };

  return (
    <div className="mx-auto mt-8 min-h-[100vh-7.2rem] max-w-screen-2xl bg-[#121217] px-2 lg:mt-0">
      {!isAddTaskOpen && !isEditProcess && (
        <>
          <div className="flex items-center justify-between gap-x-4 bg-[#121217]">
            <div className="flex h-12 w-3/4 items-center justify-between gap-x-4 sm:w-1/2">
              <div className="flex h-12 flex-grow items-center justify-between bg-[#121217]">
                <label htmlFor="search" className="sr-only">
                  Search
                </label>
                <div className="relative flex w-full">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                    <MagnifyingGlassIcon
                      className="h-5 w-5 text-gray-50"
                      aria-hidden="true"
                    />
                  </div>
                  <input
                    id="search"
                    name="search"
                    className="block w-full rounded-full border-0 bg-[#121217] py-1.5 pl-10 pr-3 text-gray-50 ring-1 ring-inset ring-gray-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"
                    type="search"
                    value={searchQuery}
                    onChange={handleSerchQueryChanged}
                  />
                </div>
              </div>
              <div className="flex">
                <Category filters={filters} setFilters={setFilters} />
              </div>
            </div>
            <div className="flex h-12 flex-shrink items-center justify-end bg-[#121217]">
              <button
                className="relative flex h-9 w-9 items-center justify-center gap-x-1 rounded-full bg-gradient-to-r from-[#EE7b4d] to-[#E5007E] text-gray-50 sm:w-32"
                onClick={() => {
                  setIsAddTaskOpen(true);
                }}
              >
                <span className="hidden w-fit text-sm sm:flex">新規作成</span>
                <PlusCircleIcon className="flex h-6 w-6 items-center justify-center" />
              </button>
            </div>
          </div>
          {tasks && tasks.length > 0 && (
            <div className="flex">
              <div
                className="hidden-scrollbar flex h-[calc(100svh-210px)] w-full flex-grow snap-y snap-mandatory flex-col scroll-smooth px-0 md:w-1/2 md:overflow-y-scroll"
                ref={tasksDiv}
              >
                {tasks.map((task) => {
                  if (task.status !== "cancelled") {
                    return (
                      <>
                        <ProposaledTask
                          key={task.taskId}
                          task={task}
                          isSelected={task.taskId === taskId}
                          onClick={() => {
                            handleClick(task.taskId);
                          }}
                        />
                        {task.taskId === selectedTask?.taskId && (
                          <div className="mb-8 md:hidden">
                            <TaskDetail
                              daoId={props.daoId}
                              task={selectedTask}
                              callback={callbackHandler}
                              editCallbackHandler={editCallbackHandler}
                              outputText={outputText}
                              setOutputText={setOutputText}
                              heighlightOutputId={props.outputId}
                            />
                          </div>
                        )}
                      </>
                    );
                  }
                })}
              </div>

              <div
                className="hidden h-[calc(100svh-210px)] w-1/2 overflow-y-scroll md:block"
                ref={detaliDiv}
              >
                {selectedTask && (
                  <TaskDetail
                    daoId={props.daoId}
                    task={selectedTask}
                    callback={callbackHandler}
                    editCallbackHandler={editCallbackHandler}
                    outputText={outputText}
                    setOutputText={setOutputText}
                    heighlightOutputId={props.outputId}
                  />
                )}
              </div>
            </div>
          )}
        </>
      )}

      {isAddTaskOpen && currentUser && (
        <TaskAdd callback={callbackHandler} daoId={props.daoId} />
      )}

      {isEditProcess && currentUser && selectedTask != null && (
        <TaskEdit
          callback={callbackHandler}
          daoId={props.daoId}
          proposedTaskForEdit={selectedTask}
        />
      )}

      {isErrorTasks && (
        <div className="fixed left-[calc(100svw/2)] top-[calc(100svh/2)] -translate-x-1/2 -translate-y-1/2 bg-transparent text-lg text-gray-100">
          タスクの取得に失敗しました
        </div>
      )}
      {tasks && tasks.length === 0 && !isAddTaskOpen && (
        <div className="fixed left-[calc(100svw/2)] top-[calc(100svh/2)] -translate-x-1/2 -translate-y-1/2 bg-transparent text-lg text-gray-100">
          タスクがありません
        </div>
      )}

      <Loading
        isShow={isLoadingTasks || isLoadingCurrentUser}
        message="Loading..."
      />
    </div>
  );
};

export default Task;
