import { useState } from "react";
import {
  approveOutput,
  rejectOutput,
} from "src/actions/blockchain/approveOutput";
import { fetchDAOById } from "src/actions/blockchain/fetchDAO";
import { createEventNotification } from "src/actions/firebase/notification";
import { useIsUpdating } from "src/hooks/useIsUpdating";
import { useUser } from "src/hooks/useUser";
import { restApi } from "src/libs/restClient";
import { OutputsForAllTask } from "src/types/api";
import { NotificationEvent } from "src/types/notification";
import { ApprovalInfoType } from "src/types/task";
import useSWR from "swr";

interface OutputFilter {
  approvalStatus?: ("approved" | "rejected" | "pending")[];
}

const fetchOutputs = (daoId: string, outputFilter: OutputFilter) => {
  console.log("fetchOutputs", daoId);
  return restApi.adminControllerFetchOutputsForAllTasks({
    daoId: daoId,
    approvalStatus: outputFilter.approvalStatus,
  });
};

export function useAdminOutputs(daoId: string, outputFilter: OutputFilter) {
  const key = (() => {
    let key = `/admin/${daoId}/outputs`;
    if (outputFilter.approvalStatus) {
      key += `&approvalStatus=${outputFilter.approvalStatus.join(",")}`;
    }
    return key;
  })();

  const {
    data,
    error,
    isLoading,
    mutate: mutateAdminOutputs,
  } = useSWR([key, daoId, outputFilter], ([_, daoId, outputFilter]) =>
    fetchOutputs(daoId, outputFilter)
  );
  const [isApproving, setIsApproving] = useState(false);
  const { isUpdating, checkUpdated } = useIsUpdating({
    data,
    mutate: mutateAdminOutputs,
  });
  const { user: currentUser } = useUser();

  const mutate = async () => {
    checkUpdated();
    await mutateAdminOutputs();
  };

  const approve = async (outputs: OutputsForAllTask[]) => {
    setIsApproving(true);
    console.log("approve", outputs);
    try {
      const dao = await fetchDAOById(daoId);
      if (!currentUser || !dao) {
        throw new Error("Failed to fetch user or dao");
      }
      const approvalInfos = outputs.map((output): ApprovalInfoType => {
        const outputDesc = {
          author: {
            address: output.author.address,
          },
          note: output.note,
          date: output.date,
          verified: true,
          status: "approved",
          verifier: currentUser.address,
          outputFiles: output.outputFiles,
        };
        return {
          outputInfo: {
            owner: currentUser.address,
            daoId: daoId,
            taskId: output.task.taskId,
            outputId: output.outputId,
            output: JSON.stringify(outputDesc),
          },
          rewardInfo: {
            from: dao[4][0], // admin address
            to: output.author.address,
            token: [dao[7][0], Number(dao[7][1]), Number(dao[7][2])], // token
            reward: output.task.operation.reward,
          },
        };
      });
      await approveOutput(approvalInfos);
      outputs.forEach((output) => {
        const notificationEvent: NotificationEvent = {
          daoId: daoId,
          timestamp: Date.now(),
          eventType: "approveTask",
          message: output.note!,
          from: currentUser.address,
          to: [output.author.address],
          data: {
            taskId: output.task.taskId,
            outputId: output.outputId,
          },
        };
        createEventNotification(notificationEvent);
      });
      await mutate();
    } finally {
      setIsApproving(false);
    }
  };

  const reject = async (outputs: OutputsForAllTask[]) => {
    setIsApproving(true);
    console.log("reject", outputs);
    try {
      if (!currentUser) {
        throw new Error("Failed to fetch user");
      }
      const approvalInfos = outputs.map((output): ApprovalInfoType => {
        const outputDesc = {
          author: {
            address: output.author.address,
          },
          note: output.note,
          date: output.date,
          verified: false,
          status: "rejected",
          verifier: currentUser.address,
          outputFiles: output.outputFiles,
        };
        return {
          outputInfo: {
            owner: currentUser.address,
            daoId: daoId,
            taskId: output.task.taskId,
            outputId: output.outputId,
            output: JSON.stringify(outputDesc),
          },
        };
      });
      await rejectOutput(approvalInfos);
      outputs.forEach((output) => {
        const notificationEvent: NotificationEvent = {
          daoId: daoId,
          timestamp: Date.now(),
          eventType: "rejectTask",
          message: output.note!,
          from: currentUser.address,
          to: [output.author.address],
          data: {
            taskId: output.task.taskId,
            outputId: output.outputId,
          },
        };
        createEventNotification(notificationEvent);
      });
      await mutate();
    } finally {
      setIsApproving(false);
    }
  };

  return {
    outputs: data?.outputs,
    isError: error,
    isLoading: isLoading || isApproving || isUpdating,
    approve,
    reject,
  };
}
