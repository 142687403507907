/* tslint:disable */
/* eslint-disable */
/**
 * Unyte SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EditDAOOperator } from './EditDAOOperator';
import {
    EditDAOOperatorFromJSON,
    EditDAOOperatorFromJSONTyped,
    EditDAOOperatorToJSON,
} from './EditDAOOperator';
import type { TokenInfo } from './TokenInfo';
import {
    TokenInfoFromJSON,
    TokenInfoFromJSONTyped,
    TokenInfoToJSON,
} from './TokenInfo';

/**
 * 
 * @export
 * @interface EditDAOArgs
 */
export interface EditDAOArgs {
    /**
     * DAOのID
     * @type {string}
     * @memberof EditDAOArgs
     */
    id: string;
    /**
     * DAOの名前
     * @type {string}
     * @memberof EditDAOArgs
     */
    name: string;
    /**
     * DAOの説明文
     * @type {string}
     * @memberof EditDAOArgs
     */
    description: string;
    /**
     * DAOの画像
     * @type {string}
     * @memberof EditDAOArgs
     */
    img: string;
    /**
     * DAOの管理者のアドレス
     * @type {Array<string>}
     * @memberof EditDAOArgs
     */
    admins: Array<string>;
    /**
     * DAOのjoinトークンの情報
     * @type {TokenInfo}
     * @memberof EditDAOArgs
     */
    joinToken: TokenInfo;
    /**
     * DAOのガバナンストークンの情報
     * @type {TokenInfo}
     * @memberof EditDAOArgs
     */
    governanceToken: TokenInfo;
    /**
     * DAOの投票トークンの情報
     * @type {TokenInfo}
     * @memberof EditDAOArgs
     */
    contributionToken: TokenInfo;
    /**
     * DAOが公開されているかどうか
     * @type {boolean}
     * @memberof EditDAOArgs
     */
    isOpen: boolean;
    /**
     * DAOが設定しているリンク
     * @type {Array<string>}
     * @memberof EditDAOArgs
     */
    links: Array<string>;
    /**
     * 変更をするユーザーのアドレス
     * @type {EditDAOOperator}
     * @memberof EditDAOArgs
     */
    operator: EditDAOOperator;
}

/**
 * Check if a given object implements the EditDAOArgs interface.
 */
export function instanceOfEditDAOArgs(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('name' in value)) return false;
    if (!('description' in value)) return false;
    if (!('img' in value)) return false;
    if (!('admins' in value)) return false;
    if (!('joinToken' in value)) return false;
    if (!('governanceToken' in value)) return false;
    if (!('contributionToken' in value)) return false;
    if (!('isOpen' in value)) return false;
    if (!('links' in value)) return false;
    if (!('operator' in value)) return false;
    return true;
}

export function EditDAOArgsFromJSON(json: any): EditDAOArgs {
    return EditDAOArgsFromJSONTyped(json, false);
}

export function EditDAOArgsFromJSONTyped(json: any, ignoreDiscriminator: boolean): EditDAOArgs {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'description': json['description'],
        'img': json['img'],
        'admins': json['admins'],
        'joinToken': TokenInfoFromJSON(json['joinToken']),
        'governanceToken': TokenInfoFromJSON(json['governanceToken']),
        'contributionToken': TokenInfoFromJSON(json['contributionToken']),
        'isOpen': json['isOpen'],
        'links': json['links'],
        'operator': EditDAOOperatorFromJSON(json['operator']),
    };
}

export function EditDAOArgsToJSON(value?: EditDAOArgs | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'description': value['description'],
        'img': value['img'],
        'admins': value['admins'],
        'joinToken': TokenInfoToJSON(value['joinToken']),
        'governanceToken': TokenInfoToJSON(value['governanceToken']),
        'contributionToken': TokenInfoToJSON(value['contributionToken']),
        'isOpen': value['isOpen'],
        'links': value['links'],
        'operator': EditDAOOperatorToJSON(value['operator']),
    };
}

