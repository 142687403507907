import React, { useCallback, useEffect, useState } from "react";
import { getVoteResultByUser } from "../../actions/secretVote/vote-manager.ts";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/24/outline";
import { ModifiedProposal } from "src/pages/voting/Vote.tsx";
import { ANONYMOUS_ICON } from "src/const/const.ts";
import { useUser } from "src/hooks/useUser.ts";

type Props = {
  proposal: ModifiedProposal;
  daoId: string;
  index: number;
  activeIndex: number;
  callback: (proposalToAdd: ModifiedProposal | null) => void;
  onClick: (index: number) => void;
  updateTime: number;
};

const VoteStatus = {
  preparation: {
    text: "待機中",
    color: "text-gray-100",
    bgcolor: "border-transparent",
  },
  active: {
    text: "",
    color: "text-gray-800",
    bgcolor: "border-transparent",
  },
  passed: {
    text: "可決",
    color: "text-gray-100",
    bgcolor: "border-t-[#336AE9]",
  },
  rejected: {
    text: "否決",
    color: "text-gray-100",
    bgcolor: "border-t-[#D11277]",
  },
};

const UserVoteStatus = {
  none: {
    text: "進行中",
    color: "text-gray-800",
    bgcolor: "bg-gray-100",
  },
  preparation: {
    text: "待機中",
    color: "text-gray-100",
    bgcolor: "bg-gray-600",
  },
  expired: {
    text: "終了",
    color: "text-gray-100",
    bgcolor: "bg-gray-600",
  },
  favor: { text: "投票済", color: "text-gray-100", bgcolor: "bg-[#336AE9]" },
  against: { text: "投票済", color: "text-gray-100", bgcolor: "bg-[#D11277]" },
  abstention: {
    text: "投票済",
    color: "text-gray-100",
    bgcolor: "bg-[#969696]",
  },
};

const getFormatedDatetime = (
  date: Date = new Date(),
  format: string | undefined = "YYYY/MM/DD hh:mm:ss"
) =>
  [
    ["YYYY", date.getFullYear()],
    ["MM", date.getMonth() + 1],
    ["DD", date.getDate()],
    ["hh", date.getHours()],
    ["mm", date.getMinutes()],
    ["ss", date.getSeconds()],
    ["iii", date.getMilliseconds()],
  ].reduce(
    (s: any, a: any) => s.replace(a[0], `${a[1]}`.padStart(a[0].length, "0")),
    format
  );

const ProposedVote = (props: Props) => {
  const isActive = props.index === props.activeIndex;
  const [userVoteStatus, setUserVoteStatus] = useState<any>();
  const { user } = useUser();

  const getUserVoteStatus = useCallback(async () => {
    if (props.proposal.status === "preparation") {
      setUserVoteStatus(UserVoteStatus.preparation);
    } else {
      const voteResult = await getVoteResultByUser({
        daoId: props.daoId,
        proposalId: props.proposal.proposalId,
        publicKey: props.proposal.publicKey,
        userAddress: user!.address,
        votes: props.proposal.voters,
      });
      if (voteResult === "none" && props.proposal.status !== "active") {
        setUserVoteStatus(UserVoteStatus["expired"]);
      } else {
        setUserVoteStatus(UserVoteStatus[voteResult]);
      }
    }
  }, [user!.address, props.proposal.proposalId, props.updateTime]);
  useEffect(() => {
    const init = async () => {
      await getUserVoteStatus();
    };
    init();
  }, [props.updateTime]);
  const handleClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ): void => {
    props.onClick(props.index);
  };
  return (
    <div
      className={`flex w-full ${
        isActive ? "mb-0 md:mb-8" : "mb-8"
      } snap-start snap-always`}
      onClick={handleClick}
    >
      <div
        className={`relative flex w-full flex-col items-start justify-between gap-y-2 px-4 pb-1 pt-4 text-gray-50 md:min-h-[112px] md:p-4 ${
          isActive ? "bg-[#1A1D24] md:bg-[#38373D]" : "bg-[#1A1D24]"
        }`}
      >
        <div
          className={`absolute right-0 top-0 h-8 w-8 border-l-[48px] border-t-[48px]  ${
            VoteStatus[props.proposal.status!].bgcolor
          } border-l-transparent`}
        ></div>

        <div className="flex w-full items-center justify-between">
          <h3 className="text-base">{props.proposal.title}</h3>
        </div>

        <div className="flex w-full flex-wrap items-center justify-between gap-y-2">
          <div className="flex items-center justify-start -space-x-2">
            {props.proposal.voters.map((voter, idx) => (
              <div key={`${props.proposal.proposalId}_${idx}`}>
                <img
                  className={`h-6 w-6 rounded-full bg-[#1A1D24]`}
                  src={voter.img ? voter.img : ANONYMOUS_ICON}
                  alt={"voter"}
                />
              </div>
            ))}
          </div>

          <div className="flex items-center space-x-2">
            <div
              className={`p-1" } flex  h-6 w-fit items-center justify-center gap-x-1 rounded-md
            text-sm`}
            >
              <span>
                {getFormatedDatetime(
                  new Date(props.proposal.startTime),
                  "MM/DD"
                )}
              </span>
              <span>~</span>
              <span>
                {getFormatedDatetime(new Date(props.proposal.endTime), "MM/DD")}
              </span>
            </div>

            {userVoteStatus && (
              <div
                className={`flex min-w-[68px] items-center rounded-md px-1 py-0.5 ${userVoteStatus.bgcolor} ${userVoteStatus.color}`}
              >
                {userVoteStatus.text === "投票済" && (
                  <CheckIcon className="h-4 w-4" />
                )}
                <span
                  className={`flex w-full justify-center text-xs font-semibold`}
                >
                  {userVoteStatus.text}
                </span>
              </div>
            )}
          </div>
        </div>
        {!isActive && (
          <div className="-mt-3 flex w-full justify-center md:hidden">
            <ChevronDownIcon className="mt-1 h-5 w-5 text-gray-400" />
          </div>
        )}
      </div>
      <div className="hidden h-full w-16 items-center md:flex">
        {isActive && (
          <div className="h-0 w-0 border-b-[56px] border-l-[48px] border-t-[56px] border-b-transparent border-l-[#38373D] border-t-transparent"></div>
        )}
      </div>
    </div>
  );
};

export default ProposedVote;
