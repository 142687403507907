import { restApi } from "src/libs/restClient";
import useSWR from "swr";

const fetcher = async () => {
  return restApi.daoControllerFetchDaos();
};

export const useDaos = () => {
  const keys = ["/daos"];
  const { data, error, isLoading } = useSWR(keys, ([_]) => fetcher());

  return { dataDaos: data, isError: error, isLoading };
};
