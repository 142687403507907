/* tslint:disable */
/* eslint-disable */
/**
 * Unyte SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Voter
 */
export interface Voter {
    /**
     * 
     * @type {string}
     * @memberof Voter
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof Voter
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Voter
     */
    img?: string;
    /**
     * 
     * @type {string}
     * @memberof Voter
     */
    value: string;
    /**
     * 
     * @type {string}
     * @memberof Voter
     */
    zkp: string;
    /**
     * 
     * @type {number}
     * @memberof Voter
     */
    voteTime: number;
    /**
     * 
     * @type {string}
     * @memberof Voter
     */
    url: string;
}

/**
 * Check if a given object implements the Voter interface.
 */
export function instanceOfVoter(value: object): boolean {
    if (!('address' in value)) return false;
    if (!('name' in value)) return false;
    if (!('value' in value)) return false;
    if (!('zkp' in value)) return false;
    if (!('voteTime' in value)) return false;
    if (!('url' in value)) return false;
    return true;
}

export function VoterFromJSON(json: any): Voter {
    return VoterFromJSONTyped(json, false);
}

export function VoterFromJSONTyped(json: any, ignoreDiscriminator: boolean): Voter {
    if (json == null) {
        return json;
    }
    return {
        
        'address': json['address'],
        'name': json['name'],
        'img': json['img'] == null ? undefined : json['img'],
        'value': json['value'],
        'zkp': json['zkp'],
        'voteTime': json['voteTime'],
        'url': json['url'],
    };
}

export function VoterToJSON(value?: Voter | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'address': value['address'],
        'name': value['name'],
        'img': value['img'],
        'value': value['value'],
        'zkp': value['zkp'],
        'voteTime': value['voteTime'],
        'url': value['url'],
    };
}

