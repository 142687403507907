import React from "react";
import clsx from "clsx";
import { BaseProps } from "../../../../types/common";

export type ItemInventoryProps = {
  inventory: string;
} & BaseProps;

export const ItemInventory: React.FC<ItemInventoryProps> = ({
  className,
  inventory,
}) => {
  return (
    <div className={clsx(className, "text-gray-900", "dark:text-white")}>
      <span className={clsx("mr-1")}>残り</span>
      <span
        className={clsx(
          `${
            Number(inventory) === 0 && "text-red-500"
          } text-base font-bold lg:text-lg`
        )}
      >
        {inventory}
      </span>
    </div>
  );
};
