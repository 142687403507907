import React from "react";
import { BaseProps } from "../../types/common/index.ts";
import { useIntl } from "react-intl";
import { joinDAO } from "src/actions/blockchain/joinDAO.js";
import { useSetAtom } from "jotai";
import { inProgressDaoListState } from "src/stores/inProgressDaoListState.ts";
import { useUser } from "src/hooks/useUser.ts";

export type GetNftButtonProps = { daoId: string; isOpen: boolean } & BaseProps;

export const GetNftButton: React.FC<GetNftButtonProps> = ({
  className,
  daoId,
}) => {
  const intl = useIntl();
  const setInProgress = useSetAtom(inProgressDaoListState);
  const { user } = useUser();

  const getNft = async () => {
    setInProgress({ isShow: true, message: "NFTを取得中..." });
    try {
      await joinDAO(daoId, user);
      setInProgress({ isShow: false, message: "" });
      window.location.href = `${process.env.REACT_APP_BASE_FRONT_END_URL}/${daoId}`;
    } catch (e) {
      setInProgress({ isShow: false, message: "" });
      if (e instanceof Error) {
        alert(
          `DAO参加に失敗した可能性があります。ご確認ください。\n${e.stack}`
        );
      } else
        alert(
          `DAO参加に失敗した可能性があります。ご確認ください。\n\n${JSON.stringify(
            e
          )}`
        );
      return;
    }
  };

  return (
    <button
      className={`${className} rounded-full bg-unyte`}
      onClick={() => getNft()}
    >
      {intl.formatMessage({
        id: "list_get_nft",
        defaultMessage: "NFTを入手する",
      })}
    </button>
  );
};
