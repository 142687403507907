import React from "react";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import { useLocation, useNavigate } from "react-router-dom";
import { useRole } from "../../actions/firebase/role.ts";
import AdminOutputList from "./task/AdminOutputList.tsx";
import { ADMIN_MENU_ITEMS } from "src/const/const.ts";
import AdminChannelList from "src/pages/admin/message/AdminChannelList.tsx";
import AdminApplicationsList from "src/pages/admin/applications/AdminApplicationsList.tsx";

const Admin: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const daoId = location.pathname.split("/")[1];
  const daoMode = location.pathname.split("/")[3];
  const role = useRole(daoId);

  if (role !== "admin") {
    return <div className="text-white">No permission</div>;
  }
  return (
    <div className="divide-y divide-gray-600 text-white">
      <div className="flex h-16 items-center space-x-8 px-4 text-xl">
        <button
          onClick={() => {
            navigate(`/${daoId}`);
          }}
        >
          <ArrowLeftIcon className="h-12" />
        </button>
        <div className="space-x-4">
          {ADMIN_MENU_ITEMS.map((menuItem) => (
            <button
              key={menuItem}
              className={`uppercase hover:cursor-pointer ${
                daoMode == menuItem ? "underline" : "text-[#969696]"
              }`}
              onClick={() => {
                navigate(`/${daoId}/admin/${menuItem}`);
              }}
            >
              {menuItem}
            </button>
          ))}
        </div>
      </div>
      <div>
        {(() => {
          switch (daoMode) {
            case "task":
              return <AdminOutputList daoId={daoId} />;
            case "message":
              return <AdminChannelList daoId={daoId} />;
            case "applications":
              return <AdminApplicationsList daoId={daoId} />;
            default:
              return <div>Unknown mode</div>;
          }
        })()}
      </div>
    </div>
  );
};

export default Admin;
