import { firebaseFirestore } from "./config";
import {
  collection,
  doc,
  setDoc,
  getDoc,
  updateDoc,
  arrayUnion,
} from "firebase/firestore";

const taskConverter = {
  toFirestore(task) {
    return {
      taskId: task.taskId,
      daoId: task.daoId,
      author: {
        address: task.author.address,
        name: task.author.name || null,
        img: task.author.img || null,
      },
      title: task.title,
      operation: {
        operationId: task.operation.operationId,
        name: task.operation.name,
        color: task.operation.color,
        reward: task.reward.value || null,
        value: task.operation.value,
      },
      repeatId: task.repeatId,
      maxOutput: task.maxOutput,
      description: task.description,
      startTime: task.startTime,
      closingTime: task.closingTime,
      signature: task.signature || null,
    };
  },
  fromFirestore(snapshot, options) {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      name: data.name,
    };
  },
};

function createTaskCollection(daoId) {
  return collection(firebaseFirestore, "xTasks", daoId, "tag");
}

export async function updateXTask(daoId, taskId, task) {
  console.log("🚀 ~ file: createXTask.js:50 ~ updateXTask ~ task:", task);
  const taskRef = doc(createTaskCollection(daoId), taskId);
  if (task.operation.value.includes("#")) {
    task.operation.value = task.operation.value.replace("#", "");
  }
  const content = {
    author: task.author,
    daoId: task.daoId,
    description: task.description,
    taskId: taskId,
    startTime: task.startTime,
    closingTime: task.closingTime,
    status: task.status,
    operationId: task.operation.operationId,
    repeat: task.repeatId,
    reward: task.operation.reward,
    tag: task.operation.value,
    title: task.title,
  };
  console.log("🚀 ~ file: createXTask.js:69 ~ updateXTask ~ content:", content);
  try {
    await setDoc(taskRef, content);
  } catch (e) {
    console.log(e);
  }
}

export async function createXTask(task, daoId) {
  console.log("🚀 ~ file: createXTask.js:78 ~ createXTask ~ task:", task);
  const xTaskRef = doc(
    collection(firebaseFirestore, "xTasks", daoId, "tag"),
    task.taskId
  );
  // もしxTaskRefが存在しない場合は作成
  // tagに#が入っている場合は削除
  if (task.operation.value.includes("#")) {
    task.operation.value = task.operation.value.replace("#", "");
  }
  const content = {
    author: task.author,
    daoId: task.daoId,
    description: task.description,
    taskId: task.taskId,
    startTime: task.startTime,
    closingTime: task.closingTime,
    operationId: task.operation.operationId,
    repeat: task.repeatId,
    reward: task.operation.reward,
    tag: task.operation.value,
    title: task.title,
  };
  console.log(
    "🚀 ~ file: createXTask.js:101 ~ createXTask ~ content:",
    content
  );
  try {
    content.author.img = content.author.img || null; // imgがundefinedだとfirebase errorになるのでnullに変換
    await setDoc(xTaskRef, content);
    // 別テーブルにも登録
    const fetchTweetsRef = doc(firebaseFirestore, "fetchTweets", daoId);

    // 新しいタグを作成
    const newTag = {
      daoId: task.daoId,
      tag: task.operation.value,
      taskId: xTaskRef.id,
    };

    // ドキュメントが存在するか確認
    const docSnap = await getDoc(fetchTweetsRef);

    if (docSnap.exists()) {
      // ドキュメントが存在する場合、既存のドキュメントを更新
      await updateDoc(fetchTweetsRef, {
        tags: arrayUnion(newTag),
      });
    } else {
      // ドキュメントが存在しない場合、新しいドキュメントを作成
      await setDoc(fetchTweetsRef, { tags: [newTag] });
    }
  } catch (e) {
    console.log(e);
  }
}
