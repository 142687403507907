import { fetchDAOById } from "../../actions/blockchain/fetchDAO.js";
import { NETWORK_ERROR_MSG, EDIT_DAO_ENDPOINT } from "../../const/const.ts";
import { fireauth } from "src/actions/firebase/config.js";

async function editDAOAction(
  id,
  name,
  description,
  img,
  admins,
  joinToken,
  governanceToken,
  contributionToken,
  isOpen,
  links,
  owner
) {
  const json = {
    type: "editDAO",
    args: {
      id: id,
      name: name,
      description: description,
      img: img,
      admins: admins,
      joinToken: joinToken,
      governanceToken: governanceToken,
      contributionToken: contributionToken,
      isOpen: isOpen,
      links: links,
      operator: owner,
    },
  };

  const user = fireauth.currentUser;
  const token = await user.getIdToken(true);
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(json),
  };
  let response;
  try {
    response = await fetch(EDIT_DAO_ENDPOINT, options);
  } catch (e) {
    console.error(e);
    throw new Error(NETWORK_ERROR_MSG);
  }
  const responseJson = await response.json();
  if (responseJson.status !== "success") throw new Error(responseJson.message);
  return { daoId: id, response: responseJson };
}

export async function editDAO(
  daoId,
  name,
  description,
  img,
  confirmedValues,
  isOpen,
  links,
  owner
) {
  if (name === "" || description === "" || img === null)
    throw new Error("DAO名と説明文、画像を全て入力してください");
  if (owner == undefined) throw new Error("ログインしてください");

  const daoArray = await fetchDAOById(daoId);

  const joinToken = Object.values(daoArray[5]);
  const governanceToken = Object.values(daoArray[6]);
  const contributionToken = Object.values(daoArray[7]);
  const governanceTokenArray = [
    governanceToken[0],
    Number(governanceToken[1]),
    Number(governanceToken[2]),
  ];
  const contributionTokenArray = [
    contributionToken[0],
    Number(contributionToken[1]),
    Number(contributionToken[2]),
  ];
  const joinTokenArray = [
    joinToken[0],
    Number(joinToken[1]),
    Number(joinToken[2]),
  ];
  // DAOの情報をブロックチェーンに書き込むためにlambdaエンドポイントを叩く
  const linksJSON = JSON.stringify(links);
  console.log(daoId, name, description, img, linksJSON);
  const response = await editDAOAction(
    daoId,
    name,
    description,
    img,
    confirmedValues,
    joinTokenArray,
    governanceTokenArray,
    contributionTokenArray,
    isOpen,
    linksJSON,
    owner
  );
  return response;
}
