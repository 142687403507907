import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Vote from "../voting/Vote.tsx";
import { DaoDetailView } from "../../types/dao";
import Task from "../task/Task.tsx";
import DaoSetting from "./DaoEdit.tsx";
import Message from "../message/Message.tsx";
import Market from "../market/Market.tsx";
import "../../components/styles/SidebarAnime.css";
import Main from "../../components/main/main.tsx";
import { Progress } from "../../types/common/index.ts";
import { useRole } from "../../actions/firebase/role.ts";
import "../../components/styles/DaoDetailAnimation.css";
import clsx from "clsx";

import { useUser } from "src/hooks/useUser.ts";
import { useJoinedDaos } from "src/hooks/useJoinedDaos.ts";
import Sidebar from "src/components/dao/Sidebar.tsx";
import { SidebarSp } from "src/components/dao/SidebarSp.tsx";
import Thanks from "src/pages/thanks/Thanks.tsx";

const DaoDetail: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user, isError: isUserError, isLoading: isUserLoading } = useUser();
  const {
    dataJoinedDaos,
    isError: isErrorJoinedDao,
    isLoading: isLoadingJoinedDao,
  } = useJoinedDaos(user?.address);

  const daoId = location.pathname.split("/")[1];
  if (!daoId) {
    if (dataJoinedDaos && dataJoinedDaos.daos.length > 0) {
      navigate(dataJoinedDaos.daos[0].daoId);
    }
  }
  const role = useRole(daoId);
  const canEditChannels = role === "admin";
  let modeData = [
    "main",
    "vote",
    "task",
    "message",
    "thanks",
    "market",
  ] as DaoDetailView[];
  if (canEditChannels) {
    modeData = [
      "main",
      "vote",
      "task",
      "message",
      "thanks",
      "market",
      "setting",
      "admin",
    ] as DaoDetailView[];
  }
  const daoMode = location.pathname.split("/")[2] as DaoDetailView | undefined;
  const selectedChannelId =
    daoMode === "message" ? location.pathname.split("/")[3] : null;
  daoMode === "task" ? location.pathname.split("/")[3] : undefined;
  const outputId =
    daoMode === "task" ? location.pathname.split("/")[4] : undefined;
  const [inProgress, setInProgress] = useState<Progress>({
    isShow: true,
    message: "",
  });

  const navigateList = () => {
    navigate("/list");
  };
  const navigateRoot = () => {
    navigate(`/`);
  };

  useEffect(() => {
    if (isUserLoading || isLoadingJoinedDao) {
      setInProgress({ isShow: true, message: "Loading..." });
      return;
    }
    setInProgress({ isShow: false, message: "" });
  }, [isUserLoading, isLoadingJoinedDao]);

  useEffect(() => {
    if (!daoId) return;
    if (isLoadingJoinedDao || isUserLoading || !dataJoinedDaos || !user) return;
    const isJoined = dataJoinedDaos.daos.some((dao) => dao.daoId === daoId);
    if (!isJoined) {
      alert("指定したDAOへの参加権限がありません。");
      navigateRoot();
    }
  }, [daoId, user, isUserLoading, isLoadingJoinedDao]);
  return (
    <>
      <div
        className={clsx(
          "h-full w-full",
          "overflow-none",
          "flex flex-col",
          "lg:h-[calc(100vh-7.2rem)] lg:flex-row"
        )}
      >
        {dataJoinedDaos && daoId && (
          <>
            <Sidebar
              daoId={daoId}
              daos={dataJoinedDaos.daos}
              mode={daoMode}
              modeData={modeData}
              selectedChannelId={selectedChannelId}
            />
            <div className="h-full w-full lg:w-9/12">
              {dataJoinedDaos &&
                dataJoinedDaos.daos.length === 0 &&
                !inProgress.isShow && (
                  <div className="fixed flex h-full w-full items-center justify-center bg-unyte-main lg:justify-start">
                    <div className="mx-auto mb-10 w-2/3 text-white lg:mb-40 lg:ml-80 lg:w-full">
                      <h2 className="text-2xl">
                        どうやら、DAOに参加していないようです...
                      </h2>
                      <div className="m-auto mt-10 w-1/2 lg:ml-40 lg:mr-0">
                        <button
                          className="h-10 w-28 rounded-full bg-unyte"
                          onClick={() => navigateList()}
                        >
                          DAOを探す
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              {(isUserLoading || isLoadingJoinedDao) && <></>}
              {(isUserError ||
                !user ||
                !dataJoinedDaos ||
                isErrorJoinedDao) && <></>}
              {!daoMode && <Main daoId={daoId} />}
              {daoMode === "vote" && <Vote daoId={daoId} />}
              {daoMode === "task" && <Task daoId={daoId} outputId={outputId} />}
              {daoMode === "message" && selectedChannelId && (
                <Message daoId={daoId} channelId={selectedChannelId} />
              )}
              {daoMode === "setting" && <DaoSetting daoId={daoId} />}
              {daoMode === "market" && <Market daoId={daoId} />}
              {daoMode === "thanks" && <Thanks daoId={daoId} />}
            </div>
          </>
        )}
      </div>
      {dataJoinedDaos && daoId && (
        <SidebarSp
          daoId={daoId}
          daos={dataJoinedDaos.daos}
          mode={daoMode}
          modeData={modeData}
          selectedChannelId={selectedChannelId}
        />
      )}
    </>
  );
};

export default DaoDetail;
