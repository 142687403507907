/* tslint:disable */
/* eslint-disable */
/**
 * Unyte SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CreateDAOArgs } from './CreateDAOArgs';
import {
    CreateDAOArgsFromJSON,
    CreateDAOArgsFromJSONTyped,
    CreateDAOArgsToJSON,
} from './CreateDAOArgs';

/**
 * 
 * @export
 * @interface CreateDAORequest
 */
export interface CreateDAORequest {
    /**
     * Lambda関数の種類でcreateDAOを指定
     * @type {string}
     * @memberof CreateDAORequest
     */
    type: CreateDAORequestTypeEnum;
    /**
     * createDAOの引数
     * @type {CreateDAOArgs}
     * @memberof CreateDAORequest
     */
    args: CreateDAOArgs;
}


/**
 * @export
 */
export const CreateDAORequestTypeEnum = {
    CreateDao: 'createDAO',
    CreateNft: 'createNFT',
    EditDao: 'editDAO',
    AddItem: 'addItem',
    EditItem: 'editItem',
    Exchange: 'exchange',
    Proposal: 'proposal',
    Vote: 'vote',
    ApproveOutput: 'approveOutput',
    RejectOutput: 'rejectOutput',
    CreateOutput: 'createOutput',
    CreateTask: 'createTask'
} as const;
export type CreateDAORequestTypeEnum = typeof CreateDAORequestTypeEnum[keyof typeof CreateDAORequestTypeEnum];


/**
 * Check if a given object implements the CreateDAORequest interface.
 */
export function instanceOfCreateDAORequest(value: object): boolean {
    if (!('type' in value)) return false;
    if (!('args' in value)) return false;
    return true;
}

export function CreateDAORequestFromJSON(json: any): CreateDAORequest {
    return CreateDAORequestFromJSONTyped(json, false);
}

export function CreateDAORequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateDAORequest {
    if (json == null) {
        return json;
    }
    return {
        
        'type': json['type'],
        'args': CreateDAOArgsFromJSON(json['args']),
    };
}

export function CreateDAORequestToJSON(value?: CreateDAORequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'type': value['type'],
        'args': CreateDAOArgsToJSON(value['args']),
    };
}

