import React from "react";
import clsx from "clsx";
import { BaseProps } from "../../../../types/common";
import { useSetAtom } from "jotai";
import { isOpenEditPageMarketState } from "../../../../stores/isOpenEditPageMarketState.ts";
import { tmpAddAndEditItemState } from "../../../../stores/tmpItemState.ts";

export type ItemEditButtonProps = {
  item: any;
} & BaseProps;

export const ItemEditButton: React.FC<ItemEditButtonProps> = ({
  className,
  item,
}) => {
  const setIsOpenEditPage = useSetAtom(isOpenEditPageMarketState);
  const setTmpItemState = useSetAtom(tmpAddAndEditItemState);

  const handleClick = () => {
    setIsOpenEditPage(true);
    const tmpItem = item;
    setTmpItemState(tmpItem);
  };

  return (
    <button
      className={clsx(
        className,
        "flex",
        "rounded-xl bg-yellow-500 px-2 py-1 lg:px-5 lg:py-2.5",
        "text-white",
        "transition-all duration-300",
        "hover:rounded-3xl hover:bg-yellow-600"
      )}
      onClick={handleClick}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        strokeWidth="2"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
        />
      </svg>
    </button>
  );
};
