import React from "react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { BiLinkExternal } from "react-icons/bi";
import { TwitterTweetEmbed } from "react-twitter-embed";
import { Output, Task } from "src/types/api";
import { useUser } from "src/hooks/useUser";

const OutputStatus = {
  none: {
    text: "未承認",
    color: "text-gra-100",
    bgcolor: "bg-[#013bff]",
  },
  verified: {
    text: "承認済",
    color: "text-gray-100",
    bgcolor: "bg-[#260a40]",
  },
  verify: {
    text: "承認する",
    color: "text-gray-200",
    bgcolor: "bg-unyte",
  },
};

const getTweetId = (tweetLink: string) => {
  try {
    const match = tweetLink.match(/\/status\/(\d+)/);
    if (match) {
      return match[1];
    } else {
      console.error("no match", "getTweetId", tweetLink);
      return null;
    }
  } catch (e) {
    console.error(e.message, "getTweetId", tweetLink);
    return null;
  }
};

export type OffChainOutputDetailProps = {
  task: Task;
  output: Output;
};

export const OffChainOutputDetail: React.FC<OffChainOutputDetailProps> = ({
  task,
  output,
}: OffChainOutputDetailProps) => {
  const [showDetail, setShowDetail] = useState<boolean>(false);
  const { user } = useUser();

  return (
    <div className="w-full">
      <div className="mt-2 flex w-full flex-col gap-y-1">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-x-2 lg:ml-6">
            <div>
              <img
                className={`h-5 w-5 rounded-full bg-[#121217]`}
                src={output.author.img}
                alt={output.author.name}
              />
            </div>
            <div className="text-sm">{output.author.name}</div>
            <div className="text-xs"></div>
            <a href={output.link}>
              <BiLinkExternal />
            </a>
          </div>
          <div>
            <div
              className={`flex min-w-[68px] items-center rounded-md px-2 py-0.5 
                ${
                  output.status === "approved"
                    ? OutputStatus.verified.bgcolor
                    : task.author.address === user?.address
                      ? OutputStatus.verify.bgcolor
                      : OutputStatus.none.bgcolor
                } 
                ${
                  output.status === "approved"
                    ? OutputStatus.verified.color
                    : task.author.address === user?.address
                      ? OutputStatus.verify.color
                      : OutputStatus.none.color
                }`}
            >
              <span
                className={`flex w-full justify-center text-xs font-semibold`}
              >
                {output.status === "approved"
                  ? OutputStatus.verified.text
                  : task.author.address === user?.address
                    ? OutputStatus.verify.text
                    : OutputStatus.none.text}
              </span>
            </div>
          </div>
        </div>
        <div className="flex justify-center">
          {showDetail ? (
            <button
              onClick={() => {
                setShowDetail(false);
              }}
            >
              <ChevronUpIcon className="h-5 w-5" />
            </button>
          ) : (
            <button
              onClick={() => {
                setShowDetail(true);
              }}
            >
              <ChevronDownIcon className="h-5 w-5" />
            </button>
          )}
        </div>
        <div className="text-xs lg:ml-6">
          {new Date(output.date).toLocaleDateString("ja-JP")}
        </div>

        {showDetail && (
          <div className="flex w-full justify-center bg-[#121217] px-6">
            <TwitterTweetEmbed
              tweetId={getTweetId(output.link) ?? ""}
              placeholder={"取得中..."}
              options={{ theme: "dark", lang: "ja" }}
            />
          </div>
        )}
      </div>
    </div>
  );
};
