/* tslint:disable */
/* eslint-disable */
/**
 * Unyte SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AddItemArgs
 */
export interface AddItemArgs {
    /**
     * DAOのオーナーのアドレス
     * @type {string}
     * @memberof AddItemArgs
     */
    owner: string;
    /**
     * DAOのID
     * @type {string}
     * @memberof AddItemArgs
     */
    daoId: string;
    /**
     * アイテムの名前
     * @type {string}
     * @memberof AddItemArgs
     */
    itemName: string;
    /**
     * アイテムの説明文
     * @type {string}
     * @memberof AddItemArgs
     */
    itemDescription: string;
    /**
     * アイテムの画像
     * @type {string}
     * @memberof AddItemArgs
     */
    itemImg: string;
    /**
     * アイテムの価格
     * @type {number}
     * @memberof AddItemArgs
     */
    reward: number;
    /**
     * アイテムの在庫数
     * @type {number}
     * @memberof AddItemArgs
     */
    amount: number;
}

/**
 * Check if a given object implements the AddItemArgs interface.
 */
export function instanceOfAddItemArgs(value: object): boolean {
    if (!('owner' in value)) return false;
    if (!('daoId' in value)) return false;
    if (!('itemName' in value)) return false;
    if (!('itemDescription' in value)) return false;
    if (!('itemImg' in value)) return false;
    if (!('reward' in value)) return false;
    if (!('amount' in value)) return false;
    return true;
}

export function AddItemArgsFromJSON(json: any): AddItemArgs {
    return AddItemArgsFromJSONTyped(json, false);
}

export function AddItemArgsFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddItemArgs {
    if (json == null) {
        return json;
    }
    return {
        
        'owner': json['owner'],
        'daoId': json['daoId'],
        'itemName': json['itemName'],
        'itemDescription': json['itemDescription'],
        'itemImg': json['itemImg'],
        'reward': json['reward'],
        'amount': json['amount'],
    };
}

export function AddItemArgsToJSON(value?: AddItemArgs | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'owner': value['owner'],
        'daoId': value['daoId'],
        'itemName': value['itemName'],
        'itemDescription': value['itemDescription'],
        'itemImg': value['itemImg'],
        'reward': value['reward'],
        'amount': value['amount'],
    };
}

