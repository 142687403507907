/* tslint:disable */
/* eslint-disable */
/**
 * Unyte SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Task } from './Task';
import {
    TaskFromJSON,
    TaskFromJSONTyped,
    TaskToJSON,
} from './Task';

/**
 * 
 * @export
 * @interface TasksDto
 */
export interface TasksDto {
    /**
     * 
     * @type {Array<Task>}
     * @memberof TasksDto
     */
    tasks: Array<Task>;
}

/**
 * Check if a given object implements the TasksDto interface.
 */
export function instanceOfTasksDto(value: object): boolean {
    if (!('tasks' in value)) return false;
    return true;
}

export function TasksDtoFromJSON(json: any): TasksDto {
    return TasksDtoFromJSONTyped(json, false);
}

export function TasksDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TasksDto {
    if (json == null) {
        return json;
    }
    return {
        
        'tasks': ((json['tasks'] as Array<any>).map(TaskFromJSON)),
    };
}

export function TasksDtoToJSON(value?: TasksDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'tasks': ((value['tasks'] as Array<any>).map(TaskToJSON)),
    };
}

