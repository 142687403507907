import { doc, getDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { fireauth, firebaseFirestore } from "./config";
import { onAuthStateChanged } from "firebase/auth";

function roleRef(uid: string, daoId: string) {
  return doc(firebaseFirestore, "users", uid, "roles", daoId);
}

export function hasAdminRole(uid: string, daoId: string) {
  return getDoc(roleRef(uid, daoId)).then((doc) => {
    return doc.data()?.name === "admin";
  });
}

export function useRole(daoId: string): "member" | "admin" {
  const [role, setRole] = useState<"member" | "admin">("member");

  useEffect(() => {
    if (daoId === "") return;

    const unsubscribe = onAuthStateChanged(fireauth, async (user) => {
      if (!user) {
        return;
      }
      const ref = roleRef(user.uid, daoId);
      getDoc(ref)
        .then((doc) => {
          setRole(doc.data()?.name);
        })
        .catch((err) => {
          console.error(err);
        });
    });
    return unsubscribe;
  }, [daoId]);

  return role;
}
