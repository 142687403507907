import React, { useState, useEffect } from "react";
import { ImCamera } from "react-icons/im";
import UniSwaTextField from "../../components/atoms/UniSwaTextField.tsx";
import { ProfileChangeErrorType } from "../../types/error/index.ts";
import { isValidEthAddress } from "../../actions/blockchain/wallet.js";
import unyteSquare from "../../assets/unyte_square.png";
import { updateUser } from "../../actions/firebase/user.ts";
import { ChangeProfile } from "../../types/common/index.ts";
import { uploadFile } from "../../actions/firebase/imageUpload.ts";
import { BiPencil } from "react-icons/bi";
import { trim } from "../../utils/textInputUtils.ts";
import { useUser } from "src/hooks/useUser.ts";
import { requestForToken } from "../../../src/actions/firebase/config.js";

const Profile: React.FC = () => {
  const { user, mutate: mutateUser } = useUser();
  const [userName, setUserName] = useState<string>("");
  const [profileImage, setProfileImage] = useState<string | null>("");
  const [profileBase64, setProfileBase64] = useState<any>(null);
  const [iconFile, setIconFile] = useState<File | null>(null);
  const [onNotificationSetting, setOnNotificationSetting] =
    useState<boolean>(false);

  const [stateError, setStateError] = useState<ProfileChangeErrorType>({
    isUserNameError: false,
    isOutsideAccountError: false,
    isXAccountError: false,
  });

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      if (!verifyImageFile(file)) return;
      setIconFile(file);
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target?.result === undefined) return;
        if (event.target.result instanceof ArrayBuffer) return;
        setProfileImage(event.target.result);
        if (reader.result === null) return;
        if (reader.result instanceof ArrayBuffer) return;
        const base64 = reader.result.split(",")[1];
        setProfileBase64(base64);
      };
      reader.readAsDataURL(file);
    }
  };

  const verifyImageFile = (file: File) => {
    // png, jpg, jpeg, webpのみ許可
    if (!file.type.startsWith("image/")) {
      alert("画像ファイルを選択してください。");
      return false;
    }
    if (file.size > 1024 * 1024 * 10) {
      alert("画像ファイルのサイズは10MB以下にしてください。");
      return false;
    }
    return true;
  };
  const updateProfile = async () => {
    try {
      const url = await handleUpload();
      if (!url) return;
      if (!user) return;
      if (!isValidEthAddress(user.address)) {
        alert("外部アカウントの形式が正しくありません。");
        setStateError((prev) => ({ ...prev, isOutsideAccountError: true }));
        return;
      }
      const trimedName = trim(userName);
      if (trimedName.length > 50) {
        alert("ユーザーネームは50文字以下にしてください。");
        setStateError((prev) => ({ ...prev, isUserNameError: true }));
        return;
      }
      setStateError((prev) => ({
        ...prev,
        isOutsideAccountError: false,
        isUserNameError: false,
      }));
      let changeUser: ChangeProfile = {
        uid: user.uid,
        name: trimedName,
        outsideAccount: user.address,
        img: url,
      };
      await updateUser(changeUser);
      alert("プロフィールを更新しました。");
      mutateUser();
    } catch (error) {
      console.error(error);
      alert("プロフィールの更新に失敗しました。");
    }
  };

  const handleUpload = async () => {
    // fileをpng, jpg, jpeg, webpのみ許可

    if (!iconFile) return profileImage;
    if (!user) return;
    try {
      const result = await uploadFile(iconFile, user.uid);
      return result;
    } catch (error) {
      console.error(error);
      alert("画像のアップロードに失敗しました。");
      return null;
    }
  };

  useEffect(() => {
    if (user) {
      setUserName(user.name);
      user.img ? setProfileImage(user.img) : setProfileImage(unyteSquare);
    }
  }, [user]);

  return (
    <div className="h-scren mt-4 flex w-full flex-col items-center lg:mt-28">
      <div className="mx-auto w-full text-white lg:flex lg:w-5/6 lg:justify-between">
        <div className="text-white lg:w-1/4">
          <label
            htmlFor="profileImage"
            className="relative m-auto flex h-20 w-20 items-center justify-center rounded-full bg-[#38373D] hover:cursor-pointer lg:h-36 lg:w-36"
          >
            <input
              type="file"
              className="hidden bg-gray-50"
              id="profileImage"
              onChange={handleImageChange}
            />
            <div className="absolute bottom-[6px] right-[4px] flex h-4 w-4 items-center justify-center rounded-full bg-white lg:hidden">
              <BiPencil className="text-xs text-[#29282E]" />
            </div>

            {!profileImage ? (
              <div className="flex flex-col">
                <ImCamera className="mx-auto mb-2 text-center text-5xl text-[#29282E]" />
                <p className="text-center text-sm text-[#8B8B8C] hover:cursor-pointer">
                  ファイルを
                  <br />
                  選択する
                </p>
              </div>
            ) : (
              <img
                src={profileImage}
                alt="Profile"
                className="h-20 w-20 cursor-pointer rounded-full lg:h-36 lg:w-36"
              />
            )}
          </label>
        </div>

        <div className="mx-auto w-11/12 text-sm lg:w-3/4">
          <div className="mb-4 text-white">
            <div className="flex justify-between">
              <p className="mb-2 ml-6">ユーザーネーム</p>
              <p className="mb-2 mr-2">{userName ? userName.length : 0} / 50</p>
            </div>
            <UniSwaTextField
              value={userName}
              placeholder=""
              isCreateDao={true}
              maxLength={50}
              onChange={(e) => setUserName(e.target.value)}
            />
          </div>
          <div className="mb-4 text-white">
            <p className="mb-2 ml-6">ログインアカウント情報</p>
            <UniSwaTextField
              value={user?.address ?? ""}
              placeholder=""
              isCreateDao={true}
              disabled={true}
              onChange={() => {}}
            />
          </div>
        </div>
      </div>

      <div className="mx-auto mt-4 w-11/12 space-y-6 text-white lg:mt-12 lg:flex lg:w-3/5 lg:justify-center lg:space-y-0">
        <div>
          <button
            className="w-full rounded-full bg-unyte px-6 py-2 text-white lg:mr-8 lg:w-fit lg:py-1"
            onClick={() => updateProfile()}
          >
            更新
          </button>
        </div>
        <div>
          <button
            className={`w-full rounded-full px-6 py-2 text-white lg:w-fit lg:py-1 ${
              onNotificationSetting ? "bg-gray-500" : "bg-task-button"
            }`}
            onClick={async () => {
              setOnNotificationSetting(true);
              await requestForToken();
              setOnNotificationSetting(false);
            }}
            disabled={onNotificationSetting}
          >
            {onNotificationSetting ? "処理中..." : "プッシュ通知を有効にする"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Profile;
