import { useIsUpdating } from "src/hooks/useIsUpdating";
import { restApi } from "src/libs/restClient";
import useSWR from "swr";

const fetcher = async (key: string) => {
  const daoId = key.split("/")[2];
  return restApi.daoControllerFetchDaoDetail({ daoId });
};

export const useDaoDetail = (daoId: string) => {
  const {
    data,
    error,
    isLoading,
    mutate: mutateDaoDetail,
  } = useSWR(`/daos/${daoId}`, fetcher);

  const { isUpdating, checkUpdated } = useIsUpdating({
    data,
    mutate: mutateDaoDetail,
  });

  const mutate = async () => {
    checkUpdated();
    await mutateDaoDetail();
  };
  return {
    daoDetail: data,
    isError: error,
    isLoading,
    isUpdating,
    mutate,
    checkUpdated,
  };
};
