import React from "react";
import clsx from "clsx";
import { BaseProps } from "../../../types/common/index.ts";
import { useSetAtom } from "jotai";
import { PlusCircleIcon, PlusIcon } from "@heroicons/react/24/outline";
import { isOpenAddPageMarketState } from "../../../stores/isOpenAddPageMarketState.ts";

export type OpenAddPageButtonProps = {} & BaseProps;

export const OpenAddPageButton: React.FC<OpenAddPageButtonProps> = ({
  className,
}) => {
  const setIsOpenAddPage = useSetAtom(isOpenAddPageMarketState);

  const handleClick = () => {
    setIsOpenAddPage(true);
  };

  return (
    <button
      className={clsx(
        className,
        "flex items-center justify-center",
        "p-2",
        "rounded-full",
        "bg-gradient-to-r from-[#EE7b4d] to-[#E5007E]",
        "text-gray-50",
        "sm:px-4, sm:py-2"
      )}
      onClick={handleClick}
    >
      <PlusCircleIcon className={clsx("hidden", "h-6 w-6", "sm:block")} />
      <span className={clsx("hidden", "text-sm", "w-fit", "pl-0", "sm:block")}>
        追加
      </span>
      <PlusIcon className={clsx("h-4 w-4", "sm:hidden")} />
    </button>
  );
};
