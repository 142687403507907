/* tslint:disable */
/* eslint-disable */
/**
 * Unyte SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EditDAOOperator
 */
export interface EditDAOOperator {
    /**
     * ユーザーのアドレス
     * @type {string}
     * @memberof EditDAOOperator
     */
    address: string;
    /**
     * ユーザーのメールアドレス
     * @type {string}
     * @memberof EditDAOOperator
     */
    email: string;
    /**
     * ユーザーの画像
     * @type {string}
     * @memberof EditDAOOperator
     */
    img: string;
    /**
     * ユーザーの名前
     * @type {string}
     * @memberof EditDAOOperator
     */
    name: string;
    /**
     * ユーザーのshareのID
     * @type {string}
     * @memberof EditDAOOperator
     */
    shareId: string;
    /**
     * ユーザーのtwitterのID
     * @type {string}
     * @memberof EditDAOOperator
     */
    twitterUseName?: string;
    /**
     * ユーザーのUID
     * @type {string}
     * @memberof EditDAOOperator
     */
    uid: string;
}

/**
 * Check if a given object implements the EditDAOOperator interface.
 */
export function instanceOfEditDAOOperator(value: object): boolean {
    if (!('address' in value)) return false;
    if (!('email' in value)) return false;
    if (!('img' in value)) return false;
    if (!('name' in value)) return false;
    if (!('shareId' in value)) return false;
    if (!('uid' in value)) return false;
    return true;
}

export function EditDAOOperatorFromJSON(json: any): EditDAOOperator {
    return EditDAOOperatorFromJSONTyped(json, false);
}

export function EditDAOOperatorFromJSONTyped(json: any, ignoreDiscriminator: boolean): EditDAOOperator {
    if (json == null) {
        return json;
    }
    return {
        
        'address': json['address'],
        'email': json['email'],
        'img': json['img'],
        'name': json['name'],
        'shareId': json['shareId'],
        'twitterUseName': json['twitterUseName'] == null ? undefined : json['twitterUseName'],
        'uid': json['uid'],
    };
}

export function EditDAOOperatorToJSON(value?: EditDAOOperator | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'address': value['address'],
        'email': value['email'],
        'img': value['img'],
        'name': value['name'],
        'shareId': value['shareId'],
        'twitterUseName': value['twitterUseName'],
        'uid': value['uid'],
    };
}

