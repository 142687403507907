/* tslint:disable */
/* eslint-disable */
/**
 * Unyte SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LambdaFuncEnum } from './LambdaFuncEnum';
import {
    LambdaFuncEnumFromJSON,
    LambdaFuncEnumFromJSONTyped,
    LambdaFuncEnumToJSON,
} from './LambdaFuncEnum';
import type { VoteArgs } from './VoteArgs';
import {
    VoteArgsFromJSON,
    VoteArgsFromJSONTyped,
    VoteArgsToJSON,
} from './VoteArgs';

/**
 * 
 * @export
 * @interface VoteRequest
 */
export interface VoteRequest {
    /**
     * 
     * @type {LambdaFuncEnum}
     * @memberof VoteRequest
     */
    type: LambdaFuncEnum;
    /**
     * 投票内容
     * @type {VoteArgs}
     * @memberof VoteRequest
     */
    args: VoteArgs;
}

/**
 * Check if a given object implements the VoteRequest interface.
 */
export function instanceOfVoteRequest(value: object): boolean {
    if (!('type' in value)) return false;
    if (!('args' in value)) return false;
    return true;
}

export function VoteRequestFromJSON(json: any): VoteRequest {
    return VoteRequestFromJSONTyped(json, false);
}

export function VoteRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): VoteRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'type': LambdaFuncEnumFromJSON(json['type']),
        'args': VoteArgsFromJSON(json['args']),
    };
}

export function VoteRequestToJSON(value?: VoteRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'type': LambdaFuncEnumToJSON(value['type']),
        'args': VoteArgsToJSON(value['args']),
    };
}

