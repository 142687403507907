import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// @ts-ignore
import Ikea from "../../assets/ikea.jpg";
// @ts-ignore
import SectionBox from "../../components/createDao/SectionBox.tsx";
// @ts-ignore
import UniSwaTextField from "../../components/atoms/UniSwaTextField.tsx";
// @ts-ignore
import BadgeRadioButton from "../../components/createDao/BadgeRadioButton.tsx";
// @ts-ignore
import BadgeMultiButton from "../../components/createDao/BadgeMultiButton.tsx";
// @ts-ignore
import UnyteButton from "../../components/atoms/UnyteButton.tsx";
import { DaoTaskCreationOption } from "../../types/dao";

interface Props {
  daoTaskCreationOption: DaoTaskCreationOption;
  setDaoTaskCreationOption: React.Dispatch<
    React.SetStateAction<DaoTaskCreationOption>
  >;
}

type OptionType = {
  value: string;
  label: string;
};

const DaoSetting: React.FC<Props> = ({
  daoTaskCreationOption,
  setDaoTaskCreationOption,
}) => {
  const [taskName, setTaskName] = useState<string>("");
  const [a, setA] = useState<string>("");
  const [b, setB] = useState<string[]>([""]);
  const [c, setC] = useState<string>("");

  const badgeOptions: OptionType[] = [
    { value: "option1", label: "Option 1" },
    { value: "option2", label: "Option 2" },
    { value: "opti on1", label: "Opti 1" },
    { value: "opti on2", label: "Opn 2" },
    // ... 他のオプション
  ];
  const ops2: OptionType[] = [
    { value: "a", label: "Option 1" },
    { value: "b", label: "Option 2" },
    { value: "c", label: "Opti 1" },
    { value: "d", label: "Opn 2" },
    // ... 他のオプション
  ];
  const navigate = useNavigate();
  const daoId = useLocation().pathname.split("/")[1] as string;

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedDao, setSelectedDao] = useState<number | null>(null);

  const handleCardClick = (idx: number) => {
    setSelectedDao(idx);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedDao(null);
  };

  const navigateVote = () => {
    navigate(`${daoId}/vote`);
  };

  const navigateEdit = () => {
    navigate(`${daoId}/detail/edit`);
  };

  const navigateBack = () => {
    navigate(-1);
  };

  return (
    <div className="my-4 min-h-screen w-full sm:flex">
      <div className="flex-col sm:w-4/5">
        <SectionBox title="タスク名">
          <UniSwaTextField
            placeholder="X (旧Twitter) アカウントフォロー"
            value={taskName}
            onChange={(e) => setTaskName(e.target.value)}
          />
        </SectionBox>

        <SectionBox title="分類">
          <BadgeRadioButton
            options={badgeOptions}
            selectedValue={a}
            onChange={(value) => setA(value)}
          />
        </SectionBox>

        <SectionBox title="アクション">
          <BadgeRadioButton
            options={badgeOptions}
            selectedValue={a}
            onChange={(value) => setA(value)}
          />
        </SectionBox>

        <SectionBox title="対象アカウント">
          <UniSwaTextField
            placeholder="abcdefu"
            value={taskName}
            onChange={(e) => setTaskName(e.target.value)}
          />
        </SectionBox>

        <SectionBox title="リワード額">
          <div className="mb-2 text-base">UNYT </div>
          <UniSwaTextField
            placeholder="X (旧Twitter) アカウントフォロー"
            value={taskName}
            onChange={(e) => setTaskName(e.target.value)}
          />
        </SectionBox>

        <SectionBox title="条件">
          <BadgeMultiButton
            options={ops2}
            selectedValues={b}
            onChange={(value) => setB(value)}
          />
        </SectionBox>

        <div className="flex justify-around">
          <UnyteButton
            name="下書き保存"
            onClick={() => setDaoTaskCreationOption("draft")}
            buttonValue={false}
            status={true}
          ></UnyteButton>
          <UnyteButton
            name="公開"
            onClick={() => setDaoTaskCreationOption("public")}
            buttonValue={false}
            status={true}
          ></UnyteButton>
        </div>
      </div>
      <div className="flex-col sm:w-1/5">
        <div className="mb-4 text-base">テンプレート一覧</div>
      </div>
    </div>
  );
};

export default DaoSetting;
