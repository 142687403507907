import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import "./index.css";
import App from "./App.tsx";
import reportWebVitals from "./reportWebVitals";
import { IntlProvider } from "react-intl";
import ja from "./i18n/ja";
import en from "./i18n/en";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

const currentLang = navigator.language || "ja"; // デフォルトを日本語に設定

const i18nMessages = {
  en: en,
  ja: ja,
};

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <Router>
      <IntlProvider
        locale={currentLang}
        messages={i18nMessages[currentLang] || i18nMessages.en}
      >
        <App />
      </IntlProvider>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
serviceWorkerRegistration.register();
