import React from "react";
import clsx from "clsx";
import { BaseProps } from "../../../../../types/common/index.ts";

export type ItemBuyButtonProps = {
  setIsBuyFlag: React.Dispatch<React.SetStateAction<boolean>>;
  setIsOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
} & BaseProps;

export const ItemBuyButton: React.FC<ItemBuyButtonProps> = ({
  className,
  setIsBuyFlag,
  setIsOpenModal,
}) => {
  const handleClick = async () => {
    setIsBuyFlag(true);
    setIsOpenModal(false);
  };

  return (
    <button
      className={clsx(
        className,
        "py-2",
        "w-2/3",
        "bg-unyte",
        "rounded-full",
        "lg:w-1/3"
      )}
      onClick={handleClick}
    >
      Buy
    </button>
  );
};
