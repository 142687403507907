/* tslint:disable */
/* eslint-disable */
/**
 * Unyte SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PurchaseArgs } from './PurchaseArgs';
import {
    PurchaseArgsFromJSON,
    PurchaseArgsFromJSONTyped,
    PurchaseArgsToJSON,
} from './PurchaseArgs';

/**
 * 
 * @export
 * @interface PurchaseRequest
 */
export interface PurchaseRequest {
    /**
     * Lambdaの関数でPurchaseを指定する
     * @type {string}
     * @memberof PurchaseRequest
     */
    type: PurchaseRequestTypeEnum;
    /**
     * 購入するアイテムの情報
     * @type {PurchaseArgs}
     * @memberof PurchaseRequest
     */
    args: PurchaseArgs;
}


/**
 * @export
 */
export const PurchaseRequestTypeEnum = {
    CreateDao: 'createDAO',
    CreateNft: 'createNFT',
    EditDao: 'editDAO',
    AddItem: 'addItem',
    EditItem: 'editItem',
    Exchange: 'exchange',
    Proposal: 'proposal',
    Vote: 'vote',
    ApproveOutput: 'approveOutput',
    RejectOutput: 'rejectOutput',
    CreateOutput: 'createOutput',
    CreateTask: 'createTask'
} as const;
export type PurchaseRequestTypeEnum = typeof PurchaseRequestTypeEnum[keyof typeof PurchaseRequestTypeEnum];


/**
 * Check if a given object implements the PurchaseRequest interface.
 */
export function instanceOfPurchaseRequest(value: object): boolean {
    if (!('type' in value)) return false;
    if (!('args' in value)) return false;
    return true;
}

export function PurchaseRequestFromJSON(json: any): PurchaseRequest {
    return PurchaseRequestFromJSONTyped(json, false);
}

export function PurchaseRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PurchaseRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'type': json['type'],
        'args': PurchaseArgsFromJSON(json['args']),
    };
}

export function PurchaseRequestToJSON(value?: PurchaseRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'type': value['type'],
        'args': PurchaseArgsToJSON(value['args']),
    };
}

