/* tslint:disable */
/* eslint-disable */
/**
 * Unyte SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PostAuthXAccountDto
 */
export interface PostAuthXAccountDto {
    /**
     * Twitterの認証コード
     * @type {string}
     * @memberof PostAuthXAccountDto
     */
    code: string;
}

/**
 * Check if a given object implements the PostAuthXAccountDto interface.
 */
export function instanceOfPostAuthXAccountDto(value: object): boolean {
    if (!('code' in value)) return false;
    return true;
}

export function PostAuthXAccountDtoFromJSON(json: any): PostAuthXAccountDto {
    return PostAuthXAccountDtoFromJSONTyped(json, false);
}

export function PostAuthXAccountDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostAuthXAccountDto {
    if (json == null) {
        return json;
    }
    return {
        
        'code': json['code'],
    };
}

export function PostAuthXAccountDtoToJSON(value?: PostAuthXAccountDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'code': value['code'],
    };
}

