import React, { useCallback, useEffect, useRef, useState } from "react";
import { ethers } from "ethers";
import {
  VoteData,
  TypedMessageDomain,
  TypedMessageType,
} from "../../types/vote";
import {
  getVoteResultValue,
  addVote,
  getVoteResultByUser,
} from "../../actions/secretVote/vote-manager.ts";
import { decryptoPrivateKey } from "../../actions/blockchain/generateKeys";
import * as voteLib from "../../actions/secretVote/vote-lib.js";
import Loading from "../../components/common/Loading.tsx";
import {
  ArrowTopRightOnSquareIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/24/outline";
import Debug from "./Debug.tsx";
import { useNavigate } from "react-router-dom";
import { formatName } from "../../utils/formatAddress.ts";
import { ModifiedProposal } from "src/pages/voting/Vote.tsx";
import { ANONYMOUS_ICON } from "src/const/const.ts";
import { useUser } from "src/hooks/useUser.ts";

const formatAddress = (address: string) => {
  try {
    const start = address.substring(0, 6);
    const end = address.substring(address.length - 4);
    return `${start}...${end}`;
  } catch (e) {
    console.error((e as Error).message);
  }
};

const getFormatedDatetime = (
  date: Date = new Date(),
  format: string | undefined = "YYYY/MM/DD hh:mm:ss"
) =>
  [
    ["YYYY", date.getFullYear()],
    ["MM", date.getMonth() + 1],
    ["DD", date.getDate()],
    ["hh", date.getHours()],
    ["mm", date.getMinutes()],
    ["ss", date.getSeconds()],
    ["iii", date.getMilliseconds()],
  ].reduce(
    (s: any, a: any) => s.replace(a[0], `${a[1]}`.padStart(a[0].length, "0")),
    format
  );

const contractAddress = process.env.REACT_APP_VOTE_CONTRACT_ADDRESS as string;

const typedMessageDomain: TypedMessageDomain = {
  name: "Vote",
  chainId: Number(process.env.REACT_APP_OUTPUT_CHAIN_ID),
  verifyingContract: contractAddress,
  version: "1",
  salt: "",
};

const typedMessageType: TypedMessageType = {
  Vote: [
    { name: "voter", type: "address" },
    { name: "value", type: "string" },
    { name: "zkp", type: "string" },
    { name: "voteTime", type: "string" },
    { name: "hash", type: "bytes32" },
  ],
};

const UserVoteStatus = {
  none: {
    type: "none",
    text: "進行中",
    color: "text-gray-800",
    bgcolor: "bg-gray-100",
  },
  preparation: {
    type: "preparation",
    text: "待機中",
    color: "text-gray-100",
    bgcolor: "bg-gray-600",
  },
  favor: {
    type: "favor",
    text: "投票済",
    color: "text-gray-100",
    bgcolor: "bg-[#336AE9]",
  },
  against: {
    type: "against",
    text: "投票済",
    color: "text-gray-100",
    bgcolor: "bg-[#D11277]",
  },
  abstention: {
    type: "abstention",
    text: "投票済",
    color: "text-gray-100",
    bgcolor: "bg-[#969696]",
  },
};

type Props = {
  daoId: string;
  proposal: ModifiedProposal;
  activeIndex: number;
  callback: (proposalToVoteAdd: ModifiedProposal | null) => void;
  editCallbackHandler: (IsEditProcess: boolean) => void;
  updateTime: number;
};

const VoteDetail = (props: Props) => {
  const navigate = useNavigate();
  const [voteResult, setVoteResult] = useState<any>(null);
  const [isMore, setIsMore] = useState<boolean>(false);

  const [userVoteStatus, setUserVoteStatus] = useState<any>();
  const { user } = useUser();

  const getUserVoteStatus = useCallback(async () => {
    if (props.proposal.status === "preparation") {
      setUserVoteStatus(UserVoteStatus.preparation);
    } else {
      const voteResult = await getVoteResultByUser({
        daoId: props.daoId,
        proposalId: props.proposal.proposalId,
        publicKey: props.proposal.publicKey,
        userAddress: user!.address,
        votes: props.proposal.voters,
      });
      setUserVoteStatus(UserVoteStatus[voteResult]);
    }
  }, [JSON.stringify(props.proposal), props.activeIndex, props.updateTime]);

  const [inProgress, setInProgress] = useState<{
    isShow: boolean;
    message: string;
  }>({ isShow: false, message: "" });
  const isProcessing = useRef<boolean>(false);

  const [isDebugOpen, setIsDebugOpen] = useState<string | null>(null);

  useEffect(() => {
    const init = async () => {
      setInProgress({ isShow: true, message: "復号/計算中..." });
      setVoteResult(null);
      await getUserVoteStatus();
      if (
        props.proposal.status === "passed" ||
        props.proposal.status === "rejected"
      ) {
        getVoteResultValue({
          daoId: props.daoId,
          proposalId: props.proposal.proposalId,
          publicKey: props.proposal.publicKey,
          votes: props.proposal.voters,
        }).then((value) => {
          setVoteResult(value);
        });
      } else {
        setVoteResult({
          publicKey: props.proposal.publicKey,
          votes: props.proposal.voters,
        });
      }
      setInProgress({ isShow: false, message: "" });
    };
    init();
  }, [JSON.stringify(props.proposal), props.activeIndex, props.updateTime]);

  const findVoteTime = (voteTime: number): number | null => {
    const date = getFormatedDatetime(new Date(voteTime), "MM/DD hh:mm");
    return date;
  };

  const buttonClickHandler = async (selected: number) => {
    console.log("selected", props);
    if (isProcessing.current) return;
    isProcessing.current = true;

    setInProgress({ isShow: true, message: "投票中..." });
    let value: number[] | null = null;
    if (selected === 1) {
      value = [1, 0];
    } else if (selected === 2) {
      value = [0, 1];
    } else if (selected === 3) {
      value = [0, 0];
    } else if (selected === 4) {
      props.editCallbackHandler(true);
      isProcessing.current = false;
      return;
    } else {
      return;
    }

    let keyBytes: string[];
    try {
      keyBytes = (await decryptoPrivateKey(user)) as string[];
      if (!keyBytes || keyBytes.length === 0)
        throw new Error("秘密鍵の復元に失敗しました。");
    } catch (e) {
      console.error(e);
      setInProgress({ isShow: false, message: "" });
      if (e instanceof Error) {
        const isConfirm = confirm(
          `投票に失敗しました\n${e.message}\nシェアを再設定しますか？`
        );
        if (isConfirm) navigate("/reset");
      } else alert("不明なエラーです。運営にお問い合わせください。");
      return;
    }
    const privateKey = keyBytes.join("").replace("\u0000\u0000", "");
    const wallet = new ethers.Wallet(privateKey);
    await voteLib.init();
    const v = voteLib.vote(value, props.proposal.publicKey);
    const hash = ethers.keccak256(
      ethers.toUtf8Bytes(
        JSON.stringify({
          daoId: props.daoId,
          proposalId: props.proposal.proposalId,
          proposer: props.proposal.proposer.address,
          title: props.proposal.title,
          description: props.proposal.description,
          startTime: props.proposal.startTime,
          closingTime: props.proposal.endTime,
        })
      )
    );

    const voteData: VoteData = {
      voter: wallet.address,
      value: v.value,
      zkp: v.zkp,
      voteTime: v.time.toString(),
      hash: hash,
    };
    typedMessageDomain.salt = ethers.keccak256(
      ethers.toUtf8Bytes(v.time.toString())
    );
    voteData.signature = await wallet.signTypedData(
      typedMessageDomain,
      typedMessageType,
      voteData
    );

    try {
      await addVote({
        daoId: props.daoId,
        voteId: props.proposal.proposalId,
        vote: voteData,
      });
    } catch (e) {
      console.error(e);
      setInProgress({ isShow: false, message: "" });
      if (e instanceof Error) {
        alert(`投票に失敗した可能性があります。ご確認ください。\n${e.stack}`);
      } else
        alert(
          `投票に失敗した可能性があります。ご確認ください。\n\n${JSON.stringify(
            e
          )}`
        );
    }

    const result = { ...props.proposal };

    props.callback(result);
    isProcessing.current = false;
    setInProgress({ isShow: false, message: "" });
  };

  return (
    <div className="h-full w-full bg-[#1A1D24] px-4 pb-4 pt-2 md:bg-[#121217] md:px-8">
      {props.proposal && (
        <div className="w-full text-gray-50">
          <div className="hidden text-2xl md:flex">{props.proposal.title}</div>
          <div>
            <p
              className={`ml-0 mt-0 whitespace-pre-wrap text-xs md:ml-6 md:mt-2 md:text-xl ${
                isMore ? "" : "line-clamp-6"
              }`}
            >
              {props.proposal.description}
            </p>
            <div className="mt-2 flex justify-center">
              <button onClick={() => setIsMore(!isMore)}>
                {isMore ? (
                  <ChevronUpIcon className="h-5 w-5" />
                ) : (
                  <ChevronDownIcon className="h-5 w-5" />
                )}
              </button>
            </div>
            <div className="ml-0 mt-2 flex flex-wrap items-center gap-x-1 text-sm md:ml-6">
              <span>proposed by:</span>
              <img
                className="h-4 w-4 rounded-full bg-[#1A1D24]"
                src={
                  props.proposal.proposer.img
                    ? props.proposal.proposer.img
                    : ANONYMOUS_ICON
                }
                alt={props.proposal.proposer.name}
                onError={(e) => {
                  e.currentTarget.onerror = null;
                  e.currentTarget.src = ANONYMOUS_ICON;
                }}
              />
              <div className="text-sm">{props.proposal.proposer.name}</div>
              <div className="text-xs">
                {`(${formatAddress(props.proposal.proposer.address)})`}
              </div>
            </div>
            {/* <div className="flex text-sm mt-2 gap-x-2 ml-6">
              <span
                className={`rounded-md px-2 ${
                  statusColor[props.proposal.status ?? 0]
                }`}
              >
                {props.proposal.status}
              </span>
              <span>
                {getFormatedDatetime(
                  new Date(props.proposal.startTime),
                  "YYYY/MM/DD"
                )}
              </span>
              <span>~</span>
              <span>
                {getFormatedDatetime(
                  new Date(props.proposal.closingTime),
                  "YYYY/MM/DD"
                )}
              </span>
            </div> */}

            {props.proposal.status === "active" && userVoteStatus?.type && (
              <div className="ml-0 mt-8 space-y-2 md:ml-6">
                <div className="text-xs">投票する</div>
                <div className="space-y-4 sm:grid sm:grid-flow-row-dense sm:grid-cols-3 sm:gap-3 sm:space-y-0">
                  <button
                    type="button"
                    className={`inline-flex w-full justify-center rounded-full ${
                      userVoteStatus.type === "favor" ||
                      userVoteStatus.type === "none"
                        ? "bg-[#336AE9] text-gray-100"
                        : "border-2 border-gray-400 bg-transparent text-gray-400"
                    } px-3 py-2 text-sm font-semibold sm:col-start-1`}
                    onClick={() => buttonClickHandler(1)}
                    disabled={userVoteStatus.type === "favor"}
                  >
                    賛成
                  </button>
                  <button
                    type="button"
                    className={`inline-flex w-full justify-center rounded-full ${
                      userVoteStatus.type === "against" ||
                      userVoteStatus.type === "none"
                        ? "bg-[#D11277] text-gray-100"
                        : "border-2 border-gray-400 bg-transparent text-gray-400"
                    } px-3 py-2 text-sm font-semibold sm:col-start-2`}
                    onClick={() => buttonClickHandler(2)}
                    disabled={userVoteStatus.type === "against"}
                  >
                    反対
                  </button>
                  <button
                    type="button"
                    className={`inline-flex w-full justify-center rounded-full ${
                      userVoteStatus.type === "abstention" ||
                      userVoteStatus.type === "none"
                        ? "bg-[#969696] text-gray-100"
                        : "border-2 border-gray-400 bg-transparent text-gray-400"
                    } px-3 py-2 text-sm font-semibold sm:col-start-3`}
                    onClick={() => buttonClickHandler(3)}
                    disabled={userVoteStatus.type === "abstention"}
                  >
                    棄権
                  </button>
                </div>
              </div>
            )}

            {(props.proposal.status === "passed" ||
              props.proposal.status === "rejected") && (
              <div className="space-y-1">
                <div className="ml-0 mt-8 text-xs md:ml-6">投票結果</div>

                <div className="ml-0 flex items-center gap-x-3 text-xs md:ml-6">
                  <div
                    className={`flex w-16 items-center justify-center rounded-full text-sm ${
                      props.proposal.status === "passed"
                        ? "bg-[#336AE9]"
                        : props.proposal.status === "rejected"
                          ? "bg-[#D11277]"
                          : ""
                    }`}
                  >
                    {props.proposal.status === "passed"
                      ? "賛成"
                      : props.proposal.status === "rejected"
                        ? "反対"
                        : ""}
                  </div>

                  <div className="flex items-center gap-x-1 text-[#336AE9]">
                    <span>賛成:</span>
                    {voteResult?.result?.favor}
                  </div>
                  <div className="flex items-center gap-x-1 text-[#D11277]">
                    <span>反対:</span>
                    {voteResult?.result?.against}
                  </div>
                  <div className="flex items-center gap-x-1 text-[#969696]">
                    <span>棄権:</span>
                    {voteResult?.result?.abstention}
                  </div>
                  {/* <div className="">
                    {voteResult && (
                      <EyeIcon
                        className="h-4 w-4 cursor-pointer"
                        onClick={() => {
                          handleDebug("all");
                        }}
                      />
                    )}
                  </div> */}
                </div>
              </div>
            )}

            <div className="ml-0 mt-6 flex flex-col space-y-1 md:ml-6">
              <div className="mb-1 text-xs">投票者</div>
              {props.proposal.voters?.map((voter, idx) => (
                <div
                  key={`voter_${props.proposal.proposalId}_${idx}`}
                  className="ml-1 flex flex-wrap items-center justify-between gap-x-2 text-sm"
                >
                  <div className="flex items-center justify-start space-x-1">
                    <img
                      className={`h-5 w-5 rounded-full bg-[#121217]`}
                      src={voter.img ? voter.img : ANONYMOUS_ICON}
                      alt={voter.name}
                      onError={(e) => {
                        e.currentTarget.onerror = null;
                        e.currentTarget.src = ANONYMOUS_ICON;
                      }}
                    />
                    <div className="">{formatName(voter.name, 20)}</div>
                    <div className="text-xs">{`(${formatAddress(
                      voter.address
                    )})`}</div>
                  </div>
                  {/* debugがいらない際に以下のdiv周りをコメントアウト*/}
                  <div className="flex flex-nowrap items-center justify-end space-x-2">
                    <div className="text-xs">
                      {findVoteTime(voter.voteTime)}
                    </div>
                    <div className="flex items-center gap-x-1">
                      <div className="">
                        {voteResult && (
                          <a href={voter.url} target="_blank" rel="noreferrer">
                            <ArrowTopRightOnSquareIcon className="h-5 w-5" />
                          </a>
                        )}
                      </div>
                      {/* <div className="">
                        {voteResult && (
                          <EyeIcon
                            className="h-4 w-4 cursor-pointer"
                            onClick={() => {
                              handleDebug(voter.address);
                            }}
                          />
                        )}
                      </div> */}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {props.proposal.status === "preparation" &&
            !isNaN(props.proposal.startTime) &&
            !isNaN(props.proposal.endTime) && (
              <div className="mt-8 grid-cols-3 sm:mt-16 sm:grid sm:grid-flow-row-dense sm:gap-3">
                <button
                  type="button"
                  className="flex w-full items-center justify-center rounded-full bg-gradient-to-r from-[#5E00FF] to-[#00A0FF] px-3 py-2 text-sm font-semibold text-gray-50"
                  onClick={() => buttonClickHandler(4)}
                >
                  <div className="flex items-center gap-x-1">
                    <span>編集する</span>
                  </div>
                </button>
                <button
                  type="button"
                  className="mt-3 inline-flex w-full justify-center rounded-full bg-gray-700 px-3 py-2 text-sm font-semibold text-gray-100 shadow-sm disabled:text-gray-500 sm:col-start-2 sm:mt-0"
                  onClick={() => buttonClickHandler(5)}
                  disabled
                >
                  <div className="flex items-center gap-x-1">
                    <span>キャンセル</span>
                  </div>
                </button>
              </div>
            )}
        </div>
      )}

      {isDebugOpen && (
        <Debug
          votes={voteResult}
          address={isDebugOpen}
          callback={setIsDebugOpen}
        />
      )}
      <Loading {...inProgress} />
    </div>
  );
};

export default VoteDetail;
