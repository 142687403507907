import { fireauth } from "./config";
import {
  signInWithPopup,
  TwitterAuthProvider,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { signOut } from "firebase/auth";
import { register, checkUser } from "./utils";
import { checkShare, createShare } from "../shamir/createShare";

async function registerShare(user) {
  // TODO: これリファクタリング対象
  try {
    await register(user);
    const share = await checkShare(user);
    if (
      share &&
      typeof share === "object" &&
      Object.values(share).every(Array.isArray)
    ) {
      console.log("registered Share");
      return share;
    } else {
      throw new Error("failed to register Share");
    }
  } catch (e) {
    console.error(e);
    return null;
  }
}

export async function resetShareForResetPrivateKey(user) {
  const share = await createShare(user);
  if (
    share &&
    typeof share === "object" &&
    Object.values(share).every(Array.isArray)
  ) {
    console.log("registered Share");
    return share;
  } else {
    throw new Error("failed to register Share");
  }
}

export async function signUpWithEmail(email, password) {
  return new Promise((resolve, reject) => {
    createUserWithEmailAndPassword(fireauth, email, password)
      .then(async (userCredential) => {
        // TODO：ユーザーのシェアを登録する
        const user = userCredential.user;
        console.log("ログインに成功しました");
        try {
          const result = await registerShare(user);
          resolve(["newUser", user, result]);
        } catch (e) {
          alert(e);
          window.location.reload();
        }
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode);
        console.log(errorMessage);
        resolve([false, errorCode, errorMessage]);
      });
  });
}

export async function Login(loginBy, email, password) {
  //
  return new Promise((resolve, reject) => {
    let provider;
    if (loginBy === "twitter") {
      provider = new TwitterAuthProvider();
    } else if (loginBy === "google") {
      provider = new GoogleAuthProvider();
    }
    let result = [];
    // ログイン用関数
    var img = "";
    try {
      if (loginBy === "email") {
        signInWithEmailAndPassword(fireauth, email, password)
          .then(async (userCredential) => {
            // Signed in
            const user = userCredential.user;
            console.log(user);
            console.log("ログインに成功しました");
            result = [true, user, img];
            resolve(result);
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode);
            console.log(errorMessage);
            result = [false, errorCode, errorMessage];
            resolve(result);
          });
      } else if (loginBy === "twitter" || loginBy === "google") {
        signInWithPopup(fireauth, provider).then(async (res) => {
          if (res) {
            img = res.user.photoURL;
            console.log("ログインに成功しました");
            // ユーザーが登録されているかチェック
            const isUserExists = await checkUser(res.user);
            if (isUserExists) {
              console.log("user exists");
              result = [true, res.user, img];
            } else {
              // ユーザーのシェアを登録する
              const share = await registerShare(res.user);
              result = ["newUser", res.user, share];
            }
            resolve(result);
          } else {
            console.log("ログインに失敗しました");
            result = ["error", "ログインに失敗しました"];
            resolve(result);
          }
        });
      }
    } catch {
      console.log("ログインに失敗しました");
      result = ["error", "ログインに失敗しました"];
      resolve(result);
    }
  });
}

export async function Logout() {
  try {
    await signOut(fireauth);
    console.log("ログアウトに成功しました");
  } catch {
    console.log("ログアウトに失敗しました");
  }
}

export default Login;
