/* tslint:disable */
/* eslint-disable */
/**
 * Unyte SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ProposalArgs } from './ProposalArgs';
import {
    ProposalArgsFromJSON,
    ProposalArgsFromJSONTyped,
    ProposalArgsToJSON,
} from './ProposalArgs';

/**
 * 
 * @export
 * @interface ProposalRequest
 */
export interface ProposalRequest {
    /**
     * Lambda関数でproposalを実行するためのtype
     * @type {string}
     * @memberof ProposalRequest
     */
    type: ProposalRequestTypeEnum;
    /**
     * 提案の引数
     * @type {ProposalArgs}
     * @memberof ProposalRequest
     */
    args: ProposalArgs;
}


/**
 * @export
 */
export const ProposalRequestTypeEnum = {
    CreateDao: 'createDAO',
    CreateNft: 'createNFT',
    EditDao: 'editDAO',
    AddItem: 'addItem',
    EditItem: 'editItem',
    Exchange: 'exchange',
    Proposal: 'proposal',
    Vote: 'vote',
    ApproveOutput: 'approveOutput',
    RejectOutput: 'rejectOutput',
    CreateOutput: 'createOutput',
    CreateTask: 'createTask'
} as const;
export type ProposalRequestTypeEnum = typeof ProposalRequestTypeEnum[keyof typeof ProposalRequestTypeEnum];


/**
 * Check if a given object implements the ProposalRequest interface.
 */
export function instanceOfProposalRequest(value: object): boolean {
    if (!('type' in value)) return false;
    if (!('args' in value)) return false;
    return true;
}

export function ProposalRequestFromJSON(json: any): ProposalRequest {
    return ProposalRequestFromJSONTyped(json, false);
}

export function ProposalRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProposalRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'type': json['type'],
        'args': ProposalArgsFromJSON(json['args']),
    };
}

export function ProposalRequestToJSON(value?: ProposalRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'type': value['type'],
        'args': ProposalArgsToJSON(value['args']),
    };
}

