/* tslint:disable */
/* eslint-disable */
/**
 * Unyte SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApplicationsDto
 */
export interface ApplicationsDto {
    /**
     * 
     * @type {string}
     * @memberof ApplicationsDto
     */
    uid: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationsDto
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationsDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationsDto
     */
    img: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationsDto
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationsDto
     */
    status: ApplicationsDtoStatusEnum;
}


/**
 * @export
 */
export const ApplicationsDtoStatusEnum = {
    Pending: 'PENDING',
    Approved: 'APPROVED',
    Rejected: 'REJECTED'
} as const;
export type ApplicationsDtoStatusEnum = typeof ApplicationsDtoStatusEnum[keyof typeof ApplicationsDtoStatusEnum];


/**
 * Check if a given object implements the ApplicationsDto interface.
 */
export function instanceOfApplicationsDto(value: object): boolean {
    if (!('uid' in value)) return false;
    if (!('address' in value)) return false;
    if (!('name' in value)) return false;
    if (!('img' in value)) return false;
    if (!('message' in value)) return false;
    if (!('status' in value)) return false;
    return true;
}

export function ApplicationsDtoFromJSON(json: any): ApplicationsDto {
    return ApplicationsDtoFromJSONTyped(json, false);
}

export function ApplicationsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplicationsDto {
    if (json == null) {
        return json;
    }
    return {
        
        'uid': json['uid'],
        'address': json['address'],
        'name': json['name'],
        'img': json['img'],
        'message': json['message'],
        'status': json['status'],
    };
}

export function ApplicationsDtoToJSON(value?: ApplicationsDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'uid': value['uid'],
        'address': value['address'],
        'name': value['name'],
        'img': value['img'],
        'message': value['message'],
        'status': value['status'],
    };
}

