export const getFormatedDatetime = (
  date: Date = new Date(),
  format: string | undefined = "YYYY/MM/DD hh:mm:ss"
) =>
  [
    ["YYYY", date.getFullYear()],
    ["MM", date.getMonth() + 1],
    ["DD", date.getDate()],
    ["hh", date.getHours()],
    ["mm", date.getMinutes()],
    ["ss", date.getSeconds()],
    ["iii", date.getMilliseconds()],
  ].reduce(
    (s: any, a: any) => s.replace(a[0], `${a[1]}`.padStart(a[0].length, "0")),
    format
  );
