import {
  shamirSecretRecovery,
  splitStringIntoArray,
} from "src/actions/shamir/shamir";

const byteArrayToInt = (byteArray: Uint8Array) => {
  let value = 0;
  for (let i = byteArray.length - 1; i >= 0; i--) {
    value = value * 256 + byteArray[i];
  }
  return value;
};

export const UNYTE_SHARE_PREFIX = "UnyteShare";

export enum ShareTypeEnum {
  UnyteDB = 1,
  LocalStorage = 2,
  Backup = 3,
}

/**
 * 文字列をバイト列に変換し、10進数に変換
 */
export const convertToNum = (s: string) => {
  const sByte = new TextEncoder().encode(s);
  return byteArrayToInt(sByte);
};

/**
 * シェアをbase64ForUnyteに変換
 * @param share シェア
 * @returns {string} base64ForUnyte
 */
export const encodeToBase64ForUnyte = (share: number[]): string =>
  `${UNYTE_SHARE_PREFIX}_${btoa(share.join(", "))}`;

/**
 * base64ForUnyteをシェアに変換
 * @param base64ForUnyte base64ForUnyte
 * @returns {number[]} シェア
 */
export const decodeBase64ForUnyte = (base64ForUnyte: string): number[] => {
  const base64 = base64ForUnyte.split("_")[1];
  return atob(base64)
    .split(", ")
    .map((s) => parseInt(s));
};

/**
 * base64ForUnyteかどうかを判定
 * @param base64ForUnyte base64ForUnyte
 * @returns {boolean} base64ForUnyteかどうか
 */
export const isBase64ForUnyte = (base64ForUnyte: string): boolean =>
  UNYTE_SHARE_PREFIX === base64ForUnyte.split("_")[0];

type ShareInfo = {
  share: number[];
  type: ShareTypeEnum;
};

/**
 * 秘密鍵を復号する
 * @param shareInfo1 秘密鍵のシェア1
 * @param shareInfo2 秘密鍵のシェア2
 * @returns 秘密鍵
 */
export const recoverPrivateKey = ({
  shareInfo1,
  shareInfo2,
}: {
  shareInfo1: ShareInfo;
  shareInfo2: ShareInfo;
}): string => {
  let privateKey = "";
  shareInfo1.share.map((y1, index) => {
    const y2 = shareInfo2.share[index];
    const fraction = shamirSecretRecovery([
      [shareInfo1.type, y1],
      [shareInfo2.type, y2],
    ]);
    privateKey += fraction;
  });
  // eslint-disable-next-line no-control-regex
  return privateKey.replace(/\u0000/g, ""); // NULL文字が終端に2つ入ってしまうので削除
};

/**
 * シェアが正しいか判定
 * @param share シェア
 * @returns {boolean} シェアが正しいか
 */
export const isValidShare = (share: number[]): boolean => share.length === 17;

/**
 * 秘密鍵が正しいか判定
 * @param privateKey 秘密鍵
 * @returns {boolean} 秘密鍵が正しいか
 */
export const isValidPrivateKey = (privateKey: string): boolean => {
  if (!privateKey.startsWith("0x")) return false;
  const hexPattern = /^[0-9a-fA-F]+$/;
  return hexPattern.test(privateKey.substring(2));
};

/**
 * 秘密鍵のシェアを復号する
 * @param targetShareType 復号したいシェアの種類
 * @param privateKey 秘密鍵
 * @param shareUnyteDB シェア
 * @returns {number[]} シェア
 */
export const recoverShare = (
  targetShareType: ShareTypeEnum.LocalStorage | ShareTypeEnum.Backup,
  privateKey: string,
  shareUnyteDB: number[]
): number[] => {
  const privateKeyChunks = splitStringIntoArray(privateKey) as string[];
  const share: number[] = [];
  privateKeyChunks.map((privateKeyChunk, index) => {
    const b = convertToNum(privateKeyChunk);
    const x1 = ShareTypeEnum.UnyteDB;
    const y1 = shareUnyteDB[index];
    const a = (y1 - b) / x1;
    const x = targetShareType;
    const y = a * x + b;
    share.push(y);
  });
  return share;
};
