import React, { useState } from "react";
import { BaseProps } from "../../../../types/common/index.ts";
import clsx from "clsx";
import { ItemIconInput } from "../ItemIconInput/ItemIconInput.tsx";
import { ItemDescriptionInput } from "../ItemTextInput/ItemDescriptionInput/ItemDescriptionInput.tsx";
import { ItemNameInput } from "../ItemTextInput/ItemNameInput/ItemNameInput.tsx";
import { ItemCancelButton } from "../ItemCancelButton/ItemCancelButton.tsx";
import { ItemPriceInput } from "../ItemNumberInput/ItemPriceInput/ItemPriceInput.tsx";
import { ItemEditSubmitButton } from "../ItemSubmitButton/ItemEditSubmitButton/ItemEditSubmitButton.tsx";

export type EditItemProps = { daoId: string } & BaseProps;

export const EditItem: React.FC<EditItemProps> = ({ className, daoId }) => {
  return (
    <div className={clsx(className)}>
      <div
        className={clsx(
          "flex flex-col items-center gap-y-8",
          "w-full",
          "mt-8",
          "sm:flex-row sm:items-start",
          "sm:mt-28"
        )}
      >
        <ItemIconInput className={clsx("sm:w-1/4")} />
        <div className={clsx("sm:w-3/4", "text-sm text-white")}>
          <ItemNameInput className={clsx("mb-4")} />
          <ItemDescriptionInput className={clsx("mb-4")} />
          <ItemPriceInput className={clsx("mb-4")} />
        </div>
      </div>
      <div
        className={clsx(
          "flex items-center justify-center",
          "mb-12 mt-6",
          "gap-x-12"
        )}
      >
        <ItemEditSubmitButton daoId={daoId} />
        <ItemCancelButton />
      </div>
    </div>
  );
};
