import React from "react";
import {
  CurrencyDollarIcon,
  CheckIcon,
  ChevronDownIcon,
} from "@heroicons/react/24/outline";
import { ApprovedOutputAuthor, Task } from "src/types/api/index.ts";
import { ANONYMOUS_ICON } from "src/const/const";

type Props = {
  task: Task;
  isSelected: boolean;
  onClick: () => void;
};

const TaskStatus = {
  preparation: {
    text: "待機中",
    color: "text-gray-100",
    bgcolor: "border-transparent",
  },
  active: {
    text: "進行中",
    color: "text-gray-800",
    bgcolor: "bg-white",
  },
  expired: {
    text: "終了",
    color: "text-gray-100",
    bgcolor: "border-transparent",
  },
};

const getFormatedDatetime = (
  date: Date = new Date(),
  format: string | undefined = "YYYY/MM/DD hh:mm:ss"
) =>
  [
    ["YYYY", date.getFullYear()],
    ["MM", date.getMonth() + 1],
    ["DD", date.getDate()],
    ["hh", date.getHours()],
    ["mm", date.getMinutes()],
    ["ss", date.getSeconds()],
    ["iii", date.getMilliseconds()],
  ].reduce(
    (s: any, a: any) => s.replace(a[0], `${a[1]}`.padStart(a[0].length, "0")),
    format
  );

const ProposaledTask = ({ task, isSelected, onClick }: Props) => {
  const uniqueAuthors = task.approvedOutputAuthors.reduce((acc, current) => {
    const isUniqueAuthor = acc.find((item) => item.address === current.address);
    if (!isUniqueAuthor) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, [] as ApprovedOutputAuthor[]);
  return (
    <div>
      <div
        className={`flex w-full ${
          isSelected ? "mb-0 md:mb-8" : "mb-8"
        } snap-start snap-always`}
        onClick={onClick}
      >
        <div
          className={`relative flex w-full flex-col items-start justify-between gap-y-2 px-4 pb-1 pt-4 text-gray-50 md:min-h-[112px] md:p-4 ${
            isSelected ? "bg-[#1A1D24] md:bg-[#38373D]" : "bg-[#1A1D24]"
          }`}
        >
          <div className="flex w-full items-center justify-between">
            <h3 className="line-clamp-3 text-base">{task.title}</h3>
          </div>

          <div className="flex w-full flex-wrap items-center justify-between gap-y-2">
            <div className="flex items-center justify-start -space-x-2">
              {uniqueAuthors?.map((author) => (
                <div key={`${task.taskId}_${author.img}`}>
                  <img
                    className={`h-6 w-6 rounded-full bg-[#1A1D24]`}
                    src={author.img ? author.img : ANONYMOUS_ICON}
                    alt={author.name}
                    onError={(e) => {
                      (e.target as HTMLImageElement).onerror = null;
                      (e.target as HTMLImageElement).src = ANONYMOUS_ICON;
                    }}
                  />
                </div>
              ))}
            </div>

            <div className="flex w-[240px] items-center justify-end space-x-1">
              <div
                className={`border-white" } flex h-6 w-fit items-center justify-center space-x-1 rounded-md border p-1
            text-xs`}
              >
                <CurrencyDollarIcon className="h-4 w-4" />
                <span className="text-xs">{task.operation.reward}</span>
                <span className="text-xs">{"token"}</span>
              </div>

              <div className="flex w-[120px] items-center justify-center space-x-1 text-xs">
                <span>
                  {getFormatedDatetime(new Date(task.startTime), "MM/DD")}
                </span>
                <span>~</span>
                <span>
                  {getFormatedDatetime(new Date(task.closingTime), "MM/DD")}
                </span>
              </div>

              {TaskStatus && (
                <div
                  className={`flex min-w-[68px] items-center rounded-md px-1 py-0.5 ${
                    TaskStatus[task.status].bgcolor
                  } ${TaskStatus[task.status].color}`}
                >
                  {TaskStatus[task.status] && <CheckIcon className="h-4 w-4" />}
                  <span
                    className={`flex w-full justify-center text-xs font-semibold`}
                  >
                    {TaskStatus[task.status!].text}
                  </span>
                </div>
              )}
            </div>
          </div>
          {!isSelected && (
            <div className="-mt-3 flex w-full justify-center md:hidden">
              <ChevronDownIcon className="mt-1 h-5 w-5 text-gray-400" />
            </div>
          )}
        </div>
        <div className="hidden h-full w-16 items-center md:flex">
          {isSelected && (
            <div className="h-0 w-0 border-b-[56px] border-l-[48px] border-t-[56px] border-b-transparent border-l-[#38373D] border-t-transparent"></div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProposaledTask;
