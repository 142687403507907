/* tslint:disable */
/* eslint-disable */
/**
 * Unyte SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OutputAuthor } from './OutputAuthor';
import {
    OutputAuthorFromJSON,
    OutputAuthorFromJSONTyped,
    OutputAuthorToJSON,
} from './OutputAuthor';

/**
 * 
 * @export
 * @interface Output
 */
export interface Output {
    /**
     * 
     * @type {string}
     * @memberof Output
     */
    outputId: string;
    /**
     * 
     * @type {OutputAuthor}
     * @memberof Output
     */
    author: OutputAuthor;
    /**
     * 
     * @type {number}
     * @memberof Output
     */
    date: number;
    /**
     * 
     * @type {string}
     * @memberof Output
     */
    link: string;
    /**
     * 
     * @type {string}
     * @memberof Output
     */
    note?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Output
     */
    outputFiles?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Output
     */
    status: OutputStatusEnum;
}


/**
 * @export
 */
export const OutputStatusEnum = {
    Approved: 'approved',
    Rejected: 'rejected',
    Pending: 'pending'
} as const;
export type OutputStatusEnum = typeof OutputStatusEnum[keyof typeof OutputStatusEnum];


/**
 * Check if a given object implements the Output interface.
 */
export function instanceOfOutput(value: object): boolean {
    if (!('outputId' in value)) return false;
    if (!('author' in value)) return false;
    if (!('date' in value)) return false;
    if (!('link' in value)) return false;
    if (!('status' in value)) return false;
    return true;
}

export function OutputFromJSON(json: any): Output {
    return OutputFromJSONTyped(json, false);
}

export function OutputFromJSONTyped(json: any, ignoreDiscriminator: boolean): Output {
    if (json == null) {
        return json;
    }
    return {
        
        'outputId': json['outputId'],
        'author': OutputAuthorFromJSON(json['author']),
        'date': json['date'],
        'link': json['link'],
        'note': json['note'] == null ? undefined : json['note'],
        'outputFiles': json['outputFiles'] == null ? undefined : json['outputFiles'],
        'status': json['status'],
    };
}

export function OutputToJSON(value?: Output | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'outputId': value['outputId'],
        'author': OutputAuthorToJSON(value['author']),
        'date': value['date'],
        'link': value['link'],
        'note': value['note'],
        'outputFiles': value['outputFiles'],
        'status': value['status'],
    };
}

