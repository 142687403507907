/* tslint:disable */
/* eslint-disable */
/**
 * Unyte SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OutputInfo } from './OutputInfo';
import {
    OutputInfoFromJSON,
    OutputInfoFromJSONTyped,
    OutputInfoToJSON,
} from './OutputInfo';
import type { RewardInfo } from './RewardInfo';
import {
    RewardInfoFromJSON,
    RewardInfoFromJSONTyped,
    RewardInfoToJSON,
} from './RewardInfo';

/**
 * 
 * @export
 * @interface ApproveOutputInfos
 */
export interface ApproveOutputInfos {
    /**
     * outputの情報
     * @type {OutputInfo}
     * @memberof ApproveOutputInfos
     */
    outputInfo: OutputInfo;
    /**
     * 報酬の情報
     * @type {RewardInfo}
     * @memberof ApproveOutputInfos
     */
    rewardInfo: RewardInfo;
}

/**
 * Check if a given object implements the ApproveOutputInfos interface.
 */
export function instanceOfApproveOutputInfos(value: object): boolean {
    if (!('outputInfo' in value)) return false;
    if (!('rewardInfo' in value)) return false;
    return true;
}

export function ApproveOutputInfosFromJSON(json: any): ApproveOutputInfos {
    return ApproveOutputInfosFromJSONTyped(json, false);
}

export function ApproveOutputInfosFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApproveOutputInfos {
    if (json == null) {
        return json;
    }
    return {
        
        'outputInfo': OutputInfoFromJSON(json['outputInfo']),
        'rewardInfo': RewardInfoFromJSON(json['rewardInfo']),
    };
}

export function ApproveOutputInfosToJSON(value?: ApproveOutputInfos | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'outputInfo': OutputInfoToJSON(value['outputInfo']),
        'rewardInfo': RewardInfoToJSON(value['rewardInfo']),
    };
}

