/* tslint:disable */
/* eslint-disable */
/**
 * Unyte SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Vote
 */
export interface Vote {
    /**
     * 投票者のアドレス
     * @type {string}
     * @memberof Vote
     */
    voter: string;
    /**
     * 投票内容
     * @type {string}
     * @memberof Vote
     */
    value: string;
    /**
     * zkp
     * @type {string}
     * @memberof Vote
     */
    zkp: string;
    /**
     * 投票時間
     * @type {string}
     * @memberof Vote
     */
    voteTime: string;
    /**
     * hash
     * @type {string}
     * @memberof Vote
     */
    hash: string;
    /**
     * 署名
     * @type {string}
     * @memberof Vote
     */
    signature?: string;
    /**
     * url
     * @type {string}
     * @memberof Vote
     */
    url?: string;
}

/**
 * Check if a given object implements the Vote interface.
 */
export function instanceOfVote(value: object): boolean {
    if (!('voter' in value)) return false;
    if (!('value' in value)) return false;
    if (!('zkp' in value)) return false;
    if (!('voteTime' in value)) return false;
    if (!('hash' in value)) return false;
    return true;
}

export function VoteFromJSON(json: any): Vote {
    return VoteFromJSONTyped(json, false);
}

export function VoteFromJSONTyped(json: any, ignoreDiscriminator: boolean): Vote {
    if (json == null) {
        return json;
    }
    return {
        
        'voter': json['voter'],
        'value': json['value'],
        'zkp': json['zkp'],
        'voteTime': json['voteTime'],
        'hash': json['hash'],
        'signature': json['signature'] == null ? undefined : json['signature'],
        'url': json['url'] == null ? undefined : json['url'],
    };
}

export function VoteToJSON(value?: Vote | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'voter': value['voter'],
        'value': value['value'],
        'zkp': value['zkp'],
        'voteTime': value['voteTime'],
        'hash': value['hash'],
        'signature': value['signature'],
        'url': value['url'],
    };
}

