import React from "react";

import { FaTwitter, FaFacebook, FaGithub, FaGlobe } from "react-icons/fa";

export const getSocialIcon = (url: string) => {
  if (url.includes("x.com") || url.includes("twitter.com")) {
    return <FaTwitter className="text-white" />;
  } else if (url.includes("facebook.com")) {
    return <FaFacebook className="text-white" />;
  } else if (url.includes("github.com")) {
    return <FaGithub className="text-white" />;
  } else {
    return <FaGlobe className="text-white" />;
  }
};
