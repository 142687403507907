/* tslint:disable */
/* eslint-disable */
/**
 * Unyte SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateOutputArgs
 */
export interface CreateOutputArgs {
    /**
     * outputの作成者のアドレス
     * @type {string}
     * @memberof CreateOutputArgs
     */
    owner: string;
    /**
     * DAOのID
     * @type {string}
     * @memberof CreateOutputArgs
     */
    daoId: string;
    /**
     * タスクのID
     * @type {string}
     * @memberof CreateOutputArgs
     */
    taskId: string;
    /**
     * outputのID
     * @type {string}
     * @memberof CreateOutputArgs
     */
    outputId: string;
    /**
     * outputの内容
     * @type {string}
     * @memberof CreateOutputArgs
     */
    output: string;
    /**
     * outputのファイル
     * @type {string}
     * @memberof CreateOutputArgs
     */
    outputFiles: string;
}

/**
 * Check if a given object implements the CreateOutputArgs interface.
 */
export function instanceOfCreateOutputArgs(value: object): boolean {
    if (!('owner' in value)) return false;
    if (!('daoId' in value)) return false;
    if (!('taskId' in value)) return false;
    if (!('outputId' in value)) return false;
    if (!('output' in value)) return false;
    if (!('outputFiles' in value)) return false;
    return true;
}

export function CreateOutputArgsFromJSON(json: any): CreateOutputArgs {
    return CreateOutputArgsFromJSONTyped(json, false);
}

export function CreateOutputArgsFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateOutputArgs {
    if (json == null) {
        return json;
    }
    return {
        
        'owner': json['owner'],
        'daoId': json['daoId'],
        'taskId': json['taskId'],
        'outputId': json['outputId'],
        'output': json['output'],
        'outputFiles': json['outputFiles'],
    };
}

export function CreateOutputArgsToJSON(value?: CreateOutputArgs | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'owner': value['owner'],
        'daoId': value['daoId'],
        'taskId': value['taskId'],
        'outputId': value['outputId'],
        'output': value['output'],
        'outputFiles': value['outputFiles'],
    };
}

