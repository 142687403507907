import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  ClipboardDocumentIcon,
  ClipboardDocumentCheckIcon,
} from "@heroicons/react/24/outline";
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";
import UniSwaTextField from "../../components/atoms/UniSwaTextField.tsx";
import { decryptoPrivateKey } from "../../actions/blockchain/generateKeys";
import { useAuthState } from "react-firebase-hooks/auth";
import { getAuth } from "firebase/auth";
import { useUser } from "src/hooks/useUser.ts";

const Profile: React.FC = () => {
  const [accountId, setAccountId] = useState("0x");
  const [privateKey, setPrivateKey] = useState("");
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const location = useLocation();
  const [session] = useAuthState(getAuth());
  const { user } = useUser();
  const closeModal = () => {
    setIsModalOpen(false);
  };
  const [isCopied, setIsCopied] = useState(false);
  let state = location.state;

  useEffect(() => {
    if (session) {
      const init = async () => {
        if (!user) return;
        setAccountId(user.address);
      };
      const fetch = async () => {
        const keyBytes = (await decryptoPrivateKey(user)) as string[];
        const privateKey = keyBytes.join("").replace("\u0000\u0000", "");
        setPrivateKey(privateKey);
        // TODO: Alchemy APIからDAOの情報を取得するようにする
        if (!state) init();
      };
      fetch();
    }
  }, [session, user]);

  const handleCopyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(privateKey);
      setIsCopied(true);
      alert("Copied to clipboard!");
    } catch (err) {
      console.error("Failed to copy text: ", err);
      setIsCopied(false);
      alert(`Failed to copy text: ${err}`);
    }
  };

  return (
    <div className="h-scren mt-28 flex w-full justify-center">
      <div className="flex w-full flex-col items-center text-white lg:w-3/5">
        <div className="w-11/12 text-sm lg:w-3/4">
          <div className="mb-4 text-white">
            <p className="mb-2 ml-6">アカウント選択</p>
            <UniSwaTextField
              value={accountId}
              isCreateDao={true}
              onChange={(e) => {}}
            />
          </div>
        </div>

        <div className="mx-auto w-11/12 text-white lg:flex lg:w-3/4 lg:justify-center">
          <button
            className="h-10 w-full rounded-full bg-unyte px-6 py-1 text-white lg:h-fit lg:w-fit"
            onClick={() => setIsModalOpen(true)}
          >
            秘密鍵の表示
          </button>
        </div>
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 z-10">
          <div className="flex h-screen items-center justify-center">
            <div
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              onClick={closeModal}
            ></div>

            <div className="w-11/12 max-w-xl transform overflow-hidden rounded-lg bg-[#28282D] bg-unyte p-px text-left text-white shadow-xl transition-all sm:w-1/2">
              <div className="h-full w-full rounded-lg bg-[#28282D] px-4 pb-4 pt-5">
                <div className="flex justify-end">
                  <button onClick={closeModal}>×</button>
                </div>

                <div className="py-8 lg:p-16 lg:px-4">
                  <div className="mb-4 text-sm text-white">
                    <p className="mb-2 ml-6">秘密鍵</p>
                    <div className="mb-2 flex w-full items-center justify-between break-all rounded-full border border-gray-600 px-2 py-2 lg:px-4">
                      <p>{privateKey}</p>
                      {isCopied && (
                        <ClipboardDocumentCheckIcon
                          className="mr-2 h-6 cursor-pointer font-bold text-lime-500"
                          onClick={handleCopyToClipboard}
                        ></ClipboardDocumentCheckIcon>
                      )}
                      {!isCopied && (
                        <ClipboardDocumentIcon
                          className="mr-2 h-6 cursor-pointer font-bold"
                          onClick={handleCopyToClipboard}
                        ></ClipboardDocumentIcon>
                      )}
                    </div>
                  </div>

                  <div className="flex w-full items-center rounded-lg bg-white px-2 py-3 lg:px-4">
                    <ExclamationTriangleIcon className="mr-2 h-10 font-bold text-pink-500"></ExclamationTriangleIcon>
                    <p className="flex-1 text-sm text-black">
                      警告:
                      プライベートキーは誰にも公開しないでください。プライベートキーを知っている人はあなたのウォレットから資産を盗むことができます。
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Profile;
