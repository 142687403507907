import React from "react";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import { useSetAtom } from "jotai";
import { isOpenInsufficientPointModalMarketState } from "../../../../../stores/isOpenInsufficientPointModalMarketState.ts";
import { BaseProps } from "../../../../../types/common";

export type NavTaskButtonProps = {
  daoId: string;
} & BaseProps;

export const NavTaskButton: React.FC<NavTaskButtonProps> = ({
  className,
  daoId,
}) => {
  const navigate = useNavigate();
  const setIsOpenModal = useSetAtom(isOpenInsufficientPointModalMarketState);

  const handleClick = () => {
    setIsOpenModal(false);
    navigate(`/${daoId}/task`);
  };

  return (
    <button
      className={clsx(
        className,
        "py-2",
        "w-2/3",
        "bg-unyte",
        "rounded-full",
        "lg:w-1/3"
      )}
      onClick={handleClick}
    >
      タスクを確認する
    </button>
  );
};
