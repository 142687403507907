/* tslint:disable */
/* eslint-disable */
/**
 * Unyte SaaS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ThanksUser } from './ThanksUser';
import {
    ThanksUserFromJSON,
    ThanksUserFromJSONTyped,
    ThanksUserToJSON,
} from './ThanksUser';

/**
 * 
 * @export
 * @interface ThanksGiven
 */
export interface ThanksGiven {
    /**
     * トランザクションハッシュ
     * @type {string}
     * @memberof ThanksGiven
     */
    txHash: string;
    /**
     * DAO ID
     * @type {string}
     * @memberof ThanksGiven
     */
    daoId: string;
    /**
     * 送信元のユーザー情報
     * @type {ThanksUser}
     * @memberof ThanksGiven
     */
    from: ThanksUser;
    /**
     * 送信先のユーザー情報
     * @type {ThanksUser}
     * @memberof ThanksGiven
     */
    to: ThanksUser;
    /**
     * メッセージ
     * @type {string}
     * @memberof ThanksGiven
     */
    message: string;
    /**
     * 送られたトークンの量
     * @type {number}
     * @memberof ThanksGiven
     */
    amount: number;
    /**
     * 作成日時
     * @type {number}
     * @memberof ThanksGiven
     */
    createdAt: number;
}

/**
 * Check if a given object implements the ThanksGiven interface.
 */
export function instanceOfThanksGiven(value: object): boolean {
    if (!('txHash' in value)) return false;
    if (!('daoId' in value)) return false;
    if (!('from' in value)) return false;
    if (!('to' in value)) return false;
    if (!('message' in value)) return false;
    if (!('amount' in value)) return false;
    if (!('createdAt' in value)) return false;
    return true;
}

export function ThanksGivenFromJSON(json: any): ThanksGiven {
    return ThanksGivenFromJSONTyped(json, false);
}

export function ThanksGivenFromJSONTyped(json: any, ignoreDiscriminator: boolean): ThanksGiven {
    if (json == null) {
        return json;
    }
    return {
        
        'txHash': json['txHash'],
        'daoId': json['daoId'],
        'from': ThanksUserFromJSON(json['from']),
        'to': ThanksUserFromJSON(json['to']),
        'message': json['message'],
        'amount': json['amount'],
        'createdAt': json['createdAt'],
    };
}

export function ThanksGivenToJSON(value?: ThanksGiven | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'txHash': value['txHash'],
        'daoId': value['daoId'],
        'from': ThanksUserToJSON(value['from']),
        'to': ThanksUserToJSON(value['to']),
        'message': value['message'],
        'amount': value['amount'],
        'createdAt': value['createdAt'],
    };
}

