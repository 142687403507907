import React, { useState, useRef, useEffect } from "react";
// @ts-ignore
import UniSwaTextField from "../atoms/UniSwaTextField.tsx";
import "../../components/styles/UniSwaTextField.css";
import { VotingTokenCreateType } from "../../types/dao";
// @ts-ignore
import UnyteButton from "../atoms/UnyteButton.tsx";
import "../../components/styles/CustomRadio.css";
import { ImCamera } from "react-icons/im";
import { useIntl, FormattedMessage } from "react-intl";

interface Props {
  votingTokenCreateType: VotingTokenCreateType | null;
  setVotingTokenCreateType: React.Dispatch<
    React.SetStateAction<VotingTokenCreateType | null>
  >;
  votingTokenName: string;
  setVotingTokenName: React.Dispatch<React.SetStateAction<string>>;
  votingTokenDescription: string;
  setVotingTokenDescription: React.Dispatch<React.SetStateAction<string>>;
  openSeaUrlForVotingToken: string;
  setOpenSeaUrlForVotingToken: React.Dispatch<React.SetStateAction<string>>;
  votingTokenImage: any;
  setVotingTokenImage: React.Dispatch<React.SetStateAction<any>>;
  votingTokenImageBase64: any;
  setVotingTokenImageBase64: React.Dispatch<React.SetStateAction<any>>;
}

const CreateDAOPage: React.FC<Props> = ({
  votingTokenCreateType,
  setVotingTokenCreateType,
  votingTokenName,
  setVotingTokenName,
  votingTokenDescription,
  setVotingTokenDescription,
  openSeaUrlForVotingToken,
  setOpenSeaUrlForVotingToken,
  votingTokenImage,
  setVotingTokenImage,
  votingTokenImageBase64,
  setVotingTokenImageBase64,
}) => {
  const intl = useIntl();
  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setVotingTokenImage(event.target?.result);
        const base64 = reader.result.split(",")[1];
        console.log(base64);
        setVotingTokenImageBase64(base64);
      };
      reader.readAsDataURL(file);
    }
  };
  return (
    <div className="my-4 flex w-full flex-col">
      <div className="mb-4 text-center text-sm text-white">
        <FormattedMessage
          id="step_select"
          defaultMessage="どれか一つを選択してください"
        />
      </div>
      <div className="flex w-full">
        <div className=" mx-auto space-x-12">
          <UnyteButton
            name={intl.formatMessage({
              id: "step3_new_create",
              defaultMessage: "新規発行",
            })}
            onClick={() => setVotingTokenCreateType("new-create")}
            buttonValue={"new-create"}
            status={votingTokenCreateType}
          />
          <UnyteButton
            name={intl.formatMessage({
              id: "step3_reuse",
              defaultMessage: "既存のNFTを使用",
            })}
            onClick={() => setVotingTokenCreateType("reuse-by-opensea")}
            buttonValue={"reuse-by-opensea"}
            status={votingTokenCreateType}
          />
          <UnyteButton
            name={intl.formatMessage({
              id: "step3_reuse_joinToken",
              defaultMessage: "会員権を利用",
            })}
            onClick={() => setVotingTokenCreateType("reuse-by-membership")}
            buttonValue={"reuse-by-membership"}
            status={votingTokenCreateType}
          />
        </div>
      </div>

      <div className="mt-5">
        <div className="space-between flex w-full">
          <div className="m-auto flex space-x-40">
            <div
              className={`border-b-[20px] border-r-[15px] border-r-transparent ${
                votingTokenCreateType === "new-create"
                  ? "border-b-[#29282E]"
                  : "border-b-[#131218]"
              } border-l-[15px] border-l-transparent`}
            ></div>
            <div
              className={`border-b-[20px] border-r-[15px] border-r-transparent ${
                votingTokenCreateType === "reuse-by-opensea"
                  ? "border-b-[#29282E]"
                  : "border-b-[#131218]"
              } border-l-[15px] border-l-transparent`}
            ></div>
            <div
              className={`border-b-[20px] border-r-[15px] border-r-transparent ${
                votingTokenCreateType === "reuse-by-membership"
                  ? "border-b-[#29282E]"
                  : "border-b-[#131218]"
              } border-l-[15px] border-l-transparent`}
            ></div>
          </div>
        </div>
        {votingTokenCreateType === "new-create" && (
          <div className="space-between m-auto flex w-10/12 rounded-lg bg-[#29282E] px-12 pb-5 pt-10">
            <div className="mr-4 w-3/12">
              <label
                htmlFor="profileImage"
                className="m-auto flex h-32 w-32 items-center justify-center rounded-full bg-[#131218] text-white hover:cursor-pointer"
              >
                <input
                  type="file"
                  className="hidden bg-gray-50"
                  id="profileImage"
                  onChange={handleImageChange}
                />
                {!votingTokenImage ? (
                  <div className="flex flex-col">
                    <ImCamera className="mx-auto mb-2 text-center text-5xl text-[#29282E]" />
                    <div className="text-center text-sm text-[#8B8B8C] hover:cursor-pointer">
                      <div>
                        <FormattedMessage
                          id="select_file"
                          defaultMessage="ファイルを"
                        />
                      </div>
                      <div>
                        <FormattedMessage
                          id="select_file2"
                          defaultMessage="選択する"
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <img
                    src={votingTokenImage}
                    alt="Profile"
                    className="h-32 w-32 cursor-pointer rounded-full"
                  />
                )}
              </label>
            </div>
            <div className="w-9/12">
              <div className="space-y-4">
                <div className="mx-auto w-10/12">
                  <div className="mb-2 w-full">
                    <UniSwaTextField
                      placeholder={intl.formatMessage({
                        id: "step3_name_placeholder",
                        defaultMessage: "名前を入力してください。",
                      })}
                      isCreateDao={true}
                      addClass="!bg-[#131218] !border-[#131218]"
                      value={votingTokenName}
                      onChange={(e) => setVotingTokenName(e.target.value)}
                    />
                  </div>
                  <div className="w-full">
                    <div className="mb-2 w-full">
                      <UniSwaTextField
                        placeholder={intl.formatMessage({
                          id: "step3_description_placeholder",
                          defaultMessage:
                            "投票用トークンについての説明を入力してください。",
                        })}
                        isCreateDao={true}
                        addClass="!bg-[#131218] !border-[#131218]"
                        value={votingTokenDescription}
                        onChange={(e) =>
                          setVotingTokenDescription(e.target.value)
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {votingTokenCreateType === "reuse-by-opensea" && (
          <div className="m-auto w-9/12 rounded-lg bg-[#29282E] px-12 pb-5 pt-10">
            <div className="mx-auto w-5/6">
              <UniSwaTextField
                placeholder={intl.formatMessage({
                  id: "step3_reuse_placeholder",
                  defaultMessage: "OpenseaのCollection URLを入力してください。",
                })}
                isCreateDao={true}
                value={openSeaUrlForVotingToken}
                addClass="!bg-[#131218] !border-[#131218]"
                onChange={(e) => setOpenSeaUrlForVotingToken(e.target.value)}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CreateDAOPage;
