import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { getXAccount } from "../../actions/firebase/authXAccount.ts";
import { updateUser } from "../../actions/firebase/user.ts";
import { getAuth } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { useUser } from "src/hooks/useUser.ts";

const XAuth = () => {
  const [session] = useAuthState(getAuth());
  const location = useLocation();
  const [userXName, setUserXName] = useState<string>("");
  const [checkXAuth, setCheckXAuth] = useState<boolean>(false);
  const path = location.search;
  const code = path.split("code=")[1];
  const { user } = useUser();

  useEffect(() => {
    if (session) {
      if (!code) return;
      if (checkXAuth) {
        window.close();
        return;
      }
      const getXaccount = async () => {
        if (!user) {
          alert("認証に失敗しました。再度お試しください。");
          return;
        }
        const account = await getXAccount(user.uid, code);
        if (account) {
          try {
            await updateUser({
              uid: user.uid,
              twitter: account.twitter.id,
              twitterUserName: account.twitter.name,
            });
            localStorage.removeItem("XAuthInfo");
            setUserXName(account.twitter.name);
            setCheckXAuth(true);
          } catch (error) {
            alert("認証に失敗しました。再度お試しください。");
            console.error(error);
          }
        } else {
          alert("認証に失敗しました。再度お試しください。");
        }
        window.close();
      };
      getXaccount();
    }
  }, [session, code, userXName]);
  return <div className="text-3xl text-white"></div>;
};
export default XAuth;
