import { fireauth } from "./config";
import { onAuthStateChanged } from "firebase/auth";
import { signOut } from "firebase/auth";
import { checkUser } from "./utils";

export const isValidUser = () => {
  return new Promise<boolean>((resolve) => {
    onAuthStateChanged(fireauth, async (user) => {
      if (!user) resolve(false);

      const isUserExists = await checkUser(user);
      if (!isUserExists) resolve(false);

      resolve(true);
    });
  });
};

export const fbLogout = async () => {
  try {
    await signOut(fireauth);
    console.log("Success: Logout");
    return true;
  } catch (e) {
    console.error(e);
    console.log("Error: Logout");
    return false;
  }
};
