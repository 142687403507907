import React, { useEffect } from "react";
import "../../../styles/UniSwaTextField.css";
import { FormattedMessage } from "react-intl";
import { BaseProps } from "../../../../types/common/index.ts";
import clsx from "clsx";

export type ItemNumberInputProps = {
  msgId: string;
  defaultMessage: string;
  value?: number;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
} & BaseProps;

export const ItemNumberInput: React.FC<ItemNumberInputProps> = ({
  id,
  className,
  msgId,
  defaultMessage,
  value,
  onChange,
}) => {
  useEffect(() => {
    if (!id) return;
    document.getElementById(id)!.addEventListener("wheel", function (e) {
      e.preventDefault();
    });
  }, []);

  return (
    <div className={clsx(className)}>
      <div className={clsx("mb-2 ml-6")}>
        <FormattedMessage id={msgId} defaultMessage={defaultMessage} />
      </div>
      <div className={clsx("text-field-container flex-1")}>
        <input
          id={id}
          type="number"
          min={0}
          className={clsx(
            "custom-text-field",
            "w-full rounded-full",
            "mb-2 p-2",
            "border"
          )}
          placeholder={""}
          value={value && value > 0 ? value : ""}
          onKeyDown={(event) => {
            (event.key === "e" ||
              event.key === "-" ||
              event.key === "+" ||
              event.key === "*" ||
              event.key === "." ||
              event.key === "/") &&
              event.preventDefault();
          }}
          onChange={onChange}
        />
      </div>
    </div>
  );
};
