import React from "react";
import { BaseProps } from "../../../../../types/common/index.ts";
import clsx from "clsx";
import { tmpAddAndEditItemState } from "../../../../../stores/tmpItemState.ts";
import { useAtom } from "jotai";
import { ItemNumberInput } from "../ItemNumberInput.tsx";

export type ItemPriceInputProps = {} & BaseProps;

export const ItemPriceInput: React.FC<ItemPriceInputProps> = ({
  className,
}) => {
  const [tmpItem, setTmpItem] = useAtom(tmpAddAndEditItemState);
  const price = tmpItem && tmpItem.price;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTmpItem((prev) => {
      const newTmpItem = { ...prev };
      newTmpItem.price = Number(e.target.value);
      return newTmpItem;
    });
  };

  return (
    <ItemNumberInput
      id="item-price"
      className={clsx(className)}
      msgId="item_price"
      defaultMessage="価格(アイテム獲得時に消費する貢献トークン量)"
      value={price}
      onChange={handleChange}
    />
  );
};
