import React, { useEffect, useState } from "react";
import UniSwaTextField from "../atoms/UniSwaTextField.tsx";
import {
  fetchUserByWallet,
  searchUserByEmail,
} from "../../actions/firebase/utils";
import "../../components/styles/UniSwaTextField.css";
import { ImCamera } from "react-icons/im";
import { FormattedMessage } from "react-intl";
import { editDAO } from "../../actions/blockchain/editDAO";
import { Progress, User } from "../../types/common/index.ts";
import UnyteSquare from "../../assets/unyte_square.png";
import { BiPencil } from "react-icons/bi";
import { updateCheckSumAddress } from "../../actions/blockchain/wallet.js";
import Loading from "../common/Loading.tsx";
import { useUser } from "src/hooks/useUser.ts";

interface Props {
  daoId: string;
  daoName: string;
  setDaoName: React.Dispatch<React.SetStateAction<string>>;
  description: string;
  setDescription: React.Dispatch<React.SetStateAction<string>>;
  inputValue: string;
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
  confirmedValues: string[];
  setConfirmedValues: React.Dispatch<React.SetStateAction<string[]>>;
  profileImage: any;
  setProfileImage: React.Dispatch<React.SetStateAction<any>>;
  profileBase64: any;
  setProfileBase64: React.Dispatch<React.SetStateAction<any>>;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  link1: string;
  setLink1: React.Dispatch<React.SetStateAction<string>>;
  link2: string;
  setLink2: React.Dispatch<React.SetStateAction<string>>;
  link3: string;
  setLink3: React.Dispatch<React.SetStateAction<string>>;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  daoDetailMutate: (data?: any, shouldRevalidate?: boolean) => Promise<any>;
}

const DAOInfoSetting: React.FC<Props> = ({
  daoId,
  daoName,
  setDaoName,
  description,
  setDescription,
  inputValue,
  setInputValue,
  confirmedValues,
  setConfirmedValues,
  profileImage,
  setProfileImage,
  profileBase64,
  setProfileBase64,
  isOpen,
  setIsOpen,
  link1,
  setLink1,
  link2,
  setLink2,
  link3,
  setLink3,
  setStep,
  daoDetailMutate,
}) => {
  // NOTE: State
  const [users, setUsers] = useState<User[] | null>(null);
  const [isAdminListOpen, setIsAdminListOpen] = useState<boolean>(false);
  const [userAddress, setUserAddress] = useState<string>("");
  const [tmpUser, setTmpUser] = useState<User | null>(null);
  const [inProgress, setInProgress] = useState<Progress>({
    isShow: false,
    message: "",
  });
  const { user } = useUser();
  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setProfileImage(event.target?.result);
        if (reader.result === null) return;
        if (reader.result instanceof ArrayBuffer) return;
        const base64 = reader.result?.split(",")[1];
        setProfileBase64(base64);
      };
      reader.readAsDataURL(file);
    }
  };
  // NOTE: Function
  const editDaoHandler = async () => {
    if (user === undefined) return;
    setInProgress({
      isShow: true,
      message: "DAO情報を更新中...",
    });

    try {
      // link1 ~ link3のバリデーション
      if (link1 && !varidateLink(link1)) {
        throw new Error("link1のURLが不正です。");
      }
      if (link2 && !varidateLink(link2)) {
        throw new Error("link2のURLが不正です。");
      }
      if (link3 && !varidateLink(link3)) {
        throw new Error("link3のURLが不正です。");
      }

      await editDAO(
        daoId,
        daoName.trim(),
        description.trim(),
        profileBase64,
        confirmedValues,
        isOpen,
        [link1, link2, link3],
        user
      );
    } catch (e) {
      console.error(e);
      if (e instanceof Error) {
        alert(
          `DAO編集に失敗した可能性があります。ご確認ください。\n${e.stack}`
        );
      } else
        alert(
          `DAO編集に失敗した可能性があります。ご確認ください。\n\n${JSON.stringify(
            e
          )}`
        );
      setInProgress({
        isShow: false,
        message: "",
      });
      return;
    }
    alert("DAO情報を更新しました。");
    setInProgress({
      isShow: false,
      message: "",
    });
    await daoDetailMutate();
    return;
  };

  const varidateLink = (url: string) => {
    // https:// で始まらない場合は、エラーを返す
    return url.startsWith("https://");
  };

  const formatAddress = (address: string) => {
    try {
      const start = address.substring(0, 5);
      const end = address.substring(address.length - 4);
      return `${start}...${end}`;
    } catch (e) {
      console.error((e as Error).message);
    }
  };

  const handleKeyDown = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && inputValue.trim()) {
      const user = await searchUserByEmail(inputValue);
      if (user == null) {
        alert("ユーザーが見つかりませんでした。");
      } else {
        console.log(user);
        setInputValue("");
        setConfirmedValues((prev) => [...prev, user]);
      }
    }
  };

  const setUserAddressHandler = async (address: string) => {
    if (address?.length !== 42) {
      setTmpUser(null);
      return;
    }
    if (confirmedValues.includes(address)) return;
    updateCheckSumAddress(address.toLowerCase());
    const user = await fetchUserByWallet(address);
    if (user == null) return null;
    setTmpUser(user);
    return;
  };

  const removeAdmins = (address) => {
    const newConfirmedValues = confirmedValues.filter(
      (value) => value !== address
    );
    if (newConfirmedValues.length === 0) {
      alert("管理者が存在しない変更をすることはできません。");
      return;
    }
    setConfirmedValues(newConfirmedValues);
  };

  useEffect(() => {
    const fetchUser = async () => {
      const users: User[] | null = await Promise.all(
        confirmedValues
          .map(async (user) => {
            console.log(confirmedValues);
            return await fetchUserByWallet(user);
          })
          .filter(Boolean)
      );
      console.log(users);
      setUsers(users);
    };
    fetchUser();
  }, [confirmedValues]);

  return (
    <>
      <div className="flex w-full flex-col lg:mt-4 lg:flex-row">
        <Loading {...inProgress} />
        <div className="text-white lg:w-1/4">
          <label
            htmlFor="profileImage"
            className="relative m-auto flex h-20 w-20 items-center justify-center rounded-full bg-[#38373D] hover:cursor-pointer lg:h-40 lg:w-40"
          >
            <input
              type="file"
              className="hidden bg-gray-50"
              id="profileImage"
              onChange={handleImageChange}
            />
            <div className="absolute bottom-[6px] right-[4px] flex h-4 w-4 items-center justify-center rounded-full bg-white lg:hidden">
              <BiPencil className="text-xs text-[#29282E]" />
            </div>
            {!profileImage ? (
              <div className="flex flex-col">
                <ImCamera className="mx-auto mb-2 text-center text-5xl text-[#29282E]" />
                <div className="text-center text-sm text-[#8B8B8C] hover:cursor-pointer">
                  <div>
                    <FormattedMessage
                      id="select_file"
                      defaultMessage="ファイルを"
                    />
                  </div>
                  <div>
                    <FormattedMessage
                      id="select_file2"
                      defaultMessage="選択する"
                    />
                  </div>
                </div>
              </div>
            ) : (
              <img
                src={profileImage}
                alt="Profile"
                className="h-20 w-20 cursor-pointer rounded-full lg:h-40 lg:w-40"
              />
            )}
          </label>
        </div>
        <div className="mr-auto w-11/12 text-sm lg:w-7/12">
          <div className="mb-4 text-white">
            <div className="mb-2 ml-6">
              <FormattedMessage id="step1_name" defaultMessage="DAO 名" />
            </div>
            <UniSwaTextField
              value={daoName}
              isCreateDao={true}
              onChange={(e) => setDaoName(e.target.value)}
            />
          </div>

          <div className="mb-4 text-white">
            <div className="mb-2 ml-6">
              <FormattedMessage
                id="step1_description"
                defaultMessage="DAOについての説明を入力してください。"
              />
            </div>
            <UniSwaTextField
              value={description}
              isCreateDao={true}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
          <div className="mb-4 text-white">
            <div className="mb-2 flex flex-row items-center">
              <div className="ml-6">
                <FormattedMessage
                  id="step1_description_admin"
                  defaultMessage="管理者を追加したい場合は、ウォレットアドレスを入力してください。(任意)"
                />
              </div>
              <button
                className="ml-2 flex h-4 w-4 items-center justify-center rounded-full bg-white"
                onClick={() => setIsAdminListOpen(true)}
              >
                <p className="text-gray-800">+</p>
              </button>
            </div>

            <div className="ml-2">
              <input
                type="text"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onKeyDown={handleKeyDown}
                className={`hidden`}
              />

              <div className="z-10 flex w-full	flex-wrap gap-x-2">
                {users &&
                  users.map((value, idx) => (
                    <div
                      key={idx}
                      className="custom-label text-main mb-1 flex w-fit items-center justify-start break-all rounded rounded-full border px-2 lg:w-fit"
                    >
                      <div className="flex h-6 w-2/12 w-6 items-center rounded-full">
                        <img
                          className="h-5 w-5"
                          src={value?.img ? value.img : UnyteSquare}
                          alt="profile icon"
                        />
                      </div>
                      <div className="mx-auto w-10/12">
                        <p className="ml-2 text-white">
                          {value?.name
                            ? value.name
                            : formatAddress(value?.address)}
                        </p>
                      </div>
                      <button
                        className="ml-1"
                        onClick={() => removeAdmins(value?.address)}
                      >
                        <p className="text-base text-black">×</p>
                      </button>
                    </div>
                  ))}
              </div>
            </div>
          </div>

          <div className="mb-4 text-white">
            <div className="mb-2 ml-6">
              <FormattedMessage id="step1_link" defaultMessage="link" />
            </div>
            <UniSwaTextField
              value={link1}
              isCreateDao={true}
              onChange={(e) => setLink1(e.target.value)}
            />
          </div>
          <div className="mb-4 text-white">
            <div className="mb-2 ml-6">
              <FormattedMessage id="step1_link2" defaultMessage="link" />
            </div>
            <UniSwaTextField
              value={link2}
              isCreateDao={true}
              onChange={(e) => setLink2(e.target.value)}
            />
          </div>
          <div className="mb-4 text-white">
            <div className="mb-2 ml-6">
              <FormattedMessage id="step1_link3" defaultMessage="link" />
            </div>
            <UniSwaTextField
              value={link3}
              isCreateDao={true}
              onChange={(e) => setLink3(e.target.value)}
            />
          </div>
          <div className="mt-2 space-x-3">
            <p className="text-xs text-gray-50 ">
              DAOの状態（公開=誰でも参加可能：非公開=NFT保有者のみ参加可能）
            </p>
            <div className="mt-2 flex items-center space-x-4 lg:flex-row">
              <div className="flex items-center space-x-1">
                <input
                  type="radio"
                  id="showResult"
                  name="isShowResult"
                  className={`${!isOpen ? "text-[#5E00FF]" : "text-[#E5007E]"}`}
                  onChange={() => setIsOpen(true)}
                  checked={isOpen}
                />
                <label className="text-sm text-gray-50" htmlFor="showResult">
                  公開
                </label>
              </div>
              <div className="flex items-center space-x-1">
                <input
                  type="radio"
                  id="notShowResult"
                  name="isShowResult"
                  className={`${isOpen ? "text-[#5E00FF]" : "text-[#E5007E]"}`}
                  onChange={() => setIsOpen(false)}
                  checked={!isOpen}
                />
                <label className="text-sm text-gray-50" htmlFor="notShowResult">
                  非公開
                </label>
              </div>
            </div>
            <div className="mx-auto mt-4 w-11/12 space-y-6 text-white lg:flex lg:w-full lg:gap-x-8 lg:space-y-0">
              <div className="flex w-1/2 justify-center">
                <button
                  className="mx-auto w-full rounded-full bg-[#C3C3C4] px-6 py-2 text-white lg:w-fit lg:py-1"
                  onClick={() => setStep(1)}
                >
                  戻る
                </button>
              </div>
              {/* NOTE: linkは特定のドメインを指定するか？ 
                      Imageを変更しない場合の処理を考える必要がある。
            */}
              <div className="flex w-1/2 justify-center">
                <button
                  className="w-full rounded-full bg-unyte px-6 py-2 text-white lg:mr-8 lg:w-fit lg:py-1"
                  onClick={() => {
                    editDaoHandler();
                  }}
                >
                  更新
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isAdminListOpen && (
        <div className="fixed inset-0 z-40">
          <div className="flex h-screen items-center justify-center">
            <div
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              onClick={() => {
                setIsAdminListOpen(false);
              }}
            ></div>
            <div className="w-11/12 max-w-xl transform overflow-hidden rounded-lg bg-[#28282D] bg-unyte p-px text-left text-white shadow-xl transition-all md:w-1/2">
              <div className="h-full w-full rounded-lg bg-[#28282D] px-4 pb-4 pt-5">
                <div className="flex justify-end">
                  <button
                    onClick={() => {
                      setIsAdminListOpen(false);
                    }}
                  >
                    ×
                  </button>
                </div>
                <div className="mx-auto w-11/12 pb-14">
                  <div className="flex flex-col">
                    <div className="w-full">
                      <h3 className="text-xl font-medium leading-6 text-gray-900 text-white"></h3>
                      <div className="mb-4 mt-2">
                        <p className="text-center text-base text-gray-500 text-white">
                          <FormattedMessage
                            id="dao_settings_add_admin"
                            defaultMessage="管理者を追加したい場合は、ウォレットアドレスを入力して下さい。"
                          />
                        </p>
                      </div>
                      <div className="my-3 flex h-10 w-full justify-between text-xs font-extralight">
                        <UniSwaTextField
                          maxLength={42}
                          value={userAddress}
                          isCreateDao={true}
                          onChange={(e) => {
                            setUserAddress(e.target.value);
                            setUserAddressHandler(e.target.value);
                          }}
                        />
                      </div>
                      {tmpUser && (
                        <button
                          className="custom-label text-main mx-auto mt-8 flex w-10/12 items-center justify-start break-all rounded rounded-full border px-2 py-1 lg:w-7/12"
                          onClick={() => {
                            setConfirmedValues((prev) => {
                              return [...prev, tmpUser.address];
                            });
                            setUserAddress("");
                            setTmpUser(null);
                          }}
                        >
                          <div className="flex h-6 w-2/12 w-6 items-center rounded-full">
                            <img
                              className="h-5 w-5"
                              src={tmpUser.img ? tmpUser.img : UnyteSquare}
                              alt="profile icon"
                            />
                          </div>
                          <div className="mx-auto w-10/12">
                            <p className="ml-2 text-white">
                              {tmpUser.name
                                ? tmpUser.name
                                : formatAddress(tmpUser.address)}
                            </p>
                          </div>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Loading {...inProgress} />
          {inProgress.isShow && inProgress.message === "DAO情報を更新中..." && (
            <div className="fixed inset-0 z-40 bg-gray-500 opacity-75"></div>
          )}
        </div>
      )}
    </>
  );
};

export default DAOInfoSetting;
