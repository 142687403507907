import { useIsUpdating } from "src/hooks/useIsUpdating";
import { restApi } from "src/libs/restClient";
import { MarketControllerFetchItemsItemStatusEnum } from "src/types/api";
import useSWR from "swr";

const fetcher = async (
  daoId: string,
  itemStatus: Array<MarketControllerFetchItemsItemStatusEnum>
) => {
  if (itemStatus.length === 0) return { items: [] };
  return restApi.marketControllerFetchItems({
    daoId: daoId,
    itemStatus: itemStatus,
  });
};

export const useItems = (
  daoId: string,
  itemStatus: Array<MarketControllerFetchItemsItemStatusEnum>
) => {
  const {
    data,
    error,
    isLoading,
    mutate: mutateItems,
  } = useSWR(
    [`/items/${daoId}?itemStatus=${itemStatus}`, daoId, itemStatus],
    ([_, daoId, itemStatus]) => fetcher(daoId, itemStatus)
  );
  const { isUpdating, checkUpdated } = useIsUpdating({
    data,
    mutate: mutateItems,
  });

  const mutate = async () => {
    checkUpdated();
    await mutateItems();
  };

  return {
    items: data?.items ?? [],
    isError: error,
    isLoading,
    isUpdating,
    mutate,
  };
};
