import React, { useEffect, useState } from "react";
import { Card, CardContent, CardFooter } from "src/components/shadcn/card";
import { Checkbox } from "src/components/shadcn/checkbox";
import {
  Table,
  TableHeader,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
} from "src/components/shadcn/table";
import {
  Avatar,
  AvatarImage,
  AvatarFallback,
} from "src/components/shadcn/avatar";
import { Button } from "src/components/shadcn/button";
import {
  ApplicationsDto,
  ApplicationsDtoStatusEnum,
  ApplicationsResponseDto,
  CheckContractStatusDto,
  CheckContractStatusDtoStatusEnum,
} from "src/types/api";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "src/components/shadcn/tabs";
import { Badge } from "src/components/shadcn/badge";
import { cn } from "src/lib/utils";
import { toast, Toaster } from "sonner";
import { Loader2 } from "lucide-react";
import { BASE_BACK_END_URL } from "src/config/config.ts";
import { Input } from "src/components/shadcn/input";
import { Label } from "src/components/shadcn/label";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "src/components/shadcn/dialog";

interface Props {
  daoId: string;
}

enum ApplicationStatusAll {
  ALL = "all",
}

type ApplicationStatus = ApplicationsDtoStatusEnum | ApplicationStatusAll;

// ApplicationsDtoStatusEnumからPENDINGを除いたenumを作成
type ApplicationChangeable = Exclude<ApplicationsDtoStatusEnum, "PENDING">;

const statusColors = {
  [ApplicationsDtoStatusEnum.Pending]: "bg-yellow-100 text-yellow-900",
  [ApplicationsDtoStatusEnum.Approved]: "bg-green-100 text-green-900",
  [ApplicationsDtoStatusEnum.Rejected]: "bg-red-100 text-red-900",
  ["ALREADY_REJECTED"]: "bg-black text-white hover:text-white",
};

const statusLabels = {
  [ApplicationsDtoStatusEnum.Pending]: "承認待ち",
  [ApplicationsDtoStatusEnum.Approved]: "承認済み",
  [ApplicationsDtoStatusEnum.Rejected]: "棄却済み",
  ["ALREADY_REJECTED"]: "過去に棄却済み",
};

const AdminApplicationsList: React.FC<Props> = ({ daoId }) => {
  const [contractInfo, setContractInfo] =
    useState<CheckContractStatusDtoStatusEnum>();
  const [applications, setApplications] = useState<ApplicationsDto[] | null>(
    []
  );
  const [rejectedUsers, setRejectedUsers] = useState<Set<string>>(new Set());
  const [selectedApplications, setSelectedApplications] = useState<Set<string>>(
    new Set()
  );
  const [currentTab, setCurrentTab] = useState<ApplicationStatus>(
    ApplicationsDtoStatusEnum.Pending
  );
  const [isLoading, setIsLoading] = useState(false);
  const [explanation, setExplanation] = useState<string>("");
  const [isUpdatingExplanation, setIsUpdatingExplanation] = useState(false);
  const [isDialogWindowOpen, setIsDialogWindowOpen] = useState(false);
  const [isApproveButtonClicked, setIsApproveButtonClicked] = useState(false);

  const isUnyte = contractInfo === CheckContractStatusDtoStatusEnum.Unyte;

  const fetchContractInfo = async () => {
    const response = await fetch(
      `${BASE_BACK_END_URL}/applications/check/${daoId}`
    );
    const data: CheckContractStatusDto = await response.json();
    setContractInfo(data.status);
  };

  const fetchApplications = async () => {
    const response = await fetch(`${BASE_BACK_END_URL}/applications/${daoId}`);
    const data: ApplicationsResponseDto = await response.json();
    if (data.applications.length === 0) {
      setApplications(null);
    } else {
      setApplications(data.applications);
    }
    setExplanation(data.explanation);

    if (data.applications.length > 0) {
      for (const application of data.applications) {
        if (application.status === ApplicationsDtoStatusEnum.Rejected)
          rejectedUsers.add(application.address);
      }
    }
  };

  const handleSelectAll = (checked: boolean, status: ApplicationStatus) => {
    if (checked && applications !== null) {
      const newSelected = new Set<string>();
      applications.forEach((app) => {
        if (app.status === status) {
          newSelected.add(app.uid);
        }
      });
      setSelectedApplications(newSelected);
    } else {
      setSelectedApplications(new Set<string>());
    }
  };

  const handleSelectOne = (checked: boolean, applicationId: string) => {
    const newSelected = new Set(selectedApplications);
    if (checked) {
      newSelected.add(applicationId);
    } else {
      newSelected.delete(applicationId);
    }
    setSelectedApplications(newSelected);
  };

  const handleTabChange = (newTab: ApplicationStatus) => {
    setCurrentTab(newTab);
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(
      () => {
        toast.success("アドレスをコピーしました", {
          position: "top-center",
          duration: 2000,
        });
      },
      (err) => {
        console.error("Could not copy text: ", err);
        toast.error("アドレスのコピーに失敗しました", {
          position: "top-center",
          duration: 2000,
        });
      }
    );
  };

  useEffect(() => {
    setSelectedApplications(new Set<string>());
  }, [currentTab]);

  const handleDialogWindow = () => {
    setIsDialogWindowOpen(!isDialogWindowOpen);
  };

  const handleStatusChange = async (status: ApplicationChangeable) => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${BASE_BACK_END_URL}/applications/${daoId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            applications: Array.from(selectedApplications),
            status: status,
          }),
        }
      );
      if (!response.ok) {
        throw new Error("Failed to update status");
      }
      const result = await response.json();

      if (result?.status === "success") {
        await fetchApplications();
        setSelectedApplications(new Set<string>());
        toast.success("すべての申請を更新しました", {
          position: "top-center",
          duration: 2000,
        });
      } else {
        throw new Error("Failed to update status");
      }
    } catch (error) {
      await fetchApplications();
      setSelectedApplications(new Set<string>());
      toast.error("申請の更新に失敗したものがあります", {
        position: "top-center",
        duration: 2000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleExplanationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setExplanation(e.target.value);
  };

  const updateExplanation = async () => {
    setIsUpdatingExplanation(true);
    try {
      const response = await fetch(
        `${BASE_BACK_END_URL}/applications/explanation/${daoId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ explanation: explanation }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update explanation");
      }

      toast.success("説明文を更新しました", {
        position: "top-center",
        duration: 2000,
      });
    } catch (error) {
      console.error("Error updating explanation:", error);
      toast.error("説明文の更新に失敗しました", {
        position: "top-center",
        duration: 2000,
      });
    } finally {
      setIsUpdatingExplanation(false);
    }
  };

  const renderButton = (
    label: string,
    status: ApplicationChangeable,
    variant: "default" | "destructive" = "default",
    className?: string
  ) => {
    return (
      <Button
        variant={variant}
        className={className}
        size="sm"
        onClick={() => {
          if (status === ApplicationsDtoStatusEnum.Approved) {
            setIsApproveButtonClicked(true);
            handleDialogWindow();
          } else {
            handleStatusChange(status);
          }
        }}
        disabled={isLoading || selectedApplications.size === 0}
      >
        {isLoading ? (
          <>
            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
            更新中...
          </>
        ) : (
          label
        )}
      </Button>
    );
  };

  useEffect(() => {
    fetchContractInfo();
    fetchApplications();
  }, [daoId]);

  const renderApplicationCard = (
    application: ApplicationsDto,
    isPendingTab: boolean,
    isUnyte: boolean
  ) => (
    <Card key={application.uid} className="mb-4 border-gray-400 bg-unyte-main">
      <CardContent className="pt-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-4">
            <Checkbox
              id={`mobile-${application.uid}`}
              checked={selectedApplications.has(application.uid)}
              onCheckedChange={(checked) =>
                handleSelectOne(checked as boolean, application.uid)
              }
              disabled={!isPendingTab}
              className="bg-white"
            />
            <Avatar className="h-10 w-10 text-gray-100">
              <AvatarImage
                src={application.img}
                alt={`${application.name}'s avatar`}
              />
              <AvatarFallback>{application.name[0]}</AvatarFallback>
            </Avatar>
            <div>
              <p className="font-medium text-gray-100">{application.name}</p>
              <Badge
                className={cn(
                  "mt-1",
                  statusColors[application.status],
                  `hover:bg-[${statusColors[application.status]}]`,
                  "sm:text-xs"
                )}
              >
                {statusLabels[application.status]}
              </Badge>
              {isPendingTab && rejectedUsers.has(application.address) && (
                <Badge
                  className={cn(
                    "ml-2 mt-1",
                    statusColors["ALREADY_REJECTED"],
                    `hover:bg-[${statusColors["ALREADY_REJECTED"]}]`,
                    "sm:text-xs"
                  )}
                >
                  {statusLabels["ALREADY_REJECTED"]}
                </Badge>
              )}
            </div>
          </div>
        </div>
        <p className="mt-2 break-all text-sm text-gray-100">
          {application.message}
        </p>
      </CardContent>
    </Card>
  );

  const renderContent = (
    applications: ApplicationsDto[] | null,
    status: ApplicationStatus
  ) => {
    if (applications === null) return null;
    const filteredApplications =
      status === ApplicationStatusAll.ALL
        ? applications
        : applications.filter((app) => app.status === status);

    const isAllSelected =
      filteredApplications.length === 0
        ? false
        : filteredApplications.every((app) =>
            selectedApplications.has(app.uid)
          );

    const isPendingTab = status === ApplicationsDtoStatusEnum.Pending;
    const isUnyte = contractInfo === CheckContractStatusDtoStatusEnum.Unyte;

    return (
      <TabsContent value={status}>
        <Card className="border-0 bg-unyte-main pt-5">
          <CardContent>
            {/* Mobile view */}
            <div className="md:hidden">
              {filteredApplications.map((application) =>
                renderApplicationCard(application, isPendingTab, isUnyte)
              )}
            </div>

            {/* Desktop view */}
            <div className="hidden overflow-x-auto md:block">
              <Table>
                <TableHeader>
                  <TableRow className="hover:bg-gray-700/50">
                    <TableHead className="w-1/12">
                      <Checkbox
                        id={`select-all-header-${status}`}
                        checked={isAllSelected}
                        onCheckedChange={(checked) =>
                          handleSelectAll(checked as boolean, status)
                        }
                        disabled={!isPendingTab}
                        className="bg-white"
                      />
                    </TableHead>
                    <TableHead className="w-2/12 text-gray-100 sm:text-sm">
                      ユーザー
                    </TableHead>
                    <TableHead className="w-2/12 text-gray-100 sm:text-sm">
                      ステータス
                    </TableHead>
                    <TableHead className="w-7/12 text-gray-100 sm:text-sm">
                      メッセージ
                    </TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {filteredApplications?.length > 0 &&
                    filteredApplications.map((application, index) => (
                      <TableRow
                        key={index}
                        onClick={() => {
                          if (isPendingTab && isUnyte) {
                            handleSelectOne(
                              !selectedApplications.has(application.uid),
                              application.uid
                            );
                          }
                        }}
                        className={cn(
                          "cursor-pointer",
                          !isPendingTab || !isUnyte
                            ? "hover:bg-gray-700/50"
                            : ""
                        )}
                      >
                        <TableCell className="align-middle">
                          <Checkbox
                            id={application.uid}
                            checked={selectedApplications.has(application.uid)}
                            onCheckedChange={(checked) =>
                              handleSelectOne(
                                checked as boolean,
                                application.uid
                              )
                            }
                            disabled={!isPendingTab}
                            className="bg-white"
                          />
                        </TableCell>
                        <TableCell
                          className="align-middle"
                          onClick={() =>
                            isPendingTab && !isUnyte
                              ? copyToClipboard(application.address)
                              : null
                          }
                        >
                          <div className="flex flex-col items-center gap-3 sm:flex-row">
                            <Avatar className="h-8 w-8 text-gray-100">
                              <AvatarImage
                                src={application.img}
                                alt={`${application.name}'s avatar`}
                              />
                              <AvatarFallback>
                                {application.name[0]}
                              </AvatarFallback>
                            </Avatar>
                            <div className="t-2 grid gap-0.5 sm:mt-0">
                              <div className="font-medium text-gray-100">
                                {application.name}
                              </div>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell
                          className="align-middle"
                          onClick={() =>
                            isPendingTab && !isUnyte
                              ? copyToClipboard(application.address)
                              : null
                          }
                        >
                          <div className="flex flex-col items-center gap-3 sm:flex-row">
                            <Badge
                              className={cn(
                                "font-semibold",
                                statusColors[application.status],
                                `hover:bg-[${
                                  statusColors[application.status]
                                }]`,
                                "sm:text-xs"
                              )}
                            >
                              {statusLabels[application.status]}
                            </Badge>
                            {currentTab === ApplicationsDtoStatusEnum.Pending &&
                            rejectedUsers.has(application.address) ? (
                              <Badge
                                className={cn(
                                  "font-semibold",
                                  statusColors["ALREADY_REJECTED"],
                                  `hover:bg-[${statusColors["ALREADY_REJECTED"]}]`,
                                  "sm:text-xs"
                                )}
                              >
                                {statusLabels["ALREADY_REJECTED"]}
                              </Badge>
                            ) : null}
                          </div>
                        </TableCell>
                        <TableCell
                          className="align-middle"
                          onClick={() =>
                            isPendingTab && !isUnyte
                              ? copyToClipboard(application.address)
                              : null
                          }
                        >
                          <div className="max-w-xs break-all text-sm text-gray-100">
                            {application.message}
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </div>
          </CardContent>
          {!isPendingTab || filteredApplications.length === 0 ? null : (
            <CardFooter className="flex justify-end gap-2">
              {renderButton(
                "承認する",
                ApplicationsDtoStatusEnum.Approved,
                undefined,
                "bg-blue-500 hover:bg-blue-500/90"
              )}
              {renderButton(
                "棄却する",
                ApplicationsDtoStatusEnum.Rejected,
                "destructive"
              )}
            </CardFooter>
          )}
        </Card>
      </TabsContent>
    );
  };

  return (
    <div className="mx-auto w-11/12 py-3">
      <Tabs
        defaultValue={ApplicationsDtoStatusEnum.Pending}
        className="w-full pt-1"
        onValueChange={(value) => handleTabChange(value as ApplicationStatus)}
      >
        <TabsList className="grid w-full grid-cols-4 bg-gray-700">
          <TabsTrigger value={ApplicationsDtoStatusEnum.Pending}>
            未承認
          </TabsTrigger>
          <TabsTrigger value={ApplicationsDtoStatusEnum.Approved}>
            承認済み
          </TabsTrigger>
          <TabsTrigger value={ApplicationsDtoStatusEnum.Rejected}>
            棄却済み
          </TabsTrigger>
          <TabsTrigger value={ApplicationStatusAll.ALL}>全て</TabsTrigger>
        </TabsList>
        {renderContent(applications, ApplicationsDtoStatusEnum.Pending)}
        {renderContent(applications, ApplicationsDtoStatusEnum.Approved)}
        {renderContent(applications, ApplicationsDtoStatusEnum.Rejected)}
        {renderContent(applications, ApplicationStatusAll.ALL)}
      </Tabs>
      <div className="flex flex-col gap-1 space-y-1 p-3">
        <Label>申請フォーム自由記入欄の説明文</Label>
        <div className="flex gap-2">
          <Input
            className="w-full rounded text-black"
            value={explanation}
            onChange={handleExplanationChange}
          />
          <Button
            className="bg-unyte"
            onClick={updateExplanation}
            disabled={isUpdatingExplanation}
          >
            {isUpdatingExplanation ? (
              <>
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                更新中...
              </>
            ) : (
              "更新する"
            )}
          </Button>
        </div>
      </div>
      {isDialogWindowOpen && isApproveButtonClicked ? (
        <Dialog open={isDialogWindowOpen} onOpenChange={setIsDialogWindowOpen}>
          <DialogContent className="w-11/12 rounded-md border-2 border-pink-600 bg-unyte-main text-gray-50 sm:max-w-[425px]">
            <DialogHeader>
              <DialogTitle>
                {isUnyte
                  ? "トークンを送付しますか？"
                  : "トークンの送付は済みましたか？"}
              </DialogTitle>
              <DialogDescription className="text-gray-400">
                {isUnyte
                  ? "「送付する」をクリックすると、申請者にトークンが送付されます。"
                  : "承認をするを押しても自動でトークンは送付されません。OpenSeaなどで申請者にトークンを送る必要があります。"}
              </DialogDescription>
            </DialogHeader>
            <DialogFooter className="w-full">
              <Button
                size={"sm"}
                className="group relative overflow-hidden rounded bg-gradient-to-r from-[#ff1493] to-[#ff7f50] text-gray-50 transition-all duration-300 hover:font-bold hover:text-white"
                onClick={() => {
                  setIsDialogWindowOpen(false);
                  handleStatusChange(ApplicationsDtoStatusEnum.Approved);
                }}
              >
                {isUnyte ? "送付する" : "はい、送付しました"}
              </Button>
              <Button
                size={"sm"}
                className="bg-gray-400 hover:font-bold"
                onClick={() => setIsDialogWindowOpen(false)}
              >
                {isUnyte ? "戻る" : "いいえ、送付していません"}
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      ) : null}
      <Toaster />
    </div>
  );
};

export default AdminApplicationsList;
