import { atom } from "jotai";
import { Progress } from "../types/common";

export type InProgressMarketState = Progress;

/**
 * マーケット処理の状態
 */
export const inProgressMarketState = atom<InProgressMarketState>({
  isShow: false,
  message: "",
});
