import React, { ReactNode } from "react";

type Props = {
  children: ReactNode;
  title: string;
};

const SectionBox = (props: Props) => {
  return (
    <div className="m-8 flex-col rounded-md border border-solid border-white p-4 text-white">
      <div className="mb-2 text-base lg:mb-4">{props.title}</div>
      <div className="flex flex-col">{props.children}</div>
    </div>
  );
};

export default SectionBox;
