import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { signUpWithEmail } from "../../actions/firebase/login";
import { FormattedMessage } from "react-intl";
import { validateEmail } from "../../utils/validateEmail.ts";
import { register } from "src/actions/firebase/utils.js";

const EmailSignUp: React.FC = () => {
  const navigate = useNavigate();
  const [auth, setAuth] = useState({
    email: "",
    password: "",
    passwordConfirmation: "",
  });
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const signUp = async () => {
    if (!isValid()) return;
    try {
      const result = await signUpWithEmail(auth.email, auth.password);
      if (!result[0]) {
        if (result[0] !== undefined && result[1] === "auth/invalid-email") {
          setEmailError("不正なEmailです。");
        } else if (
          result[0] !== undefined &&
          result[1] === "auth/email-already-in-use"
        ) {
          setEmailError("このEmailは既に登録されています。");
        } else if (
          result[0] !== undefined &&
          result[1] === "auth/weak-password"
        ) {
          setEmailError("パスワードは6文字以上に設定してください。");
        } else {
          setEmailError("不明なエラーです。運営にお問い合わせください。");
        }
        return;
      }
      navigate("/register", {
        state: {
          user: result[1].uid,
          email: result[1].email,
          share: {
            user: result[2].userShare,
            local: result[2].localStorageShare,
            unyte: result[2].unyteShare,
          },
          register: true,
        },
      });
    } catch (error) {
      console.error(error);
      alert("エラーが発生しました。");
      window.location.reload();
    }
  };

  const isValid = () => {
    setEmailError("");
    setPasswordError("");
    const validEmail = isValidEmail();
    const validPassword = isValidPassword();
    return validEmail && validPassword;
  };

  const isValidEmail = () => {
    if (auth.email === "" || !validateEmail(auth.email)) {
      setEmailError("Emailを入力してください。");
      return false;
    }
    return true;
  };

  const isValidPassword = () => {
    if (auth.password === "" || auth.passwordConfirmation === "") {
      setPasswordError("パスワードを入力してください。");
      return false;
    }
    if (auth.password !== auth.passwordConfirmation) {
      setPasswordError("パスワードが一致しません。");
      return false;
    }
    return true;
  };

  return (
    <div className="flex h-screen items-center justify-center bg-gray-200">
      <div className="mx-4 w-96 rounded-lg bg-white p-8 shadow-md">
        <h1 className="mb-5 text-2xl font-bold">
          <FormattedMessage
            id="email_signup_title"
            defaultMessage="Emailサインアップ"
          />
        </h1>
        {emailError && <p className="mb-1 text-red-500">{emailError}</p>}
        <input
          type="email"
          placeholder="Email"
          className="mb-4 w-full rounded-md border border-gray-300 p-3 shadow-sm"
          onChange={(e) => {
            setAuth({ ...auth, email: e.target.value });
          }}
        />
        {passwordError && <p className="mb-1 text-red-500">{passwordError}</p>}
        <input
          type="password"
          placeholder="Password"
          className="mb-4 w-full rounded-md border border-gray-300 p-3 shadow-sm"
          onChange={(e) => {
            setAuth({ ...auth, password: e.target.value });
          }}
        />
        <input
          type="password"
          placeholder="Password Confirmation"
          className="mb-4 w-full rounded-md border border-gray-300 p-3 shadow-sm"
          onChange={(e) => {
            setAuth({ ...auth, passwordConfirmation: e.target.value });
          }}
        />
        <button
          className="w-full rounded-md bg-blue-600 p-3 text-white hover:bg-blue-700"
          onClick={() => {
            signUp();
          }}
        >
          <FormattedMessage
            id="email_signup_button"
            defaultMessage="サインアップ"
          />
        </button>
        <hr className="my-8 h-px border-0 bg-gray-200 dark:bg-gray-700"></hr>
        <button
          className="w-full rounded-md bg-white p-3 text-blue-600 hover:bg-gray-100"
          onClick={() => navigate("/login-with-email")}
        >
          <FormattedMessage
            id="email_signup_exit"
            defaultMessage="Emailでログイン"
          />
        </button>
        <button
          className="w-full rounded-md bg-white p-3 text-blue-600 hover:bg-gray-100"
          onClick={() => navigate("/login")}
        >
          <FormattedMessage
            id="email_signup_back_sso"
            defaultMessage="SSOログインに戻る"
          />
        </button>
      </div>
    </div>
  );
};

export default EmailSignUp;
