import React, { useState } from "react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { SelectablePeriod, selectablePeriods } from "src/pages/thanks/Thanks";
import { BsThreeDots } from "react-icons/bs";
import { User } from "src/types/common";
import unyteSquare from "../../assets/unyte_square.png";
import userIcon from "../../assets/userIcon.svg";
import { FaArrowRightArrowLeft } from "react-icons/fa6";
import { FormattedMessage } from "react-intl";

type FilterProps = {
  user: User | null | undefined;
  daoIcon: any;
  filterAddr: string | undefined;
  setFilterAddr: (addr: string | undefined) => void;
  searchQuery: string;
  handleSearchQueryChanged: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  selectedPeriod: SelectablePeriod;
  setSelectedPeriod: (selected: SelectablePeriod) => void;
};

type DAOUserProps = {
  img: React.ImgHTMLAttributes<HTMLImageElement>;
  button: React.ImgHTMLAttributes<HTMLButtonElement>;
};

const ThanksFilter = ({
  user,
  daoIcon,
  filterAddr,
  setFilterAddr,
  searchQuery,
  handleSearchQueryChanged,
  selectedPeriod,
  setSelectedPeriod,
}: FilterProps) => {
  // dao or user filter selector settings
  const [isPeriodTabOpen, setIsPeriodTabOpen] = useState(false);
  const daoProps = {
    img: {
      src: daoIcon,
      alt: "dao icon",
      onError: (e: React.SyntheticEvent<HTMLImageElement>) => {
        e.currentTarget.onerror = null;
        e.currentTarget.src = userIcon;
      },
    },
    button: {
      onClick: () =>
        filterAddr !== undefined
          ? setFilterAddr(undefined)
          : setFilterAddr(user?.address),
    },
  };

  const userProps = {
    img: {
      src: user?.img ?? unyteSquare,
      alt: "user icon",
      onError: (e: React.SyntheticEvent<HTMLImageElement>) => {
        e.currentTarget.onerror = null;
        e.currentTarget.src = userIcon;
      },
    },
    button: {
      onClick: () =>
        user?.address && filterAddr === undefined
          ? setFilterAddr(user.address)
          : setFilterAddr(undefined),
    },
  };

  const daoUserProps: [DAOUserProps, DAOUserProps] =
    filterAddr === undefined ? [daoProps, userProps] : [userProps, daoProps];

  const handlePeriodChange = (period: SelectablePeriod) => () =>
    selectedPeriod !== period ? setSelectedPeriod(period) : undefined;

  return (
    <div className="mt-2 flex h-min w-full flex-col ">
      <div className=" flex h-4/5 w-full flex-row">
        <div className="relative w-1/4">
          <button
            className="absolute -top-12 left-4 h-20 w-20 rounded-full bg-gray-300 lg:-top-12 lg:h-24 lg:w-24 xl:-top-20 xl:h-32 xl:w-32"
            {...daoUserProps[0].button}
          >
            <img
              {...daoUserProps[0].img}
              className="h-20 w-20 rounded-full lg:h-24 lg:w-24 xl:-top-20 xl:h-32 xl:w-32"
            />
          </button>
          <button
            className="relative left-24 top-0 text-white lg:left-32 xl:left-40"
            onClick={() => {
              filterAddr !== undefined
                ? setFilterAddr(undefined)
                : setFilterAddr(user?.address);
            }}
          >
            <FaArrowRightArrowLeft className="h-6 w-6" />
          </button>
          <button
            className="relative left-28 h-8 w-8 rounded-full lg:left-40 lg:h-12
            lg:w-12 xl:left-48 xl:h-16 xl:w-16"
            {...daoUserProps[1].button}
          >
            <img
              {...daoUserProps[1].img}
              className="h-8 w-8 rounded-full lg:h-12 lg:w-12 xl:h-16 xl:w-16"
            />
          </button>
        </div>
        {/** mobileでの表示 */}
        <div className="flex w-full justify-end lg:hidden">
          <button
            className="mr-4 text-white"
            onClick={() => setIsPeriodTabOpen(!isPeriodTabOpen)}
          >
            <BsThreeDots className="text-xl font-bold" />
          </button>
        </div>
        {isPeriodTabOpen && (
          <div className="relative lg:hidden">
            <div className="absolute -top-48 right-0 z-50 mt-2 w-48 rounded-md bg-gray-800 bg-white shadow-lg ring-1 ring-black ring-opacity-5">
              {selectablePeriods.map((period, idx) => (
                <button
                  key={idx}
                  onClick={() => {
                    handlePeriodChange(period)();
                    setIsPeriodTabOpen(false);
                  }}
                  className={
                    "block w-full bg-gray-800 px-4 py-2 text-left text-sm leading-5 text-gray-200 hover:bg-gray-100 focus:bg-gray-100 focus:outline-none" +
                    (selectedPeriod === period
                      ? " font-bold text-gray-100"
                      : "")
                  }
                >
                  <FormattedMessage
                    id={`thanks_period_${period}`}
                    defaultMessage={period}
                  />
                </button>
              ))}
            </div>
            <div
              className="fixed inset-0 z-10"
              onClick={() => setIsPeriodTabOpen(false)}
            ></div>
          </div>
        )}
        {/** pcでの表示 */}
        <div className="ml-auto hidden w-3/4 grid-cols-5 divide-x divide-transparent lg:block lg:grid">
          {selectablePeriods.map((period, idx) => (
            <div
              className="mx-auto flex h-full w-full items-center justify-center"
              key={idx}
            >
              <button
                onClick={handlePeriodChange(period)}
                className={
                  "h-1/2 text-center align-middle leading-8" +
                  (selectedPeriod === period
                    ? " font-bold text-gray-100"
                    : " text-gray-400")
                }
              >
                <FormattedMessage
                  id={`thanks_period_${period}`}
                  defaultMessage={period}
                />
              </button>
            </div>
          ))}
        </div>
      </div>
      <div className="mt-4 flex h-1/5 w-4/5 min-w-fit items-center justify-between gap-x-4">
        <div className="flex h-12 flex-grow items-center justify-between bg-[#121217]">
          <label htmlFor="search" className="sr-only">
            Search
          </label>
          <div className="relative flex w-full">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3">
              <MagnifyingGlassIcon
                className="h-5 w-5 text-gray-50"
                aria-hidden="true"
              />
            </div>
            <input
              id="search"
              name="search"
              className="block w-full rounded-full border-0 bg-[#121217] py-1.5 pl-10 pr-3 text-gray-50 ring-1 ring-inset ring-gray-800 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"
              type="search"
              value={searchQuery}
              onChange={handleSearchQueryChanged}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThanksFilter;
