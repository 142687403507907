import React, { useEffect, useRef } from "react";
import "../styles/ScrollDiv.css";

interface ScrollDivProps extends React.HTMLProps<HTMLDivElement> {
  scrollSpeed?: number;
}

const ScrollDiv: React.FC<ScrollDivProps> = ({
  children,
  scrollSpeed = 50,
  ...props
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const scroll = () => {
    if (!ref.current) return;

    const prevPos = ref.current.scrollLeft;
    ref.current.scrollLeft += 1;

    if (prevPos === ref.current.scrollLeft) {
      setTimeout(() => {
        if (ref.current) {
          ref.current.scrollLeft = 0;
          setTimeout(scroll, 3000);
        }
      }, 1000);
    } else {
      setTimeout(scroll, scrollSpeed);
    }
  };
  useEffect(() => {
    scroll();
  }, []);
  return (
    <div
      ref={ref}
      {...props}
      className={`hidden-scrollbar overflow-y-scroll whitespace-nowrap ${
        props.className || ""
      }`}
    >
      {children}
    </div>
  );
};

export default ScrollDiv;
