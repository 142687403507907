import { ethers } from "ethers";
import { VoteData } from "../../types/vote";
import * as voteLib from "../../actions/secretVote/vote-lib.js";
import { fetchPrivateKey } from "../../actions/firebase/utils.js";
import { NETWORK_ERROR_MSG, STAGE, VOTE_ENDPOINT } from "../../const/const.ts";
import { Voter } from "src/types/api/index.ts";
import { fireauth } from "src/actions/firebase/config.js";

const contractAddress = process.env.REACT_APP_VOTE_CONTRACT_ADDRESS;
const provider = new ethers.JsonRpcProvider(
  process.env.REACT_APP_ALCHEMY_ENDPOINT
);
await voteLib.init();

const abi = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "voter",
        type: "address",
      },
      {
        indexed: true,
        internalType: "bytes32",
        name: "daoId",
        type: "bytes32",
      },
      {
        indexed: true,
        internalType: "bytes32",
        name: "voteId",
        type: "bytes32",
      },
      { indexed: false, internalType: "string", name: "vote", type: "string" },
    ],
    name: "Voted",
    type: "event",
  },
  {
    inputs: [
      { internalType: "address", name: "voter", type: "address" },
      { internalType: "string", name: "value", type: "string" },
      { internalType: "string", name: "zkp", type: "string" },
      { internalType: "string", name: "voteTime", type: "string" },
      { internalType: "bytes32", name: "hash", type: "bytes32" },
      { internalType: "bytes", name: "signature", type: "bytes" },
    ],
    name: "verify",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes32", name: "_daoId", type: "bytes32" },
      { internalType: "bytes32", name: "_voteId", type: "bytes32" },
      { internalType: "string", name: "_vote", type: "string" },
    ],
    name: "vote",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

const addVote = async (vote: {
  daoId: string;
  voteId: string;
  vote: VoteData;
}) => {
  const user = fireauth.currentUser;
  const token = user ? await user.getIdToken(true) : "";
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ type: "vote", args: vote }),
  };
  let response;
  try {
    response = await fetch(VOTE_ENDPOINT, options);
  } catch (e) {
    console.error(e);
    throw new Error(NETWORK_ERROR_MSG);
  }
  const responseJson = await response.json();
  if (responseJson.status !== "success") throw new Error(responseJson.message);
  return responseJson;
};

const getVotes = async (
  daoId?: string | null,
  proposalId?: string | null,
  address?: string | null
): Promise<({ proposalId: string; vote: VoteData } | undefined)[]> => {
  try {
    if (contractAddress) {
      const contract = new ethers.Contract(contractAddress, abi, provider);
      const filter = contract.filters.Voted(address, daoId, proposalId);
      const fromBlock = 42815066;
      const toBlock = "latest";

      const logs = await contract.queryFilter(filter, fromBlock, toBlock);
      const decoder = ethers.AbiCoder.defaultAbiCoder();
      const votes = logs
        .map((log) => {
          try {
            const data = decoder.decode(["string"], log.data);
            const proposalId = log.topics[3];
            const vote: VoteData = JSON.parse(data.toString());
            vote.url =
              process.env.REACT_APP_STAGE === STAGE.MAIN ||
              process.env.REACT_APP_STAGE === STAGE.DEMO
                ? `https://polygonscan.com/tx/${log.transactionHash}`
                : `https://amoy.polygonscan.com/tx/${log.transactionHash}`;
            return { proposalId: proposalId, vote: vote };
          } catch (e) {
            console.error((e as Error).message);
            return undefined;
          }
        })
        .filter(Boolean);

      const uniqueVotes = Array.from(
        new Map(
          votes.map((vote) => [`${vote!.proposalId}${vote!.vote.voter}`, vote])
        ).values()
      );
      return uniqueVotes.reverse();
    } else {
      throw new Error("Config Error");
    }
  } catch (error) {
    await new Promise((resolve) => setTimeout(resolve, 1250));
    return getVotes(daoId, proposalId, address);
  }
};

const getVoteResultByUser = async (data: {
  daoId: string;
  proposalId: string;
  publicKey: string;
  userAddress: string;
  votes: Voter[];
}): Promise<string> => {
  const result = data.votes.find((v) => v.address === data.userAddress);
  if (result) {
    const keyInfo = await fetchPrivateKey(data.proposalId);
    const value = voteLib.verify([result], data.publicKey);
    const calcValue = voteLib.result(value, keyInfo!.privateKey);
    if (calcValue.favor) {
      return "favor";
    } else if (calcValue.against) {
      return "against";
    } else {
      return "abstention";
    }
  }
  return "none";
};

const getVoteResult = async (data: {
  proposalId: string;
  publicKey: string;
  votes: Voter[];
}): Promise<boolean> => {
  const keyInfo = await fetchPrivateKey(data.proposalId);
  const value = voteLib.verify(data.votes, data.publicKey);
  const calcValue = voteLib.result(value, keyInfo!.privateKey);
  return calcValue.favor > calcValue.against;
};

const getVoteResultValue = async (data: {
  daoId: string;
  proposalId: string;
  publicKey: string;
  votes: Voter[];
}): Promise<{
  publicKey: string;
  votes: Voter[];
  value: string;
  privateKey: string;
  result: { favor: string; against: string; abstention?: string };
}> => {
  const keyInfo: any = await fetchPrivateKey(data.proposalId);
  const value = voteLib.verify(data.votes, data.publicKey);
  const calcValue = voteLib.result(value, keyInfo!.privateKey);
  calcValue.abstention =
    data.votes.length - (calcValue.favor + calcValue.against);
  return {
    publicKey: data.publicKey,
    votes: data.votes,
    value: value,
    privateKey: keyInfo!.privateKey,
    result: calcValue,
  };
};

export {
  addVote,
  getVotes,
  getVoteResult,
  getVoteResultValue,
  getVoteResultByUser,
};
