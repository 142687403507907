import React from "react";
import { BaseProps } from "../../types/common/index.ts";

export type GoDaoProps = {} & BaseProps;

export const GoDao: React.FC<GoDaoProps> = ({ className }) => {
  return (
    <div className={`${className} rounded-full bg-unyte py-2 text-white`}>
      Go
    </div>
  );
};
