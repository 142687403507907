import { BASE_BACK_END_URL } from "src/config/config.ts";
import { DefaultApi } from "../types/api/apis/DefaultApi.ts";
import { Configuration, Middleware } from "../types/api/runtime.ts";
import { fireauth } from "src/actions/firebase/config.js";

const authMiddleware: Middleware = {
  async pre(context) {
    const user = fireauth.currentUser;
    if (user) {
      const uidToken = await user.getIdToken(true);
      context.init.headers = {
        ...context.init.headers,
        authorization: `Bearer ${uidToken}`,
      };
    }
    return context;
  },
};

const config = new Configuration({
  basePath: BASE_BACK_END_URL,
  middleware: [authMiddleware],
});

export const restApi = new DefaultApi(config);
