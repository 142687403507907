const {
  NETWORK_ERROR_MSG,
  CREATE_TASK_ENDPOINT,
} = require("../../const/const.ts");
import { fireauth } from "src/actions/firebase/config.js";

async function createTaskAction(owner, task) {
  const json = {
    type: "createTask",
    args: {
      owner: owner,
      task: task,
    },
  };
  const user = fireauth.currentUser;
  const token = await user.getIdToken(true);
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(json),
  };
  let response;
  try {
    response = await fetch(CREATE_TASK_ENDPOINT, options);
  } catch (e) {
    console.error(e);
    throw new Error(NETWORK_ERROR_MSG);
  }
  const responseJson = await response.json();
  if (responseJson.status !== "success") throw new Error(responseJson.message);
  return responseJson;
}
/**
 *
 * @param {*} task taskの具体データ
 * @returns NFTを発行した際の通信情報
 */
export async function createOnChainTask(task, owner) {
  if (owner == undefined) throw new Error("ログインしてください。");
  // NFT発行のためにlambdaエンドポイントを叩く
  const response = await createTaskAction(owner.address, task);
  return response;
}
