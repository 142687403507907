import { Cog8ToothIcon } from "@heroicons/react/24/outline";
import { Chip } from "@mui/material";
import React, { useState } from "react";
import { ChannelWithUnreadCount } from "src/actions/firebase/channels";
import { EditChannelModal } from "src/pages/dao/EditChannelModal";
import { getFormatedDatetime } from "src/utils/formatDatetime";

interface Props {
  daoId: string;
  channel: ChannelWithUnreadCount;
  onUpdate: () => void;
}

const AdminChannelDetail: React.FC<Props> = ({ daoId, channel, onUpdate }) => {
  const [showEditModal, setShowEditModal] = useState(false);

  return (
    <>
      <div>
        <div className="flex flex-row space-x-2">
          <div>{channel.name}</div>
          {channel.archived && <Chip label="アーカイブ済み" color="error" />}
          <button className="w-1/12" onClick={() => setShowEditModal(true)}>
            <Cog8ToothIcon className="h-4 w-4 text-white" />
          </button>
        </div>
        <div className="pl-4 text-sm text-gray-400">
          <div>{channel.description}</div>
          <div>作成日時: {getFormatedDatetime(channel.createdAt)}</div>
        </div>
      </div>
      {showEditModal && (
        <EditChannelModal
          daoId={daoId}
          channel={channel}
          onUpdate={(channel) => {
            setShowEditModal(false);
            onUpdate();
          }}
          onDelete={(channelId) => {
            setShowEditModal(false);
            onUpdate();
          }}
          onClose={() => setShowEditModal(false)}
        />
      )}
    </>
  );
};

export default AdminChannelDetail;
